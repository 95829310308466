/**
 * ModalContainer.js
 * 
 * contains a button which triggers a modal view to open, and the modal view itself
 * the modal view remains hidden until the trigger button is clicked, and handles all of the necessary styling and control aspects to present the modal view
 */


import React from 'react';
import Modal from './Modal.js';
import TriggerButton from './TriggerButton.js';

export class ModalContainer extends React.Component {
    state = {isShown: false};

    //shows the modal, which should place focus on the button to close the modal, and also stops the user from scrolling on the background component
    showModal = (event) => {
        event.preventDefault();
        this.setState({isShown: true}, () => {
            this.closeButton.focus();
            this.toggleScrollLock();
        });
    };

    //locks the user out of scrolling on the background component. this allows the user to scroll on the modal view without also scrolling aroudn in the background
    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    //closes the modal view. this is given to the child component so that the modal can close when submitted.
    closeModal = () => {
        this.setState({isShown: false});
        this.TriggerButton.focus();
        this.toggleScrollLock();
    };

    //closes the modal view if the user presses ESC
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };

    //closes the modal view if the user clicks outside the view
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        // this.closeModal();  Don't close modal ... just let it be
    };

    //renders the modal container, which is simply abutton until the user clicks and displays the modal
    render() {
        return (
            <>
                <TriggerButton 
                buttonClass={this.props.buttonClass ? this.props.buttonClass : 'btn center modal-button'}
                triggerText={this.props.triggerText}
                showModal={this.showModal}
                buttonRef={(n) => (this.TriggerButton = n)}
                />

                {this.state.isShown ? ( //conditionally displays the modal view based on the state
                <Modal
                FormComponent={this.props.FormComponent}
                formData={this.props.formData}
                submitForm={this.props.submitForm}
                modalRef={(n) => (this.modal = n)}
                buttonRef={(n) => (this.closeButton = n)}
                closeModal={this.closeModal}
                onKeyDown={this.onKeyDown}
                onClickOutside={this.onClickOutside}
                />
                ) : null}   
            </>
        );
    }
}

export default ModalContainer;