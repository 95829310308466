import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const AppraiserConversionReport = () => {
    let context = useContext(AppContext);

    let [reportType, setReportType] = useState('summary');
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);
    let [subRep, setSubRep] = useState("")
    let [subType, setSubType] = useState('organic');
    let [orderBy, setOrderBy] = useState("rep")


    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/appraiser-conversion', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({reportType: reportType, subRep: subRep, orderBy: orderBy,startDate: startDate, endDate: endDate, subType: subType})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    useEffect(() => { 
        fetchReport();
    }, [context, reportType, subRep,orderBy, startDate, endDate, subType])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>

                <div className = "bg-light rounded  dark-text">
                    <div class = "container">
                        <h1 className="text-3xl font-bold" style={{padding: '5px 10px'}}>Appraiser Conversion Report</h1>
                        <InfoButton onClick = {infoFunction}/> 
                    
                    
                    </div>
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report shows all current quotes. </p>
                        <p></p>
                        <br></br>
                        <p>Consign Only = Consign button checked AND no buy price present</p>
                        <p>Consign Recommended = Consign button checked AND buy price present</p>
                    </div>
                    
                    <div className="container" >
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            <b className="dark-text">Report Type</b>
                                <div style={{padding: '15px 15px'}}>
                                    <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail <i>(Export for full report)</i></label>
                                    <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                                </div>
                        </div>
                        <div style={{padding: '15px 10px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 30px'}}>
                            <b className="dark-text">Date Range</b>
                            <div className='container'>
                                <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                            </div>
                            <div className='container'>
                                <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                            </div>
                            <p><i>(Leave end date blank to get a one-day report)</i></p>                                       
                        </div> 
                        <div style={{padding: '15px 10px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            <div>
                                <b>Submission Type:</b> 
                            </div>
                            <div style={{padding: '15px 15px'}}>  
                                <select  name="subType" id="selectSubType" onChange={e => setSubType(e.target.value)}>
                                    <option selected value="organic" >Organic</option>
                                    <option value="sg" >Self-Generated (Manual)</option>
                                    <option value="any" >Any</option>
                                </select>
                            </div>
                        </div> 
                    </div>

                    

                    <div className="container" >
                        <div  style={{padding: '10px 10px'}}>
                            <CSVLink data={report} filename='appraiser_conversion.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                        </div>
                    </div>
                
                </div>
                


                <br/>
                


                <h2 className="light-text" style={{padding: '0px 0px 15px 0px'}}>Report at a glance:</h2>
                
                {reportType=='summary' ? <>
                <br></br>
                {/* <label className="light-text text-left" for="orderBy" style={{padding: '4px 15px'}} >Order By:      
                    <select  name="orderBy" id="selectOrderBy" onChange={e => setOrderBy(e.target.value)}>
                        <option selected value="appraised_items" >Appraised Items</option>
                        <option value="submissions" >Total Submissions</option>
                        <option  value="recieved_TPAs" >Received RMAs</option>
                        <option value="conversion_sorting_ignore" >Conversion Percentage</option>    
                    </select>
                </label> */}
                <TableList columnNames={['Appraiser','Appraised Items','Items on TPAs','Received Items','Received %','Buy/Market Ratio', 'Buy/List Ratio', 'Market/List Ratio','Consign Only Percentage', 'Consign Recommended Percentage', 'Passed Items Percentage']}
                    columnWidths={['15%','12%','12%','12%','12%','12%','12%', '12%','12%','12%','12%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.appraiser == 'Totals' ? <b>{row?.appraiser}</b> : row?.appraiser,
                                row?.appraiser == 'Totals' ? <b><>{row?.unique_appraised_items}{' ('+row?.appraised_items+')'}</></b> : <>{row?.unique_appraised_items}&nbsp;&nbsp;&nbsp;{' ('+row?.appraised_items+')'}</>,
                                row?.appraiser == 'Totals' ? <b><>{row?.unique_items_with_TPA}{' ('+row?.items_with_TPA+')'}</></b> : <>{row?.unique_items_with_TPA}&nbsp;&nbsp;&nbsp;{' ('+row?.items_with_TPA+')'}</>,
                                row?.appraiser == 'Totals' ? <b><>{row?.unique_items_received}{' ('+row?.items_received +')'}</></b> : <>{row?.unique_items_received}&nbsp;&nbsp;&nbsp;{' ('+row?.items_received +')'}</>,
                                row?.appraiser == 'Totals' ? <b>{row?.received}</b> : row?.received,
                                row?.appraiser == 'Totals' ? <b>{row?.avg_buy_market_ratio}</b> : row?.avg_buy_market_ratio,
                                row?.appraiser == 'Totals' ? <b>{row?.avg_buy_list_ratio}</b> : row?.avg_buy_list_ratio,
                                row?.appraiser == 'Totals' ? <b>{row?.avg_market_list_ratio}</b> : row?.avg_market_list_ratio,
                                row?.appraiser == 'Totals' ? <b>{row?.consign_only_percentage}</b> : row?.consign_only_percentage,
                                row?.appraiser == 'Totals' ? <b>{row?.consign_recommended_percentage}</b> : row?.consign_recommended_percentage,
                                row?.appraiser == 'Totals' ? <b>{row?.passed_item_percentage}</b> : row?.passed_item_percentage
                            ]
                        })
                    } />
                </> : <>
                <br></br>
                <label className="light-text text-left bg-" for="subRep" style={{padding: '4px 15px'}}  >Appraiser:      
                        <select  name="subRep" id="selectSubRep" onChange={e => setSubRep(e.target.value)}>
                            <option selected value="" >Select an Option</option>
                            <option value="34" >Ben Klenk</option>
                            <option  value="55" >Devin Schmidt</option>
                            <option value="10" >Ian Lahood</option>
                            <option value="60" >Jason Manning</option>
                            <option value="42" >Jerry Kall</option>
                            <option value="14" >Joshua Jackson</option>
                            <option value="36" >Mario Alonzo</option>
                            <option value="54" >Michael Horne</option>
                            <option value="25" >Michael Lowe</option>
                            <option value="26" >Nicholas Lucini</option>
                            <option value="51" >Ryan McFarlin</option>
                            <option value="44" >Ryan Tarasen</option>
                        </select>
                </label>

                <TableList columnNames={['Appraiser','Submission ID','Manufacturer','Model Number','Recommended Buy Price','Recommended List Price','Market Value','Quote Date','Sub Type']}
                    columnWidths={['8%','8%', '12%','22%','10%','10%','10%','15%','5%']}
                    entries={report?.map(row => {
                        return [
                            row?.appraiser,
                            row?.submission_id,
                            row?.manufacturer,
                            row?.model_number,
                            row?.rec_buy_price,
                            row?.rec_list_price,
                            row?.market_value,
                            row?.quote_date,
                            row?.submission_type
                            
                            
                        ]
                    })}
                />
                </>}
                <br/>
            </div>
        </div>
    );
}

export default AppraiserConversionReport;