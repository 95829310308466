import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { useAuth0 } from "@auth0/auth0-react";
import { secureFetch } from "../util/flip_utils";
import Loading from "./Loading";
import Navigation from "../components/Navigation";
import Auth0Profile from "../components/Auth0Profile";


const AuthBoundary = ({children}) => {
    let context = useContext(AppContext);
    const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    let [fetched, setFetched] = useState(false);

    // running this outside of a useEffect like this means it won't re-render children when
    // it updates and cause a bunch of useEffects downstream
    getAccessTokenSilently()
    .then(tk => {
        context.accessToken = tk;
        //get userdata (namely user_id) from backend
        secureFetch(context?.server + '/check-oauth', {
            method: 'GET',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${tk}`}
        })
        .then(resp => resp?.json())
        .then(us => {
            context.user = us;
            setFetched(true);
        })
    })
    .catch(e => {
        //re-login on certain errors
        if(e.error == 'login_required' || e.error == 'consent_required') {
            loginWithRedirect();
        }
        throw e
    })

    if(isLoading) {
        return (
            <Loading />
        )
    } else if(!fetched) {
        return <Loading/>
    }

    return (
        <div>
            <div className='container bg-darkest' style={{ margin: "0px"}} >
                <img className={"flex-none"} src="/images/tmr-logo.png" alt="The Music Room Logo"/>

                <div className={"flex-auto mx-auto w-full"}>
                    <div className='container w-full' style={{ margin: "0px" }}>
                        {
                        context?.environment == "production" ? <div className='flex-initial mx-auto'></div> :
                        <div style={{margin: 'auto'}} className='bg-orange rounded flex-initial'>
                            <p className='light-text text-center'>This is a {context?.environment} environment of FLIP</p>
                            {/* <p className='light-text text-center'>{JSON.stringify(props.app.feature_flags)}</p> */}
                        </div>
                        
                        }
                        <div className='flex-initial mr-0'>
                        {isAuthenticated ? <Auth0Profile /> : <></>}
                        </div>
                    </div>
                </div>
            </div>

            <div className={"flex min-h-screen flex-wrap flex-col"} >
                {isAuthenticated ? <Navigation/> : <></>}
                <div className={"flex-auto "}>
                    {isLoading ? <Loading/> : children}
                </div>
            </div>
        </div>
    )
}

export default AuthBoundary;