import { useContext, useState } from "react";
import AppContext from "../AppContext";


const SplitQuantityForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);

    let [item, setItem] = useState(formData);
    let [newQty, setNewQty] = useState(1);
    let [formDisable, setFormDisable] = useState(false);

    function submit(event) {
        event.preventDefault();
        setFormDisable(true);
        submitForm(newQty);
        closeModal();
    }

    if(!context?.feature_flags['split_merge_quantity']) {
        return (
            <div>
                <h2>This feature is not currently enabled on your version of FLIP</h2>
            </div>
        )
    }

    return (
        <div>
            <form id='SplitQuantityForm' onSubmit={submit} />
            <fieldset form='SplitQuantityForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <label className="dark-text text-left">New SKU Quantity:
                        <input type='number' value={newQty} onChange={e => setNewQty((e.target.value < item?.quantity && e.target.value > 0) ? e.target.value : newQty)} />
                    </label>
                    <br/>
                    <i>(this will leave {parseInt(item?.quantity) - parseInt(newQty)} left on SKU {item?.sku})</i>
                    <br/>
                    <input form='SplitQuantityForm' className='btn bg-dark light-text' type='submit' />
                
                </div>
            </fieldset>
        </div>
    );
}

export default SplitQuantityForm;