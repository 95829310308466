import { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';

/**
 * see https://github.com/sstur/react-rte for documentation
 * @param {string} text - string or html to render inside editor
 * @param {function} updateParent - essentially a onChange function for the parent to use to update its own state using the html content inside the RTE
 * @returns 
 */

const FlipRTE = ({text, updateParent}) => {
    let [value, setValue] = useState(text ? 
        RichTextEditor.createValueFromString(text, 'html') :
        RichTextEditor.createEmptyValue());

    function onChange(value) {
        setValue(value);
        updateParent({target: {value: value.toString('html')}})
    }

    return (
        <RichTextEditor
        value={value}
        onChange={onChange}/>
    );
}

export default FlipRTE;