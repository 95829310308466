/**
 * Modal.js
 * 
 * essentially a container for the modal form. this handles the display of the actual modal, locking the focus inside the modal, and
 * styling. also displays the form itself and gives it the necessary information
 */


import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';


export const Modal = ({ onClickOutside, onKeyDown, modalRef, buttonRef, closeModal, submitForm, formData, FormComponent}) => {
    return ReactDOM.createPortal( //create a portal in which to render the modal view
        <FocusTrap>
            <aside
            tag="aside"
            role="dialog"
            tabIndex="-1"
            aria-modal="true"
            className="modal-cover"
            onClick={onClickOutside}
            onKeyDown={onKeyDown}
            >
                <div className="modal-area" ref={modalRef}>
                    <button
                    ref={buttonRef}
                    aria-label="Close Modal"
                    aria-labelledby="close-modal"
                    className="_modal-close"
                    onClick={closeModal}
                    >
                    <span id="close-modal" className="_hide-visual">
                    Close
                    </span>
                    <svg className="_modal-close-icon" viewBox="0 0 40 40">
                    <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
                    </svg>
                    </button>
                    <div className="modal-body">
                        <FormComponent submitForm={submitForm} formData={formData} closeModal={closeModal} />
                    </div>
                </div>
            </aside>
        </FocusTrap>,
        document.body
    );
};
export default Modal;