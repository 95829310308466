import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";


const SelectThumbnailForm = ({formData, submitForm, closeModal}) => {
    let [images, setImages] = useState([]);
    let [fetched, setFetched] = useState(false);
    let context = useContext(AppContext);

    function fetchImages() {
        setFetched(false);
        secureFetch(context.server + '/product-images', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: formData?.id})
        })
        .then(resp => resp.json())
        .then(results => {
            setImages(results);
            setFetched(true);
        })
    }

    /* eslint-disable */
    useEffect( () => {
        fetchImages();
    }, [context, formData]);
    /* eslint-enable */

    function selectImage(img) {
        secureFetch(context.server + '/update-product-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: img?.product_id,
                keys: ['thumbnail'],
                values: [img?.photo_link],
                message: 'reassigned product thumbnail image'
            })
        })
        .then(resp => resp.json())
        .then(results => {
            submitForm(results);
            closeModal();
        })
    }
    
    return (
    <>
        {fetched ? <></>
        :
        <h2 className='dark-text'>Loading images...</h2>
        }
        <h1 className='dark-text'>Select Image</h1>
        <TableList columnNames={['Image','SKU',' ']}
        columnWidths={['50%','25%','25%']}
        entries={
            images.map( img => {
                return [
                    <img style={{maxHeight: '100px'}} src={img?.photo_link} alt={'Unavailable'} />,
                    img?.sku,
                    <button className='btn dark-text' onClick={() => selectImage(img)}>Select</button>
                ]
            })
        }
        />
    </>
    );
}

export default SelectThumbnailForm;