import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext';
import { formatDate, secureFetch } from '../util/flip_utils';
import PaginatedTableList from '../components/PaginatedTableList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const OrderList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [orders, setOrders] = useState([])
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '')

    function fetchOrders(pageNumber, pageSize) {
        secureFetch(context.server + '/search-orders', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(resp => resp.json())
        .then(results => setOrders(results))
    }

    useEffect(() => {
        fetchOrders(0, 20);
    }, [context, searchValue])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/order-list', {searchValue: val})
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Search Orders</h1>
            <form>
                <div className='container'>
                    <input autoFocus type='text' className="search-bar" value={searchValue} onChange={e => updateSearchValue(e.target.value)}
                    onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}/>
                </div>
            </form>
            <br/>
            <br/>
            <PaginatedTableList
            columnNames={['ShipStation Order #','Order Date','Status','Customer','Ship Date','Total',' ',' ']}
            columnWidths={['10%','10%','10%','20%','20%','10%','10%','10%']}
            entries={
                orders.map( (row) => {
                    return [
                        row?.order_number,
                        formatDate(row?.created),
                        row?.order_status,
                        row?.customer_id
                        ? <Link className='btn dark-text contained' to={{pathname: `/customer-view/${row?.customer_id}`, state: {customer_id: row?.customer_id} }}>{row?.customer}</Link>
                        : 'Unavailable',
                        row?.ship_date,
                        row?.total,
                        <Link className='btn contained dark-text' to={{pathname: `/order-view/${row?.id}`, state: {order_id: row?.id}}}>View</Link>,
                        <a className='btn contained dark-text' href={'https://ship.shipstation.com/orders/all-orders-search-result?quickSearch='+String(row?.order_number)} target='_blank' rel='noreferrer'>ShipStation</a>
                    ]
                })
            }
            getData={fetchOrders}
            />

        </div>
    );
}

export default OrderList;
