import { Link } from "react-router-dom";
import TabbedDisplay from "../components/TabbedDisplay";


const Reports = () => {
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Reports</h1>
            <br/>
            <div className="bg-green rounded" style={{padding: '15px 15px'}}>
                <h2 className="dark-text">Try this generic report puller!</h2>
                <Link className='btn dark-text' to={{pathname: '/reports/generic-reports'}} >Generic Reports</Link>
            </div> 
            <br/>

            <TabbedDisplay tabNames={['Submissions','Testing','Sales','Shipping','Accounting','Inventory','Warehouse']}
            contents={[
                <>
                    <div style={{padding: '10px 10px'}}>
                        <Link className='btn dark-text' to={{pathname: '/reports/buyer-conversion'}} >Buyer Conversion Reports</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/rma-receiving'}} >RMA Receiving</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/submissions-progress'}} >Submissions Progress Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/rmas-written-received'}} >RMAs Written/Received</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/consignment-items-received'}} >Consignment Items Received</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/rma-item-brand-rating'}} >RMA Item Brand Ratings</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/appraiser-conversion'}} >Appraiser Conversion Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/unappraised-submissions'}} >Unappraised Submissions</Link><br/><br/>
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/tpas-received'}} >RMAs Received Report</Link><br/><br/> */}
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/submissions-opened'}} >Submissions Opened Report</Link><br/><br/> */}
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/pending-submissions'}} >Pending Submissions Report</Link> */}
                    </div>
                </>,
                <>
                    <div style={{padding: '10px 10px'}}>
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/testing'}} >Testing Report</Link>
                        <i className="light-text">{'  (not functional at this time)'}</i><br/><br/> */}
                        <Link className='btn dark-text' to={{pathname: '/reports/tester-return-performance'}} >Tester Return Performance Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/defective-by-tester'}} >Defective Returns (by tester) Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/nad-by-tester'}} >Not As Described Returns (by tester) Report</Link><br/><br/>
                    </div>
                </>,
                <>
                    <div style={{padding: '10px 10px'}}>
                        <Link className='btn dark-text' to={{pathname: '/reports/sales-scorecard'}} >Sales Scorecard</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/spiff-returns'}} >Spiff Returns Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/new-product-velocity'}} >New Product Velocity Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/sales-scorecard'}} >Sales Scorecard Report</Link>
                        <i className="light-text">{'  (not functional at this time)'}</i>
                    </div>
                </>,
                <>
                    <div style={{padding: '10px 10px'}}>
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/pick-list'}} >Pick List Report</Link><br/><br/> */}
                        {/* <Link className='btn dark-text' to={{pathname: '/reports/recent-ss-orders'}} >Recent SS Orders Report</Link><br/><br/> */}
                        <Link className='btn dark-text' to={{pathname: '/reports/colorado-delivery-tax'}} >CO Delivery Tax Report</Link><br/><br/>
                    </div>
                </>,
                <>
                    <div style={{padding: '10px 10px'}}>
                        <Link className='btn dark-text' to={{pathname: '/reports/payout-report'}} >Payout Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/ras-opened'}} >RAs Opened Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/ras-paid'}} >RAs Paid Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/ras-pending-issues'}} >Pending RA Issues Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/ebay-defective-returns'}} >eBay Defective Returns</Link>
                    </div>
                </>,
                <>
                    <div style={{padding: '10px 10px'}}>
                        <Link className='btn dark-text' to={{pathname: '/reports/new-product-inventory'}} >New Product Inventory</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/variance-report'}} >Variance Report</Link><br/><br/>
                        <Link className='btn dark-text' to={{pathname: '/reports/bc-finale-inventory-rec'}} >BC/Finale Inventory Reconciliation</Link><br/><br/>
                    </div>
                </>,
                                <>
                                <div style={{padding: '10px 10px'}}>
                                    <Link className='btn dark-text' to={{pathname: '/reports/items-tested'}} >Items Tested</Link><br/><br/>
                                    <Link className='btn dark-text' to={{pathname: '/reports/items-photographed'}} >Items Photographed</Link><br/><br/>                        
                                    <Link className='btn dark-text' to={{pathname: '/reports/items-stocked'}} >Items Stocked</Link><br/><br/>
                                    <Link className='btn dark-text' to={{pathname: '/reports/items-listed'}} >Items Listed</Link><br/><br/>
                                    <Link className='btn dark-text' to={{pathname: '/reports/value-by-stage'}} >Value by Stage</Link><br/><br/>
                                </div>
                            </>
            ]} />
            
        </div>
    );
}

export default Reports;