import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import AppContext from "../AppContext"
import EditPaymentForm from "../components/EditPaymentForm";
import ModalContainer from "../components/ModalContainer";
import TableList from "../components/TableList";
import PaginatedTableList from "../components/PaginatedTableList";
import { formatDate, secureFetch } from "../util/flip_utils";
import ConfirmButton from "../components/ConfirmButton";
import PayOutForm from "../components/PayOutForm";
import GridView from "../components/GridView";

const OutgoingPayments = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [payments, setPayments] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [methods, setMethods] = useState([]);
    let [queryTerms, setQueryTerms] = useState({
        sort: {
            key: 'p.created',
            order: -1
        },
        status: props.location.state?.status ? props.location.state?.status : -1,
        dateOrder: props.location.state?.dateOrder ? props.location.state?.dateOrder : -1,
        source: props.location.state?.source ? props.location.state?.source : 'any',
        method: props.location.state?.method ? props.location.state?.method : -1
    });
    let [searchValue, setSearchValue] = useState("");
    let [fetching, setFetching] = useState(false);

    function fetchPayments(pageNumber, pageSize) {
        setFetching(true);
        secureFetch(context.server + '/search-payments', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...queryTerms,
                searchValue: searchValue,
                pageSize: pageSize,
                pageNumber: pageNumber
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setFetching(false);
            setPayments(results)
        })
    }

    function fetchStatuses() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'payment_statuses'})
        })
        .then(resp => resp.json())
        .then(results => setStatuses(results))
    }

    function fetchMethods() {
        let methodsParams = {
            equal: [{
                key: 'active',
                value: '1',
            }]
        }
        if(queryTerms?.source == "raOnly") {
            methodsParams.equal.push({key: "ra", value: '1'})
        } else if(queryTerms?.source == 'consignOnly' || queryTerms?.source == "buyOnly") {
            methodsParams.equal.push({key: "rma", value: '1'})
        }
        
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'payment_methods', params: methodsParams})
        })
        .then(resp => resp.json())
        .then(results => {
            results.push({id: -1, payment_method: 'Any', active: 1})
            setMethods(results)
            if(results?.map(r => r?.id)?.indexOf(queryTerms?.method) < 0)
                updateQueryTerms({...queryTerms, method: -1});
        })
    }

    useEffect(() => {
        fetchStatuses();
    }, [])

    useEffect(() => {
        fetchMethods();
    }, [queryTerms?.source])

    useEffect(() => {
        fetchPayments(0, 20);
    }, [context, queryTerms]);

    function createPayment() {
        secureFetch(context.server + '/create-payment', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                keys: ['notes','payment_status_id'],
                values: [`Blank payment created by ${context.user.email}`,'1']
            })
        })
        .then(() => {fetchPayments(0,20)})
    }

    function updateQueryTerms(qterm) {
        setQueryTerms(qterm);
        history.replace('/outgoing-payments', {...qterm, searchValue: searchValue});
    }

    const searchHandler = (e) => e.key == "Enter" && fetchPayments(0,20);

    return (
        <div style={{padding: '15px 15px'}}>
            <div className="container">
                <h1 className='light-text contained'>Outgoing Payments</h1>
                <ConfirmButton buttonText='Create Blank Payment' clickHandler={() => createPayment()}
                confirmation='Are you sure you would like to create a new blank payment? You can edit it afterwords from the Outgoing Payments screen.' />
            </div>
            <br/>
            <h2 className="light-text">Filters:</h2>
                <div className="container bg-light rounded ml-0" style={{maxWidth: '90%'}}>
                    <h3 className="dark-text">Payment Status:</h3>
                    <label className="contained dark-text"><input type='radio' value={-1} checked={queryTerms?.status === -1} onChange={e => updateQueryTerms({...queryTerms, status: -1})} />Any</label>
                    {statuses?.length > 0 ? statuses?.map(stat =>
                        <label className="contained dark-text"><input type='radio' value={stat?.id} checked={queryTerms?.status === stat?.id} onChange={e => updateQueryTerms({...queryTerms, status: stat?.id})} />{stat?.payment_status}</label>)
                    : <h3 className="dark-text">Fetching statuses...</h3>}
                </div>
                <br/>
                <div className="bg-light rounded" style={{maxWidth: '90%'}}>
                    <h3 className="dark-text">Payment Method:</h3>
                    <GridView columns={5}>
                        {methods?.map(mthd => <label className="dark-text text-left"><input type='radio' value={mthd?.id} checked={queryTerms?.method === mthd?.id} onChange={e => updateQueryTerms({...queryTerms, method: mthd?.id})} />&nbsp;&nbsp;{mthd?.payment_method}</label>)}
                    </GridView>
                </div>
                <br/>
                <div className="container bg-light rounded" style={{maxWidth: '40%'}}>
                    <h3 className="dark-text">Sort by Date:</h3>
                    <label className="contained dark-text"><input type='radio' value={1} checked={queryTerms?.dateOrder === 1} onChange={e => updateQueryTerms({...queryTerms, dateOrder: 1})} />Least Recent</label>
                    <label className="contained dark-text"><input type='radio' value={-1} checked={queryTerms?.dateOrder === -1} onChange={e => updateQueryTerms({...queryTerms, dateOrder: -1})} />Most Recent</label>
                </div>
                <br/>
                <div className="container bg-light rounded" style={{maxWidth: '70%'}}>
                    <h3 className="dark-text">Payment Source:</h3>
                    <label className="contained dark-text"><input type='radio' value={'any'} checked={queryTerms?.source=='any'} onChange={e => updateQueryTerms({...queryTerms, source: 'any'})} />Any Source</label>
                    <label className="contained dark-text"><input type='radio' value={'raOnly'} checked={queryTerms?.source=='raOnly'} onChange={e => updateQueryTerms({...queryTerms, source: 'raOnly'})} />RAs</label>
                    <label className="contained dark-text"><input type='radio' value={'buyOnly'} checked={queryTerms?.source=='buyOnly'} onChange={e => updateQueryTerms({...queryTerms, source: 'buyOnly'})} />Buy/Trades</label>
                    <label className="contained dark-text"><input type='radio' value={'consignOnly'} checked={queryTerms?.source=='consignOnly'} onChange={e => updateQueryTerms({...queryTerms, source: 'consignOnly'})} />Consignments</label>
                    <label className="contained dark-text"><input type='radio' value={'other'} checked={queryTerms?.source=='other'} onChange={e => updateQueryTerms({...queryTerms, source: 'other'})} />Other</label>
                </div>
                <br/>
                <div className="container bg-light rounded" style={{maxWidth: '60%'}}>
                    <h3 className="dark-text">Search:</h3>
                    <label className="contained dark-text text-left"><input style={{width: '90%'}} type='text' value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyPress={searchHandler} />&nbsp;<button className="btn bg-green light-text" onClick={() => searchHandler({key: 'Enter'})}>{fetching ? '...' : 'OK'}</button></label>
                </div>
            <br/>
            <PaginatedTableList columnNames={['SKU', 'Title/Rep', 'Source', 'RMA Type', 'Customer', 'Amount', 'Status', 'Notes', 'Date', 'Actions']}
            columnWidths={['10%', '13%', '6%','6%', '10%', '7%', '8%', '24%', '8%', '8%']}
            entries={
                payments.map( row => {
                    return [
                        <Link className='dark-text' to={{pathname: `/item-view/${row?.item_id}`, state: {item_id: row?.item_id}}}>{row?.sku}</Link>,
                        row?.title ? row?.title : row?.salesperson,
                        row?.tpa_id ?
                            <Link className='dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>RMA {row?.tpa_number}</Link>
                            : row?.ra_id ?
                                <Link className='dark-text' to={{pathname: `/ra-view/${row?.ra_id}`, state: {ra_id: row?.ra_id}}}>RA {row?.ra_id}</Link>
                                : "Unknown",
                        row?.tpa_consignment==1 ? 'Consign' : row?.tpa_consignment==0 ? 'Buy' : '',
                        <Link className='btn text-white' to={{pathname: `/customer-view/${row?.customer_id}`, state: {customer_id: row?.customer_id}}}>{row?.customer}</Link>,
                        row?.payment_amount,
                        row?.payment_status,
                        row?.notes,
                        row?.payment_status == 'Paid' ? `Paid ${formatDate(row?.payment_date)}` : formatDate(row?.created),
                        <div className='container' style={{maxWidth: '100%'}}>
                            {/*<ModalContainer triggerText='View' formData={row} FormComponent={EditPaymentForm} submitForm={() => fetchPayments(0,20)} />*/}
                            <Link className="btn text-white center contained" to={{pathname: `/payment-view/${row?.id}`, state: {payment_id: row?.id}}}>View</Link>
                            <ModalContainer triggerText='Pay Out' formData={row} FormComponent={PayOutForm} submitForm={() => fetchPayments(0,20)} />
                        </div>
                    ]
                })
            }
            getData={fetchPayments}
            />
        </div>
    )
}

export default OutgoingPayments;