

import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const PhotoItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [notes, setNotes] = useState(formData?.photo_notes || '');
    let [item, setItem] = useState(formData)
    let [formDisable, setFormDisable] = useState(false);

    function submit(event) {
        event.preventDefault();
        setFormDisable(true);
        secureFetch(context.server + '/photo-item', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: context.user.id,
                item_id: formData?.id,
                notes: notes,
                item_status_history: item?.status_history
            })
        })
        .then(() => {
            submitForm(notes);
            closeModal();
        })
    }

    return (
        <div>
            <form id='PhotoItemForm' onSubmit={submit} />
            <fieldset form='PhotoItemForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Add a note?</h1>
                    <label className='dark-text text-left'>Notes:</label>
                    <textarea form='PhotoItemForm' style={{maxWidth: '80%', width: '80%', minWidth: '80%'}} rows='15' value={notes} onChange={e => setNotes(e.target.value)} />
                    <br/>
                    <label className='dark-text text-left'>Quantity: {item.quantity}</label>
                    {item?.customerBtob || item?.submissionBtob ? <b className="blue-text">B2B</b> : <></>}
                    <br/>
                    <input form='PhotoItemForm' className='btn bg-dark light-text' type='submit' />
                
                </div>
            </fieldset>
        </div>
    );
}

export default PhotoItemForm;