import { useContext, useState, useEffect } from "react"
import AppContext from "../AppContext"
import InfoBox from "../components/InfoBox";
import ShowHideBox from "../components/ShowHideBox";
import { secureFetch } from "../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from '../components/TableList'


const ProductManagementTools = () => {
    let context = useContext(AppContext);

    let [productLoaderVals, setProductLoaderVals] = useState({bcId: "", supplierInfo: ""})
    let [dropshipToolVals, setDropshipToolVals] = useState({purchaseOrderId:"",carrierCode:"",trackingNumber:""})
    let [report, setReport] = useState([]);
    let [finaleSuppliers, setFinaleSuppliers] = useState([]);


    function runFinaleProductLoader(event) {
        secureFetch(context.server + '/finale/product-loader', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idList: productLoaderVals?.bcId,
                supplierInfo: productLoaderVals?.supplierInfo
            })
        })
        .then(res => res.json())
        .then(data => {setReport(data)})
    }

    function runDropshipTool (event){ 
        secureFetch(context.server + '/finale/dropship-receiving-tool', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                purchaseOrderId: dropshipToolVals?.purchaseOrderId,
                carrierCode: dropshipToolVals?.carrierCode,
                trackingNumber: dropshipToolVals?.trackingNumber
            })
        })
        .then(resp => resp.json())
        .then(() => alert('done'))
    }

    function setCarrierOrShowOtherInput(x){ //show/hide description box for "Other" carrier info
        if(x.target.value == 'Other') {
            document.getElementById("otherLabel").style.display = ""
            document.getElementById("otherBox").style.display = ""
            setDropshipToolVals({...dropshipToolVals, carrierCode: ''}) 
        } else {
            document.getElementById("otherLabel").style.display = "none"
            document.getElementById("otherBox").style.display = "none" 
            setDropshipToolVals({...dropshipToolVals, carrierCode: x.target.value})  

        }

                
    }

     //getting finale suppliers names, supplier IDs, and discount percentages from backend getter.
     async function addSuppliers(){
        let suppliers = await secureFetch(context?.server + '/finale/finaleSuppliers', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}
        }).then(resp => resp.json())


        setFinaleSuppliers(suppliers);
        
    }

    useEffect(addSuppliers,report,context)


    return (
        <div style={{padding: '15px 15px'}}>
            <h1 className="light-text">Product Management Tools</h1>
            <br/><br/>
            <div className="container">
                <div className="contained"><ShowHideBox showText="Finale Product Loader" isShown={false} content={<><br/>
                    <InfoBox title="Finale Product Loader">
                        
                        <label className="dark-text text-left">BC ID: &nbsp;<input type='text' value={productLoaderVals?.bcId} onChange={e => setProductLoaderVals({...productLoaderVals, bcId: e.target.value})} /></label>
                        
                        <label className="dark-text text-left" for="supplier" >Select Supplier:
                            <select name="supplier" id="selectSupplier" value={productLoaderVals?.supplierInfo} onChange={e => setProductLoaderVals({...productLoaderVals, supplierInfo: e.target.value})}>
                        
                                {finaleSuppliers?.map( (row, ind) => {
                                    return <option key={ind} value={[row[1],row[2]]}>{row[0]}</option>
                                })}

                            </select>
                        </label>
                        
                        
                        <button className="btn dark-text" onClick={runFinaleProductLoader}>RUN</button>
                        
                <br></br>
                <br></br>
                <TableList columnNames={['Messages:']}
                        columnWidths={['100%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.message
                                ]
                            })
                        } />
                    </InfoBox>
                </>} /></div>
                <div className="contained"></div>
                <div className="contained"></div>
                
            </div>
            <div className="contained">
                <ShowHideBox showText="Dropship Receive/Ship Tool" isShown={false} content={<><br/>
                    <InfoBox title="Dropship Receive/Ship Tool">
                        <label className="dark-text text-left">Finale Purchase Order ID: &nbsp;<input type='text' value={dropshipToolVals?.purchaseOrderId} onChange={e => setDropshipToolVals({...dropshipToolVals, purchaseOrderId: e.target.value})} /></label>
                        <label className="dark-text text-left" for="supplier" >Select Carrier:
                        <select name="supplier" id="selectSupplier" onChange={e => setCarrierOrShowOtherInput(e)}>
                            <option selected disabled>Choose Carrier</option>
                            <option value="fedex" >FedEx</option>
                            <option value="ups" >UPS</option>
                            <option value="usps" >USPS</option>
                            <option value="Other" >Other</option>
                        </select></label>
                        <label  id = "otherLabel" className="dark-text text-left" style = {{display:"none"}}>Carrier Name(if Other): &nbsp;<input id = 'otherBox' type='text' style = {{display:"none"}} value={dropshipToolVals?.carrierCode} onChange={e => setDropshipToolVals({...dropshipToolVals, carrierCode: e.target.value})} /></label>
                        <label className="dark-text text-left">Tracking Number: &nbsp;<input type='text' value={dropshipToolVals?.trackingNumber} onChange={e => setDropshipToolVals({...dropshipToolVals, trackingNumber: e.target.value})} /></label>

                        <button className="btn dark-text" onClick={runDropshipTool}>RUN</button>
   
                    </InfoBox>
                </>} /></div>
                <div className="contained"></div>
        </div>
        
    )
}

export default ProductManagementTools;