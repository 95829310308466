import { useEffect, useState } from "react"


const StockItemForm = ({formData, submitForm, closeModal}) => {
    let [dimensions, setDimensions] = useState({...formData?.dimensions});
    let [disableForm, setDisableForm] = useState(false);
    let [item, setItem] =useState({})
    let [qtyConfirmed, setQtyConfirmed] = useState(0);

    //make formData stateful
    useEffect(() => {
        setDimensions(formData?.dimensions);
        setItem(formData)
    }, [formData])

    useEffect(() => {
        if(item?.quantity==1){setQtyConfirmed(1)}
    },[item?.quantity])

    function submit() {
        setDisableForm(true);
        submitForm({...dimensions, has_packaging: item?.has_packaging});
        closeModal();
    }

    function addDimsBox(event) {
        event.preventDefault();
        if(dimensions?.boxes?.length > 0) {
            let nextBox = {...dimensions?.boxes[dimensions?.boxes?.length - 1]};
            let newBoxes = dimensions?.boxes?.map(i=>i);
            newBoxes?.push(nextBox)
            setDimensions({...dimensions, boxes: newBoxes})
        } else {
            setDimensions({...dimensions, boxes: [{width: 0, depth: 0, height: 0}]})
        }
    }

    function removeDimsBox(index) {
        return (event) => {
            event.preventDefault();
            let newBoxes = dimensions?.boxes?.map(i=>i);
            newBoxes.splice(index,1);
            setDimensions({...dimensions, boxes: newBoxes});
        }
    }

    function updateDimsBox(index, value) {
        let newBoxes = dimensions?.boxes?.map(i=>i);
        newBoxes[index] = value;
        setDimensions({...dimensions, boxes: newBoxes});
    }

    return (
        <div>
            <h2 className="dark-text">Stocking Info</h2><br/>
            <form id='StockItem' onSubmit={submit} />
            <fieldset form='StockItem' disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <label className='dark-text text-left'>Factory Packaging:&nbsp;&nbsp;
                            <input form='StockItem'  type='checkbox' checked={item?.has_packaging===1} onChange={ (e) => setItem({...item, has_packaging: e.target.checked ? 1 : 0})}/>
                    </label>
                    <br/>
                    <label className='dark-text text-left'>Quantity: {item.quantity}</label>
                    <br/>
                    <i className='dark-text text-left'>{'(Please only use whole numbers, and always round decimal measurements UP)'}</i>
                    <label className='dark-text text-left'>Total weight (per item, all boxes): 
                        <input form='StockItem' type='number' step='1' min='0' value={dimensions?.weight} onChange={e => setDimensions({...dimensions, weight: e.target.value})}>
                        </input></label> <br/>
                    {dimensions?.boxes?.length > 0 ? <div className="container bg-light">
                        {dimensions?.boxes?.map((row, ind) => <div className="contained bg-lightest" style={{margin: '5px 5px', maxWidth: '180px', minWidth: '180px'}}>
                            <label className='dark-text text-left'>Width:</label>
                                <input form='StockItem' type='number' step='1' min='0' value={row?.width} onChange={e => updateDimsBox(ind, {...row, width: e.target.value})}/>
                            <label className='dark-text text-left'>Depth:</label>
                                <input form='StockItem' type='number' step='1' min='0' value={row?.depth} onChange={e => updateDimsBox(ind, {...row, depth: e.target.value})}/>
                            <label className='dark-text text-left'>Height:</label>
                                <input form='StockItem' type='number' step='1' min='0' value={row?.height} onChange={e => updateDimsBox(ind, {...row, height: e.target.value})}/><br/><br/>
                            <button className="btn bg-red light-text" onClick={removeDimsBox(ind)}>X</button>
                            </div>)}
                    </div> : <></>}
                    <button className="btn bg-green light-text" onClick={addDimsBox}>+</button>
                    <br/>   
                    <br/>                
                    <div hidden={qtyConfirmed ? "hidden" : ""}>
                        <p className='red-text'>ITEM HAS QUANTITY: {item?.quantity} - PLEASE CONFIRM THIS IS CORRECT</p>
                        <button className='btn bg-green light-text' onClick={() => setQtyConfirmed(1)}>Confirm</button>
                    </div>
                    <div hidden={qtyConfirmed ? "" : "hidden"}>
                        <input type='submit' value="Submit" form='StockItem'  className = {qtyConfirmed ? "btn bg-dark light-text" : "btn bg-lightest red-text"}/>
                    </div>
                </div>
                <br/>
            </fieldset>
        </div>
    )
}

export default StockItemForm;