import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext';
import { formatDate, secureFetch } from '../util/flip_utils';
import PaginatedTableList from '../components/PaginatedTableList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const DraftOrderList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [draftOrders, setDraftOrders] = useState([])
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '')
    let [showAll, setShowAll] = useState(0);

    let [manualOrderNumber, setManualOrderNumber] = useState('');

    function fetchDraftOrders(pageNumber, pageSize) {
        secureFetch(context.server + '/search-draft-orders', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify({
                query: searchValue,
                showAll: showAll,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(resp => resp.json())
        .then(results => setDraftOrders(results))
    }

    useEffect(() => {
        fetchDraftOrders(0, 20);
    }, [context, searchValue, showAll])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/draft-order-list', {searchValue: val})
    }

    function copyLink(code) {
        navigator.clipboard.writeText(`https://tmraudio.com/custom-checkout/?cart=${code}`);
        //alert('copied to clipboard!');
    }

    function manualDraftOrderPull() {
        secureFetch(context.server + '/bc/order-created', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify({
                data: {
                    id: manualOrderNumber
                }
            })
        })
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Search Draft Orders</h1>
            <form>
                <div className='container'>
                    <input autoFocus type='text' className="search-bar" value={searchValue} onChange={e => updateSearchValue(e.target.value)}
                    onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}/>
                </div>
                <br/>
                <div className='container'>
                    <div className='contained'>
                        <label className='light-text text-left'>DO Status:</label>
                        <div className='bg-light rounded container' style={{maxWidth: '400px'}}>
                            <label className='dark-text text-left contained'><input type='radio' checked={showAll==0} onChange={e => setShowAll(e.target.checked ? 0 : 1)} />&nbsp;&nbsp;Open Only</label>
                            <label className='dark-text text-left contained'><input type='radio' checked={showAll==1} onChange={e => setShowAll(e.target.checked ? 1 : 0)} />&nbsp;&nbsp;All</label>
                        </div>
                    </div>
                    <div className='contained'>
                        <label className='light-text text-right'>Order #&nbsp;&nbsp;
                            <input type='number' value={manualOrderNumber} onChange={e => setManualOrderNumber(e.target.value)}/>&nbsp;&nbsp;
                            <button className='btn bg-green light-text' onClick={() => manualDraftOrderPull()}>OK</button> </label>
                    </div>
                </div>
            </form>
            <br/>
            <br/>
            <PaginatedTableList
            columnNames={['ID','Cart Code','Customer','BC Order','Date Created','Open/Closed','Int. Notes',' ']}
            columnWidths={['5%','15%','10%','10%','20%','10%','20%','10%']}
            entries={
                draftOrders.map( (row) => {
                    return [
                        row?.id,
                        <button className='btn dark-text' onClick={() => copyLink(row?.cart_code)}>{row?.cart_code}</button>,
                        row?.bc_customer_name + (row?.bc_customer_email ? ` (${row?.bc_customer_email})` : ''),
                        row?.bc_order_id,
                        formatDate(row?.created),
                        row?.is_operable==1 ? 'Open' :
                            row?.is_operable==-1 ? 'Cancelled' :
                                'Closed',
                        row?.internal_notes,
                        //<button className='btn dark-text' disabled>View (disabled)</button>
                        <Link className='btn contained dark-text' to={{pathname: `/draft-order-view/${row?.id}`, state: {draft_order_id: row?.id}}}>View</Link>,
                    ]
                })
            }
            getData={fetchDraftOrders}
            />

        </div>
    );
}

export default DraftOrderList;
