/**
 * EditItemForm.js
 * 
 * a form subcomponent which is used in the context of a modal view to edit an item's information
 */


import React, { useContext, useEffect, useState } from 'react';
import { secureFetch } from '../util/flip_utils';
import AppContext from '../AppContext';


const editable_fields = [ //for getSafeVersion
    'id',
    'sku',
    'title',
    'serial_number',
    'number_of_parts',
    'cosmetic_condition_id',
    'cosmetic_description',
    'item_condition_id',
    'service_history',
    'description',
    'what_is_included',
    'functional_notes',
    'age_in_years',
    'num_of_owners',
    'tobacco_exposure',
    'list_price',
    'purchase_amount',
    'active',
    'weight',
    'width',
    'depth',
    'height',
    'item_status_id',
    'sale_type_id',
    'warranty_type_id',
    'is_accessory',
    'ebay_only',
    'receiving_notes',
    'tester_notes',
    'photo_notes',
    'service_history',
    'tpa_id'
]

//returns a copy of the item which only contains info for fields that are allowed to be edited ^^^
function getSafeVersion(item) {
    let safe_item = {}
    editable_fields.forEach((key) => {
        safe_item[`${key}`] = item[`${key}`];
    })

    return safe_item;
}

const EditItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [item, setItem]= useState(formData);
    let [suppressEdits, setSuppressEdits] = useState(formData?.suppressEdits || false)
    let [cosmetics, setCosmetics] = useState([]);
    let [conditions, setConditions] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [warrantyTypes, setWarrantyTypes] = useState([]);
    let [disableForm, setDisableForm] = useState(false);
    // Finale variables used to determine if any fields relevant to Finale have been changed. If so, finale will be updated.
    let [finaleUpdate, setFinaleUpdate] = useState(false)

    const LISTED_STATUS = 6;
    let [numOwnersOptions, setNumOwnersOptions] = useState([]);
    let [ageOptions, setAgeOptions] = useState([]);
    

    

    const fetchFields = () => {
        //get Conditions
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'cosmetic_conditions'})
        })
        .then (resp => resp.json())
        .then(results => {
            setCosmetics(results);
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({tableName: 'item_conditions'})
            })
            .then(resp => resp.json())
            .then(results => setConditions(results))
        })

        //get Statuses
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'item_statuses'})
        })
        .then(resp => resp.json())
        .then(results => setStatuses(results));

        //get Warranty Types
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'warranty_types'})
        })
        .then(resp => resp.json())
        .then(results => setWarrantyTypes(results));

        //get owners lookups
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'number_of_owners_lkp'})
        })
        .then(resp => resp.json())
        .then(results => setNumOwnersOptions(results?.map(row => <option value={row?.id}>{row?.text}</option>)));

        //get age lookups
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'age_in_years_lkp'})
        })
        .then(resp => resp.json())
        .then(results => setAgeOptions(results?.map(row => <option value={row?.id}>{row?.text}</option>)));
    }

    useEffect(() => {
        fetchFields();
    }, [])

    //submit is called when the submit button is pushed. it has its own functionality, and also calls the props onSubmit and closeModal
    const submit = ( e ) => {
        setDisableForm(true);
        e.preventDefault();
        if(suppressEdits) {
            submitForm({...item, finaleUpdate: finaleUpdate});
            closeModal();
        } else {
            let safe_item = getSafeVersion(item);
            
            secureFetch(context.server + '/edit-item', {
                method: 'POST',
                body: JSON.stringify({item: safe_item, finaleUpdate: finaleUpdate}),
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => {
                submitForm(data);
                closeModal();
            })
        } 
        
    }

    //handles when the cosmetic condition dropdown is changed
    function cosmeticChangeHandler(event) {
        setItem({...item, cosmetic_condition_id: event.target.value, cosmetic_condition:cosmetics.find(e => e.id == event.target.value)?.cosmetic_condition})   
    }

    //handles when the item condition dropdown is changed
    function conditionChangeHandler (event) {
        setItem({...item, item_condition_id: event.target.value});
    }

    //returns the form component to be rendered
    return (
        <div>
            <form id='EditItemForm' onSubmit={submit} />
            <fieldset form='EditItemForm' disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h3>{item?.title}</h3>
                    {suppressEdits ? <div className='bg-red rounded'><i className='light-text'><center>THIS IS A NON-SAVED VERSION OF THIS FORM, YOUR CHANGES WILL<br/>NOT BE SAVED UNTIL YOU SUBMIT ON THE PAGE YOU CAME FROM</center></i></div> : <></>}
                    <br/>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Title:&nbsp;&nbsp;
                                <input form='EditItemForm' required className='contained' type='text' value={item?.title ? item?.title : ''} onChange={ (e) => {setFinaleUpdate(true); setItem({...item, title: e.target.value})}} />
                            </label><br/>
                            <label className='dark-text text-left'>Buy Price:&nbsp;&nbsp;
                                <input form='EditItemForm' className='contained' type='text' disabled  value={item?.display_cost ? item?.display_cost : ''}/>
                            </label>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>SKU:&nbsp;&nbsp;
                                <input form='EditItemForm' className='contained' disabled type='text' value={item?.sku ? item?.sku : ''} onChange={ (e) => {setFinaleUpdate(true); setItem({...item, sku: e.target.value})}}/>
                            </label><br/>
                            <label className='dark-text text-left'>List Price:&nbsp;&nbsp;
                                <input form='EditItemForm' className='contained' type='text' disabled={item?.item_type == 'new' || item?.item_status_id == LISTED_STATUS ? 'disabled' : ''} value={item?.display_price ? item?.display_price : ''} onChange={ (e) => {setFinaleUpdate(true); setItem({...item, display_price: e.target.value, list_price: e.target.value})}}/>
                            </label>
                        </div>


                    </div>
                    <div className='container'> 
                        
                        
                    </div>
                    <div className='container'>
                        <label className='contained'>Cosmetic:</label>
                        <select form='EditItemForm' onChange={ cosmeticChangeHandler } value={item?.cosmetic_condition_id}>
                            <option value='null' key={0}>*** SELECT COSMETIC CONDITION ***</option>
                            {cosmetics.map( (cos) => 
                                <option key={cos?.id} value={cos?.id} >{cos?.cosmetic_condition}</option>
                            )}
                        </select>
                        <label className='contained'>Condition:</label>
                        <select form='EditItemForm' onChange={ conditionChangeHandler } value={item?.item_condition_id}>
                            <option value='null' key={0}>*** SELECT ITEM CONDITION ***</option>
                            {conditions.map( (con) => 
                                <option key={con?.id} value={con?.id} >{con?.item_condition}</option>
                            )}
                        </select>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Cosmetic Description:</label>
                            <textarea form='EditItemForm' rows='8' cols='50' value={item?.cosmetic_description ? item?.cosmetic_description : ''} onChange={ (e) => setItem({...item, cosmetic_description: e.target.value})}/>
                            <label className='dark-text text-left'>Description:</label>
                            <textarea form='EditItemForm' rows='8' cols='50' value={item?.description ? item?.description : ''} onChange={ (e) => setItem({...item, description: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>What is Included:</label>
                            <textarea form='EditItemForm' required rows='8' cols='50' value={item?.what_is_included ? item?.what_is_included : ''} onChange={ (e) => setItem({...item, what_is_included: e.target.value})}/>
                            <label className='dark-text text-left'>Functional Notes:</label>
                            <textarea form='EditItemForm' rows='8' cols='50' value={item?.functional_notes ? item?.functional_notes : ''} onChange={ (e) => setItem({...item, functional_notes: e.target.value})}/>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Parts:&nbsp;&nbsp;
                            <input form='EditItemForm' type='number' value={item?.number_of_parts ? item?.number_of_parts : ''} onChange={ (e) => setItem({...item, number_of_parts: e.target.value})}/>
                            </label><br/>
                            <label className='dark-text text-left'>Age:&nbsp;&nbsp;
                            {/* <input form='EditItemForm' type='number' value={item?.age_in_years ? item?.age_in_years : ''} onChange={ (e) => setItem({...item, age_in_years: e.target.value})}/> */}
                            <select form="EditItemForm" onChange={e => setItem({...item, age_in_years: e.target.value, age_in_years_text:ageOptions.find(o=> o?.props?.value== e.target.value)?.props?.children })} value={item?.age_in_years}>
                                {ageOptions}
                            </select>
                            </label><br/>
                            
                        </div>
                    
                        <div className='contained'>
                            <label className='dark-text text-left'>Owners:&nbsp;&nbsp;
                            <select form="EditItemForm" onChange={e => setItem({...item, num_of_owners: e.target.value, num_of_owners_text:numOwnersOptions.find(o=> o?.props?.value== e.target.value)?.props?.children})} value={item?.num_of_owners}>
                                {numOwnersOptions}
                            </select>
                            </label><br/>
                            <label className='dark-text text-left'>SN:&nbsp;&nbsp;
                            <input form='EditItemForm' type='text' value={item?.serial_number ? item?.serial_number : ''} onChange={ (e) => setItem({...item, serial_number: e.target.value})}/>
                            </label><br/>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Receiving Notes:</label>
                            <textarea form='EditItemForm' rows='6' cols='50' value={item?.receiving_notes || ''} onChange={ (e) => setItem({...item, receiving_notes: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>Tester Notes:</label>
                            <textarea form='EditItemForm' rows='6' cols='50' value={item?.tester_notes || ''} onChange={ (e) => setItem({...item, tester_notes: e.target.value})}/>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Photo Notes:</label>
                            <textarea form='EditItemForm' rows='6' cols='50' value={item?.photo_notes || ''} onChange={ (e) => setItem({...item, photo_notes: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>Service History:</label>
                            <textarea form='EditItemForm' rows='6' cols='50' value={item?.service_history || ''} onChange={ (e) => setItem({...item, service_history: e.target.value})}/>
                        </div>
                    </div>
                    <div className='container'>
                        <label className='contained dark-text text-left'>Tobacco:&nbsp;&nbsp;
                            <input form='EditItemForm' type='checkbox' checked={item?.tobacco_exposure===1} onChange={ (e) => setItem({...item, tobacco_exposure: e.target.checked ? 1 : 0})}/>
                        </label>
                        <label className='contained dark-text text-left'>eBay Only?:&nbsp;&nbsp;
                            <input form='EditItemForm'  type='checkbox' checked={item?.ebay_only===1} onChange={ (e) => setItem({...item, ebay_only: e.target.checked ? 1 : 0})}/>
                        </label>
                        <label className='dark-text text-left text-left'>Item Status:&nbsp;&nbsp;
                            <select form='EditItemForm' required onChange={ e => setItem({...item, item_status_id: e.target.value}) } value={item?.item_status_id}>
                                {statuses.map( (stat) => 
                                    <option key={stat?.id} value={stat?.id} >{stat?.status}</option>
                                )}
                            </select>
                        </label>
                    </div>
                    <div className = "container">
                        <label className='contained dark-text text-left'>Is Accessory?:&nbsp;&nbsp;
                        <input form='EditItemForm' className='contained' type='checkbox' checked={item?.is_accessory===1} onChange={ (e) => setItem({...item, is_accessory: e.target.checked ? 1 : 0})}/>
                        </label>
                    </div>
                    <br/>
                    <input form='EditItemForm' className='btn bg-dark light-text' type='submit' />
                    <br/><br/>
                    <button className='btn bg-red light-text' onClick={() => setItem({...item, active: item?.active==1 ? 0 : 1})}>{item?.active==1 ? "Deactivate Item" : "Click Submit to Confirm Deactivation"}</button>
                </div>
            </fieldset>
        </div>
    );
};

export default EditItemForm;