import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const RmasWrittenReceived = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [reportType, setReportType] = useState('summary');
    let [interval, setInterval] = useState(60);
    let [dateType, setDateType] = useState('interval')
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [subRep, setSubRep] = useState("all")
    let [source, setSource] = useState("B2C");
    let [delay, setDelay] = useState(60);
    let [sellConsignTrade, setSellConsignTrade] = useState("All");

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }

    function changeDateTypeInterval(event){ //using this so that the report matches the default value when switching back to "interval" dateType
        setDateType('interval')
        setInterval(60)
    }

    function fetchReport() {
        secureFetch(context.server + '/reports/rmas-written-received', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({interval: interval, reportType: reportType, subRep: subRep, dateType: dateType, startDate: startDate, endDate: endDate, source:source, sellConsignTrade:sellConsignTrade, delay:delay})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, interval, reportType, subRep, source, dateType, startDate, endDate, sellConsignTrade, delay])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>RMAs Written/Received</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">                                 
                    <div className="container" >
                        
                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                        </div>

                        <div className="container bg-lightest rounded">
                            <div style={{padding: '15px 15px'}}>
                                <label className=" text-left"><input type='radio' checked={dateType=='interval'} onChange={e => e.target.checked==1 ? changeDateTypeInterval()  : null} />&nbsp;Interval</label>
                                <label className=" text-left"><input type='radio' checked={dateType=='customRange'} onChange={e => e.target.checked==1 ? setDateType('customRange') : null}/>&nbsp;Custom Date Range</label>
                            </div>
                            
                            {dateType == 'interval' ? <> 
                                <label className=" text-left" for="interval" style={{padding: '15px 15px'}} >Report Interval:
                                    <select  name="inverval" id="selectInterval" onChange={e => setInterval(Number(e.target.value))}>
                                        <option value="30" >30 Days</option>
                                        <option selected value="60" >60 Days</option>
                                        <option value="120" >120 Days</option>
                                        <option value="365" >365 Days</option>
                                        <option value="0">All Time (Without Offset)</option>
                                    </select>
                                </label>
                                <label className=" text-left" for="delay" style={{padding: '15px 15px'}} >Delay:
                                    <select  name="delay" id="selectDelay" onChange={e => setDelay(Number(e.target.value))}>
                                        <option value="0" >None</option>
                                        <option value="30" >30 Days</option>
                                        <option selected value="60" >60 Days</option>
                                        <option value="90" >90 Days</option>
                                    </select>
                                </label>

                                </> : <>
                                    <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                        </div>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                        </div>
                                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                                        {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                        <br/>
                                    </div>
                            </>}
                        </div>
                        
                        <label className=" text-left" for="sellConsignTrade" style={{padding: '15px 15px'}} >Sell/Consign/Trade:      
                            <select  name="catBrand" id="selectsellConsignTrade" onChange={e => setSellConsignTrade(e.target.value)}>
                                <option selected value="all" >All</option>
                                <option value="sell" >Sell</option>
                                <option value="consign" >Consign</option>
                                <option value="trade" >Trade</option>                                       
                            </select>
                        </label>  
                                
                        <label className=" text-left" for="source" style={{padding: '15px 15px'}} >Source:      
                            <select  name="catBrand" id="selectSource" onChange={e => setSource(e.target.value)}>
                                <option selected value="B2C" >B2C</option>
                                <option value="B2B" >B2B</option>
                                <option  value="All" >All</option>
                            </select>
                        </label>   
                    </div>
                       
                    
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='rmas_written_received.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report looks at all RMAs created during the selected time frame.</p>
                    <br></br>
                    <p><b>Report Interval:</b> </p>
                    <p>This parameter filters for RMAs created over a given time interval. Delay offsets the interval by the given number of days. For example, interval=60 and delay=30 will show RMA's written between 30 and 90 days ago. 
                    </p>
                    <i>***No delay is included when selecting a custom date range.</i>
                    <br></br>
                    <br></br>
                    <p><b>Sell/Consign/Trade:</b> </p>
                    <p>If the payment method assigned to an RMA is "trade credit" it is considered trade for the purpose of this report. Otherwise, an RMA is considered "consign" if one or more items are marked consign, and "sell" if not. 
                    </p>
                    <br></br>
                    <p><b>Caveats: </b></p>
                    <p>- Rep shown is the rep assigned to the associated submissions</p>
                    <p>- Submission rep data not populated until late 12/22</p>
                    <p>- Receiving data was not collected until late 7/22</p>
                </div>

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType=='summary' ? <>
                <TableList columnNames={['Submission Rep', 'Written RMAs', 'Received RMAs', 'Received List Value', 'Conversion','S/C/T Written Ratio','S/C/T Conversion']}
                        columnWidths={['15%','15%','15%','15%','15%','20%','20%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.submission_rep,
                                    row?.total_rmas,
                                    row?.received_rmas,
                                    row?.received_list_value,
                                    row?.received_percentage,
                                    row?.sell_consign_trade_ratio,
                                    row?.sell_consign_trade_conversion
                                ]
                            })
                        } />
            </> : <>
                <label className="light-text text-left bg-" for="subRep" style={{padding: '4px 15px'}}  >Submission Rep:      
                    <select  name="subRep" id="selectSubRep" onChange={e => setSubRep(e.target.value)}>
                        <option selected value="all" >Select an Option</option>
                        <option value="Ben Klenk" >Ben Klenk</option>
                        <option  value="Devin Schmidt" >Devin Schmidt</option>
                        <option value="Ian LaHood" >Ian Lahood</option>
                        <option value="Jason Manning" >Jason Manning</option>
                        <option value="Jerry Kall" >Jerry Kall</option>
                        <option value="Mario Alonzo" >Mario Alonzo</option>
                        <option value="Michael Horne" >Michael Horne</option>
                        <option value="Nicholas Lucini" >Nicholas Lucini</option>
                        <option value="Ryan Tarasen" >Ryan Tarasen</option>
                    </select>
                </label>
                <TableList columnNames={['Submission Rep', 'RMA Number', 'Created', 'Received','Received List Value', 'Type']}
                    columnWidths={['20%','20%','20%','20%','20%','20%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.rep_id,
                                row?.tpa_number,
                                row?.created,
                                row?.received,
                                row?.received_list_value,
                                row?.sell_consign_trade
                            ]
                        })
                    } />
            </>}       
            
        </div>
    );
}

export default RmasWrittenReceived;