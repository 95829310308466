import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import AppContext from '../AppContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import {dateDifference, formatDate, secureFetch} from '../util/flip_utils';
import KeyValuePair from '../components/KeyValuePair';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import ImageModalContainer from '../components/ImageModalContainer';
import ModalContainer from '../components/ModalContainer';
import AppraisalPopup from '../components/AppraisalPopup';
import FlipRTE from '../components/FlipRTE';
import FlipRTV from '../components/FlipRTV';
import ShippingBudgetCalculator from '../components/ShippingBudgetCalculator';
import TabbedDisplay from '../components/TabbedDisplay';
import { CosmeticColorCoder, RatingColorCoder } from '../components/ColorCoders';
import EditDimensionsForm from '../components/EditDimensionsForm';
import CheckmarkYN from '../components/CheckmarkYN';


const AppraiseItem = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { item_id } = useParams();
    let [fetched, setFetched] = useState(false); //lets us render depending on whether the data is done fetching
    let [item, setItem] = useState({}); //submission item in question
    let [sales, setSales] = useState([]); //list of sales data on past items
    let [appraisals, setAppraisals] = useState([]); //list of appraisal data on past submissions
    let [marketValue, setMarketValue] = useState(null); //these two are for the entry form
    let [listPrice, setListPrice] = useState(null);
    let [buyPrice, setBuyPrice] = useState(null);
    let [appraisingNotes, setAppraisingNotes] = useState(null);
    let [testerNotes, setTesterNotes] = useState(null);
    let [product, setProduct] = useState({}); //holds the product info associated with the item
    let [inThePipeline, setInThePipeline] = useState([]);
    let [currentListings, setCurrentListings] = useState([]);
    let [pipelineFetched, setPipelineFetched] = useState(false);
    let [recentSubs, setRecentSubs] = useState([]);
    let [quotes, setQuotes] = useState([]);
    let [passReasons, setPassReasons] = useState([]);

    let [formDisable, setFormDisable] = useState(false);
    
    function fetchData() {
        setFetched(false);
        if(!props?.location?.state?.item_id && !item_id) {
            alert('No Item ID given')
            return;
        }

        secureFetch(context.server + '/product-appraising-data', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: item_id ? item_id : props?.location?.state?.item_id}) 
        })
        .then(response => response.json())
        .then(results => { //once we get the item we want...
            //set the new state, updating the quote info if the item has already been appraised
            results.submissionItem.quote = results?.quotes?.filter(q => q?.id==results?.submissionItem?.quote_id)[0];
            setItem(results?.submissionItem);
            setMarketValue(results?.submissionItem?.quote?.market_value);
            setListPrice(results?.submissionItem?.quote?.rec_list_price);
            setBuyPrice(results?.submissionItem?.quote?.rec_buy_price);
            setAppraisingNotes(results?.submissionItem?.quote?.notes);
            setTesterNotes(results?.submissionItem?.quote?.tester_notes);
            // setSales(results?.sales);
            setAppraisals(results?.appraisals);
            setProduct(results?.product);
            setRecentSubs(results?.recentSubs);
            setQuotes(results?.quotes);

            //default to pass and reason = "Do not buy" if rated F
            if(results?.product?.rating == 'F') {
                setItem({...results?.submissionItem, pass: 1, pass_reason_id: 3})
            }
            setFetched(true);
        })
    }

    function fetchPassReasons() {
        secureFetch(context?.server + '/get-all', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({tableName: 'pass_reasons'}) 
        })
        .then(resp => resp.json())
        .then(results => {
            setPassReasons(results);
        })
    }

    function fetchProductPipelineData(){
        secureFetch(context?.server + '/product-pipeline-data', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({product_id: item?.product_id}) 
        })
        .then(res => res.json())
        .then(data => {
            

            ///clean newline characters for unshipped items report
            for(let r = 0; r < data?.unshippedItems?.length; r++) {
                let cols = Object.keys(data?.unshippedItems[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data?.unshippedItems[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data.unshippedItems[r][cols[c]] = newrow;
                }
            }
            // do the same for shipped items
            for(let r = 0; r < data?.shippedItems?.length; r++) {
                let cols = Object.keys(data?.shippedItems[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data?.shippedItems[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data.shippedItems[r][cols[c]] = newrow;
                }
            }
            setInThePipeline(data?.unshippedItems);
            setSales(data?.shippedItems)
            setPipelineFetched(true)
        })
    }

    function fetchCurrentlyListed() {
        secureFetch(context.server + '/currently-listed', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({items: [item]})
        })
        .then(response => response.json())
        .then(data => data?.[0]?.listings)
        .then(data=>{setCurrentListings(data)})
    }



    //fetch pipeline when item product_id changes
    useEffect(() => {
        if(item?.product_id > 0){ //only fetch if it exists
            fetchProductPipelineData();
            fetchCurrentlyListed();
        }
    }, [item?.product_id])

    useEffect(() => {
        fetchData(); //fetch data on component load
        fetchPassReasons();
    },[])


    function rmChangeHandler(event) {
        setMarketValue(event.target.value);
    }

    function lpChangeHandler(event) {
        setListPrice(event.target.value);
    }

    function bpChangeHandler(event) {
        setBuyPrice(event.target.value);
    }

    function saveAppraisal(event) {
        event.preventDefault();
        setFormDisable(true)
        secureFetch(context.server + '/save-appraisal', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                item: item,
                quote: {
                    id: item?.quote_id,
                    market_value: `${marketValue}`,
                    rec_list_price: `${listPrice}`,
                    rec_buy_price: `${buyPrice}`,
                    notes: `${appraisingNotes}`,
                    tester_notes: `${testerNotes}`,
                }
                
            }) 
        })
        .then(() => {
            setFormDisable(false);
            alert('Appraising Info Saved!');
            fetchData();
        })
    }


    function submit(event) { //called by the submit button to update the submission item with the quote info and who appraised it
        //console.log(event)
        event.preventDefault();
        setFormDisable(true)
        secureFetch(context.server + '/submit-appraisal', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                item: item,
                quote: {
                    market_value: `${marketValue}`,
                    rec_list_price: `${listPrice}`,
                    rec_buy_price: `${buyPrice}`,
                    user_id: `${context?.user?.id}`,
                    notes: `${appraisingNotes}`,
                    tester_notes: `${testerNotes}`,
                }
                
            }) 
        })
        .then(() => {
            history.push({pathname: `/submission-view/${item?.submission_id}`, state: {submission_id: item?.submission_id}})
            window.location.reload()
        })
    }

    function createDocument(docType) {
        secureFetch(context.server + '/create-document', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({product: product, docType: docType})
        })
        .then(res => res.json())
        .then(data => setProduct({...product, appraising_notes_link: data?.link}));
    }

    async function copyCalculation(data) {
        let copy = '';
        let keys = Object.keys(data);
        let vals = Object.values(data);

        for(let i = 0; i < keys?.length; i++) {
            copy += `\r${keys[i]}:\t${parseInt(vals[i])==vals[i] ? '$' : ''}${vals[i]}`
        }
        //alert(appraisingNotes)
        await navigator.clipboard.writeText(copy);
        //alert('copied!')
    }
    
    if(!props?.location?.state?.item_id && !item_id) {
        return <h1>Appraising information not found...</h1>
    }

    if(!fetched) {
        return <h1 className='light-text'>Fetching Appraising Information...</h1>
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained'>Appraising Information</h1>
                <Link className='btn dark-text contained' to={{pathname: `/submission-view/${item?.submission_id}`, state: {submission_id: item?.submission_id}}}>Back To Submission</Link>
            </div>
                <div className='container'>
                    <div className='contained'>
                        <InfoBox title='Item Information:'>
                            <KeyValuePair label={'Customer:'} value={item?.customer} textClass={'dark-text'} />
                            <br/>
                            <KeyValuePair label={'Product Title - Customer:'} value={item?.manufacturer + " " + item?.model_number} textClass={'dark-text'} />
                            <KeyValuePair label={'Product Title - TMR:'} value={
                                <Link className='btn dark-text' to={{pathname: `/product-view/${product?.id}`, state: {product_id: product?.id}}}>{product?.title}</Link>
                            } textClass={'dark-text'} />
                            <KeyValuePair label='Product Options:' value={product?.options} textClass='dark-text' />
                            <KeyValuePair label={'Product Shipping Notes:'} value={product?.shipping_notes} textClass='dark-text' />
                            <KeyValuePair label={'Brand-level Notes:'} value={<p className='blue-text'>{product?.brand_notes}</p>} textClass='dark-text' />
                            <KeyValuePair label={'Category-level Notes:'} value={
                                product?.classifications.every((c)=>c.notes == null)?
                                    <>-</>
                                    :
                                    product?.classifications.map( (row) =>
                                        row.notes? 
                                            <div className='blue-text'>
                                                <u >{row?.name+' Notes:'}</u><p>{row?.notes}</p>
                                            </div>
                                        :<></>
                                )} textClass='dark-text'/>
                            <br/>
                            <KeyValuePair label={'Age:'} value={item?.actual_age_of_item == -1 ? "Unknown"
                            : item?.actual_age_of_item == null ? '-'
                            : item?.actual_age_of_item == 0 ? "Less than 1 year"
                            : item?.actual_age_of_item + ' Year(s)'} textClass='dark-text' />
                            <KeyValuePair label={'Number of Owners:'} value={item?.number_of_owners == -1 ? 'Unknown'
                            : item?.number_of_owners == null ? "-"
                            : item?.number_of_owners >= 4 ? "4 or more" : item?.number_of_owners} textClass='dark-text' />
                            <KeyValuePair label={'Cosmetic Condition:'} value={<CosmeticColorCoder content={item?.cosmetic_condition == 11 ? null : (item?.cosmetic_condition || 'N/A')} cosmetic={item?.cosmetic_condition} />} textClass='dark-text' />
                            <KeyValuePair label={'Tobacco:'} value={item?.tobacco_exposure===1 ? 'Yes' : 'No'} textClass='dark-text' />
                            <KeyValuePair label={'What Is Included:'} value= {String(item?.what_is_included).split(',').join(', ')} textClass={'dark-text'} />
                            <KeyValuePair label={'MSRP (from customer):'} value={`$${item?.original_msrp}`} textClass='dark-text' />
                            <KeyValuePair label={'Desired Price (from customer):'} value={`$${item?.desired_price}`} textClass='dark-text' />
                            <br/>
                            <KeyValuePair label={'Item Notes:'} value= {item?.notes} textClass={'dark-text'} />
                            <KeyValuePair label={'Reason for Selling:'} value= {item?.reason_for_selling} textClass={'dark-text'} />
                            {item?.reason_for_selling === 'Upgrading' ?
                                <KeyValuePair label={'Upgrading to:'} value= {item?.upgrading_to} textClass={'dark-text'} />
                                : <></>}
                                <br/>
                            <KeyValuePair label={'Submission Notes:'} value={item?.additional_comments} textClass={'dark-text'} />
                        </InfoBox>
                        <br/>

                        {item?.file1 || item?.file2 || item?.file3 || item?.file4 ? <>
                        <h2 className='light-text'>Customer Images:</h2>
                        <div className='container'>
                            {item?.file1 ? 
                            <div className='contained'><ImageModalContainer styleprops={{height: '100px'}} source={item?.file1} alter={item?.manufacturer + ' ' + item?.model_number}  /></div>
                            : <></>}
                            {item?.file2 ? 
                            <div className='contained'><ImageModalContainer styleprops={{height: '100px'}} source={item?.file2} alter={item?.manufacturer + ' ' + item?.model_number}  /></div>
                            : <></>}
                            {item?.file3 ? 
                            <div className='contained'><ImageModalContainer styleprops={{height: '100px'}} source={item?.file3} alter={item?.manufacturer + ' ' + item?.model_number}  /></div>
                            : <></>}
                            {item?.file4 ? 
                            <div className='contained'><ImageModalContainer styleprops={{height: '100px'}} source={item?.file4} alter={item?.manufacturer + ' ' + item?.model_number}  /></div>
                            : <></>}
                        </div>
                        </> : <></>}
                        <br/>
                        {pipelineFetched ? 
                         <div>
                            <h1 className='light-text'>In the Pipeline:</h1>                        
                            <TableList columnNames={['SKU','RMA','Status','Buy Date']}
                            columnWidths={['20%','25%','30%','25%']}
                            entries={inThePipeline.length > 0 ?
                                inThePipeline?.map(row => {
                                    return [
                                        <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                                        row?.tpa_number == "null" ? 'None':<Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`}}>{row?.tpa_number}</Link>  ,
                                        row?.display_status,
                                        formatDate(row?.created, {date: true})                              
                                    ]
                                })
                                : [['No items in the pipeline','','','']]
                            } />
                         </div>
                        : <div className='bg-light'>Loading Pipeline Data...</div>}

                        {currentListings ?
                            <div> 
                                <br/>
                                <h3 className='light-text'>Current Listings:</h3>
                                <TableList className = 'bg-light'
                                    columnNames={['Sku','List Price','Days Listed']}
                                    columnWidths={['33%','33%','33%']}
                                    entries={
                                        currentListings.map( item => {
                                            return [
                                                item?.open_box == 0 ? <a className="btn dark-text" rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku}</a> : <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku} (OB)</a>,
                                                '$'+item?.price,
                                                item?.daysListed
                                            ]
                                        })
                                    }
                                /> 
                            </div>
                        :
                            <div> 
                                <br/>
                                <h3 className='light-text'>Current Listings:</h3>
                                <TableList className = 'bg-light'
                                            columnNames={['Sku','List Price','Days Listed']}
                                            columnWidths={['33%','33%','33%']}
                                            entries={
                                                [['No current listings']]
                                            }
                                />
                            </div>
                        }
                         
                           
                    </div>
                    <div className='contained' style={{maxWidth: '50%'}}>
                        <InfoBox title='Item Appraisal:'>
                            
                                <form id='AppraiseItem' onSubmit={submit} />
                                <fieldset form='AppraiseItem' disabled={formDisable ? 'disabled' : ''}>
                                    <div className='container'>
                                        <div className='contained' style={{maxWidth: '40%'}}>
                                            <>{parseFloat(buyPrice) > ((
                                                product?.rating=='A' ? 0.85 : 
                                                product?.rating=='B' ? 0.80 :
                                                0.75
                                            )*parseFloat(listPrice)) ?
                                                <div className="bg-orange light-text rounded" style={{padding: '2px 2px'}}>bp may be too high!</div>
                                                : <></>}</>
                                            <label className='dark-text text-left'><b>Quantity:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input style={{maxWidth:'90px'}} form='AppraiseItem' type='number' min='0' value={item?.quantity} onChange={e => setItem({...item, quantity: e.target.value})}>
                                                </input></label><br/>
                                            <label className='dark-text text-left'><b>Buy Price:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input style={{maxWidth:'90px'}} form='AppraiseItem' type='number' min='0' value={buyPrice} onChange={bpChangeHandler}>
                                                </input></label><br/>    
                                            <label className='dark-text text-left'><b>List Price: </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input style={{maxWidth:'90px'}} form='AppraiseItem' type='number' min='0' value={listPrice} onChange={lpChangeHandler}>
                                                </input> </label><br/>
                                            <label className='dark-text text-left'><b>Market Value: </b>&nbsp;
                                                <input style={{maxWidth:'90px'}} form='AppraiseItem' type='number' min='0' value={marketValue} onChange={rmChangeHandler}>
                                                </input></label>
                                            <br/><br/><br/>

                                            <button style={{width: '180px'}} className={item?.is_consignment=='1' ? 'btn bg-blue dark-text' : 'btn dark-text'}
                                            disabled={formDisable ? 'disabled' : ''} onClick={() => {
                                                if(item?.is_consignment=='1') setItem({...item, is_consignment: '0'})
                                                else setItem({...item, is_consignment: '1'});
                                            }}>Consignment</button>

                                        

                                        </div>
                                        <div className='contained' style={{maxWidth: '60%'}}>
                                            
                                            <KeyValuePair label={'Product Rating:'} value={<RatingColorCoder content={product?.rating} rating={product?.rating} />} textClass={'dark-text'}/>
                                            <br/>
                                            <KeyValuePair label={'Brand Partner:'} value={product?.brand_partner ? <p className = 'green-text'>True</p> : <p className = 'red-text'>False</p> } textClass={'dark-text'}/>
                                            <br/>
                                            <label className='dark-text text-left'>&nbsp;&nbsp;&nbsp;&nbsp;<b>Internal Product Notes:</b></label>
                                            <textarea disabled style={{width: '100%'}} >{product?.internal_notes || 'none'}</textarea>
                                            <br/>
                                            <br/>
                                            <div className='text-right'><button style={{width: '180px'}} className={item?.has_packaging=='1' ? 'btn bg-green light-text' : 'btn bg-red light-text'}
                                            disabled={formDisable ? 'disabled' : ''} onClick={() => {
                                                if(item?.has_packaging=='1') setItem({...item, has_packaging: '0'})
                                                else setItem({...item, has_packaging: '1'});
                                            }}>{item?.has_packaging=='0' ? 'No ':''}Box</button></div>
                                            <br/><br/>
                                            
                                        </div>
                                    </div>
                                    <br/>
                                    <div className='container'>
                                        <div className='contained text-left'>
                                            <button style={{width: '180px'}} className={item?.pass=='1' ? 'btn bg-red light-text' : 'btn dark-text'}
                                            disabled={formDisable ? 'disabled' : ''} onClick={() => {
                                                if(item?.pass=='1') setItem({...item, pass: '0'})
                                                else setItem({...item, pass: '1'});
                                            }}>Pass</button>
                                            <br/>
                                            {item?.pass=='1' ? <>
                                                <div className='container'>
                                                    <div className='contained'>
                                                        <label className='dark-text text-left'>Reason for Passing:&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <select form='AppraiseItem' onChange={ e => setItem({...item, pass_reason_id: e.target.value}) } value={item?.pass_reason_id}>
                                                            <option value={-1} key={0}>*** SELECT PASS REASON ***</option>
                                                            {passReasons.map( (row) => 
                                                                <option key={row?.id} value={row?.id} >{row?.reason}</option>
                                                            )}
                                                        </select></label>
                                                    </div>
                                                </div></> : <></>}
                                        </div>
                                        <div className='contained text-right'>
                                            <div className='text-right'><ModalContainer triggerText='Dimensions' formData={item} FormComponent={EditDimensionsForm}
                                                submitForm={(dims) => setItem({...item, weight: dims?.weight, width: dims?.width, depth: dims?.depth, height: dims?.height, boxes: dims?.boxes, dim_type: dims?.dim_type, freight: dims?.freight})} /></div>
                                        </div>
                                    </div>
                                    <br/><br/><br/>
                                    <div className='container'>
                                        <div className='contained' style={{maxWidth: '150px'}}><a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://script.google.com/a/tmraudio.com/macros/s/AKfycbyRk6QNRk_y6m1yCRKl0pyA9ktUYtVn_OxDTy8DU9lod97uiLQ/exec?appraisalPricing'}>Pricing Calculator</a></div>
                                        {/* <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://docs.google.com/spreadsheets/d/1N1hBdTEjdELS3P1M8V9wzswmfR63ORl25aa9adNI3q0/edit#gid=0'}>Shipping Budget calculator</a> */}
                                        <div className='contained text-right'><ModalContainer buttonClass='btn dark-text' triggerText='Shipping Budget Calculator' formData={{}} FormComponent={ShippingBudgetCalculator} submitForm={copyCalculation} /></div>
                                        {/* <div className='contained' style={{width: '80px'}}></div> */}
                                        
                                    </div>
                                    <br/>
                                    <KeyValuePair label={'Product Appraising Notes:'} value = {product?.appraising_notes} textClass='dark-text' />
                                    <br/>
                                    {/* <div className='container'>
                                        <label className='contained dark-text text-left'>
                                            <input form='AppraiseItem' type='checkbox' checked={item?.is_consignment=='1'} onChange={e => setItem({...item, is_consignment: e.target.checked ? '1' : '0'})} />
                                        &nbsp;&nbsp;Mark as Consignment</label>
                                        
                                        <label className='contained dark-text text-left'>
                                            <input form='AppraiseItem' type='checkbox' checked={item?.pass=='1'} onChange={e => setItem({...item, pass: e.target.checked ? '1' : '0'})} />
                                        &nbsp;&nbsp;Pass on this Item</label>
                                        
                                    </div>
                                    <br/><br/> */}
                                    <br/>
                                    <label className='dark-text text-left'><b>Appraising Notes (for this item):</b></label>
                                    {/* <textarea form='AppraiseItem' style={{minWidth: '90%', width: '90%', maxWidth: '90%', padding: '5px 5px'}} rows='10'
                                    value={appraisingNotes || ''}
                                    onChange={e => setAppraisingNotes(e.target.value)} /> */}
                                    <FlipRTE text={appraisingNotes} updateParent={e => setAppraisingNotes(e.target.value)} />
                                    <br/>
                                    <label className='text-left'><b>Notes to Tester:</b><br/>
                                    <textarea form='AppraiseItem' style={{padding: '5px 5px', minWidth: '100%', width: '100%', maxWidth: '100%'}} rows='5' value={testerNotes} onChange={e => setTesterNotes(e.target.value)} />
                                    </label>
                                    <br/>
                                    <input form='AppraiseItem' className='btn dark-text' type='submit' value='Submit New Appraisal' />
                                    {item?.quote_id ? <><br/><br/>
                                        {item?.quote?.user_id == context?.user?.id ? <button className={quotes?.length > 0 ? 'btn bg-green light-text' : 'btn dark-text'} onClick={saveAppraisal}
                                        >Save Current Appraisal</button> : <></>}</>
                                    : <></>}
                                </fieldset>
                        </InfoBox>
                        <div className='container'>
                            <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://www.google.com/search?q=' + String(product?.brand).split(' ').join('+') + '+' + String(product?.model).split(' ').join('+')}>Google</a>
                            <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://www.ebay.com/sh/research?marketplace=ALL&keywords' + String(product?.brand).split(' ').join('+') + '+' + String(product?.model).split(' ').join('+')}>eBay</a>
                            <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://reverb.com/marketplace?product_type=home-audio&query=' + String(product?.brand).split(' ').join('%20') + '%20' + String(product?.model).split(' ').join('%20')}>Reverb</a>
                        </div>
                        <div className='container'>
                            <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://www.hifishark.com/search?q=' + String(product?.brand).split(' ').join('+') + '+' + String(product?.model).split(' ').join('+')}>HI-FI Shark</a>
                            <a className='btn dark-text' rel="noreferrer" target='_blank' href={'https://www.audiogon.com/bluebook/search?q=' + String(product?.brand).split(' ').join('+') + '+' + String(product?.model).split(' ').join('+')}>Audiogon</a>
                            {product?.appraising_notes_link ? 
                            <a className='btn dark-text' rel='noreferrer' target='_blank' href={product?.appraising_notes_link}>Product Document</a>
                            :
                            <button className='btn dark-text' onClick={() => createDocument('appraising')}>Create Product Document</button>
                            }
                        </div>
                        {quotes?.length > 0 ? <>
                        <h3 className='light-text'>Past Quotes:</h3>
                        <TableList columnNames={['Date','Appraiser','BP','LP','MV',' ']}
                        columnWidths={['20%','20%','15%','15%','15%','15%']}
                        entries={
                            quotes?.map( row => {
                                return [
                                    formatDate(row?.created, {date:true}),
                                    row?.appraiser,
                                    row?.rec_buy_price,
                                    row?.rec_list_price,
                                    row?.market_value,
                                    <ModalContainer triggerText='Details' formData={row} FormComponent={AppraisalPopup} submitForm={() => {}} />
                                ]
                            })
                        }
                        /></> : <></>}
                    </div>
                </div>
                <br/>
                <TabbedDisplay tabNames={['Past Sales', 'Past Appraisals']}
                contents={[
                    <>  
                        {pipelineFetched ? 
                        <div>
                            <br/>
                            <h3 className='light-text'>Past Sales:</h3>
                            <br/>
                        
                            <div>
                                <TableList columnNames={['SKU','Title','Order Date','Cosmetic Condition','Factory Packaging','Sale Price','List to Sale']}
                                columnWidths={['10%','38%','10%','10%','10%','12%','10%']}
                                entries={sales?.map(row => {
                                    return [
                                        row?.sku ? <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link> : '***Error***',
                                        row?.title,
                                        row?.order_date.substr(5,2)+'/'+row?.shipped_date.substr(2,2),
                                        row?.cosmetic_condition_id,
                                        <CheckmarkYN val={row?.has_packaging ==1 ? true : false} />,
                                        '$'+row?.sale_price,
                                        row?.list_to_sale ? row?.list_to_sale + ' Days': 'N/A'                                                    
                                    ]
                                })} />
                            </div>
                        </div>   
                        : <><h3 className='light-text bg-light'>Loading past sale data...</h3></>}


                        {/* <TableList columnNames={['SKU','Title','Cosmetic','Buy Price','List Price','MV','Sale Price','Days to Sale','Sale Date']}
                        columnWidths={['10%','30%','6%','8%','8%','8%','10%','10%','10%']}
                        entries={
                            sales.map( info => {
                                return [
                                    info?.sku.split('*')[0],
                                    info?.title,
                                    <CosmeticColorCoder content={info?.cosmetic_condition == 11 ? null : (info?.cosmetic_condition || 'N/A')} cosmetic={info?.cosmetic} />,
                                    //info?.buyer ? info?.buyer : 'N/A',
                                    //info?.is_consignment===1 ? 'Yes' : 'No',
                                    info?.is_consignment ? 'C' : info?.buy_price ? `$${info?.buy_price}` : 'N/A',
                                    //info?.list_price ? `$${info?.list_price}` : 'N/A',
                                    info?.listing_price ? `$${info?.listing_price}` : 'N/A',
                                    info?.market_value ? <ModalContainer triggerText={`$${info?.market_value}`} 
                                        formData={{
                                            rec_list_price: info?.rec_list_price,
                                            rec_buy_price: info?.rec_buy_price,
                                            market_value: info?.market_value,
                                            notes: info?.quote_notes,
                                            appraiser: info?.appraiser,
                                            created: info?.quote_created,
                                        }} 
                                        FormComponent={AppraisalPopup}
                                        submitForm={()=>{}}
                                    /> : 'N/A',
                                    info?.sale_price ? `$${info?.sale_price}` : 'N/A',
                                    //info?.listing_date ? formatDate(info?.listing_date, {date:true}) : 'N/A',
                                    info?.sale_date && info?.listing_date ? dateDifference(info?.listing_date, info?.sale_date) : 'N/A',
                                    info?.sale_date ? formatDate(info?.sale_date, {date: true}) : 'N/A',
                                ]
                            })
                        }
                        /> */}
                    </>,
                    <>
                        <br/>
                        <h2 className='light-text'>Past Appraisals:</h2>
                        <br/>
                        <TableList columnNames={['Sub.','Appraiser','Cosm.','Box','BP','LP','MV','Notes','Purchase?','Date']}
                        columnWidths={['5%','10%','5%','3%','8%','7%','7%','30%','10%','15%']}
                        entries={
                            appraisals.map( info => {
                                return [
                                    info?.submission_id ? 
                                        <Link className='btn dark-text' to={{pathname: `/submission-view/${info?.submission_id}`, state: {submission_id: info?.submission_id}}}>{info?.submission_id}</Link>
                                        : 'N/A',
                                    info?.appraiser,
                                    <CosmeticColorCoder content={info?.cosmetic_condition == 11 ? null : (info?.cosmetic_condition || 'N/A')} cosmetic={info?.cosmetic_condition} />,
                                    info?.has_packaging ? <p className='green-text'>Y</p> : <p className='red-text'>N</p>,
                                    info?.rec_buy_price,
                                    info?.rec_list_price,
                                    info?.market_value,
                                    <FlipRTV text={info?.notes} />,
                                    info?.pass==1 ? 'Pass' : (info?.is_consignment==1 ? 'Consign' : 'Purchase'),
                                    formatDate(info?.appraisal_date, {date: true}),
                                ]
                            })
                        }
                        />
                    </>
                ]} />
        </div>
    );
}

export default AppraiseItem;