import { useContext, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { secureFetch } from "../../util/flip_utils";


const TestingReport = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);
    let [fetched, setFetched] = useState(true);

    function generateReport(event) {
        event.preventDefault();
        setFetched(false);
        secureFetch(context.server + '/reports/testing', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({startDate: startDate, endDate: endDate})
        })
        .then(res => res.json())
        .then(data => {
            setReport(data);
            setFetched(true);
        })
    }

    function reportAsString() {
        if(report.length < 1)
            return 'No Report Available';
        let report_string = 'Testing Report:\n\n';
        let meta = report[4][0]?.meta
        report_string += meta?.reportType==='range'
        ? `Date Range:\t${meta?.startDate} to ${meta?.endDate}`
        : `Date:\t\t${meta?.startDate}`
        report_string += '\n\nTesters:\n';
        report[0].forEach( tester => {
            report_string += `\t(id:${tester?.tester_id}) ${tester?.full_name}:\t${tester?.items_tested} items tested\n`;
        })

        report_string += `\nTotal Items Tested:\t\t${report[1][0]?.total_testings}`;
        report_string += `\nTotal Consignment Items Tested:\t${report[2][0]?.consignment_testings}`;
        report_string += `\nTotal RMA Items Tested:\t\t${report[3][0]?.tpa_testings}`;
        
        return report_string;
    }

    return(
        <div>
            <h1>Testing Report</h1>
            <div style={{padding: '30px'}} className='bg-lightest'>
                <div className='container'>
                    <div className='contained'>
                        <div className='container'>
                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                        </div>
                        <h6>ISO format: {startDate ? startDate.toISOString() : ''}</h6>
                        <div className='container'>
                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                        </div>
                        <h6>ISO format: {endDate ? endDate.toISOString() : ''}</h6>
                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                        <button onClick={generateReport} className='btn bg-dark light-text'>Submit</button>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                    <div className='contained'>
                        <textarea style={{width: '100%', minHeight: '300px', padding: '10px'}} readOnly value=
                            { !fetched ? 'Generating Report...' :
                                fetched && Object.keys(report).length > 0 ? reportAsString() :
                                    'No Report Available' }>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestingReport;