import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const EditCustomerForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [customer, setCustomer] = useState({})
    let [emails, setEmails] = useState([]);
    let [formDisable, setFormDisable] = useState(false);


    /* eslint-disable */
    useEffect(() => {
        setCustomer(formData['customer']);
        setEmails(formData['emails']);
    }, [])
    /* eslint-enable */

    const submit = (e) => {
        setFormDisable(true);
        e.preventDefault();
        secureFetch(context.server + '/edit-customer', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer: {
                    id: customer?.id,
                    name: customer?.name,
                    phone: customer?.phone,
                    notes: customer?.notes,
                    active: customer?.active,
                    blacklisted: customer?.blacklisted,
                    btob: customer?.btob,
                    company_name: customer?.company_name,
                    email: emails[0]?.email,
                },
                emails: emails
            })
        })
        .then(resp => resp.json())
        .then((cust) => {
            submitForm(cust);
            closeModal();
        })
    }

    const addEmail = () => {
        let newEmails = emails.map(e => e);
        newEmails.push({
            customer_id: customer['id'],
            email: ''
        })
        setEmails(newEmails);
    }

    const removeEmail = (index) => {
        let newEmails = emails.map(e=>e);
        newEmails.splice(index, 1);
        setEmails(newEmails);
    }

    return (
        <div>
            <form id='EditCustomerForm' onSubmit={submit} />
            <fieldset form='EditCustomerForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Edit Customer</h1>
                    <label className='dark-text'><b>Name: </b>
                    <input form='EditCustomerForm' required style={{width: '80%', height: '30px'}} type='text' value={customer?.name} onChange={e => setCustomer({...customer, name: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>Phone Number: </b>
                    <input form='EditCustomerForm' style={{width: '80%', height: '30px'}} type='text' value={customer?.phone} onChange={e => setCustomer({...customer, phone: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>Notes: </b>
                    <textarea form='EditCustomerForm' style={{maxWidth: '80%', minWidth: '80%', width: '80%'}} rows='10' value={customer?.notes} onChange={e => setCustomer({...customer, notes: e.target.value})} />
                    </label>
                    {emails.map( (row, ind) => {
                        return <label className='dark-text'><b>Email: </b>
                        <input form='EditCustomerForm' required style={{width: '60%', height: '30px'}} type='text' value={emails[ind]?.email} onChange={ev => {
                            let newEmails = emails.map(e=>e);
                            newEmails[ind].email = ev.target.value
                            setEmails(newEmails);
                        }} />
                        <button style={{width: '20%'}} className='btn bg-red dark-text' onClick={(e) => {
                            e.preventDefault();
                            removeEmail(ind)
                        }}>Remove</button>
                        </label>
                    })}
                    <button className='btn bg-dark light-text' onClick={(e) => {e.preventDefault();addEmail()}}>Add Email Address</button>
                    <br/><br/>
                    <label className='dark-text text-left'><b>Active?: </b>
                    <input form='EditCustomerForm' type='checkbox' checked={customer?.active===1} onChange={ (e) => setCustomer({...customer, active: e.target.checked ? 1 : 0})}/>
                    </label>
                    <br/>
                    <label className='dark-text text-left'><b>BLACKLISTED?: </b>
                        <label className="dark-text text-left"><input form='EditCustomerForm' type='radio' checked={customer?.blacklisted==1} onChange={ (e) => setCustomer({...customer, blacklisted: e.target.checked ? 1 : 0})}/>&nbsp;&nbsp;Yes</label>
                        <label className="dark-text text-left"><input form='EditCustomerForm' type='radio' checked={customer?.blacklisted==0} onChange={ (e) => setCustomer({...customer, blacklisted: e.target.checked ? 0 : 1})}/>&nbsp;&nbsp;No</label>
                    </label>
                    <br/>
                    <label className='dark-text text-left'><b>B-to-B?: </b>
                    <input form='EditCustomerForm' type='checkbox' checked={customer?.btob===1} onChange={ (e) => setCustomer({...customer, btob: e.target.checked ? 1 : 0})}/>
                    </label>
                    {customer?.btob==1 ? <>
                        <label className="dark-text text-left">Company Name:&nbsp;&nbsp;
                            <input form='EditCustomerForm' type='text' value={customer?.company_name} onChange={e => setCustomer({...customer, company_name: e.target.value})} />
                        </label>
                    </> : <></>}
                    <br/><br/>
                    <input form='EditCustomerForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </fieldset>
        </div>
    );
}

export default EditCustomerForm;