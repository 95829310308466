import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";


const DefectiveByTesterReport = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);

    function fetchReport() {
        secureFetch(context.server + '/reports/defective-by-tester', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data)
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context, startDate, endDate])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>Defective Returns by Tester Report</h1>
                <i className="light-text">{'   This data only exists from 5/25/2022 onward'}</i>
                <br/>
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                    <CSVLink data={report} filename='tester_return_performance_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink>
                    <br/>
                </div>
                <br/>
                <h2 className="light-text">Report at a glance:</h2>
                <TableList columnNames={['RA #', 'SKU', 'Original Tester', 'Reported Reason', 'Return Notes', 'Rec. Notes', 'Date']}
                columnWidths={['10%', '15%', '10%', '10%', '30%', '15%', '10%']}
                entries={report?.map(row => {
                    return [
                        row?.ra_id,
                        row?.sku,
                        row?.tester,
                        row?.reported_reason,
                        row?.return_notes,
                        row?.receiving_notes,
                        formatDate(row?.report_date, {date: true})
                    ]
                })}
                />
                <br/>
            </div>
        </div>
    );
}

export default DefectiveByTesterReport;