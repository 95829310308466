/**
 * TriggerButton.js
 * 
 * renders a button which will call the showModal function when pressed. this allows us to embed modal views in a button and conditionally display them.
 */


import React from 'react';

const TriggerButton = ({triggerText, buttonRef, showModal, buttonClass}) => {
    return (
        <button className={buttonClass} ref={buttonRef} onClick={showModal}>
            {triggerText}
        </button>
    );
};
export default TriggerButton;