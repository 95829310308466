import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import { RatingColorCoder } from "./ColorCoders";
import FlipRTV from "./FlipRTV";
import KeyValuePair from "./KeyValuePair";
import ShowHideBox from "./ShowHideBox";
import TableList from '../components/TableList';
import BrandPartnerLogo from "./BrandPartnerLogo";


const SendQuoteForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [submission, setSubmission] = useState({});
    let [items, setItems] = useState([]);
    let [customer, setCustomer] = useState({});

    let [saleCost, setSaleCost] = useState(0);
    let [saleValue, setSaleValue] = useState(0);
    let [buyValue, setBuyValue] = useState(0);
    let [buyCost, setBuyCost] = useState(0);
    let [nrtc, setNrtc] = useState(0);

    useEffect(() => {
        setBuyValue(items?.reduce((acc, curr) => {
            return acc + (parseInt(curr?.rec_list_price) || 0);
        }, 0))
        setBuyCost(items?.reduce((acc, curr) => {
            return acc + (parseInt(curr?.amount_offered) || 0);
        }, 0))
    }, [context, items])

    useEffect(() => {
        setNrtc(Math.max(Math.min(
            parseFloat((saleValue - saleCost) + (buyValue - buyCost)) * 0.1, 
            buyValue - buyCost
        ), 0).toFixed(2))
    }, [buyCost, buyValue, saleCost, saleValue])

    let [confirmed, setConfirmed] = useState(false);
    let [fetched, setFetched] = useState(false);

    function fetchOverview() {
        setFetched(false);
        secureFetch(context.server + '/submission-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({id: formData?.id})
        })
        .then(response => response.json())
        .then(data => {
            setSubmission(data?.submission);
            setItems(data?.items?.filter((item =>{return item?.active == 1})).map(i => {
                i.amount_offered = (i?.is_consignment || i.pass) ? 0
                    : i.amount_offered || parseInt(i?.rec_buy_price * 1) //removed offer reduction by 10%
                i.showNotes = true;
                return i;
            }));
            setCustomer(data?.customer);
            setFetched(true);
        })
    }

    //Get info about currently listed items of each product on the submission
  function fetchCurrentlyListed() {
        secureFetch(context.server + '/currently-listed', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({items: items})
        })
        .then(response => response.json())
        .then(data => { 
            let tempItems = items?.map(i=>i)
            for(let i=0;i<data?.length;i++){
               
               tempItems.find(item => item?.id == data[i].itemId).currentlyListed = data[i]
            }
            setItems(tempItems)
        })
    }

    useEffect(() => {
        fetchCurrentlyListed();
    }, [confirmed])

    useEffect(() => {
        fetchOverview();
    }, [])

    function submit(e) {
        //see if there are any F-rated products on the submission that were NOT passed or deactivated
        let f_rated = items?.reduce((acc, curr) => {
            return acc || (curr?.product_rating=='F' && curr?.pass==0 && curr?.active==1)
        }, false)
        if(f_rated) {
            alert("There are F-Rated items on this submission! Go back and Pass or Deactivate them to continue.")
            return;
        }
        e.preventDefault();
        secureFetch(context.server + '/send-quote', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                submission: submission,
                items: items,
                nrtc: {saleCost: saleCost, saleValue: saleValue, availableNrtc: parseFloat(nrtc).toFixed(2)}
            })
        })
        .then(() => {
            closeModal();
            submitForm();
        })
    }

    function updateItem(i, item) {
        let newItems = items?.map(i=>i);
        newItems[i] = item;
        setItems(newItems);
    }

    // if(!fetched) {
    //     return <h1>Fetching data...</h1>
    // }

    // //if they havent confirmed they read the notes yet, show them the notes and make them OK it
    // if(!confirmed) {
    //     return(
    //         <div className="bg-light" style={{padding: '15px 15px'}}>
    //             <h1 className="dark-text">Send Quote:</h1>
    //             <br/>
    //             <div className='bg-lightest' style={{padding: '15px 15px', minHeight: '200px'}}>
    //                 <p className="dark-text"><b>Preferred Rep:</b> {submission?.preferred_rep}</p>
    //                 <br/>
    //                 <h3 className="dark-text">Buyer Notes:</h3>
    //                 <p className="dark-text"><i>(Please read before continuing)</i></p>
    //                 <br/>
    //                 <FlipRTV text={submission?.notes} />
                    
    //             </div>
    //             <br/>
    //             <button className="btn light-text bg-dark" onClick={() => setConfirmed(true)}>Continue</button>
    //         </div>
    //     )
    // }

    return (
        

        <div className="bg-light" style={{padding: '15px 15px'}}>
            {!fetched ? <h1>Fetching data...</h1> 
            :<>
                {!confirmed ? 
                    <div className="bg-light" style={{padding: '15px 15px'}}>
                        <h1 className="dark-text">Send Quote:</h1>
                        <br/>
                        <div className='bg-lightest' style={{padding: '15px 15px', minHeight: '200px'}}>
                            <p className="dark-text"><b>Preferred Rep:</b> {submission?.preferred_rep}</p>
                            <br/>
                            <h3 className="dark-text">Buyer Notes:</h3>
                            <p className="dark-text"><i>(Please read before continuing)</i></p>
                            <br/>
                            <FlipRTV text={submission?.notes} />
                            
                        </div>
                        <br/>
                        <button className="btn light-text bg-dark" onClick={() => setConfirmed(true)}>Continue</button>
                    </div>
                :<>
                    <h1 className="dark-text">Send Quote</h1>
                    <br/>
                    <div className="bg-lightest" style={{padding: '10px 10px'}}>
                        <h3 className="dark-text">{submission?.first_name} {submission?.last_name} - <i>{submission?.email_address}</i></h3>
                    </div>
                    <br/>
                    {submission?.fd_id ?
                    <p className="dark-text"><b>FD Ticket:</b> <a className="btn bg-dark light-text" rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${submission?.fd_id}`}>View</a></p>
                    : <><div className="bg-red rounded" style={{padding: '15px 15px'}}><b className="light-text">NO FD TICKET FOR THIS SUBMISSION YET</b></div></>}
                    <br/>
                    <div style={{padding: '15px 15px'}} className='bg-lightest'>
                        <h2 className="dark-text">General Information:</h2><br/>
                        <KeyValuePair label={'Reason For Selling:'} value={submission?.reason_for_selling} textClass="dark-text" />
                        {submission?.reason_for_selling == 'Upgrading' ? <KeyValuePair label={'Upgrading To:'} value={submission?.upgrading_to} textClass="dark-text" /> : <></>}
                        {submission?.trade_towards ? <KeyValuePair label={'Trading Towards (from storefront):'} value={submission?.trading_towards} textClass="dark-text" /> : <></>}
                        <KeyValuePair label={'Interested In:'} value={submission?.interested_in} textClass="dark-text" />
                        <KeyValuePair label={'Location:'} value={submission?.state + ', ' + submission?.country + ` (${submission?.zip})`} textClass='dark-text' />
                        <KeyValuePair label={'Additional Comments:'} value={submission?.additional_comments} textClass="dark-text" />
                        <KeyValuePair label={'Buyer Notes:'} value={<ShowHideBox content={
                            <FlipRTV text={submission?.notes}/>
                        } />} textClass="dark-text" />
                        <br/><br/>
                        { items ? items.map((item, ind) => 
                        <>
                            {item?.active == '1' ? 
                            <>
                                <li className="dark-text bg-light rounded" style={{padding: '15px 15px'}}>
                                    <div className="container">
                                        { item?.brand_partner ?
                                            <h3 className="contained bg-dark text-white rounded vertically-aligned" style={{padding: '15px 15px'}}>Title:&nbsp;&nbsp;<BrandPartnerLogo height = {'30px'}/> &nbsp; {item?.manufacturer} {item?.model_number}{item?.has_packaging=='1' ? <></> : <>&nbsp;<b className="bg-red text-white">NO BOX</b></>}</h3>
                                            : 
                                            <h3 className="contained bg-dark text-white rounded vertically-aligned" style={{padding: '15px 15px'}}>Title:&nbsp; {item?.manufacturer} {item?.model_number}{item?.has_packaging=='1' ? <></> : <>&nbsp;<b className="bg-red text-white">NO BOX</b></>}</h3>
                                        }        
                                    </div>
                                    <h3 className="contained container">Rating:&nbsp;<RatingColorCoder content={`(${item?.product_rating})`} rating={item?.product_rating} /></h3>
                                    <h3 className="contained container">Quantity:&nbsp;{item?.quantity}</h3>
                                    <br></br>
                                    <div className="container">
                                            <h4>Appraising Notes:</h4>
                                            <div style={{padding: '0% 30%'}} ></div>
                                            <button className="btn bg-lightest contained"
                                            onClick={() => updateItem(ind, {...item, showNotes: !item?.showNotes})}>
                                                {item?.showNotes ? 'Hide Appr. Notes  |  v' : 'Show Appr. Notes  |  ^'}
                                            </button>
                                    </div>
                                    {item?.showNotes ?
                                        <FlipRTV text={item?.appraising_notes ? item?.appraising_notes : 'No appraising notes'} />
                                    : <></>}
                                    <br></br>
                                    <div className = 'container'>
                                        <div className="contained">
                                            <div className='contained'>Market Value: <Link className='btn bg-dark light-text' to={{pathname: `/appraise-item/${item?.id}`}} onClick={() => {closeModal()}}>Re-appraise</Link><br/>
                                                <input className={item?.pass=='1' ? 'bg-red' : ''} type='text' value={item?.pass=='1' ? 'PASS' : item?.market_value} readOnly disabled /> 
                                            </div>
                                            <br></br>
                                            <div className='contained'>Rec. List Price:<br/><input type='text' value={item?.rec_list_price} readOnly disabled /></div>
                                            <br></br>
                                            <div className='contained'>Amount Offered{item?.quantity > 1 && " (per unit)"}: <br/>
                                                <input type='number' disabled={item?.pass=='1'}
                                                value={item?.pass=='1' ? 0 : item?.amount_offered} onChange={e => updateItem(ind, {...item, amount_offered: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="contained bg-lightest rounded">
                                            <h3 className="dark-text">Current Listings:</h3>
                                            <br></br>
                                            <KeyValuePair label={'Currently Listed Units:'} value={item?.currentlyListed?.qtyListed == 0 ? '0' : item?.currentlyListed?.qtyListed} textClass="dark-text" />
                                            <KeyValuePair label={'Average List Price:'} value={item?.currentlyListed?.averagePrice ? '$'+item?.currentlyListed?.averagePrice.toFixed(2) : 'n/a'} textClass="dark-text" />
                                            {item?.currentlyListed?.listings ? 
                                                <TableList className = 'bg-light'
                                                columnNames={['Sku','List Price','Days Listed']}
                                                columnWidths={['33%','33%','33%']}
                                                entries={
                                                    item?.currentlyListed?.listings.map( item => {
                                                        return [
                                                            item?.open_box == 0 ? <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku}</a> : <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku} (OB)</a>,
                                                            '$'+item?.price,
                                                            item?.daysListed
                                                        ]
                                                    })
                                                }
                                            />
                                            : <></>}
                                        </div>
                                    </div>
                                    <br></br>
                                </li>
                                <br/>
                                &nbsp;
                            </>
                            : <></>}
                        </>
                        ):<h1>Fetching data...</h1>}

                        <div className="container">
                            <div className="contained"><p className="dark-text"><b>Total MV:</b><br/> ${items?.reduce((acc, curr) => {
                                return (curr?.active=='1' && curr?.pass==0) ? (acc + (parseFloat(curr?.market_value) * parseInt(curr?.quantity))) : acc;
                            }, 0).toFixed(2)}</p></div>
                            <div className="contained"><p className="dark-text"><b>Total LP:</b><br/> ${items?.reduce((acc, curr) => {
                                return (curr?.active=='1' && curr?.pass==0) ? (acc + (parseFloat(curr?.rec_list_price) * parseInt(curr?.quantity))) : acc;
                            }, 0).toFixed(2)}</p></div>
                            <p className="contained dark-text"><b>Total Offer:</b><br/> ${items?.reduce((acc, curr) => {
                                return (curr?.active=='1' && curr?.pass==0) ? (acc + (parseFloat(curr?.amount_offered) * parseInt(curr?.quantity))) : acc;
                            }, 0).toFixed(2)}</p>
                        </div>

                        <br/>

                        <div className="bg-dark" style={{width:'100%', height:'1px'}} />
                        <br/>
                        <h2 className="dark-text">NRTC Calculator</h2>
                        <div className="contained">
                            <h3 className="dark-text">Sale Items</h3>
                            <label className="text-left dark-text">
                                Dealer Cost:&nbsp;&nbsp;
                                <input type='number' value={saleCost} onChange={e => setSaleCost(parseInt(e.target.value))}/>
                            </label>
                            <label className="text-left dark-text">
                                Sale Price:&nbsp;&nbsp;
                                <input type='number' value={saleValue} onChange={e => setSaleValue(parseInt(e.target.value))}/>
                            </label>
                        </div>
                        <br/>
                        {/* <div className="container">
                            <label className="text-left dark-text contained">
                                Total Purchase Margin:&nbsp;&nbsp;
                                <input type='number' disabled value={buyValue - buyCost}/>
                            </label>
                            <label className="text-left dark-text contained">
                                Total Sale Margin:&nbsp;&nbsp;
                                <input type='number' disabled value={saleValue - saleCost}/>
                            </label>
                        </div>
                        <br/> */}
                        <div className="container">
                            
                            <label className="text-left dark-text contained">
                                Net Margin:&nbsp;&nbsp;$
                                <input type='text' disabled value={parseFloat((buyValue - buyCost) + (saleValue - saleCost) - nrtc).toFixed(2)} />
                            </label>
                            <label className="text-left dark-text contained">
                                Available NRTC:&nbsp;&nbsp;
                                <input type='text' disabled className="bg-red light-text" value={`$${parseFloat(nrtc).toFixed(2)}`}/>
                            </label>
                        </div>
                        <label className="text-left dark-text">
                            Net Margin (%):&nbsp;&nbsp;
                            <input type='text' disabled value={ `${(100 * ((buyValue - buyCost) + (saleValue - saleCost) - nrtc) / (parseFloat(saleValue) + buyValue - nrtc)).toFixed(2)}%` } />
                        </label>
                        <a href={`${context.server == 'http://localhost:9090' ? 'http://localhost:3000' : 'https://flip.tmraudio.net'}/inventory-list`} rel='noreferrer' target='_blank'>
                            find new costs/prices here
                        </a>
                        
                    </div>
                    <br/><br/>
                    <button className="btn bg-dark light-text" onClick={submit}>Quote Sent</button>
                </>}
            </>}
        </div>
    )
}

export default SendQuoteForm;