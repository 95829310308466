import { useEffect, useState } from "react";
import InfoBox from "./InfoBox";
import ItemOptionsUI from "./OptionsUIs/ItemOptionsUI";

/**
 * form to modify item options and using ItemOptionsUI interface. Parent component MUST handle saving changes to DB, as this form does not do so
 * 
 * @param {Object} formData - item info, including classifications as item.classifications (with supertype and name fields), and potentially including preset options as item.options 
 * @param {function} closeModal given by ModalContainer, cleanly closes modal popup
 * @param {function} submitForm callback to parent component to handle any post-processing (ie. saving changes to db, etc)
 */
const EditItemOptionsForm = ({formData, closeModal, submitForm}) => {
    let [item, setItem] = useState({});

    useEffect(() => {
        setItem(formData);
    }, [formData]);

    function submit() {
        //don't actually need to do anything here, parent component should handle updating all the fields under the hood
        closeModal();
        submitForm(item);

    }

    return (
        <div>
            {item?.id ? <>
                <InfoBox title='Classifications:'>
                    <p className="dark-text">{item?.classifications?.map(row => row?.supertype + ' | ' + row?.name)?.join(', ')}</p>
                </InfoBox>
                <br/><br/>
                <ItemOptionsUI item={item} updateParent={setItem} />

                <br/>
                <button className="btn bg-dark light-text" onClick={() => submit()}>Submit</button>
            </>
            : 'Loading item options...'}
        </div>
    )
}

export default EditItemOptionsForm;