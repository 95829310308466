import React, { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import SearchSelect from "./SearchSelect";


const editable_fields = [
    'title',
    'options',
    'brand_id',
    'model',
    'description',
    'internal_notes',
    'msrp',
]

function getSafeVersion(product) {
    let safe_product = {}
    editable_fields.forEach((key) => {
        safe_product[`${key}`] = product[`${key}`]==='' ? null : product[`${key}`];
    })
    //console.log(safe_product);
    return safe_product;
}


const CreateProductForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [product, setProduct] = useState(formData);
    let [brands, setBrands] = useState([]);
    let [classifications, setClassifications] = useState([]);
    let [productClassifications, setProductClassifications] = useState([]);
    let [productCategories, setProductCategories] = useState([]);
    let [msrpDisabled, setMsrpDisabled] = useState(false)
    let [msrpPlaceholder, setMsrpPlaceholder] = useState(null)
    let [formDisable, setFormDisable] = useState(false);

    const fetchBrands = () => {
        //fetch the brands on load. this is for the dropdown
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tableName: 'brands',
                params: {
                    limit: -1,
                    sort: {
                        key: 'brand',
                        order: 1
                    }
                }
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setBrands(results)
        })
    }

    const fetchClassifications = () => {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({params: {
                sort: {
                    key: 'sequence_number',
                    order: 1
                }
            }, tableName: "classifications"})
        })
        .then(resp => resp.json())
        .then(results => setClassifications(results))

        secureFetch(context.server + '/product-classifications', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                product_id: product?.id
            })
        })
        .then(resp => resp.json())
        .then(results => setProductClassifications(results))

    }

    function disableMsrp(){
        if(productClassifications.some(p => p.classification_id == 21 || (p.classification_id >= 27 && p.classification_id <= 30))){
            setMsrpDisabled(true)
            if(product?.msrp > 0){
                setMsrpPlaceholder(product?.msrp)
                setProduct({...product, msrp: null})
            }
        }else{
            setMsrpDisabled(false)
            if(msrpPlaceholder && !product?.msrp){
                setProduct({...product, msrp: msrpPlaceholder})
            }
        }
    }

    useEffect(() => {
        disableMsrp();
    },[productClassifications])


    useEffect(() => {
        fetchBrands();
        fetchClassifications();
    },[])

    function setProductClassification(cla, ind) {
        let newClassifications = productClassifications.map(e=>e);
        newClassifications[ind] = cla;
        setProductClassifications(newClassifications);
        //alert(JSON.stringify(newClassifications))
    }

    function addProductClassification(event) {
        event.preventDefault();
        let newClassifications = productClassifications.map(e=>e);
        newClassifications.push({
            product_id: product?.id,
            //classification_id: event.target.value
        })
        //alert(JSON.stringify(newCategories))
        setProductClassifications(newClassifications);
    }

    function removeProductClassification(event, ind) {
        event.preventDefault();
        let newClassifications = productClassifications.map(e=>e);
        newClassifications.splice(ind, 1);
        setProductClassifications(newClassifications);
    }

    const submit = (e) => { //called by the submit button. prodcesses info, sends to the backend, then calls the given closeModal and onSubmit
        e.preventDefault();
        if(productClassifications?.length <= 0) {
            alert('All products must be classified. Click Add Classification to select a category.')
            return;
        }
        setFormDisable(true);
        
        
        //only allows edits to certain fields (ie you can't change the id of the product)
        let safe_product = getSafeVersion(product);
        let body = {
            //no id needs to be given since we are just creating a new product
            keys: Object.keys(safe_product),
            values: Object.values(safe_product),
        }
        secureFetch(context.server + '/create-product', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            credentials: 'include',
        })
        .then(res => res.json())
        .then(data => {
            if(data?.id) {
                secureFetch(context.server + '/update-product-classifications', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        product_id: data?.id,
                        classifications: productClassifications
                    }),
                })
                .then(() => {
                    submitForm(data);
                    closeModal();
                })
            } else {
                submitForm(data);
                closeModal();
            }
        })
    }

    return(
        <div>
            <form id='CreateProductForm' onSubmit={submit} />
            <fieldset form='CreateProductForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Create New Product</h1>
                    <div className='container'>
                        <div className='container contained'>
                            <label className='dark-text text-left contained'>Product Title:&nbsp;&nbsp;
                            <input form='CreateProductForm' className='contained' style={{width: '60%'}} type='text' value={product?.title ? product?.title : '' }
                            onChange={ (e) => setProduct({...product, title: e.target.value})}/></label>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container contained'>
                            <label className='dark-text text-left contained'>Product Options:&nbsp;&nbsp;
                            <input form='CreateProductForm' className='contained' style={{width: '60%'}} type='text' value={product?.options ? product?.options : '' }
                            onChange={ (e) => setProduct({...product, options: e.target.value})}/></label>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='contained container'>
                            <label className='dark-text text-left contained'>Brand:&nbsp;&nbsp;
                            <select form='CreateProductForm' required className='contained' onChange={ e => setProduct({...product, brand_id: e.target.value}) } value={product?.brand_id}>
                                <option value={null} key={0}>SELECT BRAND</option>
                                {brands.map( (row) => 
                                    <option key={row?.id} value={row?.id} >{row?.brand}</option>
                                )}
                            </select></label>
                        </div>
                        <div className='contained'>
                            <div className='container'>
                                <label className='dark-text text-left contained'>Model:&nbsp;&nbsp;
                                <input form='CreateProductForm' required className='contained' type='text' value={product?.model ? product?.model : '' }
                                onChange={ (e) => setProduct({...product, model: e.target.value})}/></label>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <label className='dark-text text-left contained'>MSRP:&nbsp;&nbsp;
                            <input form='CreateProductForm' className='contained' type='number' disabled = {msrpDisabled ? 'true' :''} value={product?.msrp ? product?.msrp : 0}
                            onChange={ e => setProduct({...product, msrp: e.target.value})} /><i>{msrpDisabled ? ' disabled for cables' : ''}</i>
                        </label>
                    </div>
                    <div>
                        {productClassifications.map( (row, ind) => 
                        <div className='container'>
                            <div className='contained container'>
                                {classifications.length < 1 ? <p className='dark-text'>loading classifications...</p> : <></>}
                                <label className='dark-text text-left contained'>Classification:
                                <select form='EditProductForm' required className='contained' 
                                onChange={ e => setProductClassification({...row, classification_id: e.target.value, name: classifications.filter(cat => cat?.id==e.target.value)[0]?.name}, ind) } 
                                value={row?.classification_id}>
                                    <option value={null} key={0}>SELECT CLASSIFICATION</option>
                                    {classifications.map( (cat) => 
                                        <option key={cat?.id} value={cat?.id} >{cat?.supertype} | {cat?.name}</option>
                                    )}
                                </select></label>
                            </div>
                            <button className='btn bg-red light-text' style={{maxWidth: '150px'}} onClick={e => removeProductClassification(e, ind)}>Remove</button>
                        </div>
                        )}
                        <button className='btn bg-dark light-text' onClick={addProductClassification}>Add Classification</button>
                    </div>
                    <div>
                        <label className='dark-text text-left'>Description:</label>
                        <textarea form='CreateProductForm'  rows='8' style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} value={product?.description ? product?.description : '' }
                        onChange={ (e) => setProduct({...product, description: e.target.value})}/>
                        <br/>
                        <label className='dark-text text-left'>Internal Notes:</label>
                        <textarea form='CreateProductForm'  rows='5' style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} value={product?.internal_notes || '' }
                        onChange={ (e) => setProduct({...product, internal_notes: e.target.value})}/>

                    </div>
                    <input form='CreateProductForm' type='submit' className='btn bg-dark light-text' />
                </div>
            </fieldset>
        </div>
    )
}

export default CreateProductForm;