import '../App.css';
const KeyValuePair = ({label, value, textClass='dark-text'}) => {
    //console.log(label, value, textClass);
    return (
        <div className='container'>
            <p className={textClass + ' contained'}><b>{label ? label : '-'}</b></p>
            <p className={textClass + ' contained'}>{value ? value : '-'}</p>
        </div>
    );
}

export default KeyValuePair;