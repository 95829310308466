import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const CreateSubmissionItemForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [item, setItem] = useState({
        actual_age_of_item: null,
        quantity: 1
    });
    let [cosmetics, setCosmetics] = useState([]);
    
    let [formDisable, setFormDisable] = useState(false);

    function fetchCosmetics() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'cosmetic_conditions'})
        })
        .then(resp => resp.json())
        .then(results => setCosmetics(results));
    }

    useEffect(() => {
        fetchCosmetics();
    }, [])


    function submit(e) {
        e.preventDefault();
        if(formData?.id) {
            setFormDisable(true);
            secureFetch(context?.server + '/add-submission-item', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    submission_id: formData?.id,
                    item: item
                })
            })
            .then(() => {
                closeModal();
                submitForm();
            })
        } else {
            alert("couldn't find submission to add item to, sorry")
        }
    }


    return (
        <div>
            <form id='CreateSubmissionItemForm' onSubmit={submit} />
            <fieldset form='CreateSubmissionItemForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className="dark-text">Add New Submission Item:</h1>
                    <br/><br/>
                    <div className="container">
                        <label className='dark-text text-left contained'>Manufacturer:&nbsp;&nbsp;&nbsp;
                        <input form='CreateSubmissionItemForm' required style={{width: '250px'}} type='text' value={item?.manufacturer || '' }
                        onChange={ (e) => setItem({...item, manufacturer: e.target.value})}/></label>
                        <label className='dark-text text-left contained'>Model Number:&nbsp;&nbsp;&nbsp;
                        <input form='CreateSubmissionItemForm' required style={{width: '250px'}} type='text' value={item?.model_number || '' }
                        onChange={ (e) => setItem({...item, model_number: e.target.value})}/></label>
                    </div>
                    <br/>
                    <div className="container">
                        <label className='dark-text text-left contained'>Age of Item:&nbsp;&nbsp;&nbsp;
                        <select form="CreateSubmissionItemForm" value={item?.actual_age_of_item} onChange={e => setItem({...item, actual_age_of_item: e.target.value==-11 ? null : e.target.value})}>
                            <option value={-11}>-</option>
                            <option value={-1}>Unknown</option>
                            <option value={0}>Less than 1 year</option>
                            <option value={1}>1 year</option>
                            <option value={2}>2 years</option>
                            <option value={3}>3 years</option>
                            <option value={4}>4 years</option>
                            <option value={5}>5 years</option>
                            <option value={6}>6 years</option>
                            <option value={7}>7 years</option>
                            <option value={8}>8 years</option>
                            <option value={9}>9 years</option>
                            <option value={10}>10 years</option>
                            <option value={11}>11 years</option>
                            <option value={12}>12 years</option>
                            <option value={13}>13 years</option>
                            <option value={14}>14 years</option>
                            <option value={15}>15 years or older</option>
                        </select>
                        </label>
                        <label className='dark-text text-left contained'>Number of Owners:&nbsp;&nbsp;&nbsp;
                            <select form="CreateSubmissionItemForm" value={item?.number_of_owners} onChange={e => setItem({...item, number_of_owners: e.target.value==-11 ? null : e.target.value})}>
                                <option value={-11}>-</option>
                                <option value={0}>Unknown</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4 or more</option>
                            </select>
                        </label>
                        <label className='dark-text text-left'>Tobacco?&nbsp;&nbsp;&nbsp;
                        <input form='CreateSubmissionItemForm' style={{width: '100px'}} type='checkbox' checked={ item?.tobacco_exposure=='1' }
                        onChange={ (e) => setItem({...item, tobacco_exposure: e.target.checked ? '1' : '0'})}/></label>
                    </div>
                    <br/>
                    <div className="container">
                        <label className='dark-text text-left contained'>Cosmetic Condition:&nbsp;&nbsp;&nbsp;
                        <select form='CreateSubmissionItemForm' required onChange={ e => setItem({...item, cosmetic_condition: e.target.value}) } value={item?.cosmetic_condition}>
                            <option value='null' key={0}></option>
                            {cosmetics.map( (row) => 
                                <option key={row?.id} value={row?.id} >{row?.cosmetic_condition}</option>
                            )}
                        </select></label><br/>
                        <label className='dark-text text-right contained' style={{maxWidth: '25%'}}>Packaging?&nbsp;&nbsp;&nbsp;
                        <input form='CreateSubmissionItemForm' style={{width: '100px'}} type='checkbox' checked={ item?.has_packaging=='1' }
                        onChange={ (e) => setItem({...item, has_packaging: e.target.checked ? '1' : '0'})}/></label>
                    </div>
                    <div className="container">
                        <div className="contained">
                            <label className='dark-text text-left'>What's Included:&nbsp;&nbsp;&nbsp;</label>
                            <textarea form='CreateSubmissionItemForm' style={{minWidth: '300px', width: '350px', maxWidth: '400px'}} value={item?.what_is_included || '' }
                            onChange={ (e) => setItem({...item, what_is_included: e.target.value})}/>
                        </div>
                        <div className="contained">
                            <label className='dark-text text-left'>Notes:&nbsp;&nbsp;&nbsp;</label>
                            <textarea form='CreateSubmissionItemForm' style={{minWidth: '300px', width: '350px', maxWidth: '400px'}} value={item?.notes || '' }
                            onChange={ (e) => setItem({...item, notes: e.target.value})}/>
                        </div>
                    </div>
                    <div className="container">
                        <label className='dark-text text-left contained'><b>Quantity:</b> &nbsp;&nbsp;
                            <input style={{maxWidth:'90px'}} form='CreateSubmissionItemForm' type='number' min='0' value={item?.quantity} onChange={e => setItem({...item, quantity: e.target.value})}></input>
                        </label>
                        <br/>
                    <br/>
                    </div>
                    <input form='CreateSubmissionItemForm' type='submit' className="btn bg-dark light-text" />
                </div>
            </fieldset>
        </div>
    )
}

export default CreateSubmissionItemForm;