import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const PayoutReport = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [csv, setCsv] = useState([]);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [source, setSource] = useState("any");


    function fetchReport() {
        secureFetch(context.server + '/reports/payout-report', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate, source: source})
        })
        .then(res => res.json())
        .then(data => {
            setReport(data);
            let tempExport = JSON.parse(JSON.stringify(data))
            ///clean newline characters from report
            for(let r = 0; r < tempExport?.length; r++) {
                delete tempExport[r]?.tpa_id;
                delete tempExport[r]?.item_id;
                delete tempExport[r]?.is_consignment;
                delete tempExport[r]?.tpa_consignment;
                let cols = Object.keys(tempExport[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${tempExport[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    tempExport[r][cols[c]] = newrow;
                }
            }
            setCsv(tempExport)


        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, startDate, endDate, source])

        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Payout Report</h1>
                </div>

                <div className = "bg-light rounded  dark-text">
                    <div className="container">
                               
                        <label className="light-text text-left bg-" for="Source" style={{padding: '4px 15px'}}  >Source:      
                            <select  name="Source" id="selectSource" onChange={e => setSource(e.target.value)}>
                                <option selected value="all" >All</option>
                                <option value="buy" >Buy/Trade</option>
                                <option  value="consign" >Consign</option>
                                <option  value="ra" >RA</option>
                                <option  value="other" >Other</option>
                            </select>
                        </label>
                            
                        <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                            <div className='container'>
                                <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                            </div>
                            <div className='container'>
                                <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                            </div>
                            <p><i>(Leave end date blank to get a one-day report)</i></p>
                            {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                            <br/>
                        </div>
                                
                            
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={csv} filename='payout_report.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>              
            </div>    
            

            <TableList columnNames={['SKU', 'Source','Amount', 'RMA Type', 'Payment Notes','Trade',' ',]}
                    columnWidths={['10%','10%','8%','8%','49%','5%','10%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.sku ? <Link className='btn text-white' to={{pathname: `/item-view/${row?.item_id}`, state: {item_id: row?.item_id}}}>{row?.sku}</Link> : '',
                                row?.tpa_id ?
                                    <Link className='btn text-white' to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>RMA {row?.tpa_number}</Link>
                                    : row?.ra_id ?
                                        <Link className='btn text-white' to={{pathname: `/ra-view/${row?.ra_id}`, state: {ra_id: row?.ra_id}}}>RA {row?.ra_id}</Link>
                                        : "Unknown",
                                '$'+row?.payment_amount,
                                row?.tpa_consignment==1 ? 'Consign' : row?.tpa_consignment==0 ? 'Buy' : '',
                                row?.notes,
                                row?.is_trade ? <p className="green-text">trade</p>: '',
                                <Link className='btn text-white' to={{pathname: `/payment-view/${row?.id}`, state: {payment_id: row?.id}}}>View Payment</Link>
                            ]
                        })
                    } />
                   
            
        </div>
    );
}

export default PayoutReport;