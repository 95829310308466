import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const CreateCustomerForm = ({formData, closeModal, submitForm}) => {
    //set up stateful variables
    let context = useContext(AppContext);
    let [customer, setCustomer] = useState({})
    let [emails, setEmails] = useState([])
    let [formDisable, setFormDisable] = useState(false);

    //create the customer, then once we have their id, create their emails and such
    const submit = (e) => {
        //first disable the form
        setFormDisable(true);
        //create the base customer entry
        secureFetch(context.server + '/create-customer', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer: customer,
                emails: emails
            })
        })
        .then(resp => resp.json())    
        .then(cust => {
            //then send the customer back to the parent component for use
            submitForm(cust);
            closeModal();
        })
    }

    //add a new email to the list
    const addEmail = () => {
        let newEmails = emails.map(e => e);
        newEmails.push({
            customer_id: customer?.id,
            email: ''
        })
        setEmails(newEmails);
    }

    //remove an email by index from the list
    const removeEmail = (index) => {
        let newEmails = emails.map(e=>e);
        newEmails.splice(index, 1);
        setEmails(newEmails);
    }

    return (
        <div>
            <form id='CreateCustomerForm' onSubmit={submit} />
            <fieldset form='CreateCustomerForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h2 className='dark-text'>Create New Customer</h2><br/>
                    <label className='dark-text'><b>Name: </b>
                    <input form='CreateCustomerForm' required style={{width: '80%', height: '30px'}} type='text' value={customer?.name} onChange={e => setCustomer({...customer, name: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>Phone Number: </b>
                    <input form='CreateCustomerForm' style={{width: '80%', height: '30px'}} type='text' value={customer?.phone} onChange={e => setCustomer({...customer, phone: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>Notes: </b>
                    <textarea form='CreateCustomerForm' style={{maxWidth: '80%', minWidth: '80%', width: '80%'}} value={customer?.notes} onChange={e => setCustomer({...customer, notes: e.target.value})} />
                    </label>
                    {emails.map( (row, ind) => {
                        return <label className='dark-text'><b>Email: </b>
                        <input form='CreateCustomerForm' style={{width: '60%', height: '30px'}} type='text' value={emails[ind]?.email} onChange={ev => {
                            let newEmails = emails.map(e=>e);
                            newEmails[ind].email = ev.target.value
                            setEmails(newEmails);
                            if(ind==0) setCustomer({...customer, email: ev.target.value}); //set customer email field to the first email in the list
                        }} />
                        <button style={{width: '20%'}} className='btn bg-red dark-text' onClick={(e) => {
                            e.preventDefault();
                            removeEmail(ind)
                        }}>Remove</button>
                        </label>
                    })}
                    <button className='btn bg-dark light-text' onClick={(e) => {e.preventDefault();addEmail()}}>Add Email Address</button>
                    <br/><br/>

                    <label className='dark-text text-left'><b>B-to-B?: </b>
                    <input form='CreateCustomerForm' type='checkbox' checked={customer?.btob===1} onChange={ (e) => setCustomer({...customer, btob: e.target.checked ? 1 : 0})}/>
                    </label>
                    {customer?.btob==1 ? <>
                        <label className="dark-text text-left">Company Name:&nbsp;&nbsp;
                            <input form='CreateCustomerForm' type='text' value={customer?.company_name} onChange={e => setCustomer({...customer, company_name: e.target.value})} />
                        </label>
                    </> : <></>}
                    <br/><br/>

                    <input form='CreateCustomerForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </fieldset>
        </div>
    );
}

export default CreateCustomerForm;