import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const RatingColorCoder = ({content, rating}) => {
    let [statefulRating, setStatefulRating] = useState('');
    let [statefulContent, setStatefulContent] = useState('');

    //this causes child component (this one) to update when parent qualities update
    useEffect(() => {
        setStatefulRating(rating);
    }, [rating]);
    useEffect(() => {
        setStatefulContent(content);
    }, [content]);


    return (
        <div className={
        statefulRating == 'A' ? 'blue-text'
        : statefulRating == 'B' ? 'green-text'
            : statefulRating == 'C' ? 'orange-text'
                : statefulRating == 'D' ? 'dark-text'
                    : statefulRating == 'F' ? 'red-text'
                        : 'dark-text'}>{statefulContent}</div>
    )
}

const CosmeticColorCoder = ({content, cosmetic}) => {
    let context = useContext(AppContext);
    let [statefulCosmetic, setStatefulCosmetic] = useState('');
    let [statefulContent, setStatefulContent] = useState('');

    let [cosmetics, setCosmetics] = useState([]);

    function fetchCosmeticLanguage() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'cosmetic_conditions',
                params: {}
            })
        })
        .then(r=>r.json())
        .then(results => setCosmetics(results));
    }

    //fetch cosmetics on load
    useEffect(() => {
        fetchCosmeticLanguage();
    }, []);

    //this causes child component (this one) to update when parent qualities update
    useEffect(() => {
        setStatefulCosmetic(cosmetic);
    }, [cosmetic]);
    useEffect(() => {
        setStatefulContent(content);
    }, [content]);


    return (
        <div className={
            statefulCosmetic > 9 ? 'blue-text'
            : statefulCosmetic > 7 ? 'green-text'
                :statefulCosmetic > 6 ? 'orange-text'
                    : statefulCosmetic <= 6 && statefulCosmetic > 0 ? 'red-text'
                        : 'dark-text'}>{statefulContent || cosmetics?.filter(row => row?.id==statefulCosmetic)[0]?.cosmetic_condition}</div>
    )
}

export {RatingColorCoder, CosmeticColorCoder};