import React from 'react';
import '../App.css';
import DownChevron from '../assets/svg/down-chevron.svg';
import UpChevron from '../assets/svg/up-chevron.svg';

const NavDropdown = ({contents, title, clickHandler, closeAll, isShown}) => {

    return(
        <>
            <div onClick={clickHandler} className={"text-left inline-flex items-center min-h-full"} style={{cursor:'pointer'}}><span className={" p-4 "}>{title}</span>
                {isShown ?
                    <img className={"inline py-4 pl-0 pr-2"} src={UpChevron} alt={"UpChevron"}/>
                    :
                    <img className={"inline py-4 pl-0 pr-2"} src={DownChevron} alt={"DownChevron"}/>
                }
            </div>
            {isShown ?
                <div className={"absolute z-50 top-full min-w-full"}>
                    {contents.map( (child, index) =>
                        <div  key={index} className={"bg-submenu-gray border-b border-gray-900 min-w-full"}>
                            {child}
                        </div>
                    )}
                </div>
            :
                <></>
            }
        </>
    );
}

export default NavDropdown;