import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";

const CreateUserForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [user, setUser] = useState({active: 1});
    let [formDisable, setFormDisable] = useState(false);


    function submit(event) {
        event.preventDefault();
        setFormDisable(true);
        secureFetch(context.server + '/create-user', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                keys: Object.keys(user),
                values: Object.values(user),
            })
        })
        .then( resp => resp.json() )
        .then( result => {
            closeModal();
            submitForm(result);
        })
    }

    return (
        <div>
            <form id='CreateUserForm' onSubmit={submit} />
            <fieldset form='CreateUserForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Create New User</h1>
                
                    <label className='dark-text'><b>First Name: </b>
                    <input form='CreateUserForm' required style={{width: '80%', height: '30px'}} type='text' value={user?.first_name} onChange={e => setUser({...user, first_name: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>Last Name: </b>
                    <input form='CreateUserForm' required style={{width: '80%', height: '30px'}} type='text' value={user?.last_name} onChange={e => setUser({...user, last_name: e.target.value})} />
                    </label>
                    <label className='dark-text'><b>TMR Email: </b>
                    <input form='CreateUserForm' required style={{width: '80%', height: '30px'}} type='text' value={user?.email} onChange={e => setUser({...user, email: e.target.value})} />
                    </label>
                    <br/>
                    <label className='dark-text text-left'><b>Active?: </b>
                    <input form='CreateUserForm' type='checkbox' defaultChecked={true} checked={user?.active===1} onChange={ (e) => setUser({...user, active: e.target.checked ? 1 : 0})}/>
                    </label>
                    <br/><br/>
                    <input form='CreateUserForm' className='btn bg-dark light-text' type='submit' value='Create User' />
                </div>
            </fieldset>
        </div>
    )
}

export default CreateUserForm;