import { useContext, useEffect, useState } from "react";
import ProductOptionsUI from "./OptionsUIs/ProductOptionsUI";
import { secureFetch } from "../util/flip_utils";
import AppContext from "../AppContext";


const ProductOptionDefaultsForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [product, setProduct] = useState(formData);


    useEffect(() => {
        setProduct(formData);
    }, [formData]);

    function submit(event) {
        event?.preventDefault();
        // alert(JSON.stringify(product?.options))
        secureFetch(context?.server + '/set-product-option-defaults', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({product: {...product, options: product?.options?.filter(o => o?.option_set_id >= 0)}})
        })
        .then(() => {
            // alert(JSON.stringify(product?.options))
            submitForm();
            closeModal();
        })
    }


    return (
        <div>
            <form id='ProductOptionDefaultsForm' onSubmit={submit} />
            <div style={{padding: '15px 15px'}} className='bg-light'>
                <h1 className="dark-text">Edit Product Option Defaults:</h1>
                <ProductOptionsUI product={product} updateParent={setProduct} />
                <br/>
                <input form='ProductOptionDefaultsForm' type='submit' className="btn bg-dark light-text" />
            </div>
        </div>
    )
}

export default ProductOptionDefaultsForm;