import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";


const ReceiveRmaForm = ({formData, submitForm, closeModal}) => {
    const context = useContext(AppContext);
    let [rma, setRma] = useState({});
    let [qtyConfirmed, setQtyConfirmed] = useState(1);
    let [receivingNotes, setReceivingNotes] = useState("");


    useEffect(() => {
        let newItems = formData?.items?.map(i => {return {...i, receive: i?.received_date ? 1 : 0}})
        let n 
        for(n=0;n<newItems.length;n++){
            if(newItems[n]?.quantity > 1){setQtyConfirmed(0)}
        }
        console.log(newItems);
        setRma({...formData, items: newItems});     
    }, [formData]);

    

    function submit() {
        secureFetch(context.server + '/receive-rma', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({...rma, receivingNotes: receivingNotes})
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    return (
        <div style={{padding: '15px 15px'}} className='bg-light'>
            {rma?.items?.length ?
            <TableList columnNames={['SKU','Qty','Title','Received?']}
            columnWidths={['20%','10%','50%','20%']}
            entries={rma?.items?.map((row,ind) => {
                return [
                    <p className={row?.item_status_id >= 2 ? 'bg-green light-text' : 'dark-text'}>{row?.sku}</p>,
                    row?.quantity,
                    row?.title,
                    <input type='checkbox' className = 'large-checkbox' checked={row?.receive == 1} onChange={e => {
                        let newItems = rma?.items?.map(r => r); //copy items
                        newItems[ind].receive = e.target.checked ? 1 : 0
                        setRma({...rma, items: newItems})
                        console.log(rma);
                    }} />
                ]
            })} />
            : <p>no items to receive...</p>}
            <br/>
            <div>
                <label className="dark-text text-left">Receiving Notes:<br/>
                <textarea style={{minWidth: "100%", width: "100%", maxWidth: "100%"}} rows="4" value={receivingNotes} onChange={e => setReceivingNotes(e.target.value)} />
                </label>
                <i className="red-text">(these notes will be sent to each newly-received item. If you want to send different notes for each item, receive them one-by-one)</i>
            </div>
            <br/>
            <div hidden={qtyConfirmed ? "" : "hidden"}>
                <button className="btn bg-green light-text" onClick={() => submit()}>Submit Received</button>
                <br/>
                <br/>
                <p hidden={rma?.items?.every((item)=>item?.receive ==1) && qtyConfirmed && !rma?.received_notification_date ? "" : "hidden"}>*Submitting will notify the customer that all items have been received.</p>                   
            </div>
            <div hidden={qtyConfirmed ? "hidden" : ""}>
                        <p className='red-text'>AN ITEM HAS QTY &gt; 1  - PLEASE CONFIRM THIS IS CORRECT</p>
                        <button className='btn bg-red light-text' onClick={() => setQtyConfirmed(1)}>Confirm</button>
            </div>
            <div >
                        
            </div>
        </div>
    )
}

export default ReceiveRmaForm;