import { useContext, useState } from "react";
import AppContext from "../AppContext";
import Loading from "./Loading";
import { secureFetch } from "../util/flip_utils";

const FeatureFlagBoundary = ({children}) => {
    let context = useContext(AppContext);
    let [fetched, setFetched] = useState(false);

    //needs to have the accesstoken to run, otherwise it wont work
    if(!context?.accessToken) {
        return <Loading />
    }

    // running this outside of a useEffect like this means it won't re-render children when
    // it updates and cause a bunch of useEffects downstream
    secureFetch(context?.server + '/feature-flags', {
        method: 'GET',
        credentials: 'include',
        headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}
    })
    .then(resp => resp.json())
    .then(ffs => {
        context.feature_flags = ffs;
        setFetched(true);
    })

    if(!fetched) {
        return <Loading />
    } else

    //essentially just a passthrough once the feature flags are fetched
    return (
        <div>
            {children}
        </div>
    )
}

export default FeatureFlagBoundary;