import { useAuth0 } from "@auth0/auth0-react";
import Auth0LoginButton from "../components/Auth0LoginButton";


const Login = () => {
    let { user, isAuthenticated, isLoading } = useAuth0();

    return (
        <div className='bg-dark'>
            <br/>
            { isLoading ?
            <div>
                <h2 className="light-text">Please wait...</h2>
            </div>
            : isAuthenticated ? 
            <div><center>
                <h2 className="text-white">Welcome, {user?.name}!</h2>
                <br/>
                <img style={{marginRight: '25%', marginLeft: '25%', maxWidth: "50%"}} src="images/authorized.jpg" alt="authorized.jpg" />
                <br/>
            </center></div>
            : 
            <div>
                <center>
                    <img style={{marginRight: '35%', marginLeft: '35%', maxWidth: "30%"}} src="images/unauthorized.jpg" alt="unauthorized.jpg" />
                    <h2 className="light-text">You are not currently logged in. Please login below to continue to FLIP</h2>
                    <br/>
                    <Auth0LoginButton />
                </center>
            </div>
            }
            
        </div>
    )
}

export default Login;