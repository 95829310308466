import { useContext, useEffect, useState } from "react"
import AppContext from "../../AppContext"
import { secureFetch } from "../../util/flip_utils";
import OptionsUI from "./OptionsUI";


const ProductOptionsUI = ({product, updateParent}) => {
    let context = useContext(AppContext);

    let [defaults, setDefaults] = useState([]);
    let [availableOptionSets, setAvailableOptionSets] = useState([]);

    function fetchProductOptions() {
        secureFetch(context.server + '/product-options-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({productId: product?.id})
        })
        .then(resp => resp.json())
        .then(results => {
            setDefaults(results?.defaults);
            setAvailableOptionSets(results?.possibleOptions);
        })
    }

    useEffect(() => {
        updateParent({...product, options: defaults});
    }, [defaults]);

    useEffect(() => {
        fetchProductOptions();
    }, [])

    return(<div>
        <OptionsUI parentOptions={defaults} updateParentOptions={setDefaults} availableOptionSets={availableOptionSets} />
    </div>)
}

export default ProductOptionsUI;