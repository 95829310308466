import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { /*HashRouter*/ BrowserRouter } from "react-router-dom"
import { Auth0Provider } from '@auth0/auth0-react';

const ENV =
	window.location.href.startsWith('http://localhost:3000') ? "development" :
	window.location.href.startsWith('https://flip-test.tmraudio.net') ? "test" :
	window.location.href.startsWith('https://flip.tmraudio.net') ? "production" :
	"unknown"

const flipServer =
  ENV=="development" ? "http://localhost:9090" :
  ENV=="test" ? "https://api-test.tmraudio.net" :
  ENV=="production" ? "https://api.tmraudio.net" :
  "errorurl"


const context = {server: flipServer, environment: ENV};

ReactDOM.render((
<BrowserRouter>
	<Auth0Provider
	domain={ENV == "development" ? "dev-t7fb3o4ss3eky3gy.us.auth0.com"
		: ENV == "test" ? "dev-t7fb3o4ss3eky3gy.us.auth0.com"
		: ENV == "production" ? "tmr-prod.us.auth0.com"
		: "unknown domain"}
	clientId={ENV == "development" ? "z3bNzuxOYf7jKYa1ZJOumB3JHz8IYHgm"
		: ENV == "test" ? "yvQtNhNXS7JvOp6hkKIiY55QDrjjzuit"
		: ENV == "production" ? "HYhCtdfoWMlbBYNPqaKNa0x6TUQVs6U3"
		: "unkown clientId"}
	authorizationParams={{
		redirect_uri: window.location.origin,
		audience: ENV == "development" ? "localhost:9090"
		: ENV == "test" ? "https://api-test.tmraudio.net"
		: ENV == "production" ? "https://api.tmraudio.net"
		: "unkown audience"
	}}
	// useRefreshTokens={true}
	>
		<App app={context}/>
	</Auth0Provider>
</BrowserRouter>
), document.getElementById('root'))
	