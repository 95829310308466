import { useEffect, useState } from "react"


const EditDimensionsForm = ({formData, submitForm, closeModal}) => {
    let [dimensions, setDimensions] = useState({formData});
    let [disableForm, setDisableForm] = useState(false);

    //make formData stateful
    useEffect(() => {
        setDimensions(formData);
    }, [formData])

    function submit() {
        setDisableForm(true);
        submitForm(dimensions);
        closeModal();
    }

    return (
        <div>
            <h2 className="dark-text">Edit Dimensions</h2><br/>
            <form id='EditDimensions' onSubmit={submit} />
            <fieldset form='EditDimensions' disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <i className='dark-text text-left'>{'(Please only use whole numbers, and always round decimal measurements UP)'}</i>
                    <label className='dark-text text-left'>Total Weight: 
                        <input form='EditDimensions' type='number' step='1' min='0' value={dimensions?.weight} onChange={e => setDimensions({...dimensions, weight: e.target.value})}>
                        </input></label> <br/>
                    <label className='dark-text text-left'>Width: 
                        <input form='EditDimensions' type='number' step='1' min='0' value={dimensions?.width} onChange={e => setDimensions({...dimensions, width: e.target.value})}>
                        </input></label> <br/>
                    <label className='dark-text text-left'>Depth: 
                        <input form='EditDimensions' type='number' step='1' min='0' value={dimensions?.depth} onChange={e => setDimensions({...dimensions, depth: e.target.value})}>
                        </input></label> <br/>
                    <label className='dark-text text-left'>Height: 
                        <input form='EditDimensions' type='number' step='1' min='0' value={dimensions?.height} onChange={e => setDimensions({...dimensions, height: e.target.value})}>
                        </input></label> <br/>
                    <label className='dark-text text-left'># of Boxes: 
                        <input form='EditDimensions' type='number' step='1' min='0' value={dimensions?.boxes} onChange={e => setDimensions({...dimensions, boxes: e.target.value})}>
                        </input></label> <br/>
                    <label className='dark-text text-left container'>Dim. Type:
                        <label className='contained'><input form='EditDimensions' type='radio' value='net' checked={dimensions?.dim_type=='net'} onChange={() => setDimensions({...dimensions, dim_type: 'net'})} />&nbsp;Net</label>
                        <label className='contained'><input form='EditDimensions' type='radio' value='shipping' checked={dimensions?.dim_type=='shipping'} onChange={() => setDimensions({...dimensions, dim_type: 'shipping'})} />&nbsp;Shipping</label>
                        <label className='contained'></label>
                        <label className='contained'></label>
                        </label><br/>
                    <label className='dark-text text-left container'>Freight (Y/N):&nbsp;&nbsp;&nbsp;
                        <input form='EditDimensions' type='checkbox' checked={dimensions?.freight==1} onChange={e => setDimensions({...dimensions, freight: e.target.checked ? '1' : '0'})} />
                        </label><br/>
                    <br/>
                    <input type='submit' value='Done' form='EditDimensions' className="btn bg-dark light-text" />
                </div>
                <br/>
            </fieldset>
        </div>
    )
}

export default EditDimensionsForm;