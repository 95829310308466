import { useState } from 'react';
import RichTextEditor from 'react-rte';

/**
 * see https://github.com/sstur/react-rte for documentation
 * @param {string} text - string or html to render inside readOnly viewer
 * @returns 
 */

const FlipRTV = ({text}) => {
    let [value, setValue] = useState(text ? 
        RichTextEditor.createValueFromString(text, 'html') :
        RichTextEditor.createEmptyValue());

    return (
        <RichTextEditor
        value={value}
        readOnly
        toolbarConfig={{ // display no toolbar or anything
            display: []
        }}/>
    );
}

export default FlipRTV;