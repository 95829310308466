import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import FlipRTV from "../components/FlipRTV";
import ConfirmButton from "../components/ConfirmButton";
import TableList from "../components/TableList";
import { secureFetch } from "../util/flip_utils";
import ModalContainer from "../components/ModalContainer";
import AppraisalPopup from "../components/AppraisalPopup";
import BrandPartnerLogo from "../components/BrandPartnerLogo";

const TRADE_CREDIT_ID = 28;

const AcceptQuote = (props) => {
    let context = useContext(AppContext);
    let { submission_id } = useParams();
    let history = useHistory();
    let [submission, setSubmission] = useState({});
    let [items, setItems] = useState([]);
    let [customer, setCustomer] = useState({});
    let [payMethods, setPaymentOptions] = useState([]);
    let [formDisable, setFormDisable] = useState(false);
    let [fetched, setFetched] = useState(false);

    let [saleCost, setSaleCost] = useState(0);
    let [saleValue, setSaleValue] = useState(0);
    let [buyValue, setBuyValue] = useState(0);
    let [buyCost, setBuyCost] = useState(0);
    let [nrtc, setNrtc] = useState(0);
    
    const DIFF_OWED_ENABLED = context?.feature_flags['difference_owed'];

    useEffect(() => {
        setBuyValue(items?.reduce((acc, curr) => {
            return acc + (parseInt(curr?.rec_list_price) || 0);
        }, 0))
        setBuyCost(items?.reduce((acc, curr) => {
            return acc + (parseInt(curr?.buy_price) || 0);
        }, 0))
    }, [context, items])

    useEffect(() => {
        setNrtc(Math.max(Math.min(
            parseFloat((saleValue - saleCost) + (buyValue - buyCost)) * 0.1, 
            buyValue - buyCost
        ), 0).toFixed(2))
    }, [buyCost, buyValue, saleCost, saleValue])

    function fetchData() {
        secureFetch(context.server + '/submission-overview', { //get the submission by id
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: submission_id ? submission_id : props?.location?.state?.submission_id})
        })
        .then(res => res.json())
        .then(data => {
            setSubmission({
                ...data?.submission,
                trade_involved: '0',
                purchase_payment_method_id: -1,
                consignment_payment_method_id: -1,
                source_note: '',
                difference_owed: 0,
            });
            setItems(//tack on the extra fields on the submission item. these are necessary for the /accept-quote endpoint
                data?.items
                .filter(row => row?.active==1)
                .map( item => {return {...item, accepted: item?.pass ? 0 : 1, buy_price: item?.amount_offered || item?.rec_buy_price};})
            )
            setCustomer(data?.customer)
            setFetched(true)
        })

        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({tableName: 'payment_methods', params: {
                equal: [{
                    key: 'active',
                    value: '1',
                }, {
                    key: 'rma',
                    value: '1'
                }]
            }})
        })
        .then(resp => resp.json())
        .then(results => setPaymentOptions(results))
    }

    useEffect(() => {
        fetchData();
    },[])

    useEffect(() => {
        bounceIfUnassignedProducts();
    }, [items])

    function notifyLogistics(yn) {
        return (e) => {
            e.preventDefault();
            secureFetch(context?.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['notify_logistics', 'logistics_notes'],
                    values: [yn ? '1' : '0', submission?.logistics_notes],
                    message: `user marked submission as${yn ? '' : ' dont'} notify logistics`
                })
            })
            .then(() => setSubmission({...submission, notify_logistics: yn ? 1 : 0}))
        }
    }

    const submitQuote = (event) => { //called when the submit button is pressed. sends away all the data to the backend for processing
        event.preventDefault();
        //alert missing method if any of them are purchases
        if((!submission?.purchase_payment_method_id || submission?.purchase_payment_method_id < 0) && 
        items?.reduce( (acc, curr) => {return acc || (curr?.is_consignment==0 && curr?.pass==0 && curr?.active==1 && curr?.accepted==1)}, false)) {
            alert('please select a purchase payment method');
            return;
        }else if((!submission?.consignment_payment_method_id || submission?.consignment_payment_method_id < 0) && 
        items?.reduce( (acc, curr) => {return acc || (curr?.is_consignment==1 && curr?.pass==0 && curr?.active==1 && curr?.accepted==1)}, false)){
            alert('please select a consignment payment method');
            return;
        }
        setFormDisable(true);
        
        secureFetch(context?.server + '/accept-quote', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submission: {...submission, full_source: submission?.source + (submission?.source_note ? ' - ' + submission?.source_note : '')},
                items: items
            })
        })
        .then(resp => resp.json())
        .then(data => {
            if(data?.message) {
                //it was rejected
                alert('submission rejected');
                history.push({pathname: `/submission-list`, state: {}})
                window.location.reload()
            } else {
                    alert(`The quote is being submitted!`)
                    if(data?.purch_id && data?.cons_id) {
                        history.push({pathname: `/tpa-view/${data?.purch_id}`, state: {tpa_id: data?.purch_id}})
                        window.location.reload()
                    } else if(data?.purch_id) {
                        history.push({pathname: `/tpa-view/${data?.purch_id}`, state: {tpa_id: data?.purch_id}})
                        window.location.reload()
                    } else if(data?.cons_id) {
                        history.push({pathname: `/tpa-view/${data?.cons_id}`, state: {tpa_id: data?.cons_id}})
                        window.location.reload()
                    } else {
                        alert('couldnt redirect to RMA...');
                        history.push({pathname: `/tpa-list`, state: {searchValue: `${customer?.name}`}})
                        window.location.reload()
                }
                
                
            }
        })
        //this.props.history.push({pathname: '/submission-list'})
        //return;
    }

    function bounceIfUnassignedProducts() {
        let allAssigned = items?.reduce((acc, curr) => {return acc && curr?.product_id > 0}, 1)
        if(!allAssigned) {
            alert('You must assign product IDs to ALL items before attempting to convert to an RMA. Redirecting to submission overview.');
            history.push({pathname: `/submission-view/${submission_id}`, state: {submission_id: submission_id}});
            window.location.reload();
        }
    }

    useEffect(() => {
        if(submission?.is_difference_owed) {
            setSubmission({...submission, nrtc_amount: null})
        } else {
            setSubmission({...submission, difference_owed: null, difference_owed_payment_method_id: null})
        }
    }, [submission?.is_difference_owed])


    //auto check/uncheck trade_involved based on payment method selected
    useEffect(() => {
        let changes = {
            trade_involved: submission?.purchase_payment_method_id==TRADE_CREDIT_ID ? 1 : 0,
            difference_owed: submission?.purchase_payment_method_id==TRADE_CREDIT_ID ? 0 : -1
        }
        setSubmission({...submission, ...changes })
    }, [submission?.purchase_payment_method_id, submission?.consignment_payment_method_id])

    if(!props?.location?.state?.submission_id && !submission_id) {
        return <h1>An error occurred. Return to submission list.</h1>
    }

    if(!fetched) {
        return <h1>Loading submission data...</h1>
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Accept Quote</h1>
            <br/>
            <p className="light-text">Link to Freshdesk Ticket {'->'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {submission?.fd_id ? <a className='btn dark-text' rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${submission?.fd_id}`}>Go to Ticket</a> : 'No ticket'}
            </p>
            <br/>
            {submission?.notify_logistics ? <><h3 className="bg-blue dark-text">LOGISTICS - DO NOT SEND LABELS</h3><br/></> : <></>}
            {!submission?.assignee_id ? <><h3 className="bg-red light-text">THIS SUBMISSION HASN'T BEEN ASSIGNED TO ANYONE</h3><br/></> : <></>}
            {!submission?.rep_id ? <><h3 className="bg-red light-text">THIS SUBMISSION HAS NO REP ASSOCIATED</h3><br/></> : <></>}
            <form id='AcceptQuote' onSubmit={submitQuote} />
            <fieldset form='AcceptQuote' disabled={formDisable ? 'disabled' : ''}>
                <div className="container">
                    <div className="contained">
                        {items?.reduce( (acc, curr) => {return acc || (curr?.is_consignment==0 && curr?.pass==0 && curr?.active==1 && curr?.accepted == 1)}, false) ? <>
                            <label className='light-text text-left'>Purchase Payment Method:&nbsp;&nbsp;</label>
                            <select form='AcceptQuote' required className={submission?.purchase_payment_method_id > 0 ? 'contained border-black' : 'contained bg-red'} onChange={ e => setSubmission({...submission, purchase_payment_method_id: e.target.value}) } value={submission?.purchase_payment_method_id}>
                                <option value={-1} key={0}>*** SELECT PAYMENT METHOD ***</option>
                                {payMethods.map( (row) => 
                                    <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                                )}
                            </select>
                            <br/>
                            <br/>
                            
                            <label className='light-text text-left'>Purchase Payment Terms: <i>{'(these work just like tmros)'}</i></label>
                            <textarea form='AcceptQuote' className={"border-2 border-black"} placeholder="details" required style={{minWidth: '400px', width: '400px', maxWidth: '400px'}} rows='4' value={submission?.payment_info} onChange={e => setSubmission({...submission, payment_info: e.target.value})} />
                            <br/>

                            {submission?.purchase_payment_method_id == TRADE_CREDIT_ID ? <div className="bg-light rounded" style={{padding: '5px 5px'}}>
                                <label className="light-text text-left">Difference Owed?&nbsp;&nbsp;
                                    <input type="checkbox" form="AcceptQuote" checked={submission?.is_difference_owed} onChange={e => setSubmission({...submission,is_difference_owed: e.target.checked})} />
                                </label>

                                
                                {submission?.is_difference_owed ? <div className="container">
                                    <label className="light-text text-left contained">Amount Owed (to customer):<br/>
                                    <input form='AcceptQuote' id="diffOwedAmount" step="0.01" type="number" value={submission?.difference_owed} onChange={e => setSubmission({...submission, difference_owed: e.target.value})} /></label> 

                                    <label className="light-text text-left contained">Difference Owed Method:<br/>
                                        <select form='AcceptQuote' required className='contained' onChange={ e => setSubmission({...submission, difference_owed_payment_method_id: e.target.value}) } value={submission?.difference_owed_payment_method_id}>
                                            <option value={-1} key={0}>*** SELECT PAYMENT METHOD ***</option>
                                            {payMethods.map( (row) => 
                                                <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                                            )}
                                        </select>
                                    </label>
                                </div>: <>
                                    <label className="light-text text-left">NRTC Used:&nbsp;&nbsp;<br/>
                                    <input form='AcceptQuote' id="nrtcAmount" step="0.01" type="number" value={submission?.nrtc_amount} onChange={e => setSubmission({...submission, nrtc_amount: e.target.value})} /></label> 
                                </>}
                            </div> : <></>}
                        </> : <></>}
                    </div>
                    <div className="contained">
                        {items?.reduce( (acc, curr) => {return acc || (curr?.is_consignment==1 && curr?.pass==0 && curr?.active==1 && curr?.accepted==1)}, false) ? <>
                            <label className='light-text text-left'>Consignment Payment Method:&nbsp;&nbsp;</label>
                            <select form='AcceptQuote' required className={submission?.consignment_payment_method_id > 0 ? 'contained' : 'contained bg-red'} onChange={ e => setSubmission({...submission, consignment_payment_method_id: e.target.value}) } value={submission?.consignment_payment_method_id}>
                                <option value={-1} key={0}>*** SELECT PAYMENT METHOD ***</option>
                                {payMethods.filter(row => row?.id != 8).map( (row) => 
                                    <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                                )}
                            </select>
                            <br/>
                            <label className="light-text text-left">Consignment Rate:&emsp;&emsp;&emsp;<i>(whole numbers only!)</i></label><input form='AcceptQuote' type='number' required step='1' value={submission?.consignment_rate} onChange={e => setSubmission({...submission, consignment_rate: e.target.value})}/><br/><br/>
                            
                            <label className='light-text text-left'>Consign Payment Terms: <i>{'(these work just like tmros)'}</i></label>
                            <textarea form='AcceptQuote' className={"border-2 border-black"} placeholder="details" required style={{minWidth: '400px', width: '400px', maxWidth: '400px'}} rows='4' value={submission?.consign_payment_info} onChange={e => setSubmission({...submission, consign_payment_info: e.target.value})} />
                            <br/>
                        </> : <></>}

                        
                    </div>
                </div>
                <br/>
                <h4 className="light-text"><b>Customer's Last Used Payment Method:</b>&nbsp;&nbsp;&nbsp;&nbsp;{customer?.last_rma_payment_method}</h4>

                <br/>
                {DIFF_OWED_ENABLED ? //hide behind this feature flag
                <>
                {submission?.purchase_payment_method_id == 8 ? <div className="container" style={{padding: '5px 5px', border: 'thin solid white', maxWidth: '40%'}}>
                    <label className="light-text text-left contained">Difference Owed (to customer):<br/>
                    <input type="number" value={submission?.difference_owed} onChange={e => setSubmission({...submission, difference_owed: e.target.value})} /></label> 

                    {submission?.difference_owed != 0 ? <label className="light-text text-left contained">Difference Owed Method:<br/>
                        <select form='AcceptQuote' required className='contained' onChange={ e => setSubmission({...submission, difference_owed_payment_method_id: e.target.value}) } value={submission?.difference_owed_payment_method_id}>
                            <option value={-1} key={0}>*** SELECT PAYMENT METHOD ***</option>
                            {payMethods.map( (row) => 
                                <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                            )}
                        </select>
                    </label> : <></>}
                </div> : <></>}</>
                :<></>}
                
                <br/>
                <div hidden={DIFF_OWED_ENABLED ? 'hidden' : ''}>
                <label className='light-text text-left'>
                    <input form='AcceptQuote' type='checkbox' checked={submission?.trade_involved=='1'} onChange={e => setSubmission({...submission, trade_involved: e.target.checked ? '1' : '0'})} />
                    &nbsp;&nbsp;Trade Involved?</label><br/></div>

                <label className='light-text text-left'>Source:&nbsp;&nbsp;&nbsp;{submission?.source + ' - '}
                    <input form='AcceptQuote' type='text' className={"border-2 border-black"} placeholder="additional info" value={submission?.source_note} onChange={e => setSubmission({...submission, source_note: e.target.value})} />
                    </label><br/>
                

                <br/><br/>
                <p className="light-text">A <strong className='bg-red'>RED</strong> title means that item has <strong>NO BOX</strong></p>
                <br/>
                <TableList columnNames={[' ','Make + Model','Quote Details','Rec. LP','MV','Quantity', <p>Buy Price<br/>(per unit)</p>, 'eBay Only?', 'Accepted?', 'Consignment?']}
                columnWidths={['3.4%','22%','13%','12%','9%','9%','10%','6%','7%','8%']}
                entries={
                    items.map( (item, index) => {
                        return [
                            item?.brand_partner ? <BrandPartnerLogo height = {'28px'}/> : '',
                            item?.has_packaging==0 && item?.active==1 && item?.pass==0 ?
                                <b className="light-text bg-red">{item?.manufacturer + ' ' + item?.model_number + (item?.active=='0' ? ' ***DEACTIVATED***' : '') + (item?.pass=='1' ? '*PASSED*' : '')}</b>
                                : item?.manufacturer + ' ' + item?.model_number + (item?.active=='0' ? ' ***DEACTIVATED***' : '') + (item?.pass=='1' ? '*PASSED*' : ''),
                                <ModalContainer triggerText='View' FormComponent={AppraisalPopup} submitForm={() => {}} 
                                formData={{
                                    appraiser: item?.appraiser,
                                    created: item?.quote_date,
                                    notes: item?.appraising_notes,
                                    rec_buy_price: item?.rec_buy_price,
                                    rec_list_price: item?.rec_list_price,
                                    market_value: item?.market_value
                                }} />,
                                <p>
                                <input style={{width: '85%'}} form='AcceptQuote' required type='number' value={item?.rec_list_price}
                                onChange={ (ev) => {
                                    let temp_items = items.map(e=>e);
                                    temp_items[index] = {...item, rec_list_price: ev.target.value};
                                    setItems(temp_items);
                                }} disabled={item?.active == '0'|| item?.pass=='1'}/>
                                <br />
                                {parseFloat(item?.buy_price) > (0.8*parseFloat(item?.rec_list_price)) ?
                                <p className="bg-red light-text rounded" style={{padding: '5px 5px'}}>bp may be too high!</p>
                                : ''}
                                </p>,
                            item?.market_value ? `$${item?.market_value}` : '',
                            <input style={{width: '50%'}} form='AcceptQuote' required type='number' value={item?.quantity}
                            onChange={ (ev) => {
                                let temp_items = items.map(e=>e);
                                temp_items[index] = {...item, quantity: ev.target.value};
                                setItems(temp_items);
                            }} disabled={item?.active == '0'|| item?.pass=='1'}/>,
                            <input style={{width: '85%'}} form='AcceptQuote' required type='number' value={item?.is_consignment=='1' ? 0 : item?.buy_price}
                            onChange={ (ev) => {
                                let temp_items = items.map(e=>e);
                                temp_items[index] = {...item, buy_price: ev.target.value};
                                setItems(temp_items);
                            }} disabled={item?.active == '0' || item?.is_consignment=='1' || item?.pass=='1'}/>,
                            <input form='AcceptQuote' type='checkbox' checked={item?.ebayOnly=='1'} value={item?.ebayOnly}
                            onChange={ (ev) => {
                                let temp_items = items.map(e=>e);
                                temp_items[index] = {...item, ebayOnly: ev.target.checked ? 1 : 0};
                                setItems(temp_items);
                            }} disabled={item?.active == '0' || item?.pass=='1'}/>,
                            <input form='AcceptQuote' type='checkbox' checked={item?.accepted===1 && item?.pass=='0'} value={item?.accepted}
                            onChange={ (ev) => {
                                let temp_items = items.map(e=>e);
                                temp_items[index] = {...item, accepted: ev.target.checked ? 1 : 0};
                                setItems(temp_items);
                            }} disabled={item?.active == '0' || item?.pass=='1'}/>,
                            <input form='AcceptQuote' type='checkbox' checked={item?.is_consignment=='1'} value={item?.is_consignment}
                            onChange={ (ev) => {
                                let temp_items = items.map(e=>e);
                                temp_items[index] = {...item, is_consignment: ev.target.checked ? '1' : '0'};
                                setItems(temp_items);
                            }} disabled={item?.active == '0' || item?.pass=='1'}/>
                        ]
                    })
                }
                />
                <div className='container'>
                    <div className="container" style={{width: '65%'}}></div>
                    <label className="contained text-left light-text"> Total Buy Price<br/>
                        <input type='text' className={"border-2 border-black"} disabled value={'$' + `${items?.reduce((acc, curr) => {return (curr?.buy_price && curr?.accepted==1 && curr?.active==1 && curr?.pass==0 && curr?.is_consignment==0 ? (parseFloat(curr?.buy_price) * parseInt(curr?.quantity)) : 0) + acc}, 0)}`} />
                    </label>
                </div>
                <br/>
                <div className="bg-lightest" style={{height:'1px', width:'100%'}}></div>
                <br/>
                <h2 className="light-text">NRTC</h2>
                <div className="contained">
                    <h3 className="light-text">Sale Items</h3>
                    <label className="text-left light-text">
                        Dealer Cost:&nbsp;&nbsp;
                        <input type='number' className={"border-2 border-black"} value={saleCost} onChange={e => setSaleCost(parseInt(e.target.value))}/>
                    </label>
                    <label className="text-left light-text">
                        Sale Price:&nbsp;&nbsp;
                        <input type='number' className={"border-2 border-black"} value={saleValue} onChange={e => setSaleValue(parseInt(e.target.value))}/>
                    </label>
                </div>
                <br/>
                <div className="container">
                    <label className="text-left light-text contained">
                        Net Margin:&nbsp;&nbsp;$
                        <input type='text' className={"border-2 border-black"} disabled value={parseFloat((buyValue - buyCost) + (saleValue - saleCost) - nrtc).toFixed(2)} />
                    </label>
                    <label className="text-left light-text contained">
                        Available NRTC:&nbsp;&nbsp;
                        <input type='text' className={"border-2 border-black"} disabled value={`$${parseFloat(nrtc).toFixed(2)}`}/>
                    </label>
                </div>
                <div className="container">
                    <label className="text-left light-text contained">
                        Net Margin (%):&nbsp;&nbsp;
                        <input type='text' className={"border-2 border-black"} disabled value={ `${(100 * ((buyValue - buyCost) + (saleValue - saleCost) - nrtc) / (parseFloat(saleValue) + buyValue - nrtc)).toFixed(2)}%` } />
                    </label>
                </div>
                <a href={`${context.server == 'http://localhost:9090' ? 'http://localhost:3000' : 'https://flip.tmraudio.net'}/inventory-list`} rel='noreferrer' target='_blank'>
                    find new costs/prices here
                </a>
                <br/><br/>
                <p className="light-text">Logistics will {submission?.notify_logistics ? '' : <b>{'not '}</b>}be notified upon submission of this form&nbsp;&nbsp;&nbsp;
                {submission?.notify_logistics ?
                    <button className='btn dark-text' onClick={notifyLogistics(false)}>Don't Notify Logistics</button> :
                    <ConfirmButton buttonText='Notify Logistics' clickHandler={notifyLogistics(true)} confirmation={
                        <div>
                            <p>The following products require notifying logistics. Please confirm that you have one of the following before notifying Logistics:</p>
                            <ol>
                                <li>All McIntosh components</li>
                                <li>All turntables</li>
                                <li>All tube units with exposed tubes</li>
                                <li>The following PS Audio components:
                                    <ul style={{paddingLeft: '15px'}}>
                                        <li>All DirectStream</li>
                                        <li>All PerfectWave</li>
                                        <li>Power Plant 12</li>
                                        <li>BHK preamps and amps</li>
                                    </ul>
                                </li>
                                <li>{'Any item with a value > $10k'}</li>
                                <li>Freight shipments</li>
                                <li>Anything without factory packaging</li>
                            </ol>
                            <br/>
                            <p>Notes to Logistics:</p>
                            <textarea value={submission?.logistics_notes} className={"border-2 border-black"}
                            onChange={e => setSubmission({...submission, logistics_notes: e.target.value})}
                            style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} />
                        </div>
                    } />
                }</p>
                <br/>
                <br/>
                <input form='AcceptQuote' className='btn dark-text' type='submit' disabled={submission?.assignee_id && submission?.rep_id ? "" : "disabled"} />
            </fieldset>
        </div>
    );
}

export default AcceptQuote;