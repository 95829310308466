import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";

const LogoutButton = () => {
	let context = useContext(AppContext);
	const { logout } = useAuth0();

	function fullLogout() {
		logout({ logoutParams: { returnTo: window.location.origin + '/login' } })
	}

	return (
		<button className="btn bg-red light-text" onClick={() => fullLogout()}>
			Log Out
		</button>
	);
};

export default LogoutButton;