import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0LogoutButton from './Auth0LogoutButton';

const Auth0Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div style={{maxHeight: '60px'}} className="container rounded bg-blue">
        <div className="flex-initial">
            <img style={{maxHeight: '100%', maxWidth: '100%'}} src={user.picture} alt={user.name} />
        </div>
        <div className="flex-initial">
            <h2>{user.name}</h2>
            <p>{user.email}</p>
        </div>
        <div className="flex-initial">
            <Auth0LogoutButton />
        </div>
      </div>
    )
  );
};

export default Auth0Profile;