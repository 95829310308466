import { useEffect } from "react"


const LoadingPopup = ({formData, submitForm, closeModal}) => {

    useEffect(() => {
        formData?.task()
        .then(() => {
            closeModal();
            submitForm();
        })
    })

    return (
        <div style={{padding: '15px 15px'}} className='bg-light'>
            <h1 className="dark-text">Loading process...</h1>
            <br/>
            <h3 className="dark-text">{formData?.message}</h3>
            <p className="dark-text">Please do not use the back button, refresh button, or close the browser.</p>
        </div>
    )
    
}
export default LoadingPopup;