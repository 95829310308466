/**
 * ProductList.js
 * 
 * displays a list of products, allows for searching products
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext';
import {secureFetch} from '../util/flip_utils';
import TableList from '../components/TableList';
import ModalContainer from '../components/ModalContainer';
import CreateProductForm from '../components/CreateProductForm';
import CreateBrandForm from '../components/CreateBrandForm';
import ImageModalContainer from '../components/ImageModalContainer';
import PaginatedTableList from '../components/PaginatedTableList';
import { useHistory } from 'react-router-dom';
import { RatingColorCoder } from '../components/ColorCoders';
import BrandPartnerLogo from '../components/BrandPartnerLogo';


const ProductList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [products, setProducts] = useState([])
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '')

    //called when the component is mounted on the DOM
    function fetchProducts(pageNumber, pageSize) {
        secureFetch(context.server + '/search-products', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            }) 
        })
        .then(resp => resp.json())
        .then(results => setProducts(results))
    }

    useEffect(() => {
        fetchProducts(0, 20);
    }, [context, searchValue])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/product-list', {searchValue: val})
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained text-left'>Search Products</h1>
                <ModalContainer triggerText='Create New Product' submitForm={() => alert('Product created!')} formData={{}} FormComponent={CreateProductForm} />
                <ModalContainer triggerText='Create New Brand' submitForm={() => alert('Brand created!')} formData={{}} FormComponent={CreateBrandForm} />
            </div>
            <form>
                <div className='container'>
                        <input autoFocus type='text' className="search-bar" onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}
                        onChange={e => updateSearchValue(e.target.value)} value={searchValue}/>
                </div>
            </form>
            <br/>
            <br/>
            <PaginatedTableList
            columnNames={['Image','Title','Brand', 'Model', 'Rating', 'PID',' ']}
            columnWidths={['15%','35%','10%','20%','6%','6%','8%']}
            entries={
                products.map( (prod, index) => {
                    let img = prod?.thumbnail !== null ? 
                        <div className='contained'><ImageModalContainer styleprops={{width: '140'}} source={prod?.thumbnail} alter={prod?.title} /></div>
                        :
                        <p className='dark-text contained'>{prod?.title}</p>;
                        prod.img = img;
                    return [
                        prod?.img,
                        <Link className='dark-text' to={{pathname: `/product-view/${prod?.id}`, state: {product_id: prod?.id}}}>{prod?.title}</Link>,
                        prod?.brand_partner ? 
                            <div className='vertically-aligned'>
                                <BrandPartnerLogo height = {'25px'}/>
                                <div>
                                    &nbsp;{prod?.brand}
                                </div> 
                            </div>
                            : 
                            prod?.brand,
                        prod?.model,
                        <RatingColorCoder content={prod?.rating} rating={prod?.rating} />,
                        prod?.id,
                        <Link className='btn contained dark-text' to={{pathname: `/product-view/${prod?.id}`, state: {product_id: prod?.id}}}>View</Link>
                    ]
                })
            }
            getData={fetchProducts}
            />
            
        </div>
    );
}

export default ProductList;
