import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { formatDate, secureFetch } from "../util/flip_utils";
import { Link } from "react-router-dom";


const RaItemViewForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);

    let [raItem, setRaItem] = useState({});
    let [overview, setOverview] = useState({});
    let [returnReasons, setReturnReasons] = useState([]);
    let [fetched, setFetched] = useState(false);


    function fetchRaItem() {
        setFetched(false);
        secureFetch(context?.server + '/ra-item-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: formData?.ra_item_id
            })
        })
        .then(resp => resp.json())
        .then(result => {
            setOverview(result);
            setRaItem(result?.raItem);
            setFetched(true);
        })
    }

    function fetchReturnReasons() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'return_reasons'})
        })
        .then(resp => resp.json())
        .then(results => setReturnReasons(results));
    }

    useEffect(() => {
        fetchRaItem();
        fetchReturnReasons();
    }, []);

    return (
        <div>
            <div className="bg-light" style={{padding: '15px 15px'}}>
                <h1 className="dark-text">RA Receipt Info:</h1>
                <br/>
                {fetched ?
                <div className="bg-lightest" style={{padding: '15px 15px'}}>
                    <br/>
                    <p>SKU & Order Number: {overview?.item?.sku} - {overview?.item?.order_number}</p>
                    <p>New SKU: {overview?.raItem?.new_sku}</p>
                    <p>Received: {formatDate(raItem?.receive_date)}</p>
                    <p>Customer Return Reason: {returnReasons.filter(row => row?.id==raItem?.return_reason_id)[0]?.reason}</p>
                    <p>Actual Return Reason: {returnReasons.filter(row => row?.id==raItem?.actual_return_reason_id)[0]?.reason}</p>
                    <p>Received By: {raItem?.receiver ? raItem?.receiver : 'Not received'}</p>
                    <p>Originally Tested By: {raItem?.tester}</p>
                    <p>OK to resolve?&nbsp;&nbsp;&nbsp;{raItem?.issue ? 'No' : 'Yes'}</p>
                    {raItem?.issue ? <p>Issue: {raItem?.issue_notes}</p> : <></>}
                    <p>Receiving Notes: {raItem?.notes}</p>
                    <br/><br/>
                    <a rel='noreferrer' target='_blank' href={'https://themusicroom.freshdesk.com/a/tickets/' + raItem?.ra_id}>See FD Ticket</a><br/>
                    <a rel='noreferrer' target='_blank' href={'https://ship.shipstation.com/orders/all-orders-search-result?quickSearch='+String(overview?.item?.order_number)}>Shipstation Order</a><br/>
                    <a rel='noreferrer' target='_blank' href={'https://store-6naz0isypk.mybigcommerce.com/manage/orders?viewId='+String(overview?.item?.order_number)+'&orderTo='+String(overview?.item?.order_number)+'&orderFrom='+String(overview?.item?.order_number)}>BC Order</a>
                    <br/>
                    <br/>
                    <Link className='btn dark-text' to={{pathname: `/receive-ra-item/${raItem?.id}`, state: {ra_item_id: raItem?.id}}} onClick={() => closeModal()}>Edit Receipt Info</Link>
                </div>
                : <p>fetching data...</p>}
            </div>
        </div>
    )
}

export default RaItemViewForm;