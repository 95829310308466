import { useContext, useState } from "react";
import AppContext from "../AppContext";


const MergeQuantityForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    
    let [item, setItem] = useState(formData);
    let [itemId, setItemId] = useState();
    let [formDisable, setFormDisable] = useState(false);

    function submit(event) {
        event.preventDefault();
        setFormDisable(true);
        submitForm(itemId);
        closeModal();
    }

    if(!context?.feature_flags['split_merge_quantity']) {
        return (
            <div>
                <h2>This feature is not currently enabled on your version of FLIP</h2>
            </div>
        )
    }

    return (
        <div>
            <form id='MergeQuantityForm' onSubmit={submit} />
            <fieldset form='MergeQuantityForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <label className="dark-text text-left">Item ID for {item?.sku} to be Merged Into:<br/>
                        <input type='number' value={itemId} onChange={e => setItemId(e.target.value)} />
                    </label>
                    <br/>
                    <input form='MergeQuantityForm' className='btn bg-dark light-text' type='submit' />
                
                </div>
            </fieldset>
        </div>
    );
}

export default MergeQuantityForm;