import { useContext, useEffect, useState } from "react"
import AppContext from "../../AppContext";
import { secureFetch } from "../../util/flip_utils";
import GridView from "../GridView";
import InfoBox from "../InfoBox";
import KeyValuePair from "../KeyValuePair";


const OptionsUI = ({parentOptions, updateParentOptions, availableOptionSets}) => {

    let [optionSets, setOptionSets] = useState(availableOptionSets);
    let [options, setOptions] = useState(parentOptions);

    //update currently set options whenever parent updates
    useEffect(() => {
        setOptions(parentOptions);
        //alert(parentOptions);
    }, [parentOptions])

    //update possible options if parent updates (shouldn't really happen I dont think)
    useEffect(() => {
        setOptionSets(availableOptionSets);
    }, [availableOptionSets])

    function radioHandler(optionSetId, optionId) {
        return (event) => {
            if(event.target.checked) {
                //alert(optionId)
                let optionsIdx = options?.map(op => op?.option_set_id).indexOf(optionSetId);
                let newOptions = options.map(op=>op);
                if(optionsIdx < 0) {
                    //add this option to the list
                    newOptions.push({option_id: optionId, option_set_id: optionSetId});
                } else {
                    //we already had this, just update
                    newOptions[optionsIdx].option_id = optionId;
                }
                
                //update parent, which will trickle back down to this component because of the useEffect
                updateParentOptions(newOptions);
            }
        }

    }


    function checkboxHandler(optionSetId, optionId) {
        return (event) => {
            if(event.target.checked) {
                //if checked, add it to the list of set options
                //alert(optionId)
                let newOptions = options.map(op=>op) || [];
                newOptions.push({option_id: optionId, option_set_id: optionSetId});
                //update parent, which will trickle back down to this component because of the useEffect
                updateParentOptions(newOptions);
            } else {
                //otherwise we need to REMOVE it from the list of set options
                let optionsIdx = options?.map(op => op?.option_id).indexOf(optionId);
                let newOptions = options.map(op=>op);
                delete newOptions[optionsIdx];
                updateParentOptions(newOptions);
            }
        }
    }

    function numberHandler(optionSetId) {
        return (event) => {
            let optionsIdx = options?.map(op => op.option_set_id)?.indexOf(optionSetId);
            if(optionsIdx < 0) {//didn't have a value for this option
                let newOptions = options.map(o => o);
                newOptions.push({value: event.target.value, option_set_id: optionSetId});
                updateParentOptions(newOptions);
            } else {
                //we did have a value for this already, just update it
                let newOptions = options.map(o => o);
                newOptions[optionsIdx].value = event.target.value;
                updateParentOptions(newOptions);
            }
        }
    }

    return(
        <div>
            <InfoBox title="Options:">
                {optionSets?.map(set => <>
                    <label className="dark-text text-left"><strong>{set?.name}:</strong> &nbsp;&nbsp;&nbsp;
                        {set?.option_type=='radio' ? <><br/>
                            <GridView columns={6} children=
                            {set?.options?.map(setOpt => <>
                                <label className="text-left dark-text contained">
                                    <input type='radio' 
                                    checked={options?.map(op => op?.option_id).indexOf(setOpt?.id) >= 0}
                                    onChange={radioHandler(set?.id,setOpt.id)} />
                                &nbsp;{setOpt?.name}</label>
                            </>)} /></>
                        : set?.option_type=='checkbox' ? <><br/>
                            <GridView columns={6} children=
                            {set?.options?.map(setOpt => <>
                                <label className="text-left dark-text contained">
                                    <input type='checkbox' 
                                    checked={options?.map(op => op?.option_id).indexOf(setOpt?.id) >= 0}
                                    onChange={checkboxHandler(set?.id,setOpt.id)} />
                                &nbsp;{setOpt?.name}</label>
                            </>)} />
                        </>
                        : set?.option_type=='number' ? <>
                            <input type='number' value={options?.filter(op => op?.option_set_id==set?.id)?.length > 0 ? 
                                options?.filter(op => op?.option_set_id==set?.id)[0]?.value : 0}
                            onChange={numberHandler(set?.id)} />
                        </>
                        : <>unknown</>}
                        <div className="bg-light" style={{minWidth: '90%', height: '1px'}} />
                    </label>
                </>)}
            </InfoBox>
        </div>
    )
}

export default OptionsUI;