/**
 * CreateItemForm.js
 * 
 * a form subcomponent which is used in the context of a modal view to create a new item
 */


import React, { useContext, useEffect, useState } from 'react';
import { secureFetch } from '../util/flip_utils';
import AppContext from '../AppContext';
import TableList from './TableList';
import { Link } from 'react-router-dom';


//onSubmit, formData, and closeModal are standard for the forms displayed inside modal views.
//onSubmit is given the entity after we have made changes to it so that the parent component has the new information
//formData is the dictionary which contains all of the entity's information
//closeModal is the method from the parent function which tells us how to close the modal view when the user clicks the submit button
const CreateItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [fetched, setFetched] = useState(false);
    let [item, setItem] = useState({active: 1});
    let [cosmetics, setCosmetics] = useState([]);
    let [conditions, setConditions] = useState([]);
    let [formDisable, setFormDisable] = useState(false);
    let [productId, setProductId] = useState()

    // Variables from Select Product Form
    let [products, setProducts] = useState([]);
    let [searchValue, setSearchValue] = useState('');
    let [mergeDestinationId, setMergeDestinationId] = useState("");

    function fetchProducts() {
        secureFetch(context.server + '/search-products', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({query: searchValue})
        })
        .then(resp => resp.json())
        .then(products => {
            setProducts(products.map(p => {return {...p, mergeIsOpen: false}}))
        })
    }

    function setMergeIsOpen(ind) {
        let newProducts = products.map(p => p);
        newProducts[ind].mergeIsOpen = !newProducts[ind].mergeIsOpen;
        setProducts(newProducts);
    }

    function mergeIsOpen(ind) {
        return products[ind].mergeIsOpen;
    }

    function mergeProducts(sourceId, destinationId) {
        //alert(`${sourceId} ${destinationId}`);
        secureFetch(context.server + '/merge-products', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sourceId: sourceId,
                destinationId: destinationId
            })
        })
        .then(() => {
            alert("merge successful");
            fetchProducts();
        })
    }

    function fetchData() {
        setFetched(false);
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({tableName: 'cosmetic_conditions'})
        })
        .then (resp => resp.json())
        .then(results => {
            setCosmetics(results);
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({tableName: 'item_conditions'})
            })
            .then(resp => resp.json())
            .then(results => {
                setConditions(results);
                setFetched(true);
            })
        })
    }

    /* eslint-disable */
    useEffect(() => {
        fetchProducts();
        fetchData();
    },[])
    /* eslint-enable */

    useEffect(() => {
        fetchProducts();
    },[searchValue])

    //submit is called when the submit button is pushed. it has its own functionality, and also calls the props onSubmit and closeModal
    function submit( e ) {
        setFormDisable(true);
        e.preventDefault();
        let body = {
            keys: Object.keys(item),
            values: Object.values(item)
        }
        secureFetch(context.server + '/create-item', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(res => res.json())
        .then(data => {    
            console.log(data)
            closeModal();
            submitForm(data);
        })
        
    }

    //handles when the cosmetic condition dropdown is changed
    function cosmeticChangeHandler (event) {
        setItem({...item, cosmetic_condition_id: event.target.value});
    }

    //handles when the item condition dropdown is changed
    function conditionChangeHandler (event) {
        setItem({...item, item_condition_id: event.target.value});
    }

    if(!fetched) {
        return <h1 className='dark-text'>Loading Data...</h1>;
    }

    //returns the form component to be rendered
    return (
        <div>
            <form id='CreateItemForm' onSubmit={submit} />
            <fieldset form='CreateItemForm' disabled={formDisable ? 'disabled' : ''}>
            {!productId ? 
                <div>
                    <h1 className='dark-text'>To add an item, a product must be selected:</h1>
                    <input autoFocus type='text' className="search-bar" onChange={ev => setSearchValue(ev.target.value)} value={searchValue}/>
                    <br/><br/>
                    <TableList columnNames={[' ','ID','Title','Brand', 'Model',' ',' ']}
                    columnWidths={['10%','8%','30%','12%','20%','10%','10%']}
                    entries={
                        products.map( (row, ind) => {
                            return [
                                <div>
                                    <button className='btn dark-text' onClick={() => setMergeIsOpen(ind)}>Merge</button>
                                    {mergeIsOpen(ind) ? 
                                    <div>
                                        <label className='dark-text text-left'>Destination ID</label>
                                        <input value={mergeDestinationId} onChange={e => setMergeDestinationId(e.target.value)} />
                                        <button className='btn bg-red light-text' onClick={() => mergeProducts(row?.id, mergeDestinationId)}>Do Merge</button>
                                    </div>
                                    : <></>}
                                </div>,
                                row?.id,
                                row?.title,
                                row?.brand,
                                row?.model,
                                <Link onClick={closeModal} className='btn dark-text' to={{pathname: `/product-view/${row?.id}`, state: {product_id: row?.id}}}>View</Link>,
                                <button className='btn dark-text' onClick={() => {setProductId(row?.id); setItem({...item, title: row?.title, product_id: row?.id})}}>Select</button>,
                            ]
                        })
                    }
                    />
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                </div>
            : <>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h3>Create New Item</h3>
                    <div className='container'>
                        <label className='dark-text'>Title:&nbsp;</label>
                        <input form='CreateItemForm' required className='' type='text' size='100' value={item?.title ? item?.title : ''} onChange={ (e) => {setItem({...item, title: e.target.value})}} />
                    </div>
                    <div className='container'>
                        <label className=''>*Item Type:&nbsp;</label>
                        <select required form='CreateItemForm' value={item?.sale_type_id} onChange={ (e) => {setItem({...item, sale_type_id: e.target.value})}}>
                            <option value='' key={0}>*** SELECT ITEM CONDITION ***</option>
                            <option key={1} value={1} >Open Box</option>
                            <option key={1} value={2} >Closeout</option>
                        </select>
                    </div>
                    <div className='container'> 
                        <label className='dark-text'>*Buy Price:&nbsp;</label>
                        <input form='CreateItemForm' required className='' type='number'  value={item?.purchase_amount ? item?.purchase_amount : ''} onChange={ (e) => {setItem({...item, purchase_amount: e.target.value})}}/>
                    </div>
                    <div className='container'>
                        <label className=' dark-text'>List Price:&nbsp;</label>
                        <input form='CreateItemForm' className='' type='number' value={item?.list_price ? item?.list_price : ''} onChange={ (e) => {setItem({...item, list_price: e.target.value})}}/>
                    </div>
                    <div className='container'>
                        <label className=' dark-text'>Quantity:&nbsp;</label>
                        <input form='CreateItemForm' className='' type='number' min="1" step="1" value={item?.quantity ? item?.quantity : 1} onChange={ (e) => {setItem({...item, quantity: e.target.value})}}/>
                    </div>

                    <input form='CreateItemForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </>}
            </fieldset>
        </div>
    );
};

export default CreateItemForm;