/**
 * ProductView.js
 * 
 * displays an overview of the given product and allows for editing
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
//import InfoList from '../components/InfoList.js';
import { Link, useHistory, useParams } from 'react-router-dom';
import ModalContainer from '../components/ModalContainer';
import EditProductForm from '../components/EditProductForm.js';
import AppContext from '../AppContext';
import {formatDate, secureFetch} from '../util/flip_utils';
import KeyValuePair from '../components/KeyValuePair';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import SelectThumbnailForm from '../components/SelectThumbnailForm.js';
import ImageModalContainer from '../components/ImageModalContainer';
import AppraisalPopup from '../components/AppraisalPopup';
import FlipRTV from '../components/FlipRTV';
import { RatingColorCoder } from '../components/ColorCoders';
import ConfirmButton from '../components/ConfirmButton';
import BrandPartnerLogo from '../components/BrandPartnerLogo';
import ProductClassificationsForm from '../components/ProductClassificationsForm.js';
import ProductOptionDefaultsForm from '../components/ProductOptionDefaultsForm.js';
import CheckmarkYN from '../components/CheckmarkYN';
import TabbedDisplay from '../components/TabbedDisplay';

const ProductView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { product_id } = useParams();
    let [product, setProduct] = useState({});
    let [brand, setBrand] = useState({});
    let [productCategories, setProductCategories] = useState([]);
    let [quotes, setQuotes] = useState([]);
    let [items, setItems] = useState([]);
    let [sales, setSales] = useState([]); //list of sales data on past items
    let [inThePipeline, setInThePipeline] = useState([])
    let [pipelineFetched, setPipelineFetched] = useState(false);
    let [showMerge, setShowMerge] = useState(false);
    let [mergeDestinationId, setMergeDestinationId] = useState("");
    let [currentListings, setCurrentListings] = useState([]);
        
    function fetchData() {
        secureFetch(context.server + '/product-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: product_id ? product_id : props.location.state.product_id})
        })
        .then(resp => resp.json())
        .then(result => {
            setProduct(result?.product);
            setItems(result?.items?.slice(0,10));
            setQuotes(result?.quotes);
            setBrand(result?.brand);
        })
    }

    //called when the component is mounted on the DOM
    useEffect(() => {
        fetchData();
        fetchProductPipelineData();
        fetchCurrentlyListed();
    }, [])

    function createDocument(docType) {
        secureFetch(context.server + '/create-document', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({product: product, docType: docType})
        })
        .then(() => window.location.reload())
    }

    function fetchProductPipelineData(){
        secureFetch(context?.server + '/product-pipeline-data', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({product_id: product_id ? product_id : props.location.state.product_id}) 
        })
        .then(res => res.json())
        .then(data => {
            

            ///clean newline characters for unshipped items report
            for(let r = 0; r < data?.unshippedItems?.length; r++) {
                let cols = Object.keys(data?.unshippedItems[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data?.unshippedItems[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data.unshippedItems[r][cols[c]] = newrow;
                }
            }
            // do the same for shipped items
            for(let r = 0; r < data?.shippedItems?.length; r++) {
                let cols = Object.keys(data?.shippedItems[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data?.shippedItems[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data.shippedItems[r][cols[c]] = newrow;
                }
            }

            console.log(data);
            setInThePipeline(data?.unshippedItems);
            setSales(data?.shippedItems)
            setPipelineFetched(true)
        })
    }

    function fetchCurrentlyListed() {
        secureFetch(context.server + '/currently-listed', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({items: [{product_id: product_id}]})
        })
        .then(response => response.json())
        .then(data => data?.[0]?.listings)
        .then(data=>{setCurrentListings(data)})
    }

    function mergeProduct(event) {
        event.preventDefault();
        secureFetch(context.server + '/merge-products', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sourceId: product.id,
                destinationId: mergeDestinationId
            })
        })
        .then(() => {
            alert("merge successful");
            history.push({pathname: `/product-view/${mergeDestinationId}`, state: {product_id: mergeDestinationId}});
            window.location.reload();
        })
    }

    function selectThumbnail(prod) {
        fetchData();
    }

    
    if(!props?.location?.state?.product_id && !product_id) {
        return <h1>Product not found...</h1>
    }
    //returns the actual component to be rendered, including a modal container so that we can edit the product info in a modal
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Product Overview {product?.id ? '' : 'Loading...'}</h1>
            <div className='container'>
                <div className='contained'>
                    
                    <InfoBox title='Product Information:'>
                        <KeyValuePair label={'PID:'} value={product?.id} textClass={'dark-text'} />
                        <KeyValuePair label={'Title:'} value={product?.title} textClass={'dark-text'} />
                        <KeyValuePair label={'Brand:'} value={brand?.brand_partner ? <div className = 'vertically-aligned'><BrandPartnerLogo height = {"25px"}/>&nbsp;{product?.brand}</div>  : product?.brand} textClass={'dark-text'}/>
                        <KeyValuePair label={'Model:'} value={product?.model} textClass={'dark-text'} />
                        <KeyValuePair label={'Options:'} value={product?.options} textClass={'dark-text'} />
                        {productCategories?.length > 0 ?
                            productCategories?.map( (row, ind) => 
                                <KeyValuePair label={ind==0 ? 'Categories:' : ' '} value={row?.category + '/' + row?.subcategory} textClass={'dark-text'} />
                            )
                        :
                        <></>
                        }
                        <KeyValuePair label='Rating:' value={<RatingColorCoder content={`${product?.rating || 'N/A'} ${product?.rating != brand?.generic_rating ? '(exception to brand rule)' : ''}`} rating={product?.rating} />} textClass='dark-text' />
                        <KeyValuePair label={'MSRP:'} value={product?.msrp} textClass={'dark-text'} />
                        <KeyValuePair label={'Active?:'} value={product?.active ? 'Yes' : 'No'} textClass={'dark-text'} />
                        <KeyValuePair label={'Description:'} value={product?.description} textClass={'dark-text'} />
                        <KeyValuePair label={'Internal Notes:'} value={product?.internal_notes} textClass={'dark-text'} />
                        <KeyValuePair label={'Manual:'} value={<a rel="noreferrer" target='_blank' href={product?.manual_link}>Manual</a>} textClass={'dark-text'} />
                        <KeyValuePair label={'Specs:'} value={<a rel="noreferrer" target='_blank' href={product?.specs_link}>Specs</a>} textClass={'dark-text'} />
                        <KeyValuePair label={'Review 1:'} value={<a rel="noreferrer" target='_blank' href={product?.review_link_1}>{product?.review_name_1 || '-'}</a>} textClass={'dark-text'} />
                        <KeyValuePair label={'Review 2:'} value={<a rel="noreferrer" target='_blank' href={product?.review_link_2}>{product?.review_name_2 || '-'}</a>} textClass={'dark-text'} />
                        <KeyValuePair label={'Review 3:'} value={<a rel="noreferrer" target='_blank' href={product?.review_link_3}>{product?.review_name_3 || '-'}</a>} textClass={'dark-text'} />
                        <KeyValuePair label={'Internal Review:'} value={<FlipRTV text={product?.internal_review} />} textClass={'dark-text'} />
                        <KeyValuePair label={'Testing Notes:'} value={
                            product?.testing_notes
                        } textClass={'dark-text'} />
                        <KeyValuePair label={'Appraising Notes:'} value={
                            product?.appraising_notes
                        } textClass={'dark-text'} />
                        <KeyValuePair label={'Repair Notes:'} value={
                            product?.repair_notes
                        } textClass={'dark-text'} />
                        <KeyValuePair label={'Hi-Fi Shark:'} value={
                        <a rel="noreferrer" target='_blank' href={'https://www.hifishark.com/search?q=' + String(product?.brand).split(' ').join('+') + '+' + String(product?.model).split(' ').join('+')}>Hi-Fi Shark</a>
                        } textClass={'dark-text'} />
                        <br/>
                        <ModalContainer triggerText='Edit Product' submitForm={() => fetchData()} formData={product} FormComponent={EditProductForm} />
                        <ModalContainer triggerText='Edit Classifications' submitForm={() => fetchData()} formData={product} FormComponent={ProductClassificationsForm} />
                        <ModalContainer triggerText='Edit Option Defaults' submitForm={() => fetchData()} formData={product} FormComponent={ProductOptionDefaultsForm} />
                        <br/><br/>
                        <button className='btn bg-red light-text' onClick={() => setShowMerge(!showMerge)}>Merge into Product</button>
                        {showMerge ? <>
                            <label className='text-left light-text'>Destination ID<br/><input value={mergeDestinationId} onChange={e => setMergeDestinationId(e.target.value)} /></label>
                            <ConfirmButton buttonText="Confirm Merge" buttonClass='btn bg-light-green dark-text' clickHandler={mergeProduct} cancelHandler={() => {}} confirmation={
                                `This process is NOT reversible. Are you sure you want to merge product ${product?.id} (this one) INTO product ${mergeDestinationId}?
                                This product page will cease to exist, so make sure you save any document links, titles, etc. that you would like to keep. You will be navigated to the new product page afterwards.`
                            } />
                        </>
                        : <></>}
                    </InfoBox>
                    <br/>
                    {pipelineFetched ? 
                        <div>
                        <h1 className='light-text'>In the Pipeline:</h1>                        
                        <TableList columnNames={['SKU','RMA','Status','Buy Date']}
                        columnWidths={['20%','25%','30%','25%']}
                        entries={inThePipeline.length > 0 ?
                            inThePipeline?.map(row => {
                                return [
                                    <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                                    row?.tpa_number == "null" ? 'None':<Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`}}>{row?.tpa_number}</Link>  ,
                                    row?.display_status,
                                    formatDate(row?.created, {date: true})                              
                                ]
                            })
                            : [['No items in the pipeline','','','']]
                        } />
                        </div>
                        : 
                        <div className='bg-light'>Loading Pipeline Data...</div>
                    }
                    <br/>
                    {currentListings ?
                            <div> 
                                <br/>
                                <h3 className='light-text'>Current Listings:</h3>
                                <TableList className = 'bg-light'
                                    columnNames={['Sku','List Price','Days Listed']}
                                    columnWidths={['33%','33%','33%']}
                                    entries={
                                        currentListings.map( item => {
                                            return [
                                                item?.open_box == 0 ? <a className="btn dark-text" rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku}</a> : <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+item?.url}>{item?.sku} (OB)</a>,
                                                '$'+item?.price,
                                                item?.daysListed
                                            ]
                                        })
                                    }
                                /> 
                            </div>
                        :
                            <div> 
                                <br/>
                                <h3 className='light-text'>Current Listings:</h3>
                                <TableList className = 'bg-light'
                                            columnNames={['Sku','List Price','Days Listed']}
                                            columnWidths={['33%','33%','33%']}
                                            entries={
                                                [['No current listings']]
                                            }
                                />
                            </div>
                    }
                </div>
                <div className='contained'>
                    <ImageModalContainer styleprops={{width: '100%'}} source={product?.thumbnail} alter={product?.title} />
                
                    <ModalContainer triggerText='Select Thumbnail' submitForm={selectThumbnail} formData={product} FormComponent={SelectThumbnailForm} />

                    <h2 className='light-text'>Items:</h2>
                    <TableList columnNames={['Image','SKU','Title',' ']}
                    columnWidths={['25%','15%','40%','20%']}
                    entries={items.map( it => {
                        return [
                            <ImageModalContainer styleprops={{width: '100%'}} source={it?.thumbnail} alter={it?.title} />,
                            it?.sku.split('*')[0],
                            it?.title,
                            <Link className='btn dark-text' to={{pathname: `/item-view/${it?.id}`, state: {item_id: it?.id}}}>View</Link>
                        ]
                    })}
                    />
                    
                </div>        
            </div>
            <br/>
            <br/>
            
            <TabbedDisplay tabNames={['Past Sales', 'Past Appraisals']}
                contents={[
                    <>  
                    
                        {pipelineFetched ? 
                        <div>
                            <br/>
                            <h3 className='light-text'>Past Sales:</h3>
                            <br/>
                            <div>
                                <TableList columnNames={['SKU','Title','Order Date','Cosmetic Condition','Factory Packaging','Sale Price','List to Sale']}
                                columnWidths={['10%','38%','10%','10%','10%','12%','10%']}
                                entries={sales?.map(row => {
                                    return [
                                        row?.sku ? <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link> : '***Error***',
                                        row?.title,
                                        row?.order_date.substr(5,2)+'/'+row?.shipped_date.substr(2,2),
                                        row?.cosmetic_condition_id,
                                        <CheckmarkYN val={row?.has_packaging ==1 ? true : false} />,
                                        '$'+row?.sale_price,
                                        row?.list_to_sale ? row?.list_to_sale + ' Days': 'N/A'                                                    
                                    ]
                                })} />
                            </div>
                        </div>   
                        : <><h3 className='light-text bg-light'>Loading past sale data...</h3></>}
                    </>,
                    <>
                        <br/>
                        <h3 className='light-text'>Past Quotes:</h3>
                        <TableList columnNames={['MV','LP','BP','User','Sub.','Details']}
                            columnWidths={['15%','15%','15%','20%','15%','20%']}
                            entries={quotes?.map(row => {
                                return [
                                    row?.market_value,
                                    row?.rec_list_price,
                                    row?.rec_buy_price,
                                    row?.appraiser,
                                    <Link className='btn dark-text' to={{pathname: `/submission-view/${row?.submission_id}`, state: {submission_id: row?.submission_id}}} >{row?.submission_id}</Link>,
                                    <ModalContainer triggerText='View' formData={row} FormComponent={AppraisalPopup} submitForm={() => {}} />
                                ]
                            })}
                        />
                    </>
                ]} 
            />
            
        </div>
    );
}

export default ProductView;
