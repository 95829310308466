import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
//import InfoList from '../components/InfoList.js';
import { Link, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import {secureFetch} from '../util/flip_utils';
import InfoBox from '../components/InfoBox';
import KeyValuePair from '../components/KeyValuePair';
import ImageModalContainer from '../components/ImageModalContainer';
import ModalContainer from '../components/ModalContainer';
import SelectProductForm from '../components/SelectProductForm';
import EditSubmissionItemForm from '../components/EditSubmissionItemForm';
import { useHistory } from 'react-router-dom';
import SendQuoteForm from '../components/SendQuoteForm';
import { CosmeticColorCoder, RatingColorCoder } from '../components/ColorCoders';


const SubmissionItemView = (props) => {
    let history = useHistory();
    let context = useContext(AppContext);
    let { item_id } = useParams();
    let [item, setItem] = useState({});
    let [product, setProduct] = useState({});
    let [quote, setQuote] = useState({});

    function fetchData() {
        secureFetch(context.server + '/submission-item-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: item_id || props.location.state.item_id}) 
        })
        .then(response => response.json())
        .then(data => {
            setItem(data?.submission_item);
            setProduct(data?.product);
            setQuote(data?.quote)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    function assignProduct(id) {
        //update the submission item and wait for response so we can send the info back to the submission view page
        secureFetch(context.server + '/update-submission-item-fields', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                id: item?.id,
                keys: ['product_id'],
                values: [id],
                message: 'user reassigned product to submission item'
            })
        })
        .then(() => fetchData())
    }

    function duplicateItem(event) {
        event.preventDefault();
        //first remove submission id from item for add-item endpoint
        let sub_id = item?.submission_id;
        delete item?.submission_id;
        //also delete any bad fields that came from overview endpoint but dont belong on the item
        delete item?.assignee;
        delete item?.id;
        delete item?.submission_status_id;
        
        secureFetch(context.server + '/add-submission-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify({
                submission_id: sub_id,
                item: item
            })
        })
        .then(() => {
            alert('item duplicated!');
            history.push({pathname: `/submission-view/${sub_id}`, state: {submission_id: sub_id}});
            window.location.reload();
        })
    }

    if(!props?.location?.state?.item_id && !item_id) {
        return <h1>Item not found...</h1>
    }
    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained'>Submission Item Overview</h1>
                <div className='container contained'>
                    <Link className='btn dark-text contained' to={{pathname: `/submission-view/${item?.submission_id}`, state: {submission_id: item?.submission_id}}}>Back To Submission</Link>
                    {item?.submission_status_id==3 ?
                        <ModalContainer buttonClass='btn dark-text contained' triggerText='Send Quote' formData={{id: item?.submission_id}} FormComponent={SendQuoteForm} submitForm={() => {alert('quote sent')}} />
                        : <div className='contained'></div>}
                </div>
            </div>
            <div className='contained'>
                <h2 className='light-text'>Item Info</h2>
                <div className='container'>
                    <div className='contained'>
                        <InfoBox title='Item Information'>
                            <KeyValuePair label='ID #:' value={item?.id} textClass='dark-text' />
                            <KeyValuePair label='Manufacturer:' value={item?.manufacturer} textClass='dark-text' />
                            <KeyValuePair label='Model:' value={item?.model_number} textClass='dark-text' />
                            <KeyValuePair label='Product Title (from us):' value={product?.title} textClass='dark-text' />
                            <KeyValuePair label={'Product Rating:'} value={<RatingColorCoder content={product?.rating} rating={product?.rating} />} textClass={'dark-text'}/>
                            <KeyValuePair label='Age:' value={item?.actual_age_of_item == -1 ? "Unknown"
                            : item?.actual_age_of_item == null ? '-'
                            : item?.actual_age_of_item == 0 ? "Less than 1 year"
                            : item?.actual_age_of_item + ' Year(s)'} textClass='dark-text' />
                            <KeyValuePair label='Number of Owners:' value={item?.number_of_owners == -1 ? 'Unknown'
                            : item?.number_of_owners == null ? "-"
                            : item?.number_of_owners >= 4 ? "4 or more" : item?.number_of_owners} textClass='dark-text' />
                            <KeyValuePair label='Cosmetic:' value={<CosmeticColorCoder content={item?.cosmetic_condition > 0 ? (item?.cosmetic_condition + '/10') : 'N/A'} cosmetic={item?.cosmetic_condition} />} textClass='dark-text' />
                            <KeyValuePair label='Tobacco:' value={item?.tobacco_exposure===1 ? 'Yes' : 'No'} textClass='dark-text' />
                            <KeyValuePair label="What's Included:" value={item?.what_is_included} textClass='dark-text' />
                            <KeyValuePair label="Has Box?" value={item?.has_packaging == 1 ? 'Yes' : 'No'} textClass='dark-text' />
                            <KeyValuePair label='MSRP (from customer):' value={`$${item?.original_msrp}`} textClass='dark-text' />
                            <KeyValuePair label='Desired Price:' value={`$${item?.desired_price}`} textClass='dark-text' />
                            <KeyValuePair label='Notes:' value= {item?.notes} textClass={'dark-text'} />
                            <KeyValuePair label='Quantity:' value={item?.quantity} textClass='dark-text' />
                            <KeyValuePair label='Buy Price (from us):' value={quote?.rec_buy_price || 'not appraised'} textClass='dark-text' />
                            <KeyValuePair label='List Price (from us):' value={quote?.rec_list_price || 'not appraised'} textClass='dark-text' />
                            <KeyValuePair label='Market Value (from us):' value={quote?.market_value || 'not appraised'} textClass='dark-text' />
                            <KeyValuePair label='Accepted:' value={item?.accepted===1 ? 'Yes' : 'No'} textClass='dark-text' />
                            <ModalContainer triggerText='Edit Item' formData={item} FormComponent={EditSubmissionItemForm} submitForm={fetchData} />
                        </InfoBox>
                        <br/>
                        <button className='btn dark-text' onClick={duplicateItem}>Duplicate this Item<br/><i>(quote not included)</i></button>
                    </div>
                    <div className='contained'>
                        <h2 className='light-text'>Customer Images:</h2>
                        <div className='container'>
                            <div className='contained'>{item?.file1 ? <ImageModalContainer styleprops={{maxWidth:'100%'}} source={item?.file1} alter='unavailable' /> : <></>}</div>
                            <div className='contained'>{item?.file2 ? <ImageModalContainer styleprops={{maxWidth:'100%'}} source={item?.file2} alter='unavailable' /> : <></>}</div>
                        </div>
                        <div className='container'>
                            <div className='contained'>{item?.file3 ? <ImageModalContainer styleprops={{maxWidth:'100%'}} source={item?.file3} alter='unavailable' /> : <></>}</div>
                            <div className='contained'>{item?.file4 ? <ImageModalContainer styleprops={{maxWidth:'100%'}} source={item?.file4} alter='unavailable' /> : <></>}</div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <ModalContainer buttonClass={ 'contained ' + (item?.product_id ? 'bg-green btn dark-text' : 'btn dark-text') }
                    triggerText='Assign Product'
                    formData={{}}
                    FormComponent={SelectProductForm}
                    submitForm={ prod => assignProduct(prod?.id)}
                    />
                    <Link className={ 'contained ' + (quote?.user_id ? 'bg-green btn dark-text' : 'btn dark-text') } to={{pathname: `/appraise-item/${item?.id}`, state: {item_id: item?.id}}}>Appraise</Link>
                </div>
            </div>
        </div>
    );
}

export default SubmissionItemView;