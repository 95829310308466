import { useState } from "react";


const CancelDraftOrderForm = ({formData, submitForm, closeModal}) => {
    let [formDisable, setFormDisable] = useState(false);
    let [cancelNotes, setCancelNotes] = useState("");

    function submit(event) {
        event?.preventDefault();
        submitForm(cancelNotes);
        closeModal();
    }
    return (
        <div>
            <form id='CancelDraftOrderForm' onSubmit={submit} />
            <fieldset form='CancelDraftOrderForm' disabled={formDisable ? "disabled" : ""}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h2 className="dark-text">Cancelling DO for {formData?.bc_customer_name || "unknown"}</h2>
                    <br/>
                    <label className="dark-text text-left"><b>Add Notes:</b><br/>
                        <textarea form='CancelDraftOrderForm' value={cancelNotes} onChange={e => setCancelNotes(e.target.value)}
                        style={{minWidth: "100%", width: "100%", maxWidth: "100%"}} rows='8' />
                    </label><br/>
                    <input form='CancelDraftOrderForm' className="btn bg-dark light-text" type='submit' value='Cancel Draft Order' />
                </div>
            </fieldset>
        </div>
    )
}

export default CancelDraftOrderForm;