/**
 * EditProductForm.js
 * 
 * form component used in the context of a modal view to edit product data
 */


import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { secureFetch } from '../util/flip_utils';
import FlipRTE from './FlipRTE';
import SearchSelect from './SearchSelect';

const editable_fields = [
    'id',
    'title',
    'options',
    'brand_id',
    'manual_link',
    'specs_link',
    'review_link_1',
    'review_name_1',
    'review_link_2',
    'review_name_2',
    'review_link_3',
    'review_name_3',
    'model',
    'active',
    'description',
    'testing_notes_link',
    'appraising_notes_link',
    'repair_notes_link',
    'msrp',
    'internal_review',
    'rating',
    'shipping_notes',
    'internal_notes',
    'appraising_notes',
    'testing_notes',
    'repair_notes',
    'upc_code',
]

function getSafeVersion(product) {
    let safe_product = {}
    editable_fields.forEach((key) => {
        safe_product[`${key}`] = product[`${key}`]==='' ? null : product[`${key}`];
    })
    //console.log(safe_product);
    return safe_product;
}


const EditProductForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [product, setProduct] = useState(formData);
    let [brand, setBrand] = useState({});
    let [brands, setBrands] = useState([]);
    let [classifications, setClassifications] = useState([]);
    let [productClassifications, setProductClassifications] = useState([]);
    let [formDisable, setFormDisable] = useState(false);
    let [msrpDisabled, setMsrpDisabled] = useState(false)
    let [msrpPlaceholder, setMsrpPlaceholder] = useState(null)

    function fetchProduct() {
        secureFetch(context.server + '/product-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({id: formData?.id})
        })
        .then(resp => resp.json())
        .then(results => {
            setProduct(results?.product);
            setBrand(results?.brand);
        })
    }

    const fetchBrands = () => {
        //fetch all the brands so we can use them in the dropdown
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tableName: 'brands',
                params: {
                    limit: -1,
                    sort: {
                        key: 'brand',
                        order: 1
                    }
                }
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setBrands(results)
        })
    }
    
    const fetchClassifications = () => {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({params: {
                sort: {
                    key: 'sequence_number',
                    order: 1
                }
            }, tableName: "classifications"})
        })
        .then(resp => resp.json())
        .then(results => setClassifications(results))

        secureFetch(context.server + '/product-classifications', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                product_id: product?.id
            })
        })
        .then(resp => resp.json())
        .then(results => setProductClassifications(results))
    }

    useEffect(() => {
        fetchProduct();
        fetchBrands();
        fetchClassifications();
    }, [])

    function setProductClassification(cla, ind) {
        let newClassifications = productClassifications.map(e=>e);
        newClassifications[ind] = cla;
        setProductClassifications(newClassifications);
        //alert(JSON.stringify(newClassifications))
    }

    function addProductClassification(event) {
        event.preventDefault();
        let newClassifications = productClassifications.map(e=>e);
        newClassifications.push({
            product_id: product?.id,
            //classification_id: event.target.value
        })
        //alert(JSON.stringify(newCategories))
        setProductClassifications(newClassifications);
    }

    function removeProductClassification(event, ind) {
        event.preventDefault();
        let newClassifications = productClassifications.map(e=>e);
        newClassifications.splice(ind, 1);
        setProductClassifications(newClassifications);
    }


    function disableMsrp(){
        if(productClassifications.some(p => p.classification_id == 21 || (p.classification_id >= 27 && p.classification_id <= 30))){
            setMsrpDisabled(true)
            if(product?.msrp > 0){
                setMsrpPlaceholder(product?.msrp)
                setProduct({...product, msrp: null})
            }
        }else{
            setMsrpDisabled(false)
            if(msrpPlaceholder && !product?.msrp){
                setProduct({...product, msrp: msrpPlaceholder})
            }
        }
    }

    useEffect(() => {
        disableMsrp();
    },[productClassifications])

    //called when the submit button is pressed. performs any necessary actions, then calls the props onSubmit and closeModal
    const submit = e => {
        e.preventDefault();
        if(productClassifications?.length <= 0) {
            alert('All products must be classified. Click Add Classification to select a category.')
            return;
        }
        setFormDisable(true);
        
        let brand = product?.brand;
        let safe_product = getSafeVersion(product);
        //console.log(body)
        secureFetch(context.server + '/edit-product', {
            method: 'POST',
            body: JSON.stringify({product: safe_product}),
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            credentials: 'include',
        })
        .then(res => res.json())
        .then(data => {
            //update classifications
            secureFetch(context.server + '/update-product-classifications', {
                method: 'POST',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({
                    product_id: product?.id,
                    classifications: productClassifications
                }),
            })
            .then(() => {
                closeModal();
                submitForm({...data, brand: brand});
            })
        })

        
    }

    //returns the form to be rendered
    return (
        <div>
            <form id='EditProductForm' onSubmit={submit} />
            <fieldset form='EditProductForm' disabled={formDisable ? 'disabled' : ''} >
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Edit Product</h1><br/>
                    <div>
                        <label className='container contained dark-text text-left'>Product Title:&nbsp;&nbsp;
                        <input form='EditProductForm' disabled className='contained' type='text' value={brands?.filter(b => b?.id==product?.brand_id)[0]?.brand + " " + product?.model + (product?.options?.length > 0 ? "; " + product?.options : "")}/></label>
                        <p><i className='dark-text'>(Title is calculated as Brand + Model + Options)</i></p>
                        <br/>
                        <label className='container contained dark-text text-left'>Product Options:&nbsp;&nbsp;
                        <input form='EditProductForm' className='contained' type='text' value={product?.options ? product?.options : '' }
                        onChange={ (e) => setProduct({...product, options: e.target.value})}/></label>
                    </div>
                    <br/>
                    <div className='container'>
                        {brands.length < 1 ? <p className='dark-text'>loading brands...</p> : <></>}
                        <label className='dark-text text-left contained'>Brand:&nbsp;&nbsp;
                        <select form='EditProductForm' required className='contained' onChange={ e => setProduct({...product, brand_id: e.target.value}) } value={product?.brand_id}>
                            <option value={null} key={0}>SELECT BRAND</option>
                            {brands.map( (row) => 
                                <option key={row?.id} value={row?.id} >{row?.brand}</option>
                            )}
                        </select></label>
                        <label className='dark-text text-left contained'>Model:&nbsp;&nbsp;
                        <input form='EditProductForm' required className='contained' type='text' value={product?.model ? product?.model : '' }
                        onChange={ (e) => setProduct({...product, model: e.target.value})}/></label>
                    </div>
                    <div className='container'>
                        <label className='dark-text text-left contained'>UPC Code:&nbsp;&nbsp;
                            <input type='text' value={product?.upc_code} onChange={e => setProduct({...product, upc_code: e.target.value})} />
                            &nbsp;<i>(12 char max)</i>
                        </label>
                        <div className='contained' />
                    </div>
                    <div>
                        {productClassifications.map( (row, ind) => 
                        <div className='container'>
                            <div className='contained container'>
                                {classifications.length < 1 ? <p className='dark-text'>loading classifications...</p> : <></>}
                                <label className='dark-text text-center contained'>Classification:
                                <select form='EditProductForm' required className='contained' 
                                onChange={ e => setProductClassification({...row, classification_id: e.target.value, name: classifications.filter(cat => cat?.id==e.target.value)[0]?.name}, ind) } 
                                value={row?.classification_id}>
                                    <option value={null} key={0}>SELECT CLASSIFICATION</option>
                                    {classifications.map( (cat) => 
                                        <option key={cat?.id} value={cat?.id} >{cat?.supertype} | {cat?.name}</option>
                                    )}
                                </select></label>
                            </div>
                            <button className='btn bg-red light-text' style={{maxWidth: '150px'}} onClick={e => removeProductClassification(e, ind)}>Remove</button>
                        </div>
                        )}
                        <button className='btn bg-dark light-text' onClick={addProductClassification}>Add Classification</button>
                    </div>
                    <div className='container'>
                        
                        <div className='contained'>
                            <label className='dark-text text-left'>Description:</label>
                            <textarea form='EditProductForm' rows='8' style={{minWidth: '90%', width: '90%', maxWidth: '90%'}} value={product?.description ? product?.description : '' }
                            onChange={ (e) => setProduct({...product, description: e.target.value})}/>
                            <br/>
                            <label className='dark-text text-left'>Internal Notes:</label>
                            <textarea form='EditProductForm' rows='5' style={{minWidth: '90%', width: '90%', maxWidth: '90%'}} value={product?.internal_notes ? product?.internal_notes : '' }
                            onChange={ (e) => setProduct({...product, internal_notes: e.target.value})}/>
                        </div>
                        
                    </div>
                    <div className='container'>
                        <label className='dark-text contained'>Manual:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.manual_link ? product?.manual_link : '' }
                        onChange={ (e) => setProduct({...product, manual_link: e.target.value})}/>
                        <label className='dark-text contained'>Specs:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.specs_link ? product?.specs_link : '' }
                        onChange={ (e) => setProduct({...product, specs_link: e.target.value})}/>
                        <label className='dark-text contained'>MSRP:</label>
                        <input form='EditProductForm' className='contained' type={msrpDisabled ? 'text' :'number'} 
                            disabled = {msrpDisabled ? 'true' :''} 
                            value={msrpDisabled ? 'n/a for cables' : product?.msrp ? product?.msrp : 0 }
                            onChange={ (e) => setProduct({...product, msrp: e.target.value})}
                        />
                    </div>
                    <div className='container'>
                        <label className='dark-text contained'>Review 1 Link:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_link_1 ? product?.review_link_1 : '' }
                        onChange={ (e) => setProduct({...product, review_link_1: e.target.value})}/>
                        <label className='dark-text contained'>Review 1 Name:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_name_1 ? product?.review_name_1 : '' }
                        onChange={ (e) => setProduct({...product, review_name_1: e.target.value})}/>
                        <div className='contained'/>
                    </div>
                    <div className='container'>
                        <label className='dark-text contained'>Review 2 Link:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_link_2 ? product?.review_link_2 : '' }
                        onChange={ (e) => setProduct({...product, review_link_2: e.target.value})}/>
                        <label className='dark-text contained'>Review 2 Name:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_name_2 ? product?.review_name_2 : '' }
                        onChange={ (e) => setProduct({...product, review_name_2: e.target.value})}/>
                        <div className='contained'/>
                    </div>
                    <div className='container'>
                        <label className='dark-text contained'>Review 3 Link:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_link_3 ? product?.review_link_3 : '' }
                        onChange={ (e) => setProduct({...product, review_link_3: e.target.value})}/>
                        <label className='dark-text contained'>Review 1 Name:</label>
                        <input form='EditProductForm' className='contained' type='text' value={product?.review_name_3 ? product?.review_name_3 : '' }
                        onChange={ (e) => setProduct({...product, review_name_3: e.target.value})}/>
                        <div className='contained'/>
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Internal Review:</label>
                        <FlipRTE text={product?.internal_review} updateParent={e => setProduct({...product, internal_review: e.target.value})} />
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Appraising Notes:</label>
                        <textarea style={{width: '100%'}} rows='6' value={product?.appraising_notes} onChange={e => setProduct({...product, appraising_notes: e.target.value})} />
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Testing Notes:</label>
                        <textarea style={{width: '100%'}} rows='6' value={product?.testing_notes} onChange={e => setProduct({...product, testing_notes: e.target.value})} />
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Repair Notes:</label>
                        <textarea style={{width: '100%'}} rows='6' value={product?.repair_notes} onChange={e => setProduct({...product, repair_notes: e.target.value})} />
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Shipping Notes:</label>
                        <textarea style={{width: '100%'}} rows='6' value={product?.shipping_notes} onChange={e => setProduct({...product, shipping_notes: e.target.value})} />
                    </div>
                    <div style={{padding: '15px 15px'}}>
                        <label className='dark-text text-left'>Rating {brand?.generic_rating ? (product?.rating == brand?.generic_rating) ? '(same as Brand)' : '(exception to Brand rule!)' : '(no brand rule set!)'}:
                        <select form='EditProductForm' onChange={ e => setProduct({...product, rating: e.target.value}) } value={product?.rating}>
                            <option key='0'>N/A</option> 
                            <option key='1' value='A'>A</option> 
                            <option key='2' value='B'>B</option> 
                            <option key='3' value='C'>C</option> 
                            <option key='4' value='D'>D</option> 
                            <option key='5' value='F'>F</option> 
                        </select></label>
                    </div>
                    <div className='container'>
                        <div className='contained'></div><div className='contained'></div><div className='contained'></div><div className='contained'></div>
                        <label className='dark-text contained'>Active?:</label>
                        <input form='EditProductForm' className='contained' type='checkbox' checked={product?.active===1}
                        onChange={ (e) => setProduct({...product, active: e.target.checked ? 1 : 0})}/>
                    </div>
                    <input form='EditProductForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </fieldset>
        </div>
    );
};

export default EditProductForm;