import { useEffect, useState } from "react"


const ShowHideBox = ({showText = "Show v", hideText = "Hide ^", isShown = false, content}) => {
    let [showContent, setShowContent] = useState(isShown);

    //update showContent if parent updates it
    useEffect(() => {
        setShowContent(isShown);
    }, [isShown])

    return(<div>
        <button className="btn dark-text" onClick={() => setShowContent(!showContent)}>{showContent ? hideText : showText}</button>
        {showContent ? <><br/>
        {content}
        </> : <></>}
    </div>)
}

export default ShowHideBox;