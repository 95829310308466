import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const EditSubmissionForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [submission, setSubmission] = useState(formData);
    
    let [disableForm, setDisableForm] = useState(false);

    function submit(event) {
        event.preventDefault();
        setDisableForm(true);
        secureFetch(context?.server + '/update-submission-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: submission?.id,
                keys: ['first_name', 'last_name', 'email_address', 'phone_number', 'state', 'country', 'zip', 'interested_in', 'reason_for_selling', 'preferred_rep', 'additional_comments'],
                values: [submission?.first_name, submission?.last_name, submission?.email_address, submission?.phone_number, submission?.state, submission?.country, submission?.zip, submission?.interested_in, submission?.reason_for_selling, submission?.preferred_rep, submission?.additional_comments],
                message: 'User edited submission info manually from form'
            })
        })
        .then(() => {
            submitForm();
            closeModal();

        })
    }

    return (
        <div>
            <form id='EditSubmissionForm' onSubmit={submit} />
            <fieldset form='EditSubmissionForm' disabled={disableForm ? 'disabled' : ''}>

                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className="dark-text">Edit Submission Info</h1>
                    <br/>
                    <div className="container">
                        <label className="dark-text text-left contained">First Name:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.first_name} onChange={e => setSubmission({...submission, first_name: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Last Name:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.last_name} onChange={e => setSubmission({...submission, last_name: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Email:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.email_address} onChange={e => setSubmission({...submission, email_address: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Phone:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.phone_number} onChange={e => setSubmission({...submission, phone_number: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">State:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.state} onChange={e => setSubmission({...submission, state: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Country:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.country} onChange={e => setSubmission({...submission, country: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Zip:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.zip} onChange={e => setSubmission({...submission, zip: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Interested In:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.interested_in} onChange={e => setSubmission({...submission, interested_in: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Reason for Selling:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.reason_for_selling} onChange={e => setSubmission({...submission, reason_for_selling: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Preferred Rep:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionForm' type='text' value={submission?.preferred_rep} onChange={e => setSubmission({...submission, preferred_rep: e.target.value})} />
                        </label><br/>
                    </div>
                    <br/>
                    <label className="dark-text text-left">Additional Comments:</label>
                    <textarea form='EditSubmissionForm' rows='8' cols='45' value={submission?.additional_comments} onChange={e => setSubmission({...submission, additional_comments: e.target.value})} />
                    <br/>
                    <br/>
                    <input form='EditSubmissionForm' className="btn bg-dark light-text" type='submit' />
                </div>
            </fieldset>
        </div>
    )
}

export default EditSubmissionForm;