import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import InfoBox from "./InfoBox";


const EditOptionSetForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [optionSet, setOptionSet] = useState({});
    let [formDisable, setFormDisable] = useState(false);

    function fetchOptionSet() {
        secureFetch(context?.server + '/option-set-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: formData?.id
            })
        })
        .then(resp => resp.json())
        .then(result => setOptionSet(result))
    }

    useEffect(() => {
        fetchOptionSet();
    }, [])

    function setNewOptions(opts) {
        setOptionSet({...optionSet, newOptions: opts});
    }

    function submit(e) {
        e.preventDefault();
        setFormDisable(true);
        secureFetch(context?.server + '/update-option-set', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                optionSet: optionSet
            })
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    return (
        <div className="bg-light" style={{padding: '15px 15px'}}>
            <form id='EditOptionSetForm' onSubmit={submit} />
            <fieldset form='EditOptionSetForm' disabled={formDisable ? 'disabled' : ''}>
                <h1 className="dark-text">Edit Option Set</h1>
                <br/><br/>
                <label className='dark-text text-left'>Name:</label>
                <input type='text' form='EditOptionSetForm' required value={optionSet?.name} onChange={e => setOptionSet({...optionSet, name: e.target.value})}
                style={{width: '300px'}} />
                <br/>
                <label className='dark-text text-left'>Type:</label>
                <input type='text' disabled value={optionSet?.option_type} />

                <br/><br/>
                <InfoBox title='Options:'>
                    <i>options cannot be removed after creation, only new ones can be added</i>
                    {optionSet?.options?.map(row =>
                        <li className='dark-text'>
                            <input type='text' form='EditOptionSetForm' disabled value={row?.name} style={{width: '300px'}} />&nbsp;&nbsp;&nbsp;
                        </li>
                    )}
                    {optionSet?.newOptions?.map((row, ind) => 
                        <li className='dark-text'>
                            <input type='text' form='EditOptionSetForm' required autoFocus value={row?.name} onChange={e => {
                                let opts = optionSet?.newOptions?.map(o=>o);
                                opts[ind].name = e.target.value;
                                setNewOptions(opts);
                            }} style={{width: '300px'}} />&nbsp;&nbsp;&nbsp;
                            <button className='btn bg-red light-text' onClick={e => {
                                let opts = optionSet?.newOptions?.map(o=>o);
                                opts.splice(ind,1);
                                setNewOptions(opts);
                            }} style={{padding: '5px 5px'}} >X</button>
                        </li>
                    )}
                </InfoBox>
                <button className='btn bg-dark light-text' onClick={e => {
                    e.preventDefault();
                    let opts = optionSet?.newOptions?.length>0 ? optionSet?.newOptions?.map(o=>o) : [];
                    opts.push({name: ''});
                    setNewOptions(opts);
                }}>Add Option</button>

                <br/><br/>
                <input type='submit' form='EditOptionSetForm' className='btn bg-dark light-text' />
            </fieldset>

        </div>
    )
}

export default EditOptionSetForm;