import '../App.css';

const InfoBox = ({title, children}) => {
    return (
        <div className='bg-light rounded'>
            <h2 style={{padding: '10px'}} className='dark-text'>{title}</h2>
            <div className='bg-lightest rounded' style={{padding: '10px'}}>
                {children}
            </div>
        </div>
    );
}

export default InfoBox;