import { useContext, useState, useEffect } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import SelectProductForm from "./SelectProductForm";

const DraftItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [fetched, setFetched] = useState(false);
    let [item, setItem] = useState({active: 1});
    let [cosmetics, setCosmetics] = useState([]);
    let [conditions, setConditions] = useState([]);
    let [disableForm, setDisableForm] = useState(false);

    function fetchData() {
        setFetched(false);
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'cosmetic_conditions'})
        })
        .then (resp => resp.json())
        .then(results => {
            setCosmetics(results);
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({tableName: 'item_conditions'})
            })
            .then(resp => resp.json())
            .then(results => {
                setConditions(results);
                setFetched(true);
            })
        })
    }

    /* eslint-disable */
    useEffect(() => {
        fetchData();
    },[])
    useEffect(() => {
        setItem(formData);
    }, [formData])
    /* eslint-enable */


    //submit is called when the submit button is pushed. it has its own functionality, and also calls the props onSubmit and closeModal
    function submit( e ) {
        setDisableForm(true);
        e.preventDefault();
        submitForm(item);
        closeModal();
    }

    //handles when the cosmetic condition dropdown is changed
    function cosmeticChangeHandler (event) {
        setItem({...item, cosmetic_condition_id: event.target.value});
    }

    //handles when the item condition dropdown is changed
    function conditionChangeHandler (event) {
        setItem({...item, item_condition_id: event.target.value});
    }

    function copyProductInfo(product) {
        let newItem = {active: 1};
        Object.keys(item).forEach(key => newItem[key] = item[key])
        newItem.title = product?.title
        newItem.product_id = product?.id
        newItem.description = product?.description
        setItem(newItem);
    }

    if(!item?.product_id) {
        return <SelectProductForm formData={{}} closeModal={()=>{return}} submitForm={product => copyProductInfo(product)} />
    }

    if(!fetched) {
        return <h1 className='dark-text'>Loading Data...</h1>;
    }

    //returns the form component to be rendered
    return (
        <div>
            <form id='DraftItemForm' onSubmit={submit} />
            <fieldset disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h3>Draft New Item</h3>
                    <div className='container'>
                        <label className='contained dark-text'>Title:</label>
                        <input form='DraftItemForm' required style={{minWidth: '40%'}} className='contained' type='text' value={item?.title ? item?.title : ''} onChange={ (e) => setItem({...item, title: e.target.value})} />
                        <label className='contained dark-text'>SN:</label>
                        <input form='DraftItemForm' className='contained' type='text' value={item?.serial_number ? item?.serial_number : ''} onChange={ (e) => setItem({...item, serial_number: e.target.value})}/>
                        
                    </div>
                    <div className='container'>
                        <label className='contained dark-text'>Buy Price:</label>
                        <input form='DraftItemForm' required className='contained' type='number' value={item?.purchase_amount ? item?.purchase_amount : ''} onChange={ (e) => setItem({...item, purchase_amount: e.target.value})}/>
                        <label className='contained dark-text'>Consign?:</label>
                        <input form='DraftItemForm' className='contained' type='checkbox' checked={item?.is_consignment===1} onChange={ (e) => setItem({...item, is_consignment: e.target.checked ? 1 : 0})}/>
                        <label className='contained dark-text'>List Price:</label>
                        <input form='DraftItemForm' className='contained' type='number' value={item?.list_price ? item?.list_price : ''} onChange={ (e) => setItem({...item, list_price: e.target.value})}/>
                    </div>
                    <div className='container'>
                        <label className='contained'>Cosmetic:</label>
                        <select form='DraftItemForm' required onChange={ cosmeticChangeHandler } value={item?.cosmetic_condition_id}>
                            <option value='null' key={0}>SELECT COSMETIC CONDITION</option>
                            {cosmetics.map( (cos) => 
                                <option key={cos?.id} value={cos?.id} >{cos?.cosmetic_condition}</option>
                            )}
                        </select>
                        <label className='contained'>Condition:</label>
                        <select form='DraftItemForm' required onChange={ conditionChangeHandler } value={item?.item_condition_id}>
                            <option value='null' key={0}>SELECT ITEM CONDITION</option>
                            {conditions.map( (con) => 
                                <option key={con?.id} value={con?.id} >{con?.item_condition}</option>
                            )}
                        </select>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Cosmetic Description:</label>
                            <textarea form='DraftItemForm' rows='8' cols='30' value={item?.cosmetic_description ? item?.cosmetic_description : ''} onChange={ (e) => setItem({...item, cosmetic_description: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>Description:</label>
                            <textarea form='DraftItemForm' rows='8' cols='30' value={item?.description ? item?.description : ''} onChange={ (e) => setItem({...item, description: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>What is Included:</label>
                            <textarea form='DraftItemForm' required rows='8' cols='30' value={item?.what_is_included ? item?.what_is_included : ''} onChange={ (e) => setItem({...item, what_is_included: e.target.value})}/>
                        </div>
                    </div>
                    <div className='container'>
                        <label className='contained dark-text'>Parts:</label>
                        <input form='DraftItemForm' className='contained' type='number' value={item?.number_of_parts ? item?.number_of_parts : ''} onChange={ (e) => setItem({...item, number_of_parts: e.target.value})}/>
                        <label className='contained dark-text'>Age:</label>
                        <input form='DraftItemForm' className='contained' type='number' value={item?.age_in_years ? item?.age_in_years : ''} onChange={ (e) => setItem({...item, age_in_years: e.target.value})}/>
                        <label className='contained dark-text'>Owners:</label>
                        <input form='DraftItemForm' className='contained' type='number' value={item?.num_of_owners ? item?.num_of_owners : ''} onChange={ (e) => setItem({...item, num_of_owners: e.target.value})}/>
                        
                    </div>
                    <div className='container'>
                        
                        <label className='contained dark-text'>Tobacco:</label>
                        <input form='DraftItemForm' className='contained' type='checkbox' checked={item?.tobacco_exposure===1} onChange={ (e) => setItem({...item, tobacco_exposure: e.target.checked ? 1 : 0})}/>
                        <label className='contained dark-text'>Active?:</label>
                        <input form='DraftItemForm' className='contained' type='checkbox' checked={item?.active===1} onChange={ (e) => setItem({...item, active: e.target.checked ? 1 : 0})}/>
                    </div>
                    <input form='DraftItemForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </fieldset>
        </div>
    );
}

export default DraftItemForm;