import React, { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const CreateBrandForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [brand, setBrand] = useState({generic_rating: 'C'});
    let [formDisable, setFormDisable] = useState(false);

    const submit = (e) => { //called by the submit button. prodcesses info, sends to the backend, then calls the given closeModal and onSubmit
        setFormDisable(true);
        e.preventDefault();
        
        secureFetch(context.server + '/create-brand', {
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                keys: Object.keys(brand),
                values: Object.values(brand)
            }),
        })
        .then(res => res.json())
        .then(data => {
                submitForm(data);
                closeModal();
        })
    }

    return(
        <div>
            <form id='CreateBrandForm' onSubmit={submit} />
            <fieldset form='CreateBrandForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Create New Brand</h1>
                    <div className='container'>
                        <label className='dark-text text-left contained'>Full Brand Name:&nbsp;&nbsp;
                            <input form='CreateBrandForm' required style={{width: '200px'}} className='contained' type='text' value={brand?.full_name || '' }
                            onChange={ (e) => setBrand({...brand, full_name: e.target.value})}/>
                        </label>
                        <label className='dark-text text-left contained'>Shortened Name:&nbsp;&nbsp;
                            <input form='CreateBrandForm' style={{width: '200px'}} className='contained' type='text' value={brand?.short_name || ''}
                            onChange={ e => setBrand({...brand, short_name: e.target.value})} />
                            <br/><i>(acronyms, abbreviations, etc)</i>
                        </label>
                    </div>
                    <div className='container'>
                        <label className='dark-text text-left contained'>Brand Homepage URL: &nbsp;&nbsp;
                            <input form='CreateBrandForm' style={{width: '400px'}} className='contained' type='text' value={brand?.url || '' }
                            onChange={ (e) => setBrand({...brand, url: e.target.value})}/>
                        </label>
                    </div>
                    <div className="container">
                        <label className='dark-text text-left contained'>Brand Rating: &nbsp;&nbsp;
                            <select value={brand?.generic_rating} onChange={e => setBrand({...brand, generic_rating: e.target.value})}>
                                <option value={'A'}>A</option>
                                <option value={'B'}>B</option>
                                <option value={'C'}>C</option>
                                <option value={'D'}>D</option>
                                <option value={'F'}>F</option>
                            </select>
                        </label>
                    </div>
                    <input form='CreateBrandForm' type='submit' className='btn bg-dark light-text' />
                </div>
            </fieldset>
        </div>
    )
}

export default CreateBrandForm;