import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import TableList from "../components/TableList";
import PaginatedTableList from "../components/PaginatedTableList";
import { formatDate, secureFetch } from "../util/flip_utils";
import { useHistory } from "react-router-dom";


const RAList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [RAs, setRAs] = useState([]);
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '');

    function fetchRAs(pageNumber, pageSize) {
        secureFetch(context?.server + '/search-ras', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(resp => resp.json())
        .then(results => setRAs(results));
    }

    useEffect(() => {
        fetchRAs(0,20);
    }, [context, searchValue]);

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/ra-list', {searchValue: val})
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>RA List</h1>
            <br/>
            <h3 className="light-text">Search:</h3>
            <input autoFocus className='search-bar' type='text' value={searchValue} onChange={(e) => updateSearchValue(e.target.value)} />
            <button className="btn dark-text" onClick={() => fetchRAs(0,20)}>Refresh List</button>
            <br/><br/>
            <PaginatedTableList columnNames={['RA Number','Customer','Status','Title','Date','View']}
            columnWidths={['10%','20%','10%','25%','15%','20%']}
            entries={RAs.map(row => {
                return [
                    <Link className="dark-text" to={{pathname: `/ra-view/${row?.id}`, state: {ra_id: row?.id}}} >{row?.id}</Link>,
                    <Link className='btn dark-text' to={{pathname: `/customer-view/${row?.customer_id}`, state: {customer_id: row?.customer_id}}}>{row?.customer}</Link>,
                    row?.status,
                    row?.title?.length > 50 ? row?.title.slice(0,50) + '...' : row?.title,
                    formatDate(row?.created),
                    <Link className='btn dark-text' to={{pathname: `/ra-view/${row?.id}`, state: {ra_id: row?.id}}}>View</Link>
                ]
            })}
            getData={fetchRAs}
            />
        </div>
    )
}

export default RAList;