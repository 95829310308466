import { useEffect, useState, useContext } from "react";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";
import AppContext from "../AppContext";

const SelectCustomerForm = ({submitForm, closeModal, formData}) => {
    let context = useContext(AppContext);
    let [customers, setCustomers] = useState([]);
    let [searchValue, setSearchValue] = useState(formData?.defaultSearch || '');
    let [fetched, setFetched] = useState(false);

    const fetchCustomers = () => {
        secureFetch(context.server + '/search-customers', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: searchValue})
        })
        .then(resp => resp.json())
        .then(results => {
            setCustomers(results);
            setFetched(true);
        })
    }


    /* eslint-disable */
    useEffect(() => {
        fetchCustomers();
    },[context, searchValue])
    /* eslint-enable */


    const submit = (customer) => {
        submitForm(customer);
        closeModal();
    }

    if(!fetched) {
        return (
            <h1 className='dark-text'>Loading Customers...</h1>
        );
    }

    return (
        <div>
            <h1 className='dark-text'>Select Customer</h1>
            <input autoFocus className='search-bar' type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <br/><br/>
            <TableList columnNames={['ID','Name','Notes','Select']}
            columnWidths={['20%','20%','40%','20%']}
            entries={
                customers.map( row => {
                    return [
                        row?.id,
                        row?.name,
                        row?.notes ? row?.notes.slice(0,40) + '...' : 'None',
                        <button className='btn dark-text' onClick={() => submit(row)}>Select</button>
                    ]
                })
            }
            />
        </div>
    );
}

export default SelectCustomerForm;