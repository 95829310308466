import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import { RatingColorCoder } from "../components/ColorCoders";
import CreateBrandForm from "../components/CreateBrandForm";
import EditProductForm from "../components/EditProductForm";
import ModalContainer from "../components/ModalContainer";
import TableList from "../components/TableList";
import { secureFetch } from "../util/flip_utils";


const BrandRatings = () => {
    let context = useContext(AppContext);

    let [brand, setBrand] = useState({});
    let [currentRating, setCurrentRating] = useState('N/A');

    let [brands, setBrands] = useState([]);
    let [exceptions, setExceptions] = useState([]);

    function fetchBrands() {
        //fetch all the brands so we can use them in the dropdown
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'brands',
                params: {
                    limit: -1,
                    sort: {
                        key: 'brand',
                        order: 1
                    }
                }
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setBrands(results)
        })
    }

    function fetchExceptions() {
        if(!brand?.id) return;
        secureFetch(context.server + '/brand-rating-exceptions', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({id: brand?.id})
        })
        .then(resp => resp.json())
        .then(results => {
            setExceptions(results);
        })
    }

    //fetch brands on load
    useEffect(() => {
        fetchBrands();
    }, [])


    //update the current rating and exceptions only when a different brand is selected
    useEffect(() => {
        fetchExceptions();
        setCurrentRating(brand?.generic_rating);
    }, [brand?.id]) //need to do this otherwise it will trigger when the rating is changed as well

    
    function updateRating() {
        if(!brand?.id) return;
        secureFetch(context.server + '/update-brand-rating', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: brand?.id,
                currentRating: currentRating,
                newRating: brand?.generic_rating,
            })
        })
        .then(() => {
            window.location.reload();
        })
    }


    return (
        <div style={{padding: '15px 15px'}}>
            <h1 className="light-text">Brand Rating Manager</h1>
            <br/>
            <ModalContainer triggerText='Add Brand' formData={{}} FormComponent={CreateBrandForm} submitForm={() => window.location.reload()} />
            <br/><br/>
            <div className="bg-light rounded container">
                {brands.length < 1 ? <p className='dark-text'>loading brands...</p> : <></>}
                <label className='dark-text text-left contained'>Select Brand:<br/>
                <select autoFocus onChange={ e => setBrand(brands.filter(val => val?.id==e.target.value)[0]) } value={brand?.id}>
                    <option value={null} key={0}>SELECT BRAND</option>
                    {brands.map( (row) => 
                        <option key={row?.id} value={row?.id} >{row?.brand}</option>
                    )}
                </select></label>

                <label className="dark-text text-left contained">Current Brand Rating:<br/>
                <RatingColorCoder content={currentRating || 'N/A'} rating={currentRating} /></label>

                <label className='dark-text text-left contained'>New Rating:<br/>
                <select onChange={ e => setBrand({...brand, generic_rating: e.target.value}) } value={brand?.generic_rating}>
                    <option key='0' value='null'>N/A</option> 
                    <option key='1' value='A'>A</option> 
                    <option key='2' value='B'>B</option> 
                    <option key='3' value='C'>C</option> 
                    <option key='4' value='D'>D</option> 
                    <option key='5' value='F'>F</option> 
                </select></label>
                <br/><br/><br/>
            </div>
            <br/>
            <button disabled={currentRating==brand?.generic_rating} 
            className={currentRating==brand?.generic_rating ? "btn bg-red light-text" : "btn bg-green light-text"} 
            onClick={() => updateRating()}>Update Brand Rating</button>
            <br/><i className="light-text">(this will only update products which are NOT currently exceptions to the brand rating, or are unrated)</i>
            <br/><br/>
            <h3 className="light-text">Exceptions to Current Rating:</h3>
            <TableList columnNames={['ID','Title','Rating',' ',' ']}
            columnWidths={['15%','45%','20%','10%','10%']}
            entries={exceptions?.map(row => {
                return [
                    row?.id,
                    row?.title,
                    <RatingColorCoder content={row?.rating || 'N/A'} rating={row?.rating} />,
                    <ModalContainer triggerText='Edit' formData={row} FormComponent={EditProductForm} submitForm={() => fetchExceptions()} />,
                    <Link className="btn dark-text" to={{pathname: `/product-view/${row?.id}`, state: {product_id: row?.id}}}>View</Link>
                ]
            })} />
        </div>
    )
}

export default BrandRatings;