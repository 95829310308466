

const GridView = ({columns, children}) => {

    function constructGrid() {
        let len = children?.length;
        let grid = [];
        let cols = parseInt(columns);
        for(let r = 0; r <= len/cols; r++) {
            let row = [];
            for(let col = 0; col < cols; col++) {
                let ind = r*cols + col
                row.push(
                    <div className="contained">{ind >= children?.length ? '' : children[ind]}</div>
                )
            }
            grid.push(
                <div className="container" style={{padding: '10px 0px'}}>
                    {row}
                </div>
            )
        }
        return grid;

    }

    return (
        <div>
            {constructGrid()}
        </div>
    )
}

export default GridView;