import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const VarianceReport = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportType, setReportType] = useState('summary');

    function fetchReport() { 
        secureFetch(context.server + '/reports/variance-report', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate:startDate, endDate:endDate})
        })
        .then(res => res.json())
        .then(data => {setReport(data)})
        
       
        console.log(report);
        console.log('fart');

    
    }


    useEffect(() => {
        fetchReport();
    }, [context, reportType, startDate, endDate])





        
    return(
        
        <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Variance Report</h1>    
                </div >
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <p>**This report may take a few moments to load.</p>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                    <CSVLink data={report} filename='submissions_progress_report.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    <br/>
                </div>
                <br></br>
                <TableList columnNames={['SKU', 'Sublocation', 'Variance']}
                        columnWidths={['20%','20%','20%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.sku,
                                    row?.sublocation,
                                    row?.variance
                                ]
                            })
                        } />


               
            

           
      
            
        </div>
    );
}

export default VarianceReport;