//creates the component given the header component and each of the listentry components
const ListBuilder = ({header, entries}) => {
    return (
        <div className='list-view'>
            <div>{header}</div>
            <div>
                {entries.map( (ent, ind) => {
                    return <>
                    <div key={ind}>{ent}</div>
                    <div style={{padding: '0% 2%'}}><div className="bg-light" style={{minHeight: '1px', height: '1px', maxHeight: '1px', width: '100%'}}></div></div>
                    </>;
                })}
            </div>
        </div>
    );
}

//creates the styled header given the column names and widths
const Header = ({columnNames, columnWidths}) => {
    return (
        <div className='container header'>
            {columnNames.map( (col, index) => {
                return <div key={index} className='contained' style={{maxWidth: columnWidths[index]}}>
                    <p  key={index} className='dark-text'><b>{col ? col : '-'}</b></p>
                </div>;
            })}
        </div>
    );
}

//takes a LIST of entries, so you'll need to map your list of objects to [ [col1, col2, col3], [col1, col2, col3], [col1, col2, col3]... ]
const ListEntry = ({entryVals, columnWidths}) => {
    return (
        <div className='container'>
            {entryVals.map( (val, index) => {
                return <div key={index} className='dark-text contained container' style={{maxWidth: columnWidths[index]}}>{val!==null ? val : '-'}</div>
            })}
        </div>
    );
}

const TableList = ({columnNames, columnWidths, entries}) => {
    let numCols = columnNames.length;
    if(columnWidths.length !== numCols) return <>widths, names, and entries must be same length</>;
    let head = <Header columnNames={columnNames} columnWidths={columnWidths} />;
    let rows = entries.map( (entryVals, index) => {
        return <ListEntry entryVals={entryVals} columnWidths={columnWidths}/>;
    })
    return <ListBuilder header={head} entries={rows} />;
}

export default TableList;