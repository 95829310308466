import React from 'react'

const PageNotFound = () => {
  return (
    <div id="wrapper">
      <div id="info">
        <center><h2>PAGE NOT FOUND</h2></center>
      </div>
      <img src="https://i.imgur.com/qIufhof.png" alt="Not Found"/>
    </div >
  )
}

export default PageNotFound
