import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../AppContext";
import CancelDraftOrderForm from "../components/CancelDraftOrderForm";
import ConfirmButton from "../components/ConfirmButton";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import TableList from "../components/TableList";
import { formatDate, secureFetch } from "../util/flip_utils";


const DraftOrderView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { do_id } = useParams();
    let [overview, setOverview] = useState({});
    let [items, setItems] = useState({});

    function fetchData() {
        secureFetch(context.server + '/draft-order-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: do_id || props?.location?.state?.do_id
            })
        })
        .then(resp => resp.json())
        .then(result => {
            setOverview(result?.overview);
            setItems(result?.items);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])


    function cancelDraftOrder(notes) {
        secureFetch(context.server + '/cancel-draft-order', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...overview,
                cancelNotes: notes
            })
        })
        .then(() => {
            history.push({pathname: `/draft-order-list`, state:{}})
            window.location.reload();
        })
    }

    function handleConvertDO() {
        secureFetch(context.server + '/convert-draft-order-manual-payment', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                custom_cart_id: overview?.custom_cart_id
            })
        })
        .then(() => {
            alert("Draft order has been converted. Please click on the BC Order link here to finalize and add payment info.")
            window.location.reload();
        })
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>DO Overview</h1>
            {overview ? <>
                <InfoBox title='Order Info:'>
                    <div className="container">
                        <div className="contained">
                            <KeyValuePair label={"Customer:"} value={`${overview?.bc_customer_name} (${overview?.bc_customer_email})`} textClass="dark-text" />
                            <KeyValuePair label={"Order Number:"} value={
                                <a className="btn dark-text" target='_blank' rel='noreferrer' href={`https://store-6naz0isypk.mybigcommerce.com/manage/orders?keywords=${overview?.bc_order_id}`}>{overview?.bc_order_id}</a>} textClass="dark-text" />
                            {overview?.manual_payment_order_id ? <KeyValuePair label={"Manual Order Number:"} value={
                                <a className="btn dark-text" target='_blank' rel='noreferrer' href={`https://store-6naz0isypk.mybigcommerce.com/manage/orders?keywords=${overview?.manual_payment_order_id}`}>{overview?.manual_payment_order_id}</a>} textClass="dark-text" />
                            : <></>}
                            <KeyValuePair label={"Subtotal (ex tax):"} value={overview?.subtotal_ex_tax} textClass="dark-text" />
                            <KeyValuePair label={"Date Created:"} value={formatDate(overview?.created)} textClass="dark-text" />
                            
                        </div>
                        <div className="contained">
                            {items?.length > 0 ? <div>
                                <h2 className="dark-text">Items:</h2>
                                <div className="bg-dark rounded" style={{padding:'1px 1px'}}>
                                    <TableList columnNames={['SKU','Title','Qty']}
                                    columnWidths={['30%','60%','10%']}
                                    entries={items?.map(row => {
                                        return [
                                            row?.sku,
                                            row?.name,
                                            row?.quantity
                                        ]
                                    })} />
                                </div>
                            </div> : <></>}
                        </div>
                    </div>
                </InfoBox>

                <br/><br/>
                <ModalContainer triggerText='Cancel Draft Order' buttonClass='btn bg-red light-text' formData={overview} FormComponent={CancelDraftOrderForm} submitForm={cancelDraftOrder} />
                <ConfirmButton buttonText="Convert to Manual Payment" buttonClass="btn bg-blue dark-text" clickHandler={() => handleConvertDO()} cancelHandler={() => {}} confirmation="This is NOT an easily reversible process, are you sure?" />
            </> : <></>}
        </div>
    )
}

export default DraftOrderView;