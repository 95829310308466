import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";


const SelectUserForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [users, setUsers] = useState([]);
    let [searchValue, setSearchValue] = useState(formData?.searchValue || '');
    let [fetched, setFetched] = useState(false);

    const fetchUsers = () => {
        secureFetch(context.server + '/search-users', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: searchValue, params: formData?.params})
        })
        .then(resp => resp.json())
        .then(results => {
            setUsers(results);
            setFetched(true);
        })
    }

    useEffect(() => {
        setSearchValue(formData?.searchValue);
    }, [formData?.searchValue])

    /* eslint-disable */
    useEffect(() => {
        fetchUsers();
    },[context, searchValue])
    /* eslint-enable */


    const submit = (user) => {
        submitForm(user);
        closeModal();
    }

    if(!fetched) {
        return (
            <h1 className='dark-text'>Loading Users...</h1>
        );
    }

    return (
        <div>
            <div className="container">
                <h1 className='dark-text contained'>Select User</h1>
                <button className="btn dark-text contained" onClick={() => submit(null)}>Select None</button>
            </div>
            

            <input autoFocus className='search-bar' type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <br/><br/>
            <TableList columnNames={['ID','Name','Select']}
            columnWidths={['20%','60%','20%']}
            entries={
                users.map( row => {
                    return [
                        row?.id,
                        <div className="dark-text" style={{cursor: 'pointer'}} onClick={() => submit(row)}><u>{row?.first_name} {row?.last_name}</u></div>,
                        <button className='btn dark-text' onClick={() => submit(row)}>Select</button>
                    ]
                })
            }
            />
        </div>
    );
}

export default SelectUserForm;