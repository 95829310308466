import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const EditClassificationForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [optionSets, setOptionSets] = useState([]);
    let [selectedOptionSets, setSelectedOptionSets] = useState([]);
    let [classification, setClassification] = useState({});

    let [formDisable, setFormDisable] = useState(false);

    function fetchOptionSets() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'option_sets'})
        })
        .then(resp => resp.json())
        .then(results => setOptionSets(results));
    }

    function fetchClassification() {
        secureFetch(context.server + '/classification-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: formData?.id
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setClassification(results?.classification);
            setSelectedOptionSets(results?.optionSets);
        });
    }

    useEffect(() => {
        fetchClassification();
        fetchOptionSets();
    }, [])

    function submit(event) {
        event.preventDefault();
        setFormDisable();
        secureFetch(context?.server + '/update-classification', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                classification: classification,
                optionSets: selectedOptionSets
            })
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    return (
        <div className='bg-light' style={{padding: '15px 15px'}}>
            <form id='EditClassificationForm' onSubmit={submit} />
            <fieldset form='EditClassificationForm' disabled={formDisable ? 'disabled' : ''} >
                <h1 className='dark-text'>Edit Classification</h1>
                <div className="container">
                    <div className="contained">
                        <label className='dark-text text-left'>Name:</label>
                        <input type='text' form='EditClassificationForm' required value={classification?.name} onChange={e => setClassification({...classification, name: e.target.value})}
                        style={{width: '300px'}} />

                        <label className='dark-text text-left'>Option Sets:</label>
                        {optionSets.map(opt => 
                            <div>
                                <label className='dark-text text-left'><input type='checkbox' form='EditClassificationForm' checked={selectedOptionSets.map(o=>o?.id).indexOf(opt?.id) !== -1} onChange={e => {
                                    let newopts = selectedOptionSets.map(o=>o);
                                    if(e.target.checked) {
                                        newopts.push(opt)
                                    } else {
                                        newopts.splice(newopts.map(o=>o?.id).indexOf(opt?.id), 1);
                                    }
                                    setSelectedOptionSets(newopts);
                                }} />&nbsp;&nbsp;{opt?.name}</label>
                            </div>
                        )}
                    </div>
                    <div className='contained'>
                                <label className='dark-text text-left'>Classification Notes:</label>
                                <textarea form='EditItemForm' rows='6' cols='50' value={classification?.notes} onChange={e => setClassification({...classification, notes: e.target.value})}/>
                    </div>
                </div>
                <br/><br/>
                
                <input type='submit' form='EditClassificationForm' className='btn bg-dark light-text' />
            </fieldset>
        </div>
    )
}

export default EditClassificationForm;