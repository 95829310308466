import { useContext, useState } from "react"
import AppContext from "../AppContext"
import { secureFetch } from "../util/flip_utils";


const Sandbox = () => {
    let context = useContext(AppContext);

    let [tpaNumber, setTpaNumber] = useState('');
    let [sku, setSku] = useState('');
    let [itemId, setItemId] = useState('');
    let [supplierId, setSupplierId] = useState('');
    let [listingId, setListingId] = useState('');

    function test() {
        alert('this function is disabled')
        return;
        secureFetch(context?.server + '/get-audiogon-test', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}
        })
    }

    function sendTpaPayload(event) {
        event.preventDefault();
        secureFetch(context.server + '/resend-tpa-payload', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tpa_number: tpaNumber
            })
        }).then(() => alert('success'))
    }

    function sendReskuPayload(event) {
        event.preventDefault();
        secureFetch(context.server + '/sandbox/resku-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sku: sku
            })
        }).then(() => alert('success'))
    }

    function sendListingPayload(event) {
        event.preventDefault();
        secureFetch(context.server + '/sandbox/regenerate-listing-payload', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: listingId
            })
        }).then(() => alert('success'))
    }

    function runFinaleProductLoader(event) {
        //api request body bcid, supplierid
        secureFetch(context.server + '/finale/cpo-product-loader', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: itemId
            })
        })
        .then(resp => resp.json())
        .then(() => alert('done'))
    }

    function testAuthToken() {
        secureFetch(context.server + '/log', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({token: context?.accessToken})
        })
    }

    function testNetsuite() {
        secureFetch(context.server + '/sandbox/netsuite-testing', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
        })
    }


    return (
        <>
        <br/><br/>
        <button onClick={() => test()} className='btn dark-text'>TEST</button>

        <br/><br/>
        <input type='text' value={tpaNumber} placeholder="rma number" onChange={e => setTpaNumber(e.target.value)} />
        <button onClick={sendTpaPayload} className='btn dark-text'>Resend TPA Payload</button>
        <br/><br/>
        <input type='text' value={sku} placeholder="sku" onChange={e => setSku(e.target.value)} />
        <button onClick={sendReskuPayload} className='btn dark-text'>Re-SKU Item</button>
        <br/><br/>
        <input type='text' value={listingId} placeholder="item id" onChange={e => setListingId(e.target.value)} />
        <button onClick={sendListingPayload} className='btn dark-text'>Re-send Listing Payload</button>
        <br/><br/>
        <br></br>
        <div className="bg-light">
        <br></br>
        <i>Add to finale:</i>
        <label className="light-dark text-left">Item ID: &nbsp;<input type='text' value={itemId} onChange={e => setItemId(e.target.value)} /></label>
        <button className="btn dark-text" onClick={runFinaleProductLoader}>Create Finale Item</button>
        <br></br>
        </div>
        <br/><br/>
        <select id="arr"></select>
        <br/><br/>
        <button className="btn dark-text" onClick={() => testAuthToken()}>Test Auth Token</button>
        <br/>
        <button className="btn dark-text" onClick={() => testNetsuite()}>Test Netsuite</button>
        </>
    )
}

export default Sandbox;