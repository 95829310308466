import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";

const GenericReports = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportTypes, setReportTypes] = useState([]);
    let [report, setReport] = useState({});
    let [reportData, setReportData] = useState([]);

    function fetchReportTypes() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'generic_reports',
                params: {
                    sort: {
                        key: 'category',
                        order: 1
                    }
                }
            })
        })
        .then(resp => resp.json())
        .then(data => {
            setReportTypes(
                data?.map(row => {
                    return {
                        ...row,
                        preview_cols: row?.preview_cols?.split(','),
                        preview_widths: row?.preview_widths?.split(',')?.map(wth => wth + '%')
                    }
                })
            )
        })
    }

    useEffect(() => {
        fetchReportTypes();
    },[])



    function fetchReport() {
        if(!report?.id) return;
        secureFetch(context.server + '/reports/generic-report', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reportName: report?.view_name,
                useDate: report?.use_date==1,
                startDate: startDate,
                endDate: endDate
            })
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReportData(data)
        })
    }

    useEffect(() => {
        fetchReport();
    }, [report, startDate, endDate])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>Pull a Report</h1>
                <br/>
                <label className="light-text text-left">Select Report Type:</label>
                <select onChange={e => {
                    setReport(reportTypes?.filter(entry => entry?.id==e.target.value)[0])
                }} value={report?.id}>
                    <option key={-1} value={-1}>No Report Selected</option>
                    {reportTypes?.map(row => 
                        <option key={row?.id} value={row?.id}>{row?.report_name}</option>
                    )}
                </select>
                <br/><br/>
                <CSVLink data={reportData} filename={report?.outfile_name + '.csv'} className='btn dark-text'>Export Report to CSV</CSVLink>
                <br/><br/><br/>
                {report?.use_date ?
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                </div>
                : <p style={{padding: '5px 5px'}} className='bg-lightest dark-text rounded'>Date Range not applicable for this report</p> }
                <br/>
                {reportData?.length > 0 ? <>
                    <h2 className="light-text">Report at a glance:</h2>
                    <TableList columnNames={report?.preview_cols}
                    columnWidths={report?.preview_widths}
                    entries={reportData?.map(row => {
                        return report?.preview_cols?.map(col => row[col])
                    })}
                    />
                </> :
                <p className="light-text">Report Preview Unavailable</p>}
                <br/>
            </div>
        </div>
    );
}

export default GenericReports;