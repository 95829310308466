import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../AppContext";
import EditPaymentForm from "../components/EditPaymentForm";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import SelectRAForm from "../components/SelectRAForm";
import SelectTPAForm from "../components/SelectTPAForm";
import TableList from "../components/TableList";
import { formatDate, secureFetch } from "../util/flip_utils";
import { Link } from "react-router-dom";
import SelectItemForm from "../components/SelectItemForm";
import SelectOrderItemForm from "../components/SelectOrderItemForm";
import SelectCustomerForm from "../components/SelectCustomerForm";
import CreateCustomerForm from "../components/CreateCustomerForm";
import PayOutForm from "../components/PayOutForm";


const PaymentView = (props) => {
    let context = useContext(AppContext);
    let { payment_id } = useParams();
    let [payment, setPayment] = useState({});
    let [lineItems, setLineItems] = useState([]);
    let [adjustments, setAdjustments] = useState();

    let [fetched, setFetched] = useState(false);

    function fetchPayment() {
        setFetched(false);
        secureFetch(context.server + '/payment-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({id: payment_id ? payment_id : props?.location?.state?.payment_id})
        })
        .then(resp => resp.json())
        .then(results => {
            setPayment(results?.payment)
            setAdjustments(results?.adjustments)
            setLineItems(results?.lineItems)
            setFetched(true);
        })
    }

    useEffect(() => {
        fetchPayment();
    }, [])

    function selectTpa(tpa) {
        secureFetch(context.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: payment?.id,
                keys: ['tpa_id', 'ra_id'],
                values: [tpa?.id ? tpa?.id : 'NULL', 'NULL'],
                message: `user reassigned RA to payment. Old RMA id: ${payment?.tpa_id}`
            })
        })
        .then(() => fetchPayment())
    }

    function selectRa(ra) {
        secureFetch(context.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: payment?.id,
                keys: ['tpa_id', 'ra_id'],
                values: ['NULL', ra?.id ? ra?.id : 'NULL'],
                message: `user reassigned RA to payment. Old RA id: ${payment?.ra_id}`
            })
        })
        .then(() => fetchPayment())
    }

    function selectItem(item) {
        secureFetch(context.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: payment?.id,
                keys: ['item_id'],
                values: [item?.id ? item?.id : 'NULL'],
                message: `user reassigned item to payment. Old item id: ${payment?.item_id}`
            })
        })
        .then(() => fetchPayment())
    }

    function selectOrderItem(oitem) {
        secureFetch(context.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: payment?.id,
                keys: ['order_item_id'],
                values: [oitem?.order_item_id],
                message: `user reassigned order item to payment. Old order item id: ${payment?.order_item_id}`
            })
        })
        .then(() => fetchPayment())
    }

    function selectCustomer(cust) {
        secureFetch(context.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: payment?.id,
                keys: ['customer_id'],
                values: [cust?.id ? cust?.id : 'NULL'],
                message: `user reassigned customer to payment. Old customer id: ${payment?.customer_id}`
            })
        })
        .then(() => fetchPayment())
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <h1 className="light-text">Payment Overview</h1>
            <br/>
            <div className="container">
                <div className="contained">
                    <InfoBox title={fetched ? 'Payment Info' : 'Fetching...'}>
                        {fetched ? 
                        <>
                        <KeyValuePair label='Item SKU:' value={payment?.sku} textClass='dark-text' />
                        <KeyValuePair label='Title:' value={payment?.title} textClass='dark-text' />
                        <KeyValuePair label='Date:' value={formatDate(payment?.created)} textClass='dark-text' />
                        <KeyValuePair label='Payment Method:' value={payment?.payment_method} textClass='dark-text' />
                        {payment?.ra_id > 0 ?
                            <KeyValuePair label='RA Type:' value={payment?.payment_type} textClass='dark-text' /> :
                            <KeyValuePair label='Payment Type:' value={payment?.is_consignment == 1 || payment?.sale_type_id == 4 ? "Consignment" : "Buy/Trade"} />}
                        <KeyValuePair label='Payment Status:' value={
                            payment?.payment_status=='Paid' ? <p className="bg-green light-text rounded" style={{padding: '5px 10px'}}>{payment?.payment_status}</p> : payment?.payment_status
                        } textClass='dark-text' />
                        <KeyValuePair label='Notes:' value={payment?.notes} textClass='dark-text' />
                        {payment?.ra_id ? <KeyValuePair label='RA:' value={
                            <Link className='btn dark-text' to={{pathname: `/ra-view/${payment?.ra_id}`, state: {ra_id: payment?.ra_id}}}>{payment?.ra_id}</Link>
                        } textClass='dark-text' /> : <></>}
                        {payment?.tpa_id ? <KeyValuePair label='RMA Rep:' value={
                            payment?.salesperson
                        } textClass='dark-text' /> : <></>}
                        {payment?.tpa_id ? <KeyValuePair label='RMA:' value={
                            <Link className='btn dark-text' to={{pathname: `/tpa-view/${payment?.tpa_id}`, state: {tpa_id: payment?.tpa_id}}}>{payment?.tpa_number}</Link>
                        } textClass='dark-text' /> : <></>}
                        {payment?.customer_id ? <KeyValuePair label='Customer:' value={
                            <Link className='btn dark-text' to={{pathname: `/customer-view/${payment?.customer_id}`, state: {customer_id: payment?.customer_id}}}>{payment?.customer}</Link>
                        } textClass='dark-text' /> : <></>}
                        {/* <KeyValuePair label='Payment ID:' value={<p className="red-text">{payment?.id}</p>} textClass='dark-text' /> */}
                        {lineItems?.length > 0 ? <>
                        <h3 className="dark-text">Line Items:</h3>
                        {lineItems?.map(row => <KeyValuePair label={row?.line_item_type || row?.title} value={row?.amount} textClass='dark-text' />)}
                        <div className="bg-dark" style={{width: '90%', height: '2px'}}></div>
                        <KeyValuePair label='Total:' value={<p>
                            {payment?.amount}{parseFloat(lineItems?.reduce((acc, curr) => {return parseFloat(acc) + parseFloat(curr?.amount)}, 0)).toFixed(2) == parseFloat(payment?.amount).toFixed(2) ? <></> :
                            <><br/><i>(line items do not add to this number!)</i></>}
                        </p>} textClass={parseFloat(lineItems?.reduce((acc, curr) => {return parseFloat(acc) + parseFloat(curr?.amount)}, 0)).toFixed(2) == parseFloat(payment?.amount).toFixed(2) ?'dark-text' : 'red-text'} />
                        </> : <></>}
                        </>
                        : <></>}
                        <br/>
                        <ModalContainer triggerText='Pay Out' formData={payment} FormComponent={PayOutForm} submitForm={() => {fetchPayment()}} />
                    </InfoBox>
                    <br/>
                    <ModalContainer triggerText='Edit Payment' formData={payment} FormComponent={EditPaymentForm} submitForm={fetchPayment} />
                    <ModalContainer triggerText='Link RMA' formData={{}} FormComponent={SelectTPAForm} submitForm={selectTpa}  />
                    <ModalContainer triggerText='Link RA' formData={{}} FormComponent={SelectRAForm} submitForm={selectRa} />
                    <br/><br/>
                    <ModalContainer triggerText='Reassign Customer' formData={{}} FormComponent={SelectCustomerForm} submitForm={selectCustomer} />
                    <ModalContainer triggerText='Create + Assign Customer' formData={{}} FormComponent={CreateCustomerForm} submitForm={selectCustomer} />
                    <br/><br/>
                    <ModalContainer triggerText='Reassign to Different Item (DANGEROUS!)' buttonClass='btn bg-red light-text' formData={{}} FormComponent={SelectItemForm} submitForm={selectItem} />
                    <ModalContainer triggerText='Reassign to Order Item' buttonClass='btn bg-red light-text' formData={{}} FormComponent={SelectOrderItemForm} submitForm={selectOrderItem} />
                </div>
                <div className="contained">
                    {adjustments?.length > 0 ? <>
                    <h2 className="light-text">Adjustments:</h2>
                        <TableList columnNames={['Date','Change','Reason','User']}
                        columnWidths={['15%','15%','50%','20%']}
                        entries={
                            adjustments.map(row => {
                                return [
                                    formatDate(row?.created),
                                    row?.amount > 0 ? `+${row?.amount}` : row?.amount,
                                    row?.reason,
                                    row?.user
                                ]
                            })
                        }
                        />
                    </> : <h3 className="light-text">No Adjustments made to this payment...</h3>}
                </div>
            </div>
        </div>
    )
}

export default PaymentView;