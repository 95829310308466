import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { secureFetch } from '../util/flip_utils';


const EditBrandForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [brand, setBrand] = useState({});
    let [editedBrand, setEditedBrand] = useState({});
    let [formDisable, setFormDisable] = useState(false);

    useEffect(() => {
        setBrand(formData);
    }, [formData])

    function submit(e) {
        e.preventDefault();
        setFormDisable(true);
        // alert(JSON.stringify(editedBrand))
        secureFetch(context.server + '/edit-brand', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({brand: {...editedBrand, id: brand?.id}}),
        })
        .then(res => res.json())
        .then(data => {
            submitForm(data);
            closeModal();
        })
    }

    return (
        <div>
            <form id='EditBrandForm' onSubmit={submit} />
            <fieldset form='EditBrandForm' disabled={formDisable ? 'disabled' : ''} >
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Edit Brand</h1><br/>
                    <label className='dark-text text-left'>Brand Name:&nbsp;&nbsp;
                    <input form='EditBrandForm' type='text' required value={editedBrand?.brand || brand?.brand} onChange={e => setEditedBrand({...editedBrand, brand: e.target.value})} />
                    </label>
                    <label className='dark-text text-left'>Full Brand Name:&nbsp;&nbsp;
                        <input form='EditBrandForm' type='text' value={editedBrand?.full_name || brand?.full_name || '' } onChange={ (e) => setEditedBrand({...editedBrand, full_name: e.target.value})}/>
                    </label>
                    <label className='dark-text text-left'>Shortened Name:&nbsp;&nbsp;
                        <input form='EditBrandForm' type='text' value={editedBrand?.short_name || brand?.short_name || ''} onChange={ e => setEditedBrand({...editedBrand, short_name: e.target.value})} />
                        <br/><i>(acronyms, abbreviations, etc)</i>
                    </label>
                    <br/>
                    <label className='dark-text text-left'>Brand Partner?&nbsp;&nbsp;
                    <input form='EditBrandForm' type='checkbox' checked={editedBrand?.brand_partner > -1 ? editedBrand?.brand_partner==1 : brand?.brand_partner == 1} onChange={e => setEditedBrand({...editedBrand, brand_partner: e.target.checked ? 1 : 0})} />
                    </label>
                    <br/>
                    <label className='dark-text text-left'>Internal Brand Notes:</label>
                    <textarea form='EditBrandForm' value={editedBrand?.notes || brand?.notes} onChange={e => setEditedBrand({...editedBrand, notes: e.target.value})}
                    className='w-3/5' rows='4'/>
                    <br/>
                    <br/>
                    <input form='EditBrandForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </fieldset>
        </div>
    );
};

export default EditBrandForm;