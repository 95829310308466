import { useEffect, useState } from "react";
import ConfirmButton from "./ConfirmButton"


const LogisticsConfirmation = ({buttonText, notes, notesUpdater, clickHandler, logisticsChecked}) => {
    let [logisticsNotes, setLogisticsNotes] = useState(notes);
    let [checked, setChecked] = useState(logisticsChecked);

    //update parent when notes are updated here
    useEffect(() => {
        notesUpdater(logisticsNotes);
    }, [logisticsNotes]);

    //update this component when parent updates 'notify logistics check'
    useEffect(() => {
        setChecked(logisticsChecked);
    }, [logisticsChecked])


    return (
        <ConfirmButton buttonText={buttonText} buttonClass={checked ? 'btn bg-green dark-text' : 'btn dark-text'}
        clickHandler={clickHandler(true)} cancelHandler={clickHandler(false)} confirmation={
            <div>
                <p>The following products require notifying logistics. Please confirm that you have one of the following before notifying Logistics:</p>
                <ol>
                    <li>All McIntosh components</li>
                    <li>All turntables</li>
                    <li>All tube units with exposed tubes</li>
                    <li>The following PS Audio components:
                        <ul style={{paddingLeft: '15px'}}>
                            <li>All DirectStream</li>
                            <li>All PerfectWave</li>
                            <li>Power Plant 12</li>
                            <li>BHK preamps and amps</li>
                        </ul>
                    </li>
                    <li>{'Any item with a value > $10k'}</li>
                    <li>Freight shipments</li>
                    <li>Anything without factory packaging</li>
                </ol>
                <br/>
                <p>Notes to Logistics:</p>
                <textarea value={logisticsNotes} 
                onChange={e => setLogisticsNotes(e.target.value)}
                style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} />
                {logisticsChecked ? <>
                    <br/>
                    <i className="red-text text-left">Logistics is currently set to be notified, by clicking OK, you will save your notes WITHOUT cancelling this action.</i>
                    <br/>
                    <i className="red-text text-left">If you wish to cancel the action of notifying logistics, click "Cancel". Your notes will NOT be saved.</i>
                    <br/>
                    <i className='red-text text-left'>If you wish to exit this window, click [Esc], or the 'x' button.</i>
                </> : <></>}
            </div>
        } />
    )
}

export default LogisticsConfirmation;