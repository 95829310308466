import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const ConsignmentItemsReceived = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [interval, setInterval] = useState(60);
    

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/consignment-items-received', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({interval: interval})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, interval])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Consignment Items Received</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    <div className="container">
                        

        
                        <div className="container" >
                                <label className=" text-left" for="interval" style={{padding: '15px 15px'}} >Received Date:
                                        <select  name="inverval" id="selectInterval" onChange={e => setInterval(Number(e.target.value))}>
                                        <option value="7" >Last 7 Days</option>
                                            <option value="30" >Last 30 Days</option>
                                            <option selected value="60" >Last 60 Days</option>
                                            <option value="120" >Last 120 Days</option>
                                            <option value="365" >Last 365 Days</option>
                                            <option value="7305">All Time</option>
                                        </select>
                                </label>       
                        </div>
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='buyer_conversion.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report shows all consignment items that have been marked received.</p>
                    <br></br>
                    <p><b>Caveats: </b></p>
                    <p>- There seems to be an issue with the RMA creation date data. Some items are showing that they were received before the RMA was created. Sage suspects the RMA Created Date is being overwritten for some number of RMAs. He's investigating the issue.</p>
                    <p>- Rep shown is the rep assigned to the associated submissions</p>
                    <p>- Submission rep data not populated until late 12/22</p>
                    <p>- Receiving data was not collected until late 7/22</p>
                </div>

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            
            <TableList columnNames={['RMA Number', 'RMA Created','Received Date', 'Customer Name', 'SKU','Qty', 'Title','List Price', 'Extracted Terms', 'Notes', 'Submission Rep']}
                    columnWidths={['8%','8%','8%','8%','8%','5%','15%','8%','8%','17%','8%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.rma_number,
                                row?.rma_created,
                                row?.received_date,
                                row?.customer_name,
                                row?.sku,
                                row?.quantity,
                                row?.title,
                                row?.list_price,
                                row?.extracted_terms,
                                row?.notes,
                                row?.submission_rep
                                
                                
                            ]
                        })
                    } />
           
      
            
        </div>
    );
}

export default ConsignmentItemsReceived;