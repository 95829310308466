/**
 * EditEntitlements.js
 * 
 * Allows user to search for a particular user in the database, and edit their entitlements. The api fetch will return a
 * message that says whether or not the entitlements were edited (ie. if you are no entitled to edit entitlements, you
 * cant perform this action).
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import AppContext from '../AppContext';
import CreateUserForm from '../components/CreateUserForm';
import InfoBox from '../components/InfoBox';
import ModalContainer from '../components/ModalContainer';
import TableList from '../components/TableList';
import { secureFetch } from '../util/flip_utils';

function entitlement_names(entitlements) {
    return entitlements.map(ent => ent.entitlement);
}


const EditEntitlements = (props) => {
    let context = useContext(AppContext);
    let [user, setUser] = useState(null); //the currently selected user. when null, shows the search. otherwise, shows the edit form
    let [searchValue, setSearchValue] = useState("");
    let [users, setUsers] = useState([]); //users in the list
    let [userEntitlements, setUserEntitlements] = useState([]); //entitlements assigned to the user selected
    let [entitlements, setEntitlements] = useState([]); //all available entitlements
    let [message, setMessage] = useState(''); //message given from the backend to show at the top when submitted

    function fetchUsers() {
        secureFetch(context.server + '/search-users', { 
            credentials: 'include', 
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({query: searchValue, params: {
                sort: {
                    key: 'first_name',
                    order: '1'
                },
                equal: [{
                    key: 'active',
                    value: '1'
                }]
            }}) 
        })
        .then(resp => resp.json())
        .then(results => setUsers(results.slice(0, 50)) )
    }

    useEffect(() => {
        fetchUsers();
    }, [context, searchValue])

    function fetchEntitlements() {
        secureFetch(context.server + '/entitlements', { //dont need to do this synchronously since we wont need them till they select a user
            credentials: 'include', 
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST',
        })
        .then(resp => resp.json())
        .then(results => setEntitlements(results))
    }

    useEffect(() => {
        fetchEntitlements();
    }, [])

    function selectUser(us) {
        setUser(us) //update the user with given info, then get their entitlements and update those as well
        secureFetch(context.server + '/user-entitlements', {
            credentials: 'include', 
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({user_id: us.id})
        })
        .then(res => res.json())
        .then(results => setUserEntitlements(results))
    }

    function checkHandler(entitlement, event) { //handles updating state for each checkbox for the entitlements
        if(event.target.value === 'true') {
            let new_ent = userEntitlements.map(e=>e);
            new_ent.splice(entitlement_names(userEntitlements).indexOf(entitlement.entitlement), 1);
            setUserEntitlements(new_ent);
        } else {
            let new_ent = userEntitlements.map(e=>e);
            new_ent.push(entitlement);
            setUserEntitlements(new_ent)
        }
    }

    function submit(e) { //sends new entitlements info to the backend
        e.preventDefault();
        setSearchValue('')
        let body = {
            user_id: user.id,
            entitlements: userEntitlements
        }
        secureFetch(context.server + '/update-entitlements', {
            credentials: 'include', 
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify(body) 
        })
        .then(res => res.json())
        .then(results => {
            secureFetch(context.server + '/update-user-fields', {
                method: 'POST',
                credentials: 'include', 
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
                body: JSON.stringify({
                    id: user?.id,
                    keys: ['salesperson','appraiser','tester','purchaser'],
                    values: [user?.salesperson, user?.appraiser, user?.tester, user?.purchaser],
                    message: 'updating user roles'
                })
            })
            .then(() => {
                if(results.message) {
                    setUser(null)
                    setMessage(results.message)
                }
            })
            
        })
    }

    return(
        <div style={{padding: '15px 15px'}}>
            <p className='light-text'>{message ? message : null}</p>
            {user != null ? //shown if a user is selected, and allows you to edit their entitlements
            <div>
                <h2 className='light-text'>User Selected: {user?.first_name + ' ' + user?.last_name}</h2>
                <br/>
                <br/>
                <InfoBox title={`Entitlements${userEntitlements.length < 1 ? ' loading or unavailable' : ''}:`}>
                    <div className='container'>
                    <div>
                    {
                        entitlements.map( (ent, index) => { //set up all the checkboxes for each entitlement
                            return (
                                <label className='dark-text text-left' key={index}><input type='checkbox' 
                                checked={entitlement_names(userEntitlements).indexOf(ent.entitlement) !== -1}
                                value={entitlement_names(userEntitlements).indexOf(ent.entitlement) !== -1}
                                onChange={(e) => checkHandler(ent, e)}
                                /> &nbsp; &nbsp; {ent.entitlement}</label>
                            );
                        } )
                    }
                    <br/>
                    <br/>
                    <h3 className='dark-text'>Roles:</h3>
                    <label className='text-left dark-text'>
                        <input type='checkbox' checked={user?.salesperson} onChange={e => setUser({...user, salesperson: e.target.checked=='1' ? 1 : 0, purchaser: e.target.checked=='1' || user?.appraiser==1 ? 1 : 0})} />
                        &nbsp;&nbsp;&nbsp;Salesperson (Rep)
                    </label>
                    <label className='text-left dark-text'>
                        <input type='checkbox' checked={user?.appraiser} onChange={e => setUser({...user, appraiser: e.target.checked=='1' ? 1 : 0, purchaser: e.target.checked=='1' || user?.salesperson==1 ? 1 : 0})} />
                        &nbsp;&nbsp;&nbsp;Appraiser
                    </label>
                    <label className='text-left dark-text'>
                        <input type='checkbox' checked={user?.tester} onChange={e => setUser({...user, tester: e.target.checked=='1' ? 1 : 0})} />
                        &nbsp;&nbsp;&nbsp;Tester
                    </label>
                    {/* <label className='text-left dark-text'>
                        <input type='checkbox' disabled checked={user?.purchaser} />
                        &nbsp;&nbsp;&nbsp;Purchaser
                    </label> */}
                    <br/>
                    <button className='btn dark-text' onClick={submit}>Submit</button>
                    </div></div>
                </InfoBox>
            </div>
            : //shown if no user is selected, and allows you to search the users by name and select one.
            <div>
                <div className='container'>
                    <h1 className='contained'>Search Users</h1>
                    <ModalContainer triggerText='Create User' FormComponent={CreateUserForm} formData={{}} submitForm={selectUser} />
                </div>
                
                <form>
                    <div className='container'>
                        <input autoFocus type='text' className="search-bar" value={searchValue || ''} 
                        onChange={e => setSearchValue(e.target.value)} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}/>
                    </div>
                </form>
                <br/>
                <br/>

                <TableList columnNames={['Name','Email','Active?',' ']}
                columnWidths={['40%','30%','20%','10%']}
                entries={
                    users.map( row => {
                        return [
                            row?.first_name + ' ' + row?.last_name,
                            row?.email,
                            row?.active,
                            <button className='btn dark-text' onClick={() => selectUser(row)}>Entitlements</button>
                        ]
                    })
                }
                />

            </div>
            }
        </div>
    );

}

export default EditEntitlements;