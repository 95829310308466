import e from "cors";
import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import InventoryPopup from "../components/InventoryPopup";
import ModalContainer from "../components/ModalContainer";
import PaginatedTableList from "../components/PaginatedTableList";
import { secureFetch } from "../util/flip_utils";


const InventoryList = (props) => {
    let context = useContext(AppContext);

    let [inv, setInv] = useState([]);
    let [searchVal, setSearchVal] = useState('');
    let [showAll, setShowAll] = useState(1);


    function fetchInventory(pageNumber, pageSize) {
        secureFetch(context.server + '/search-inventory', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                search_val: searchVal,
                show_all: showAll,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(resp => resp.json())
        .then(results => setInv(results))
    }

    useEffect(() => {
        fetchInventory(0,20);
    }, [context, searchVal, showAll])


    return (
        <div style={{padding: '15px 15px'}}>
            <h1 className="light-text">Search Inventory:</h1>
            <br/>
            <div className='container'>
                <input autoFocus type='text' className="search-bar" onChange={e => setSearchVal(e.target.value)} 
                value={searchVal} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
            </div>
            <br/>
            <label className="light-text text-left">Show All Inventory?</label>
            <div className="bg-light rounded container" style={{padding: '10px 10px', maxWidth: '300px'}}>
                <label className="dark-text text-left contained"><input type='radio' value={1} checked={showAll === 1} onChange={e => setShowAll(1)} />&nbsp;&nbsp;Show All</label>
                <label className="dark-text text-left contained"><input type='radio' value={0} checked={showAll === 0} onChange={e => setShowAll(0)} />&nbsp;&nbsp;In-Stock Only</label>
            </div>
            <br/><br/>

            <PaginatedTableList columnNames={['SKU','Title','Sublocation','Qty For Sale (HQ)','Qty On Hand (HQ)','Qty Reserved', 'Qty On Hold (Demo/Repair)','Cost','Price',' ']}
            columnWidths={['10%','35%','8%','5%','5%','5%','8%','8%','8%','9%']}
            entries={inv.map( row => {
                return [
                    row?.product_id.split('*')[0],
                    row?.description,
                    row?.sublocation,
                    row?.quantity_on_hand - row?.reservation_units,
                    row?.quantity_on_hand,
                    row?.reservation_units,
                    row?.quantity_on_hold,
                    row?.display_cost || 'N/A',
                    row?.display_price || 'N/A',
                    <ModalContainer triggerText='View' formData={row} FormComponent={InventoryPopup} submitForm={()=>{}} />
                ]
            })}
            getData={fetchInventory} />
        </div>
    )
}

export default InventoryList;