import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import InfoBox from "../../components/InfoBox";
import KeyValuePair from "../../components/KeyValuePair";

const SubmissionsProgress = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);

    function fetchReport() {
        secureFetch(context.server + '/reports/submissions-progress', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate
            })
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data)
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context, startDate, endDate])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>Submissions Progress Report</h1>
                <br/>
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                    {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                    <br/>
                </div>
                <br/>
                <InfoBox title="Appraising Report">
                    <KeyValuePair label='New Submissions:' value={report?.total_submissions} textClass='dark-text' />
                    <KeyValuePair label='New WWS Submissions:' value={report?.total_wws} textClass='dark-text' />
                    <KeyValuePair label='New SourceAV Submissions:' value={report?.total_sourceav} textClass='dark-text' />
                    <KeyValuePair label='Submissions Distributed:' value={report?.submissions_distributed} textClass='dark-text' />
                    <KeyValuePair label='Total Items Appraised:' value={report?.items_appraised} textClass='dark-text' />
                    <KeyValuePair label='Unappraised Submissions (currently):' value={report?.currently_unappraised} textClass='dark-text' />
                    <KeyValuePair label='Unappraised Items (currently):' value={report?.currently_unappraised_items} textClass='dark-text' />
                    <h3 className="dark-text">Items Appraised per Appraiser</h3>
                    {report?.appraisals_by_user?.length > 0 ? <>
                        {report?.appraisals_by_user?.map((row, ind) =>
                            <KeyValuePair label={row?.user} value={row?.count} textClass='dark-text' />
                        )}
                    </> : <></>}
                </InfoBox>
                <br/>
                <InfoBox title='Sales Rep Report'>
                    <KeyValuePair label='Offers Sent:' value={report?.offers_sent} textClass='dark-text' />
                    <KeyValuePair label='Total RMAs Created:' value={report?.submissions_completed} textClass='dark-text' />
                    <br/>
                    {/* <KeyValuePair label='Submissions Awaiting Info:' value={report?.submissions_waiting} textClass='dark-text' /> */}
                    <h3 className="dark-text">Unsent Submissions per Rep</h3>
                    {report?.submissions_by_user?.length > 0 ? <>
                        {report?.submissions_by_user?.map((row, ind) =>
                            <KeyValuePair label={row?.user} value={row?.count} textClass='dark-text' />
                        )}
                    </> : <></>}
                </InfoBox>
                <br/>
            </div>
        </div>
    );
}

export default SubmissionsProgress;