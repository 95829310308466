import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";


const BcFinaleInventoryRec = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
 

    function fetchReport() { 
        secureFetch(context.server + '/reports/bc-finale-inventory-rec', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        })
        .then(res => res.json())
        .then(data => {setReport(data)})
        
       
        console.log(report);
        console.log('fart');

    
    }


    useEffect(() => {
        fetchReport();
    }, [])





        
    return(
        
        <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>BC/Finale Reconciliation (Beta)</h1>    
                </div >
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <p>**This report may take a few moments to load.</p>
                    <CSVLink data={report} filename='submissions_progress_report.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    <br/>
                </div>
                <br></br>
                <TableList columnNames={['SKU', 'BC QTY', 'Finale Available Qty', 'Finale Notes']}
                        columnWidths={['20%','10%','10%', '70%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.sku,
                                    row?.bcQty,
                                    row?.finaleQty,
                                    row?.finaleNotes
                                ]
                            })
                        } />


               
            

           
      
            
        </div>
    );
}

export default BcFinaleInventoryRec;