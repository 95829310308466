/**
 * CustomerView.js
 * 
 * Renders an overview of all of the selected customer's data. we will filter out certain columns for privacy and/or data safety reasons
 * From this page, you can also edit the user (although very few fields will be writeable by most users)
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import { formatDate, secureFetch } from '../util/flip_utils';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import KeyValuePair from '../components/KeyValuePair';
import ModalContainer from '../components/ModalContainer';
import EditCustomerForm from '../components/EditCustomerForm';
import CurrentlyListedConsignmentsForm from '../components/CurrentlyListedConsignmentsForm';
import TabbedDisplay from '../components/TabbedDisplay';
import CheckmarkYN from '../components/CheckmarkYN';


const CustomerView = (props) => {
    //constructor to declare state for the page
    let context = useContext(AppContext);
    let { customer_id } = useParams();
    let [overview, setOverview] = useState({});
    let [submissions, setSubmissions] = useState([]);
    let [orders, setOrders] = useState([]);
    let [tpas, setTpas] = useState([]);
    let [offers, setOffers] = useState([]);
    let [returns, setReturns] = useState([]);


    function fetchData() {
        secureFetch(context.server + '/customer-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: customer_id ? customer_id : props?.location?.state?.customer_id})
        })
        .then(resp => resp.json())
        .then(data => {
            setOverview({customer: data?.customer, emails: data?.emails});
            setOrders(data?.orders);
            setSubmissions(data?.submissions);
            setTpas(data?.tpas);
            setOffers(data?.offers);
            setReturns(data?.returns);
        })
    }

    //called when the component is mounted on the page
    useEffect(() => {
        fetchData();
    }, [])

    //renders the actual html for the page


    if(!props?.location?.state?.customer_id && !customer_id) {
        return <h1>Customer not found... :(</h1>
    }
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Customer Overview {overview?.customer ? '' : 'Loading...'}</h1>
            <div className='container'>
                <div className='contained'>
                <InfoBox title='Customer Information:'>
                    {overview?.customer ? <div className='container'>
                        <div className='contained'>
                            <KeyValuePair label='Customer ID:' value={overview?.customer?.id} textClass={'dark-text'} />
                            <KeyValuePair label='Name:' value={overview?.customer?.name} textClass='dark-text' />
                            <KeyValuePair label='Phone:' value={overview?.customer?.phone} textClass='dark-text' />
                            <KeyValuePair label='Last RMA Payment Method:' value={overview?.customer?.last_rma_payment_method} textClass='dark-text' />
                            <KeyValuePair label='B-to-B:' value={<CheckmarkYN val={overview?.customer?.btob} />} textClass='dark-text' />
                            {overview?.customer?.btob==1 ? <KeyValuePair label='Company Name:' value={overview?.customer?.company_name} /> : <></>}
                            <KeyValuePair label='BC Customer:' value={overview?.customer?.bc_customer_id} textClass='dark-text' />
                            <KeyValuePair label='Date Created:' value={formatDate(overview?.customer?.created)} textClass='dark-text' />
                        </div>
                        <div className='contained'>
                            {overview?.emails.map((row, ind) => 
                                <KeyValuePair label={ind===0 ? 'Emails:' : ' '} value={row?.email} textClass='dark-text' />
                            )}
                            <KeyValuePair label='Notes:' value={overview?.customer?.notes} textClass='dark-text' />
                            <ModalContainer triggerText='View Current Consignment Listings' formData={overview?.customer?.id} FormComponent={CurrentlyListedConsignmentsForm} submitForm={() => fetchData()} />

                        </div>
                        
                    </div>
                     : <></>}
                     {overview?.customer?.blacklisted==1 ? <div className='bg-red rounded' style={{padding: '15px 15px'}}><p className='light-text'><b>THIS CUSTOMER IS BLACKLISTED</b></p></div> : <></>}
                </InfoBox>
                <ModalContainer triggerText='Edit Customer' formData={overview} FormComponent={EditCustomerForm} submitForm={() => fetchData()} />
                </div>
            </div>
            

            <TabbedDisplay tabNames={['Orders', 'RMAs', 'Submissions', 'Offers', 'Returns']} contents={
                [
                    <div className='contained'>
                        <h2 className='light-text'>Lifetime Orders:</h2>
                        <TableList
                            columnNames={['BC Order #','Order Total','Date',' ']}
                            columnWidths={['25%','25%','25%','25%']}
                            entries={
                                orders?.map( row => {
                                    return [
                                        row?.bc_order_id,
                                        row?.total,
                                        formatDate(row?.created, {date:true}),
                                        <Link className={ 'btn dark-text' } to={{pathname: `/order-view/${row?.id}`, state: {order_id: row?.id}}}>View</Link>
                                    ]
                                })
                            }
                        />
                    </div>,

                    <div className='contained'>
                        <h2 className='light-text'>Lifetime RMAs:</h2>
                        <TableList
                            columnNames={['RMA #','Created','Terms','Items',' ']}
                            columnWidths={['15%','15%','20%','30%','20%']}
                            entries={
                                tpas?.map( row => {
                                    return [
                                        row?.tpa_number,
                                        formatDate(row?.created, {date:true}),
                                        row?.notes || 'None',
                                        row?.items,
                                        <Link className={ 'btn dark-text' } to={{pathname: `/tpa-view/${row?.id}`, state: {tpa_id: row?.id}}}>View</Link>
                                    ]
                                })
                            }
                        />
                    </div>,

                    <div className='contained'>
                        <h2 className='light-text'>Lifetime Submissions:</h2>
                        <TableList
                            columnNames={['Created','# of Items','Completed?','Salesperson',' ']}
                            columnWidths={['20%','15%','15%','30%','20%']}
                            entries={
                                submissions?.map( row => {
                                    return [
                                        formatDate(row?.created, {date:true}),
                                        row?.item_count,
                                        row?.completed===1 ? 'Yes' : 'No',
                                        row?.salesperson ? row?.salesperson.split('@')[0] : 'unassigned',
                                        <Link className={ 'btn dark-text' } to={{pathname: `/submission-view/${row?.id}`, state: {submission_id: row?.id}}}>View</Link>
                                    ]
                                })
                            }
                        />
                    </div>,

                    <div className='contained'>
                        <h2 className='light-text'>Lifetime Offers:</h2>
                        <TableList
                            columnNames={['SKU','Title','Offer Amount','Email','Notes']}
                            columnWidths={['15%','30%','10%','20%','25%']}
                            entries={
                                offers?.map( row => {
                                    return [
                                        row?.product_code,
                                        row?.title,
                                        row?.offer_amount,
                                        row?.email,
                                        row?.notes,
                                    ]
                                })
                            }
                        />
                    </div>,

                    <div className='contained'>
                        <h2 className='light-text'>Lifetime Returns:</h2>
                        <TableList
                            columnNames={['Agent', 'Status', 'Reason', 'Int. Notes', ' ']}
                            columnWidths={['20%','20%','20%','30%','10%']}
                            entries={
                                returns?.map( row => {
                                    return [
                                        row?.agent,
                                        row?.status,
                                        row?.return_reason,
                                        row?.notes,
                                        <a className='btn dark-text' target='_blank' rel='noreferrer' href={row?.fd_url}>FD Ticket</a>
                                    ]
                                })
                            }
                        />
                    </div>
                ]
            } />
            <br/><br/><br/><br/>

        </div>
    );
}

export default CustomerView;
