import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import { formatDate, secureFetch } from "../util/flip_utils";
import { Link, useHistory, useParams } from "react-router-dom";
import TableList from "../components/TableList";
import ModalContainer from "../components/ModalContainer";
import SelectCustomerForm from "../components/SelectCustomerForm";
import SelectItemForm from "../components/SelectItemForm";
import SelectOrderItemForm from "../components/SelectOrderItemForm";
import ConfirmButton from "../components/ConfirmButton";
import RaItemViewForm from "../components/RaItemViewForm";


const RAView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { ra_id } = useParams();
    let [ra, setRa] = useState({});
    let [raItems, setRaItems] = useState([]);
    let [returnReasons, setReturnReasons] = useState([]);
    let [payments, setPayments] = useState([]);

    let [fetched, setFetched] = useState(false);

    function fetchOverview() {
        setFetched(false)
        secureFetch(context?.server + '/ra-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra_id ? ra_id : props?.location?.state?.ra_id
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setRa(results?.ra)
            setRaItems(results?.items)
            setPayments(results?.payments)
            setFetched(true);
        })
    }

    function fetchReturnReasons() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'return_reasons'})
        })
        .then(resp => resp.json())
        .then(results => setReturnReasons(results));
    }

    useEffect(() => {
        fetchOverview();
        fetchReturnReasons();
    }, [])

    function assignCustomer(cust) {
        secureFetch(context?.server + '/update-ra-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id,
                keys: ['customer_id'],
                values: [cust?.id],
                message: `user reassigned customer to RA. Old customer id: ${ra?.customer_id}`
            })
        })
        .then(() => fetchOverview());
    }

    function addItem(item) {
        secureFetch(context?.server + '/add-ra-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id,
                item: item,
            })
        })
        .then(() => fetchOverview())
    }

    function removeItem(item) {
        secureFetch(context?.server + '/remove-ra-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item: item
            })
        })
        .then(() => fetchOverview())
    }

    function copyInfo(event) {
        event.preventDefault();
        secureFetch(context.server + '/freshdesk/copy-ra-info', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id
            })
        })
        .then(() => alert('Info copied!'))
    }

    function updateReason(event) {
        event.preventDefault();
        secureFetch(context?.server + '/update-ra-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id,
                keys: ['return_reason_id','return_notes','return_actions'],
                values: [ra?.return_reason_id, ra?.return_notes, ra?.return_actions],
                message: `user edited return reason/actions`
            })
        })
        .then(() => {alert('return reason updated!');fetchOverview()});
    }

    function resolveTicket(event) {
        event.preventDefault();
        secureFetch(context?.server + '/freshdesk/resolve-ticket', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id
            })
        })
        .then(() => fetchOverview())
    }

    function deactivateRA(event) {
        event.preventDefault();
        secureFetch(context?.server + '/update-ra-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra?.id,
                keys: ['active'],
                values: ['0'],
                message: `user deactiveated RA`
            })
        })
        .then(() => {
            alert('RA Deactivated');
            history?.push({pathname: `/ra-list`});
        })
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className="container">
                <h1 className="contained">RA Overview</h1>
                <div className="contained container">
                    <button className="btn dark-text contained" onClick={copyInfo}>Copy Info to FreshDesk</button>&nbsp;&nbsp;
                    <button className="btn dark-text contained" disabled={ra?.status=='Resolved' ? 'disabled' : ''} onClick={resolveTicket}>
                        {ra?.status=="Resolved" ? "Already Resolved" : 'Resolve FD Ticket'}
                    </button>
                </div>
            </div>
            {fetched ?
            <div className="container">
                <div className="contained">
                    <InfoBox title='RA Info:'>
                        <KeyValuePair label='RA Number:' value={
                            <a className="btn dark-text" rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${ra?.id}`}>{ra?.id}</a>
                        } textClass='dark-text' />
                        <KeyValuePair label='Customer:' value={ra?.customer_id ? 
                            <Link className='btn dark-text' to={{pathname: `/customer-view/${ra?.customer_id}`, state: {customer_id: ra?.customer_id}}}>{ra?.customer}</Link>
                        : 'None'} textClass='dark-text' />
                        <KeyValuePair label='Date Issued:' value={formatDate(ra?.created)} textClass='dark-text' />
                        <KeyValuePair label='FD Ticket Status:' value={ra?.status} textClass='dark-text' />
                        <KeyValuePair label='Title:' value={ra?.title} textClass='dark-text' />
                        {/* <KeyValuePair label='Summary:' value={ra?.description} textClass='dark-text' /> */}
                        <br/><br/>
                        
                    </InfoBox>
                    <ModalContainer triggerText='Assign Customer' FormComponent={SelectCustomerForm} formData={{}} submitForm={assignCustomer} />
                    <br/>
                    <br/>
                    <h2 className="light-text">Payments:</h2>
                    <TableList columnNames={['Payment ID','SKU','Amount','Status','View']}
                    columnWidths={['15%','35%','15%','15%','20%']}
                    entries={payments?.map(row => {
                        return [
                            row?.id,
                            row?.sku,
                            row?.amount,
                            row?.payment_status=='Paid' ? <p className="bg-green light-text rounded" style={{padding: '5px 10px'}}>{row?.payment_status}</p> : row?.payment_status,
                            <Link className="btn dark-text" to={{pathname: `/payment-view/${row?.id}`, state: {payment_id: row?.id}}}>View</Link>
                        ]
                    })}
                    />
                </div>
                <div className="contained">
                    <TableList columnNames={['SKU','Title','View','Receive','Remove']}
                    columnWidths={['25%','45%','10%','10%','10%']}
                    entries={raItems?.map(row => {
                        return [
                            row?.sku,
                            row?.title,
                            <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>View</Link>,
                            row?.received ?
                                row?.issue ?
                                    <ModalContainer triggerText='Received' FormComponent={RaItemViewForm} formData={row} submitForm={()=>{}} buttonClass='btn bg-red light-text' />
                                :
                                <ModalContainer triggerText='Received' FormComponent={RaItemViewForm} formData={row} submitForm={()=>{}} buttonClass='btn bg-green light-text' />
                            :
                                <Link className='btn dark-text' to={{pathname: `/receive-ra-item/${row?.ra_item_id}`, state: {ra_item_id: row?.ra_item_id}}}>Receive</Link>,
                            <ConfirmButton buttonText='X' clickHandler={() => removeItem(row)} confirmation={`Are you sure you would like to remove this item (${row?.sku}) from this RA?`} />
                        ]
                    })}
                    />
                    <ModalContainer triggerText='Add Item to RA' formData={{}} FormComponent={SelectOrderItemForm} submitForm={addItem} />
                    <br/><br/>
                    <InfoBox title='Return Reason:'>
                        <label className="dark-text text-left">Return Reason:&nbsp;&nbsp;&nbsp;
                            <select className={ra?.return_reason_id ? '' : 'bg-red'} value={ra?.return_reason_id} onChange={e => setRa({...ra, return_reason_id: e.target.value})} >
                                <option value='' key={0}>*** SELECT RETURN REASON ***</option>
                                {returnReasons?.map(row =>
                                    <option key={row?.id} value={row?.id}>{row?.reason}</option>    
                                )}
                            </select>
                        </label>
                        <br/>
                        <label className="dark-text text-left flex flex-col items-start"><span>Return Notes:</span>
                            <textarea value={ra?.return_notes} onChange={e => setRa({...ra, return_notes: e.target.value})}
                            style={{minWidth: '500px', width: '500px', maxWidth: '500px', height: '100px'}} />
                        </label>
                        <br/>
                        <label className="dark-text text-left flex flex-col items-start"><span>Return Actions:</span>
                            <textarea value={ra?.return_actions} onChange={e => setRa({...ra, return_actions: e.target.value})}
                            style={{minWidth: '500px', width: '500px', maxWidth: '500px', height: '100px'}} />
                        </label>
                        <br/>
                        <button className="btn bg-dark light-text" onClick={updateReason}>Save Return Reason/Notes</button>
                        <br/><br/>
                    </InfoBox>
                    <br/>
                    <ConfirmButton confirmation='Are you sure you would like to cancel this RA? Retrieving it will require support from the tech team.' buttonText='Cancel FLIP RA' clickHandler={deactivateRA} />
                </div>
            </div>
            :
            <div>
                <h3 className="light-text">Fetching Overview...</h3>
            </div>
            }
        </div>
    )
}

export default RAView;