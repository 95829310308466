import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import EditPaymentForm from "../components/EditPaymentForm";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import TableList from "../components/TableList";
import { formatDate, secureFetch } from "../util/flip_utils";
import EditTpaForm from "../components/EditTpaForm";
import EditItemForm from "../components/EditItemForm";
import ConfirmButton from "../components/ConfirmButton";
import ConsignToPurchaseForm from "../components/ConsignToPurchaseForm";
import PurchaseToConsignForm from "../components/PurchaseToConsignForm";
import ReceiveRmaForm from "../components/ReceiveRmaForm";
import SplitTpaForm from "../components/SplitTpaForm";
import AddRmaItemForm from "../components/AddRmaItemForm";
import BrandPartnerLogo from '../components/BrandPartnerLogo';
import MultiChangeSaleTypeForm from "../components/MultiChangeSaleTypeForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BulkPayoutForm from "../components/BulkPayoutForm";


const TPAView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { tpa_id } = useParams();
    let [tpa, setTpa] = useState({});
    let [items, setItems] = useState([]);
    let [relatedTpas, setRelatedTpas] = useState([]);
    let [payments, setPayments] = useState([]);

    const fetchTpa = () => {
        secureFetch(context.server + '/tpa-overview', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: tpa_id || props?.location?.state?.tpa_id
            })
        })
        .then(resp => resp.json())
        .then(result => {
            setTpa(result?.tpa);
            setItems(result?.items);
            setRelatedTpas(result?.related_tpas);
            setPayments(result?.payments);
        })
    }

    /* esnlint-disable */
    useEffect(() => {
        fetchTpa();
        //fetchFinancials();
    },[context, tpa_id]) //refresh data when tpa # changes (so you can link to tpa-view from tpa-view)
    /* esnlint-enable */


    function removeItem(id) {
        secureFetch(context.server + '/update-item-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                keys: ['active'],
                values: ['0'],
                message: "deactivating from RMA page"
            })
        })
        .then(() => fetchTpa())
    }

    if(!tpa) {
        return (
            <h1 textClass='light-text'>Loading RMA...</h1>
        )
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained'>RMA Overview</h1>
            </div>
            <div className='container'>
                <div className='contained-smaller'>
                    <InfoBox title='RMA Info'>
                        <KeyValuePair label='RMA Number:' value={tpa?.tpa_number + `${tpa?.related_tpa_id ? ' (this is a child RMA)' :''}`} textClass='dark-text' />
                        <KeyValuePair label='RMA Type:' value={tpa?.is_consignment == 0 ? 'Buy' : tpa?.is_consignment == 1 ? 'Consign' : '' } textClass='dark-text' />
                        <KeyValuePair label='Finale PO:' value={tpa?.finale_po_id} textClass='dark-text' />
                        <KeyValuePair label='SS Order:' value={tpa?.ss_order_number ?
                            <a className='btn dark-text' target='_blank' rel='noreferrer' href={'https://ship.shipstation.com/orders/all-orders-search-result?quickSearch='+String(tpa?.ss_order_number)} >{tpa?.ss_order_number}</a> : 'None'
                        } textClass='dark-text' />
                        <KeyValuePair label='Submission:' value={
                            <Link className='btn dark-text' to={{pathname: `/submission-view/${tpa?.submission_id}`, state: {submission_id: tpa?.submission_id}}}>{tpa?.submission_id ? tpa?.submission_id : 'None'}</Link>
                        } textClass='dark-text' />
                        <KeyValuePair label='Seller:' value={
                            <Link className='btn dark-text' to={{pathname: `/customer-view/${tpa?.customer_id}`, state: {customer_id: tpa?.customer_id}}}>{tpa?.seller_name ? tpa?.seller_name : 'NO NAME - UPDATE'}</Link>
                        } textClass='dark-text' />
                        <KeyValuePair label='Salesperson:' value={tpa?.salesperson} textClass='dark-text' />
                        <KeyValuePair label='Date Created:' value={formatDate(tpa?.created)} textClass='dark-text' />
                        {tpa?.consignment_rate > 0 ? 
                            <KeyValuePair label='Consignment Rate:' value={tpa?.consignment_rate} />
                        : <></>}
                        <KeyValuePair label='Notes:' value={tpa?.notes} textClass='dark-text' />
                        {tpa?.related_tpa_id ? 
                            <KeyValuePair label='Parent RMA:' value={<Link className="btn bg-green light-text" to={{pathname: `/tpa-view/${tpa?.related_tpa_id}`}}>{tpa?.related_tpa_number}</Link>} textClass='dark-text' />
                        : <></>}
                    </InfoBox>
                    <ModalContainer triggerText='Edit RMA Info' formData={tpa} FormComponent={EditTpaForm} submitForm={() => fetchTpa()} />
                </div>
                <div className='contained'>
                    <ModalContainer triggerText="Receive Items" formData={{...tpa, items: items}} FormComponent={ReceiveRmaForm} submitForm={() => fetchTpa()} />
                    &nbsp;&nbsp;
                    <ModalContainer triggerText='Split RMA' formData={{...tpa, items: items}} FormComponent={SplitTpaForm} submitForm={() => fetchTpa()} />
                    &nbsp;&nbsp;
                    {tpa?.is_consignment ? <><ModalContainer triggerText='Convert to Buy' formData={{...tpa, items: items}} FormComponent={MultiChangeSaleTypeForm} submitForm={id => {history.push({pathname: `/tpa-view/${id}`});}} />
                    &nbsp;&nbsp;</> : <></>}
                    {tpa?.closed ? 
                        <button className="btn dark-text" onClick = {()=>{alert('This RMA has been closed (all items have been tested). No more items can be added.')}} >Add Item</button>
                        : 
                        payments.every(p => {return p?.payment_status_id < 10}) ?  
                            <ModalContainer triggerText='Add Item' formData={{...tpa, items: items, payments: payments}} FormComponent={AddRmaItemForm} submitForm={() => fetchTpa()} />
                            :
                            <button className="btn dark-text" onClick = {()=>{alert('Items can no longer be added to this RMA because of the payment status.')}} >Add Item</button>
                    }
                    {tpa?.is_consignment ?
                        <ModalContainer triggerText="Bulk Payout" formData={{...tpa, payments: payments}} FormComponent={BulkPayoutForm} submitForm={()=>fetchTpa()} />
                    : <></>}
                    <br/><br/>
                    <TableList columnNames={[' ','SKU',' ','Title','QTY','BP','LP','Status','Edit']}
                    columnWidths={['3%','8%','4%','34%','8%','12%','12%','10%','9%']}
                    entries={
                        items.map( row => {
                            return [
                                <div>
                                    {row?.item_status_id>='2' ? <><button style={{padding:'5px 5px'}} className='btn bg-green' />&nbsp;</> : <></>}
                                </div>,
                                <Link className='btn dark-text vertically-aligned' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                                row?.brand_partner ?
                                <div className="vertically-aligned">
                                    <BrandPartnerLogo height = {'25px'}/>
                                </div>
                                :
                                ' ',
                                row?.title,
                                row?.quantity,
                                row?.purchase_amount,
                                row?.list_price,
                                row?.item_status,
                                <ModalContainer triggerText='Edit Item' formData={row} FormComponent={EditItemForm} submitForm={event => {fetchTpa();if(event?.alert){alert(event?.alert)}}} />,
                            ]
                        })
                    }
                    />
                    
                    
                    {relatedTpas?.length > 0 ? <><br/><br/>
                        <br/>
                        <h2 className='light-text'>Related TPAs</h2>
                        <TableList columnNames={['RMA',' ','Status','Created']}
                        columnWidths={['25%','25%','25%','25%']}
                        entries={relatedTpas?.map(row => {
                            return [
                                <Link className="btn dark-text" to={{pathname: `/tpa-view/${row?.id}`, state: {tpa_id: row?.id}}}>{row?.tpa_number}</Link>,
                                row?.related_tpa_id ? '(Split off)': ' ',
                                row?.item_count || row?.cleared_count ? `${row?.cleared_item_count || 0}/${row?.item_count || 0} Cleared` : 'unknown',
                                formatDate(row?.created, {date:true})
                            ]
                        })}/>
                    </> : <></>}
                </div>
            </div>
            <div style={{padding: '15px 15px'}}>
                <h3 className="light-text">Payments:</h3>
                <TableList columnNames={['Amount','Notes','Status',' ']}
                columnWidths={['20%','40%','20%','20%']}
                entries={ payments.map( (row, ind) => {
                    return [
                        row?.amount,
                        row?.sku ? `SKU ${row?.sku} - ${row?.notes}` : row?.notes,
                        row?.payment_status,
                        <Link className="btn dark-text" to={{pathname: `/payment-view/${row?.id}`}}>View</Link>
                    ]
                })}
                />
                {/* <p className="light-text">PAYMENTS HANDLED IN TMROS FOR THE TIME BEING</p> */}
            </div>
        </div>
    );

}

export default TPAView;