import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { formatDate, secureFetch } from "../util/flip_utils";
import TableList from "../components/TableList";
import TableListTwo from "../components/TableListTwo";
import '../App.css';
import { Link } from "react-router-dom";


const Home = () => {
    let context = useContext(AppContext);
    let [items, setItems] = useState([]);
    let [feed, setFeed] = useState([]);

    const DEV_FLAG_ENABLED = context?.feature_flags?.dev_environment;

    function fetchData() {
        secureFetch(context.server + '/homepage', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
        })
        .then( resp => resp.json() )
        .then( data => {
            setItems(data?.items);
            setFeed(data?.events);
        })
    }

    useEffect( () => { //update feed if empty
        fetchData();
    }, [context.server]);

    return (
        <div className='home '>
            <h1 className='dark-text text-3xl font-bold mx-auto w-1/4 text-center'>The Music Room - FLIP</h1>
            {DEV_FLAG_ENABLED ? <p className="light-text">THIS IS A TEST ENVIRONMENT</p> : <></>}
            <div className='container'>
                {/* <div className='contained'>
                    <h3 className='dark-text mx-auto w-1/4 font-bold my-4'>Recent Events</h3>
                    <TableList columnNames={['Date','Event','User','Description']}
                    columnWidths={['20%','10%','20%','50%']}
                    entries={[]
                        // feed.map( row => {
                        //     return [
                        //         formatDate(row?.created),
                        //         row?.event_type,
                        //         row?.user,
                        //         row?.notes && row?.notes.length > 100 ? row?.notes.slice(0,100) + '...' : row?.notes,
                        //     ]
                        // })
                    }
                    />
                </div> */}
                <div className='contained'>
                    <h3 className='dark-text mx-auto font-bold my-4 text-left'>Recent Items</h3>
                    <TableListTwo columnNames={['SKU','Title','Created',' ']}
                    columnWidths={['12%','55%','18%','15%']}
                    entries={items?.map( row => {
                        return [
                            row?.sku.split('*')[0].split('new')[0],
                            row?.title,
                            //String(row?.created).substring(2,16).replace('T',' '),
                            formatDate(row?.created),
                            <Link className='btn contained dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>View</Link>
                        ]
                    })
                    }
                    />
                </div>
            </div>
        </div>
    );

}

export default Home;