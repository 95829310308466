import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import PaginatedTableList from "../components/PaginatedTableList";
import TableList from "../components/TableList";
import { formatDate, secureFetch } from "../util/flip_utils";


const TPAList = (props) => {
    let context = useContext(AppContext);
    let [searchValue, setSearchValue] = useState('');
    let [tpas, setTpas] = useState([]);

    const fetchTpas = (pageNumber, pageSize) => {
        secureFetch(context.server + '/search-tpas', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(resp => resp.json())
        .then(results => setTpas(results))
    }

    /* eslint-disable */
    useEffect(() => {
        fetchTpas(0, 20);
    },[context, searchValue])
    /* eslint-enable */


    return (
        <div style={{padding: '15px 15px'}}>
            <h1 className='light-text'>Search RMA's</h1>
            <form style={{padding: '15px'}}>
                <input autoFocus type='text' className='search-bar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            </form>
            <PaginatedTableList
            columnNames={['RMA','RMA Type','Customer','Notes','Date','Action']}
            columnWidths={['8%','7%','20%','37%','10%','18%']}
            entries={
                tpas.map( row => {
                    return [
                        row?.tpa_number,
                        row?.is_consignment == 1 ? 'Consign' : row?.is_consignment == 0 ? 'Buy' : '',
                        <Link className='btn dark-text' to={{pathname: `/customer-view/${row?.customer_id}`, state: {customer_id: row?.customer_id}}}>{row?.seller_name ? row?.seller_name : 'NO NAME'}</Link>,
                        row?.notes ? row?.notes.slice(0, 50) + '...' : 'None',
                        formatDate(row?.created),
                        <Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.id}`, state: {tpa_id: row?.id}}}>View</Link>
                    ]
                })
            }
            getData={fetchTpas}
            />
        </div>
    );
}

export default TPAList;