/**
 * ItemView.js
 * 
 * displays an overview of an item's information, and allows for editing in a modal view
 */


import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../App.css';
import AppContext from '../AppContext';
import EditItemForm from '../components/EditItemForm';
//import InfoList from '../components/InfoList.js';
import ModalContainer from '../components/ModalContainer.js';
import {formatDate, secureFetch} from '../util/flip_utils';
import KeyValuePair from '../components/KeyValuePair';
import EditProductForm from '../components/EditProductForm';
import InfoBox from '../components/InfoBox';
import SelectProductForm from '../components/SelectProductForm';
import CreateProductForm from '../components/CreateProductForm';
import ConfirmButton from '../components/ConfirmButton';
import ImageModalContainer from '../components/ImageModalContainer';
import AddNoteForm from '../components/AddNoteForm';
import PhotoItemForm from '../components/PhotoItemForm';
import ShowHideBox from '../components/ShowHideBox';
import TableList from '../components/TableList';
import EditDimensionsForm from '../components/EditDimensionsForm';
import StockItemForm from '../components/StockItemForm';
import CheckmarkYN from '../components/CheckmarkYN';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SplitQuantityForm from '../components/SplitQuantityForm.js';
import MergeQuantityForm from '../components/MergeQuantityForm.js';
import ChangeSaleTypeForm from '../components/ChangeSaleTypeForm.js';
import AddDeductionForm from '../components/AddDeductionForm.js';


const ItemView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { item_id } = useParams();
    let [overview, setOverview] = useState({});

    let [item, setItem] = useState({});
    let [product, setProduct] = useState({});

    let [fetched, setFetched] = useState(false);
    let [disableLabel, setDisableLabel] = useState(false);

    function fetchOverview() {
        setFetched(false);
        const body = {
            id: item_id ? item_id : props?.location?.state?.item_id
        }
        secureFetch(context.server + '/item-overview', { //item overview provides additional item information for this page
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify(body) 
        })
        .then(resp => resp.json())
        .then(results => {
            setOverview( results );
            setItem( {...results?.item, images: results?.images, customerBtob: results?.submission_item?.customer_btob, submissionBtob: results?.submission_item?.submission_btob } );
            setProduct( results?.product );
            setFetched( true );
        })
    }


    /* eslint-disable */
    //called when the component is mounted on the DOM
    useEffect( () => {
        fetchOverview();
    }, [])
    /* eslint-enable */


    const onSelectProduct = (prod) => {
        setFetched(false);
        secureFetch(context.server + '/update-item-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: item?.id,
                keys: ['product_id'],
                values: [prod?.id],
                message: "user reassigned item's product id"
            })
        })
        .then(() => {
            fetchOverview();
            setFetched(true);
        })
    }


    function fetchLabel() {
        setDisableLabel(true)
        secureFetch(context.server + '/create-label', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sku: item?.sku
            })
        })
        .then(resp => resp.text())
        .then(data => {
            //re-enable the button
            setDisableLabel(false);
            
            //pack up the image into a blob and create an object url
            let blob = new Blob([data], {type: 'application/pdf'})
            let url = URL.createObjectURL(blob);
            
            //create a hidden <a> tag to download the image and click it.
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = `${item?.sku}.pdf`;
            a.click();
            //take the object url back
            window.URL.revokeObjectURL(url);

            //console.log(data)
        })
    }

    function updateDimensions(dimensions) {
        secureFetch(context.server + `/update-item-dimensions`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...dimensions,
                item_id: item?.id,
                item_status_id: item?.item_status_id,
            })
        })
        .then(() => fetchOverview())
    }


    function splitQuantity(qty) {
        secureFetch(context.server + `/split-quantity`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item_id: item?.id,
                split_quantity: qty
            })
        })
        .then(res => res.json())
        .then(results => {
            history.push({pathname: `/item-view/${results?.item_id}`, state: {item_id: results?.item_id}});
            window.location.reload();
        })
    }

    function mergeQuantity(dest_id) {
        secureFetch(context.server + `/merge-quantity`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                source_id: item?.id,
                destination_id: dest_id
            })
        })
        .then(res => res.json())
        .then(results => {
            history.push({pathname: `/item-view/${results?.item_id}`, state: {item_id: results?.item_id}});
            window.location.reload();
        })
    }

    function changeSaleType({newSaleType, newBuyPrice, tradeInvolved, newConsignmentRate}) {
        secureFetch(context.server + `/change-sale-type`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item: item,
                new_sale_type_id: newSaleType,
                new_buy_price: newBuyPrice,
                trade_involved: tradeInvolved,
                new_consignment_rate: newConsignmentRate
            })
        })
        .then(() => window.location.reload())
    }


    return (
        <div style={{padding: '15px 15px'}}>
            {item ?
            <div>
                <div className='container'>
                    <h1 className='light-text contained'>{item?.title}</h1>
                    
                </div>
                <div>
                    <InfoBox title='Item Info:'>
                    <div className='container'>
                        <div className='contained'>
                            <KeyValuePair label={'SKU:'} value={item?.sku} textClass={'dark-text'}/>
                            <KeyValuePair label={'Starting Quantity:'} value={item?.quantity} textClass={'dark-text'}/>
                            <KeyValuePair label={'Item ID:'} value={item?.id} textClass={'dark-text'}/>
                            <KeyValuePair label={'Title:'} value={item?.title} textClass={'dark-text'}/>
                            <KeyValuePair label={'Sale Type:'} value={item?.sale_type} textClass={'dark-text'}/>
                            <KeyValuePair label={'Buy Price:'} value={<>{item?.display_cost}{
                                item?.sale_type_id==4 || item?.is_consignment ?
                                <>&nbsp;&nbsp;&nbsp;<ModalContainer triggerText='Add Deduction' FormComponent={AddDeductionForm} formData={item} submitForm={() => alert('deduction submitted')} /></>
                                : <></>
                            }</>} textClass={'dark-text'}/>
                            <KeyValuePair label={'List Price:'} value={item?.display_price} textClass={'dark-text'}/>
                            <KeyValuePair label={'Serial #:'} value={item?.serial_number} textClass={'dark-text'}/>
                            <KeyValuePair label={'Parts:'} value={item?.number_of_parts} textClass={'dark-text'}/>
                            <KeyValuePair label={'Condition:'} value={item?.item_condition} textClass={'dark-text'}/>
                            <KeyValuePair label={'What\'s Included:'} value={item?.what_is_included} textClass={'dark-text'}/>
                            <KeyValuePair label={'Age:'} value={item?.age_in_years_text} textClass={'dark-text'}/>
                            <KeyValuePair label={'# of Owners:'} value={item?.num_of_owners_text} textClass={'dark-text'}/>
                            {item?.prev_item_id ?
                                <KeyValuePair label={'Previous Item (returned):'} value={
                                    <button className='btn dark-text' onClick={() => {history.push({pathname: `/item-view/${item?.prev_item_id}`});window.location.reload();}}>View</button>
                                } />
                            : <></>}
                            {item?.ra_id ?
                                <KeyValuePair label={'RA:'} value={
                                    <Link className='btn dark-text' to={{pathname: `/ra-view/${item?.ra_id}`}}>{item?.ra_id}</Link>
                                } />
                            : <></>}
                            <KeyValuePair label={'Tobacco Exposure:'} value={item?.tobacco_exposure===1 ? 'Yes': 'No'} textClass={'dark-text'}/>
                            <KeyValuePair label={'Item Status:'} value={item?.display_status} textClass='dark-text' />
                            <KeyValuePair label={'BC Quantity:'} value={item?.bc_quantity} textClass={'dark-text'}/>
                            <KeyValuePair label={'eBay Only:'} value={<CheckmarkYN val={item?.ebay_only} />} textClass={'dark-text'}/>
                            <KeyValuePair label={'Date Created:'} value={formatDate(item?.created)} textClass='dark-text' />
                            <ShowHideBox showText='Status History +' hideText='Status History -' content={<div className='bg-dark rounded' style={{padding:'2px 2px'}}>
                                {/* {item?.status_history?.map(row => <p>{formatDate(row?.created)}: &emsp;{row?.prev_status}&emsp;{'->'}&emsp;{row?.new_status} by {row?.user}</p>)} */}
                                <TableList columnNames={['Prev.','New','User','Notes','Date']}
                                columnWidths={['15%','15%','30%','20%','20%']}
                                entries={item?.status_history?.map(row => {
                                    return [
                                        row?.prev_status == "Photographed" ? "Photo'd" : row?.prev_status,
                                        row?.new_status == "Photographed" ? "Photo'd" : row?.new_status,
                                        row?.user,
                                        row?.notes,
                                        formatDate(row?.created, {date:true, time:true, seconds:true})
                                    ]
                                })} />
                            </div>} />
                        </div>
                        <div className='bg-dark' style={{maxWidth: '2px'}}>'</div>{/** vertical line to separate */}
                        <div className='contained'>
                            <KeyValuePair label={'Cosmetic:'} value={item?.cosmetic_condition + ' ' + item?.cosmetic_description} textClass={'dark-text'}/>
                            <KeyValuePair label={'Weight / Dims:'} value={
                                <div>
                                    <p>Weight:&nbsp;&nbsp;{parseInt(item?.dimensions?.weight)} lbs</p>
                                    {item?.dimensions?.boxes?.map((box, ind) => 
                                        <li>{item?.has_packaging == 1 ? 'Box' : 'No Box'} {ind + 1}:&nbsp;&nbsp;{parseInt(box?.width)} x {parseInt(box?.depth)} x {parseInt(box?.height)}</li>
                                    )}
                                    <i>(dimensions in inches)</i>
                                </div>
                            }  />
                            <KeyValuePair label={'RMA Rep:'} value={overview?.tpa?.rep_name} textClass={'dark-text'}/>
                            <KeyValuePair label={'Receiving Notes:'} value={item?.receiving_notes} textClass={'dark-text'}/>
                            <KeyValuePair label={'Testing Notes:'} value={item?.tester_notes} textClass={'dark-text'}/>
                            <KeyValuePair label={'Photo Notes:'} value={item?.photo_notes} textClass={'dark-text'}/>
                            <KeyValuePair label={'Service History:'} value={item?.service_history} textClass={'dark-text'}/>
                            <KeyValuePair label={'Functional Notes:'} value={item?.functional_notes} textClass={'dark-text'}/>
                            <KeyValuePair label={'Description:'} value={<textarea  disabled rows='3' cols='50' value={item?.description || ''}/>} textClass={'dark-text'}/>
                            <KeyValuePair label={'Consignment?:'} value={item?.is_consignment===1 ? 'Yes' : 'No'} textClass={'dark-text'}/>
                            <KeyValuePair label={'RMA:'} 
                            value={item?.tpa_id ? <Link className='btn dark-text'
                            to={{pathname: `/tpa-view/${item?.tpa_id}`, state: {tpa_id: item?.tpa_id}}}>{overview?.tpa?.tpa_number ? overview?.tpa?.tpa_number : 'View'}</Link>
                            :<button disabled className='btn dark-text'>No RMA</button>} 
                            textClass={'dark-text'}/>
                            <KeyValuePair label={'Appraisal:'}
                            value={overview?.submission_item?.id && item?.current_quote?.id ? <Link className='btn dark-text'
                            to={{pathname: `/appraise-item/${overview?.submission_item?.id}`, state: {item_id: overview?.submission_item?.id}}}>{'Appraised by '+item?.current_quote?.appraiser}</Link>
                            :<button disabled className='btn dark-text'>No Appraisal</button>} 
                            textClass={'dark-text'}/>
                            {item?.appraising_notes ? 
                            <KeyValuePair label={'Appraiser Notes:'} value={item?.appraising_notes} textClass='dark-text' />
                            :
                            <></>
                            }
                            {item?.bc_product_id ? 
                            <KeyValuePair label='BigCommerce:' value={
                                <a className='btn dark-text' href={'https://store-6naz0isypk.mybigcommerce.com/manage/products/search-results?query=' + String(item?.bc_product_id)} target='_blank' rel='noreferrer'>{item?.bc_product_id}</a>
                            } textClass='dark-text' />
                            :
                            <></>
                            }
                            {item?.fd_ticket_id ? 
                            <KeyValuePair label={'Freshdesk Ticket:'} value={<a className='btn dark-text' rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${item?.fd_ticket_id}`}>Go to Ticket</a>}/>
                            :
                            <></>
                            }

                        </div>
                    </div>
                    </InfoBox>
                </div>
                <ModalContainer triggerText={'Edit Item'} submitForm={(event)=>{fetchOverview(); if(event?.alert){alert(event?.alert)};}} formData={item} FormComponent={EditItemForm} />&nbsp;&nbsp;
                
                {item?.status_history && item?.status_history.find(e=>e.notes.includes('test-item')) ?
                    <Link className='btn dark-text center bg-green' to={{pathname: `/testing/${item?.id}`, state: {item_id: item?.id}}}>Test Item</Link>
                    :<Link className='btn dark-text center' to={{pathname: `/testing/${item?.id}`, state: {item_id: item?.id}}}>Test Item</Link>
                }&nbsp;&nbsp;

                {item?.item_status_id > 3 && item?.item_status_id < 8 ? 
                        <ModalContainer buttonClass='btn bg-green light-text' triggerText="Photo Item (already photo'd)" submitForm={() => {fetchOverview()}}  formData={item} FormComponent={PhotoItemForm} />
                    : item?.item_status_id == 3 ?
                        <ModalContainer triggerText='Photo Item' submitForm={() => {fetchOverview()}}  formData={item} FormComponent={PhotoItemForm} />
                    : 
                        <button disabled className='btn bg-red light-text'>Photo Item<br/><i>(THIS ITEM IS NOT CLEARED YET)</i></button>}&nbsp;&nbsp;
                {/* <Link className='btn dark-text center' to={{pathname: '/stocking', state: {item_id: item?.id}}}>Stock Item</Link>&nbsp;&nbsp; */}
                
                {item?.submissionBtob || item?.customerBtob ? <b className='blue-text'>B2B &nbsp;</b> : <></>}

                {item?.status_history && item?.status_history.find(e=>e.notes.includes('stock-item')) ?
                    <ModalContainer buttonClass='btn bg-green light-text' formData={item} FormComponent={StockItemForm} triggerText='Stock Item' submitForm={updateDimensions} />
                    : item?.status_history && item?.status_history.find(e=>e.new_status_id == 4) ?
                        <ModalContainer formData={item} FormComponent={StockItemForm} triggerText='Stock Item' submitForm={updateDimensions} />
                    : 
                        <button disabled className='btn bg-red light-text'>Stock Item<br/><i>(THIS ITEM IS NOT PHOTOED YET)</i></button>
                }&nbsp;&nbsp;
                    
                <br/><br/>
                {item?.quantity > 1 ? <ModalContainer buttonClass="btn bg-blue dark-text" formData={item} FormComponent={SplitQuantityForm} triggerText="Split Quantity" submitForm={qty => splitQuantity(qty)} /> : <></>}
                <ModalContainer buttonClass="btn bg-blue dark-text" formData={item} FormComponent={MergeQuantityForm} triggerText="Merge Quantity to Another Item" submitForm={id => mergeQuantity(id)} />
                <br/><br/>
                <ModalContainer buttonClass="btn bg-blue dark-text" formData={overview} FormComponent={ChangeSaleTypeForm} triggerText="Change Sale Type/BP" submitForm={changeSaleType} />
                
                <div className='container'>
                    <div className='contained'>
                        <h3 className='light-text'>Image:</h3>
                        <div style={{padding: '10px'}}>
                        <ImageModalContainer source={item?.images?.length > 0 ? item?.images[0]?.photo_link : ''} alter={item?.title}
                        styleprops={{height: '400px'}} />
                        </div>
                        <Link className='btn dark-text' to={{pathname: `/item-images/${item?.id}`, state: {item: item}}} >View Images</Link>
                        <button disabled={disableLabel ? 'disabled' : ''} onClick={fetchLabel} className='btn bg-light'>{disableLabel ? 'Thinking...' : 'Create Label'}</button>
                    </div>
                    <div className='contained'>
                        <InfoBox title='Product Info:'>
                            {product?.id ?
                            <>
                            <div className='container'>
                                <div className='contained'>
                                    <KeyValuePair label={'Title:'} value={product?.title} textClass={'dark-text'}/>
                                    <KeyValuePair label={'Brand:'} value={product?.brand} textClass={'dark-text'}/>
                                    <KeyValuePair label={'Model:'} value={product?.model} textClass={'dark-text'}/>
                                    {product?.categories?.length > 0 ?
                                        product?.categories?.map( (row, ind) => 
                                            <KeyValuePair label={ind==0 ? 'Categories:' : ' '} value={row?.category + '/' + row?.subcategory} textClass={'dark-text'} />
                                        )
                                    :
                                    <></>
                                    }
                                </div>
                                <div className='contained'>
                                    <p className='dark-text'><b>{'Blurb:'}</b></p>
                                    <p className='dark-text'>{product?.description}</p>
                                    
                                </div>
                            </div>
                            </>
                            :
                            <h2 className='dark-text'>NO PRODUCT SELECTED</h2>
                            }
                            <div className='container'>
                                <ModalContainer triggerText={'Select Product'} submitForm={onSelectProduct}
                                formData={item} FormComponent={SelectProductForm} />

                                {product?.id ?
                                <ModalContainer className='contained' triggerText={'Edit Product'} submitForm={fetchOverview}
                                formData={product} FormComponent={EditProductForm} />
                                :
                                <ModalContainer className='contained' triggerText={'New Product'} submitForm={onSelectProduct}
                                formData={item} FormComponent={CreateProductForm} />
                                }

                                {product?.id ? <Link className='contained btn dark-text' to={{pathname: `/product-view/${product?.id}`, state: {product_id: product?.id}}}>View Product</Link>
                                :<></>}
                            </div>
                        </InfoBox>
                    </div>
                </div>
            </div>
            : fetched ?
            <div>
                <h1 className='light-text'>No Info Available</h1>
                <p className='light-text'>This page is either loading or unavailable.</p>
                <p className='light-text'>If the problem persists, return to Search Items,
                search for this item, and click 'View' to get an overview of the item.</p>
            </div>
            : <h1 className='light-text'>Loading Overview...</h1>
            }
        </div>
    );
}

export default ItemView;

