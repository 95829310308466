/**
 * AddRmaItemForm.js
 * 
 * a form subcomponent which is used in the context of a modal view to add single items to pre-existing RMAs.
 */


import React, { useContext, useEffect, useState } from 'react';
import { secureFetch } from '../util/flip_utils';
import AppContext from '../AppContext';
import TableList from './TableList';
import { Link } from 'react-router-dom';



const AddRmaItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [item, setItem]= useState({quantity:1});
    let [tpa, setTpa]= useState([]);
    let [productId, setProductId] = useState()
    let [cosmetics, setCosmetics] = useState([]);
    let [conditions, setConditions] = useState([]);
    let [ageOptions, setAgeOptions] = useState([]);
    let [numOwnersOptions, setNumOwnersOptions] = useState([]);
    let [disableForm, setDisableForm] = useState(false);

    // Variables from Select Product Form
    let [products, setProducts] = useState([]);
    let [searchValue, setSearchValue] = useState('');
    let [mergeDestinationId, setMergeDestinationId] = useState("");
    
    function fetchProducts() {
        secureFetch(context.server + '/search-products', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({query: searchValue})
        })
        .then(resp => resp.json())
        .then(products => {
            setProducts(products.map(p => {return {...p, mergeIsOpen: false}}))
        })
    }



    function setMergeIsOpen(ind) {
        let newProducts = products.map(p => p);
        newProducts[ind].mergeIsOpen = !newProducts[ind].mergeIsOpen;
        setProducts(newProducts);
    }

    function mergeIsOpen(ind) {
        return products[ind].mergeIsOpen;
    }

    function mergeProducts(sourceId, destinationId) {
        //alert(`${sourceId} ${destinationId}`);
        secureFetch(context.server + '/merge-products', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sourceId: sourceId,
                destinationId: destinationId
            })
        })
        .then(() => {
            alert("merge successful");
            fetchProducts();
        })
    }


    const fetchFields = () => {
        //get Conditions
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'cosmetic_conditions'})
        })
        .then (resp => resp.json())
        .then(results => {
            setCosmetics(results);
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({tableName: 'item_conditions'})
            })
            .then(resp => resp.json())
            .then(results => setConditions(results))
        })

        //get owners lookups
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'number_of_owners_lkp'})
        })
        .then(resp => resp.json())
        .then(results => setNumOwnersOptions(results?.map(row => <option value={row?.id}>{row?.text}</option>)));

        //get age lookups
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'age_in_years_lkp'})
        })
        .then(resp => resp.json())
        .then(results => setAgeOptions(results?.map(row => <option value={row?.id}>{row?.text}</option>)));
    }

    useEffect(() => {
        fetchFields();
    }, [])

    useEffect(() => {
        setTpa(formData)
        fetchProducts();
    }, [context,searchValue, item])

    //submit is called when the submit button is pushed. it has its own functionality, and also calls the props onSubmit and closeModal
    const submit = ( e ) => {
        setDisableForm(true);
        e.preventDefault();
        
        //need to remove the items to prevent Payload too large error
        delete tpa.items;
        if(tpa?.is_consignment) delete tpa.payments;
        // alert(JSON.stringify(tpa))
        secureFetch(context.server + '/add-rma-item', {
            method: 'POST',
            body: JSON.stringify({item: item, tpa: tpa}),
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        .then(res => res.json())
        .then(data => {
            submitForm(data);
            closeModal()
            ;
        })
        
    }

    //handles when the cosmetic condition dropdown is changed
    function cosmeticChangeHandler(event) {
        setItem({...item, cosmetic_condition_id: event.target.value});
    }

    //handles when the item condition dropdown is changed
    function conditionChangeHandler (event) {
        setItem({...item, item_condition_id: event.target.value});
    }

    //returns the form component to be rendered
    return (
        <div>
            <form id='AddRmaItemForm' onSubmit={submit} />
            <fieldset form='AddRmaItemForm' disabled={disableForm ? 'disabled' : ''}>
            {!productId ? 
                <div>
                    <h1 className='dark-text'>To add an item, a product must be selected:</h1>
                    <input autoFocus type='text' className="search-bar" onChange={ev => setSearchValue(ev.target.value)} value={searchValue}/>
                    <br/><br/>
                    <TableList columnNames={[' ','ID','Title','Brand', 'Model',' ',' ']}
                    columnWidths={['10%','8%','30%','12%','20%','10%','10%']}
                    entries={
                        products.map( (row, ind) => {
                            return [
                                <div>
                                    <button className='btn dark-text' onClick={() => setMergeIsOpen(ind)}>Merge</button>
                                    {mergeIsOpen(ind) ? 
                                    <div>
                                        <label className='dark-text text-left'>Destination ID</label>
                                        <input value={mergeDestinationId} onChange={e => setMergeDestinationId(e.target.value)} />
                                        <button className='btn bg-red light-text' onClick={() => mergeProducts(row?.id, mergeDestinationId)}>Do Merge</button>
                                    </div>
                                    : <></>}
                                </div>,
                                row?.id,
                                row?.title,
                                row?.brand,
                                row?.model,
                                <Link onClick={closeModal} className='btn dark-text' to={{pathname: `/product-view/${row?.id}`, state: {product_id: row?.id}}}>View</Link>,
                                <button className='btn dark-text' onClick={() => {setProductId(row?.id); setItem({...item, title: row?.title, description: row?.description, product_id: row?.id})}}>Select</button>,
                            ]
                        })
                    }
                    />
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                </div>
            : <>
            
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h3>{item?.title}</h3>
                    <br></br>
                    <div className='container'>
                        <label className='dark-text'>Title:&nbsp;</label>
                        <input form='AddRmaItemForm' required className='' type='text' size='100' value={item?.title ? item?.title : ''} onChange={ (e) => {setItem({...item, title: e.target.value})}} />
                    </div>
                    <div className='contained'>
                            <label className='dark-text text-left'>Description (Taken from selected product):</label>
                            <textarea disabled form='AddRmaItemForm' rows='4' cols='80' value={item?.description ? item?.description : ''} onChange={ (e) => setItem({...item, description: e.target.value})}/>
                    </div>
                    <div className='container'> 
                        <label className='dark-text'>Quantity:&nbsp;</label>
                        <input form='AddRmaItemForm' type='number' value={item?.quantity} onChange={ (e) => setItem({...item, quantity: e.target.value})}/>
                    </div>
                    <div className='container'> 
                        <label className='dark-text'>*Buy Price:&nbsp;</label>
                        {tpa?.is_consignment == 1 ? 
                        <>&nbsp;This is a consignment RMA</>
                        : <input form='AddRmaItemForm' required = {tpa?.is_consignment == 1 ? false:true} className='' type='number' disabled = {item?.item_type == 'new' ? true:false} value={item?.purchase_amount} onChange={ (e) => {setItem({...item, purchase_amount: e.target.value})}}/>}
                    </div>
                    <div className='container'>
                        <label className=' dark-text'>List Price:&nbsp;</label>
                        <input form='AddRmaItemForm' className='' type='number' value={item?.list_price ? item?.list_price : ''} onChange={ (e) => {setItem({...item, list_price: e.target.value})}}/>
                    </div>
                    <div className='container'>
                        <label className=''>*Cosmetic:&nbsp;</label>
                        <select required form='AddRmaItemForm' onChange={ cosmeticChangeHandler } value={item?.cosmetic_condition_id}>
                            <option value='' key={0}>*** SELECT COSMETIC CONDITION ***</option>
                            {cosmetics.map( (cos) => 
                                <option key={cos?.id} value={cos?.id} >{cos?.cosmetic_condition}</option>
                            )}
                        </select>
                    </div>
                    <div className='container'>
                        <label className=''>*Condition:&nbsp;</label>
                        <select required form='AddRmaItemForm' onChange={ conditionChangeHandler } value={item?.item_condition_id}>
                            <option value='' key={0}>*** SELECT ITEM CONDITION ***</option>
                            {conditions.map( (con) => 
                                <option key={con?.id} value={con?.id} >{con?.item_condition}</option>
                            )}
                        </select>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>*What is Included:</label>
                            <textarea form='AddRmaItemForm' required rows='8' cols='50' value={item?.what_is_included ? item?.what_is_included : ''} onChange={ (e) => setItem({...item, what_is_included: e.target.value})}/>
                        </div>
                        <div className='contained'>
                            <label className='dark-text text-left'>Cosmetic Description:</label>
                            <textarea form='AddRmaItemForm' rows='8' cols='50' value={item?.cosmetic_description ? item?.cosmetic_description : ''} onChange={ (e) => setItem({...item, cosmetic_description: e.target.value})}/>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='contained'>
                            <label className='dark-text text-left'>Age:&nbsp;&nbsp;
                            <select form="AddRmaItemForm" onChange={e => setItem({...item, age_in_years: e.target.value})} value={item?.age_in_years}>
                                {ageOptions}
                            </select>
                            </label><br/>
                            <label className='dark-text text-left'>Owners:&nbsp;&nbsp;
                            <select form="AddRmaItemForm" onChange={e => setItem({...item, num_of_owners: e.target.value})} value={item?.num_of_owners}>
                                {numOwnersOptions}
                            </select>
                            </label><br/>
                            <label className='dark-text text-left'>SN:&nbsp;&nbsp;
                            <input form='AddRmaItemForm' type='text' value={item?.serial_number ? item?.serial_number : ''} onChange={ (e) => setItem({...item, serial_number: e.target.value})}/>
                            </label><br/>
                        </div>
                    </div>
                    <div className='container'>
                        <label className='contained dark-text text-left'>Tobacco:&nbsp;&nbsp;
                            <input form='AddRmaItemForm' type='checkbox' checked={item?.tobacco_exposure===1} onChange={ (e) => setItem({...item, tobacco_exposure: e.target.checked ? 1 : 0})}/>
                        </label>
                    </div>
                    <div className='container'>
                        <label className='contained dark-text text-left'>eBay Only?:&nbsp;&nbsp;
                                <input form='AddRmaItemForm'  type='checkbox' checked={item?.ebay_only===1} onChange={ (e) => setItem({...item, ebay_only: e.target.checked ? 1 : 0})}/>
                        </label>
                    </div>
                    <br/>
                    <input form='AddRmaItemForm' className='btn bg-dark light-text' type='submit' />
                </div>
            </>}
            </fieldset>
        </div>
    );
};

export default AddRmaItemForm;