import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { secureFetch } from "../util/flip_utils";
import AppContext from "../AppContext";

const Auth0LoginButton = () => {
    let context = useContext(AppContext);
    const { loginWithPopup } = useAuth0();

    async function fullLogin() {
        await loginWithPopup();
        window.location.reload();
    }

    return <button className='btn bg-blue dark-text' onClick={() => fullLogin()}>Log In</button>;
};

export default Auth0LoginButton;