import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import AppContext from "../AppContext"
import EditItemOptionsForm from "../components/EditItemOptionsForm";
import EditListingForm from "../components/EditListingForm";
import ImageArranger from "../components/ImageArranger";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import { formatDate, secureFetch } from "../util/flip_utils";
import { CosmeticColorCoder, RatingColorCoder } from '../components/ColorCoders';
import TableList from "../components/TableList";
import InfoButton from "../components/infoButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CheckmarkYN from "../components/CheckmarkYN";
import FlipRTV from "../components/FlipRTV";
import EditItemForm from "../components/EditItemForm";
import ShowHideBox from "../components/ShowHideBox";
import StockItemForm from "../components/StockItemForm";






const SHIPPING_PRESET_OPTIONS = [
    <option></option>,
    <option value={7}>7</option>,
    <option value={9}>9</option>,
    <option value={12}>12</option>,
    <option value={15}>15</option>,
    <option value={19}>19</option>,
    <option value={23}>23</option>,
    <option value={27}>27</option>,
    <option value={37}>37</option>,
    <option value={55}>55</option>,
    <option value={65}>65</option>,
    <option value={80}>80</option>,
    <option value={85}>85</option>,
    <option value={99}>99</option>,
    <option value={105}>105</option>,
    <option value={135}>135</option>,
    <option value={149}>149</option>,
    <option value={179}>179</option>,
    <option value={199}>199</option>,
    <option value={219}>219</option>,
    <option value={249}>249</option>,
    <option value={279}>279</option>,
    <option value={299}>299</option>,
    <option value={399}>399</option>,
    <option value={499}>499</option>,
    <option value={599}>599</option>,
    <option value={699}>699</option>,
    <option value={799}>799</option>,
    <option value={899}>899</option>,
    <option value={999}>999</option>,
]

const AG_CATEGORY_OPTIONS = [
    <option value={null}></option>,
    <option value={78}>Accessories - D.I.Y. & Repair=78</option>,
    <option value={14}>Accessories - Equalizers=14</option>,
    <option value={12}>Accessories - Tubes=12</option>,
    <option value={24}>Accessories - Tweaks=24</option>,
    <option value={33}>Amplifiers & Receivers - Multi-Channel Amps=33</option>,
    <option value={43}>Amplifiers & Receivers - Receivers=43</option>,
    <option value={49}>Amplifiers & Receivers - Solid state=49</option>,
    <option value={17}>Amplifiers & Receivers - Tube=17</option>,
    <option value={34}>Analog - Tape decks=34</option>,
    <option value={48}>Analog - Tuners=48</option>,
    <option value={1}>Cables - AC Cables=1</option>,
    <option value={128}>Cables - Cable Management=128</option>,
    <option value={3}>Cables - Digital=3</option>,
    <option value={129}>Cables - Headphone=129</option>,
    <option value={35}>Cables - Interconnects=35</option>,
    <option value={42}>Cables - Speaker=42</option>,
    <option value={75}>Computer Audio - Media Servers=75</option>,
    <option value={77}>Computer Audio - Parts=77</option>,
    <option value={76}>Computer Audio - Sound Cards=76</option>,
    <option value={74}>Computer Audio - Speakers=74</option>,
    <option value={130}>DA Converters - Computer/USB=130</option>,
    <option value={37}>DA Converters - DA Converters=37</option>,
    <option value={131}>DA Converters - Portable=131</option>,
    <option value={132}>DA Converters - Wireless=132</option>,
    <option value={19}>Digital - CD/SACD Players=19</option>,
    <option value={133}>Digital - Music Servers & Docks=133</option>,
    <option value={45}>Digital - Transports=45</option>,
    <option value={39}>Furniture - Cabinets, Racks & Stands=39</option>,
    <option value={134}>Furniture - Home Theater Seating=134</option>,
    <option value={23}>Furniture - Room Treatments=23</option>,
    <option value={135}>Furniture - Speaker Stands & Mounts=135</option>,
    <option value={136}>Furniture - TV Wall Mounts=136</option>,
    <option value={72}>Headphones - Headphone Amps/DACs=72</option>,
    <option value={70}>Headphones - In-Ear=70</option>,
    <option value={137}>Headphones - On-Ear=137</option>,
    <option value={71}>Headphones - Over-Ear=71</option>,
    <option value={100}>Home Theater - Blu-ray/DVD/Universal Players=100</option>,
    <option value={21}>Home Theater - Processors=21</option>,
    <option value={95}>Home Theater - Projectors=95</option>,
    <option value={96}>Home Theater - Screens=96</option>,
    <option value={97}>Home Theater - Televisions=97</option>,
    <option value={28}>Integrateds - Solid state=28</option>,
    <option value={38}>Integrateds - Tube=38</option>,
    <option value={47}>Power - AC Conditioners=47</option>,
    <option value={32}>Power - AC Distribution=32</option>,
    <option value={25}>Preamplifiers - Phono=25</option>,
    <option value={46}>Preamplifiers - Solid state=46</option>,
    <option value={31}>Preamplifiers - Tube=31</option>,
    <option value={145}>Pro Audio - 500 Series=145</option>,
    <option value={144}>Pro Audio - Accessories=144</option>,
    <option value={141}>Pro Audio - Interfaces=141</option>,
    <option value={138}>Pro Audio - Microphones=138</option>,
    <option value={142}>Pro Audio - Mixers=142</option>,
    <option value={140}>Pro Audio - Outboard Gear=140</option>,
    <option value={147}>Pro Audio - PA Systems=147</option>,
    <option value={148}>Pro Audio - Patchbays=148</option>,
    <option value={146}>Pro Audio - Power Amps=146</option>,
    <option value={143}>Pro Audio - Recording=143</option>,
    <option value={139}>Pro Audio - Speakers"=139</option>,
    <option value={149}>Smart Home - Audio=149</option>,
    <option value={150}>Smart Home - Controls=150</option>,
    <option value={151}>Smart Home - Home Automation Accessories=151</option>,
    <option value={101}>Speakers - Bluetooth/Wireless=101</option>,
    <option value={16}>Speakers - Full-Range=16</option>,
    <option value={152}>Speakers - Home Theater=152</option>,
    <option value={153}>Speakers - In-Ceiling/Wall=153</option>,
    <option value={9}>Speakers - Monitors=9</option>,
    <option value={41}>Speakers - Planars=41</option>,
    <option value={99}>Speakers - Soundbars=99</option>,
    <option value={30}>Speakers - Subwoofers=30</option>,
    <option value={121}>Systems & Bundles - Home Theater=121</option>,
    <option value={122}>Systems & Bundles - Listening Room=122</option>,
    <option value={44}>Turntables - Cartridges=44</option>,
    <option value={154}>Turntables - Cleaning Systems=154</option>,
    <option value={80}>Turntables - Stylus=80</option>,
    <option value={7}>Turntables - Tonearms=7</option>,
    <option value={36}>Turntables - Turntables=36</option>,
    <option value={91}>Vintage - Amplifiers=91</option>,
    <option value={86}>Vintage - Audio Players=86</option>,
    <option value={93}>Vintage - Parts=93</option>,
    <option value={92}>Vintage - Pre-Amplifiers=92</option>,
    <option value={89}>Vintage - Receivers=89</option>,
    <option value={155}>Vintage - Reel-to-Reel=155</option>,
    <option value={90}>Vintage - Speakers=90</option>,
    <option value={88}>Vintage - Tuners=88</option>,
    <option value={87}>Vintage - Turntables=87</option>,
]

const editable_fields = [ //for getSafeVersion
    'id',
    'sku',
    'title',
    'serial_number',
    'number_of_parts',
    'cosmetic_condition_id',
    'cosmetic_description',
    'item_condition_id',
    'service_history',
    'description',
    'what_is_included',
    'functional_notes',
    'age_in_years',
    'num_of_owners',
    'tobacco_exposure',
    'list_price',
    'purchase_amount',
    'active',
    'weight',
    'width',
    'depth',
    'height',
    'item_status_id',
    'sale_type_id',
    'warranty_type_id',
    'is_accessory',
    'ebay_only',
    'receiving_notes',
    'shipping_cost'
]

//returns a copy of the item which only contains info for fields that are allowed to be edited ^^^
function getSafeVersion(item) {
    let safe_item = {}
    editable_fields.forEach((key) => {
        safe_item[`${key}`] = item[`${key}`];
    })

    return safe_item;
}

const ListItem = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { item_id } = useParams();
    let [item, setItem] = useState({});
    let [isCable, setIsCable] = useState()
    let [cableMsrp, setCableMsrp] = useState();
    let [uneditedItem, setUneditedItem] = useState({});
    let [overview, setOverview] = useState({});
    let [tpa, setTpa] = useState({});
    let [sendToTester, setSendToTester] = useState(0);
    let [product, setProduct] = useState({});
    let [classifications, setClassifications] = useState([]);
    let [listedItems, setListedItems] = useState([]);
    let [listingOptions, setListingOptions] = useState({
        badge: 'None',
        subtext: 'Certified Pre-Owned',
        price_drop_rate: 1,
        hideOnBc: 0,
        noAudiogon: 0,
        agCategory: null,
        noEbay: 0,
        specials: 0,
        featured: 0,
    });
    let [finaleUpdate, setFinaleUpdate] = useState(false)
    let [submitted, setSubmitted] = useState(false)

    


    function fetchOverview() {
        secureFetch(context.server + '/item-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: item_id || props?.location?.state?.item_id
            })
        })
        .then(res => res.json())
        .then(result => {
            setItem(result?.item)
            setOverview(result);            
            setUneditedItem(result?.item);
            if(result?.item?.tpa_id) {
                secureFetch(context.server + '/tpa-overview', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id: result?.item?.tpa_id
                    })
                })
                .then(res => res.json())
                .then(tpaResult => setTpa(tpaResult?.tpa))
            }

            secureFetch(context.server + '/product-overview', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: result?.item?.product_id
                })
            })
            .then(res => res.json())
            .then(prod => {
                //pull out brand notes
                setProduct({...prod?.product, brand_notes: prod?.brand?.notes})
                setClassifications(prod?.classifications)
                if(prod?.classifications.some(p => p.classification_id == 21 || (p.classification_id >= 27 && p.classification_id <= 30))) {
                    setIsCable(true)
                }               
                // return product?.listed_items
            })

            //Get info about currently listed items associated with the product.
            secureFetch(context.server + '/currently-listed', {
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({items: [result?.item]})
            })
            .then(response => response.json())
            .then(data => {
                setListedItems(data?.[0]?.listings);
            })       

            secureFetch(context.server + '/item-options-overview', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({itemId: result?.item?.id})
            })
            .then(resp => resp?.json())
            .then(optsResponse => {
                let itemOptions = optsResponse?.itemOptions?.map(opt => {
                    //get name of option set
                    let optionSetNameIdx = optsResponse?.possibleOptions?.map(o=>o?.id)?.indexOf(opt?.option_set_id);
                    //get name of selected option
                    let optionNameIdx = -1
                    if(optionSetNameIdx >= 0 && opt?.value == null) {
                        optionNameIdx = optsResponse?.possibleOptions[optionSetNameIdx]?.options?.map(o=>o?.id)?.indexOf(opt?.option_id)
                    }
                    //add both to option object + return to map
                    return {
                        ...opt,
                        // optionSetNameIdx: optionSetNameIdx,
                        // optionNameIdx: optionNameIdx,
                        option_set_name: optionSetNameIdx >= 0 ? optsResponse?.possibleOptions[optionSetNameIdx]?.name : 'Unknown',
                        option_name: optionNameIdx >= 0 ? optsResponse?.possibleOptions[optionSetNameIdx]?.options[optionNameIdx]?.name : 'Unknown'
                    }
                })
                // alert(JSON.stringify(itemOptions) + "/n/n" + JSON.stringify(optsResponse))

                setUneditedItem({...result?.item, options: itemOptions})
            })
        })  
    }


    useEffect(() => {
        fetchOverview();
    }, []);



    //set listing options if item is marked as ebay only
    useEffect(() => {
        if(item?.ebay_only) {
            setListingOptions({...listingOptions, noEbay: 0, hideOnBc: 1, noAudiogon: 1})
        }
    }, [item?.ebay_only])


    //listing option logic to auto-select things
    useEffect(() => {
        let opts = {...listingOptions}; //nifty copy trick to break reference to stateful object
        //if badge = None, add to specials
        //if badge = OB, closeout, or on sale, feature on BC
        if(opts?.badge == 'None') {
            opts.specials = false
            opts.subtext = "Certified Pre-Owned"
        } else {
            if(opts?.badge == 'Open Box' || opts?.badge == 'Closeout' || opts?.badge == 'On Sale')
                opts.subtext = "Factory Authorized"
            else //only gets here if badge = "Demo"
                opts.subtext = "Certified Pre-Owned"

            opts.specials = true
        }

        if(opts?.badge == 'Open Box') {
            opts.noEbay = 1
            opts.noAudiogon = 1
        }


        setListingOptions(opts)
    }, [listingOptions?.badge])

    useEffect(() => {
        let opts = {...listingOptions};
        if(opts?.hideOnBc==1) {
            opts.noAudiogon=1
        }

        setListingOptions(opts)
    }, [listingOptions?.hideOnBc])


    //submit options changes to db when form is submitted
    function updateItemOptions(opts) {
        secureFetch(context.server + '/update-item-options', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_id: item?.id,
                options: opts
            })
        })
        //.then(() => fetchOverview())
    }

    function pullPhotos() {
        secureFetch(context.server + '/fetch-item-photos', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item: item
            })
        })
        .then(() => {
            window.location.reload();
        })
    }


    function submitListing(event) {
        //Alert user if cable msrp has not been populated
        if(isCable && cableMsrp == null) {
            alert('MSRP must be inputted manually for cables')
            return;
        }

        //bounce if LP is null
        if(!item?.list_price) {
            alert('List Price required for listing. Please enter one below.')
            return;
        }

        //bounce if no shipping cost
        if(!item?.shipping_cost) {
            alert('Shipping cost required for listing. Please enter one below.')
            return;
        }

        //bounce if no image order submitted
        if(!(item?.images?.length > 0)) {
            alert('Photos required for listing. Please submit an image sequence below.')
            return;
        }

        setSubmitted(true)
        
        //if images were OK, submit the current order (in case they forgot to do so)
        secureFetch(context.server + '/update-photos', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({images: item?.images})
        })
        .catch(()=>{})

        //if everything is OK, update item dimensions to match what was edited here
        secureFetch(context.server + `/update-item-dimensions`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...item?.dimensions,
                item_id: item?.id,
                item_status_id: item?.item_status_id, //this status should be fine, this endpoint only does status change if it is "Cleared" or "Photod", it should never include a status change
                has_packaging: item?.has_packaging
            })
        })
        .catch(()=>{})
        
        let msrp = null
        if(isCable && cableMsrp > 0){
            msrp = cableMsrp
        }else if(overview?.product?.msrp && !isCable){
            msrp = overview?.product?.msrp
        }

        //Update item to reflect any changes made
        let safe_item = getSafeVersion(item);
        
        secureFetch(context.server + '/edit-item', {
            method: 'POST',
            body: JSON.stringify({item: safe_item, finaleUpdate: finaleUpdate}),
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            credentials: 'include'
        })
        .then(res => res.json())
        
        //Submit listing
        event.preventDefault();
        secureFetch(context.server + '/submit-listing', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payload: {
                    ...item,
                    //tack on images from list, but remove unnecessary fields and anything thats not in the listing
                    images: item?.images?.map(i => {
                        return {link: i?.photo_link, sequence_number: i?.sequence_number}
                    })?.filter(i => i?.sequence_number >= 0),
                    msrp: msrp,
                    category: overview?.product?.classifications?.map(row => row?.supertype + ' | ' + row?.name),
                    brand_name: overview?.product?.brand,
                    specs: overview?.product?.specs_link,
                    manual: overview?.product?.manual_link,
                    review1: overview?.product?.review_link_1,
                    review2: overview?.product?.review_link_2,
                    review3: overview?.product?.review_link_3,
                    listingOptions: listingOptions},       
                uneditedItem: uneditedItem,
                updatedItem: item,
                sendToTester: sendToTester
            })
        }).then(() => {
            history.push({pathname: '/listing-queue', state: {}})
            window.location.reload();
        })
    }

    return (
        <div>
            {item ? 
                <div style={{padding: '15px 15px'}}>
                    <br/>
                    <Link className='btn dark-text' to={{pathname: '/listing-queue'}}>Back to Queue</Link>
                    <div className='container'>
                        <div className='contained' style={{maxWidth: '50%'}}>
                            {overview?.submission_item?.id ? 
                                <InfoBox title='Submission Information:'>
                                    <br/>
                                    <KeyValuePair label={'Product Title - Customer:'} value={overview?.submission_item?.manufacturer + " " + overview?.submission_item?.model_number} textClass={'dark-text'} />
                                    <KeyValuePair label={'Product Title - TMR:'} value={
                                        <Link className='btn dark-text' to={{pathname: `/product-view/${overview?.product?.id}`, state: {product_id: overview?.product?.id}}}>{overview?.product?.title}</Link>
                                    } textClass={'dark-text'} />
                                    <KeyValuePair label={'Brand Partner:'} value={overview?.product?.brand_partner ? <p className = 'green-text'>True</p> : <p className = 'red-text'>False</p> } textClass={'dark-text'}/>
                                    <KeyValuePair label='Product Options:' value={overview?.product?.options} textClass='dark-text' />
                                    <KeyValuePair label={'Product Shipping Notes:'} value={overview?.product?.shipping_notes} textClass='dark-text' />
                                    <KeyValuePair label={'Brand-level Notes:'} value={
                                        <p className="blue-text">{product?.brand_notes || '-'}</p>} textClass='dark-text' />
                                    <br/>
                                    <KeyValuePair label={'Category-level Notes:'} value={
                                    classifications.every((c)=>c.notes == null)?
                                        <>-</>
                                        :
                                        classifications.map( (row) =>
                                            row.notes? 
                                                <div className='blue-text'>
                                                    <u >{row?.name+' Notes:'}</u><p>{row?.notes}</p>
                                                </div>
                                            :<></>
                                    )} textClass='dark-text'/> 
                                    <KeyValuePair label={'Age:'} value={overview?.submission_item?.actual_age_of_item == -1 ? "Unknown"
                                    : overview?.submission_item?.actual_age_of_item == null ? '-'
                                    : overview?.submission_item?.actual_age_of_item == 0 ? "Less than 1 year"
                                    : overview?.submission_item?.actual_age_of_item + ' Year(s)'} textClass='dark-text' />
                                    <KeyValuePair label={'Number of Owners:'} value={overview?.submission_item?.number_of_owners == -1 ? 'Unknown'
                                    : overview?.submission_item?.number_of_owners == null ? "-"
                                    : overview?.submission_item?.number_of_owners >= 4 ? "4 or more" : overview?.submission_item?.number_of_owners} textClass='dark-text' />
                                    <KeyValuePair label={'Cosmetic Condition:'} value={<CosmeticColorCoder content={overview?.submission_item?.cosmetic_condition == 11 ? null : (overview?.submission_item?.cosmetic_condition || 'N/A')} cosmetic={overview?.submission_item?.cosmetic_condition } />} textClass='dark-text' />
                                    <KeyValuePair label={'Tobacco:'} value={overview?.submission_item?.tobacco_exposure===1 ? 'Yes' : 'No'} textClass='dark-text' />
                                    <KeyValuePair label={'What Is Included:'} value= {String(overview?.submission_item?.what_is_included).split(',').join(', ')} textClass={'dark-text'} />
                                    <KeyValuePair label={'MSRP (from customer):'} value={`$${overview?.submission_item?.original_msrp}`} textClass='dark-text' />
                                    <KeyValuePair label={'Desired Price (from customer):'} value={`$${overview?.submission_item?.desired_price}`} textClass='dark-text' />
                                    <br/>
                                    <KeyValuePair label={'Item Notes:'} value= {overview?.submission_item?.notes} textClass={'dark-text'} />
                                    <KeyValuePair label={'Reason for Selling:'} value= {overview?.submission_item?.reason_for_selling} textClass={'dark-text'} />
                                    {overview?.submission_item?.reason_for_selling === 'Upgrading' ?
                                        <KeyValuePair label={'Upgrading to:'} value= {overview?.submission_item?.upgrading_to} textClass={'dark-text'} />
                                        : <></>}
                                        <br/>
                                    <KeyValuePair label={'Notes from Appraiser:'} value={
                                        item?.current_quote?.notes && item?.current_quote?.notes != 'undefined' ?
                                            item?.current_quote?.notes.replace(/<[^>]*>?/gm, '')
                                            : 
                                            '-'
                                        } textClass={'dark-text'} />
                                    <KeyValuePair label={'Submission Notes:'} value={overview?.submission_item?.additional_comments} textClass={'dark-text'} />
                                </InfoBox>
                            :
                                <InfoBox title="Product Info:">
                                    <KeyValuePair label={'Product Title - TMR:'} value={
                                        <Link className='btn dark-text' to={{pathname: `/product-view/${overview?.product?.id}`, state: {product_id: overview?.product?.id}}}>{overview?.product?.title}</Link>
                                    } textClass={'dark-text'} />
                                    <KeyValuePair label='Product Options:' value={overview?.product?.options} textClass='dark-text' />
                                    <KeyValuePair label={'Product Shipping Notes:'} value={overview?.product?.shipping_notes} textClass='dark-text' />
                                </InfoBox>
                            }
                            <br/>
                            <InfoBox title="RMA Information:">
                                <KeyValuePair label='Item Overview:' value={
                                    <Link className="btn dark-text" to={{pathname: `/item-view/${item?.id}`}}>{item?.sku}</Link>
                                } textClass='dark-text' />
                                {tpa?.id ? <>
                                    <KeyValuePair label='RMA:' value={
                                        <Link className="btn dark-text" to={{pathname: `/tpa-view/${item?.tpa_id}`}}>{tpa?.tpa_number}</Link>
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Date:' value={formatDate(tpa?.created, {date:true})} textClass='dark-text' />
                                    <KeyValuePair label='Customer:' value={
                                        <Link className='btn dark-text' to={{pathname: `/customer-view/${tpa?.customer_id}`, state: {customer_id: tpa?.customer_id}}}>{tpa?.seller_name}</Link>
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Salesperson:' value={tpa?.salesperson} textClass='dark-text' />
                                    <KeyValuePair label='Submission:' value={
                                        <Link className='btn dark-text' to={{pathname: `/submission-view/${tpa?.submission_id}`, state: {submission_id: tpa?.submission_id}}}>{tpa?.submission_id}</Link>
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Finale PO:' value={tpa?.finale_po_id} textClass='dark-text' />
                                    <KeyValuePair label='Notes:' value={tpa?.notes} textClass='dark-text' />
                                    <KeyValuePair label={'eBay Only:'} value={<CheckmarkYN val={item?.ebay_only} />} textClass={'dark-text'}/>
                                </> : <h3 className="dark-text">No RMA Available</h3>}
                            </InfoBox>
                            <br/><br/>

                            <InfoBox title="Other Listing Info:">
                                <KeyValuePair label={'Buy Price:'} value={item?.purchase_amount} />
                                <KeyValuePair label={'Consignment:'} value={item?.is_consignment_string} />
                                <KeyValuePair label='List Price:' value={
                                    <input type='number' value={item?.list_price} onChange={e => {setFinaleUpdate(true); setItem({...item, list_price: e.target.value})}} />
                                } />
                                {isCable ? 
                                    <div>
                                        <KeyValuePair label='MSRP (must be entered manually for cables):' value={                 
                                                <div>
                                                    <input form = 'ListingOptionsForm' type='number' required = {true} value={cableMsrp} onChange={e => setCableMsrp(e.target.value)} />
                                                    <div className="vertically-aligned">
                                                        <input type='checkbox' checked={cableMsrp === 0} onChange={e => setCableMsrp(e.target.checked ? 0 : null)}/>&nbsp;<i>MSRP Not Available</i>
                                                    </div>
                                                </div>
                                        }/> 
                                    </div>
                                : <></>}
                                <KeyValuePair label={'Quantity (please double check):'} value={item?.quantity} textClass={item?.quantity > 1 ? "red-text" : ""} />
                                <KeyValuePair label={'Shipping Cost:'} value={
                                    <select value = {item?.shipping_cost} selected onChange={e => setItem({...item, shipping_cost: e.target.value})}>
                                        {item?.shipping_cost ? <option selected value = {item?.shipping_cost}>{item?.shipping_cost}</option> : <option></option>}
                                        {SHIPPING_PRESET_OPTIONS}
                                    </select>
                                }/>
                                <KeyValuePair label={'Product Categories:'} value={overview?.product?.classifications?.map(row => row?.supertype + ' | ' + row?.name)?.join(', ')} />
                                <KeyValuePair label={'Brand Partner:'} value={overview?.product?.brand_partner ? <p className = 'green-text'>True</p> : <p className = 'red-text'>False</p> } textClass={'dark-text'}/>
                                <KeyValuePair label='Filter Options:' value={
                                    <ModalContainer triggerText='Check/Edit Options' buttonClass='btn bg-red light-text' formData={{...item, classifications: overview?.product?.classifications}} submitForm={newItem => updateItemOptions(newItem?.options)} FormComponent={EditItemOptionsForm} />
                                } />
                                <KeyValuePair label={'Weight / Dims:'} value={<>
                                    <ShowHideBox showText='Show  v' hideText='Hide  ^' isShown={false} content={
                                        <div>
                                            <p>Weight:&nbsp;&nbsp;{parseInt(item?.dimensions?.weight)} lbs</p>
                                            {item?.dimensions?.boxes?.map((box, ind) => 
                                                <li>{item?.has_packaging == 1 ? 'Box' : 'No Box'} {ind + 1}:&nbsp;&nbsp;{parseInt(box?.width)} x {parseInt(box?.depth)} x {parseInt(box?.height)}</li>
                                            )}
                                            <i>(dimensions in inches)</i>
                                        </div>
                                    } />
                                    <ModalContainer triggerText='Edit' formData={item} FormComponent={StockItemForm} submitForm={dims => {console.log(dims.has_packaging);
                                     setItem({...item, dimensions: dims, has_packaging: dims?.has_packaging})}} />
                                    </>
                                } />
                                <KeyValuePair label={'Testing Notes:'} value={item?.tester_notes} />
                                <br/>
                                <KeyValuePair label={'Photo Notes:'} value={item?.photo_notes} />
                            </InfoBox>
                        </div>
                        <div className='contained'>                            
                            <InfoBox title='Listing:'>
                                <div style={{padding: '15px'}}>
                                    <p className='dark-text'><b>Product: </b>{item?.title}</p>
                                    <p className='dark-text'><b>Product SKU: </b>{item?.sku || 'Incomplete'}</p>
                                    <p className='dark-text'><b>Serial Number: </b>{item?.serial_number || 'Incomplete'}</p>
                                    {uneditedItem?.options?.map(o=>o?.option_set_name)?.indexOf("Cable Length (Meters)") >= 0 ?
                                        <p className="dark-text"><b>Length / Termination: </b>
                                        {uneditedItem?.options[uneditedItem?.options?.map(o=>o?.option_set_name)?.indexOf("Cable Length (Meters)")]?.value} {"Meters - "}
                                         {uneditedItem?.options?.filter(o=>o?.option_set_name=="Analog Interconnect Termination")?.map(o=>o?.option_name)?.join(" / ")}
                                         {uneditedItem?.options?.filter(o=>o?.option_set_name=="Digital Cable Termination")?.map(o=>o?.option_name)?.join(" / ")}
                                         {uneditedItem?.options?.filter(o=>o?.option_set_name=="Speaker Cable Termination")?.map(o=>o?.option_name)?.join(" / ")}
                                         {uneditedItem?.options?.filter(o=>o?.option_set_name=="Power Cable Termination")?.map(o=>o?.option_name)?.join(" / ")}</p> 
                                        : <></>}
                                    {uneditedItem?.options?.map(o=>o?.option_set_name)?.indexOf("Input Voltage") >= 0 ?
                                        <p className='dark-text'><b>Voltage: </b>
                                        {uneditedItem?.options?.filter(o=>o?.option_set_name=="Input Voltage")?.map(o=>o?.option_name)?.join(", ")}</p>
                                        :<></>}
                                    <p className='dark-text'><b>Cosmetic Description: </b>{`${item?.cosmetic_condition} ${item?.cosmetic_description}`}</p>
                                    <p className='dark-text'><b>What's Included: </b>{item?.what_is_included}</p>
                                    <p className='dark-text'><b>Functional Notes: </b>{item?.functional_notes}</p>
                                    <p className='dark-text'><b>Age: </b>{item?.age_in_years_text}</p>
                                    <p className='dark-text'><b># of Owners: </b>{item?.num_of_owners_text}</p>
                                    <p className='dark-text'><b>Tobacco Exposure: </b>{item?.tobacco===1 ? 'Yes' : 'No'}</p>
                                    <p className='dark-text'><b>Service History: </b>{item?.service_history}</p>
                                    {isCable ? <></> : <p className='dark-text'><b>MSRP: </b>{overview?.product?.msrp || 'Incomplete'}</p>}
                                    <p className='dark-text'><b>Other Notes: </b>{item?.description || <p>Incomplete<br/><button className="green-text" onClick={() => navigator.clipboard.writeText(`${product?.description}`)}>Copy Product-Level to Clipboard</button></p>}</p>
                                    <p className='dark-text'><b>Product Specifications: </b>
                                    {overview?.product?.specs_link ? <a href={overview?.product?.specs_link}>{overview?.product?.specs_link}</a>
                                    : 'Incomplete'}</p>
                                    {overview?.product?.review_link_1 ? <p className='dark-text'><b>{overview?.product?.review_name_1 ? overview?.product?.review_name_1 + ': ' : 'Review 1: '}</b>{overview?.product?.review_link_1}</p> : <></>}
                                    {overview?.product?.review_link_2 ? <p className='dark-text'><b>{overview?.product?.review_name_2 ? overview?.product?.review_name_2 + ': ' : 'Review 2: '}</b>{overview?.product?.review_link_2}</p> : <></>}
                                    {overview?.product?.review_link_3 ? <p className='dark-text'><b>{overview?.product?.review_name_3 ? overview?.product?.review_name_3 + ': ' : 'Review 3: '}</b>{overview?.product?.review_link_3}</p> : <></>}
                                    {/* <p className='dark-text'><b>Shipping: </b>{item?.shipping_note || 'Incomplete'}</p> */}
                                    <br/>
                                    <i>(navigate to product page to modify {isCable ? '':'MSRP or '}Specs)</i>
                                    <br/>
                                    <br/>
                                    <ModalContainer triggerText='Edit Item' submitForm={i => {setItem(i); if(i?.finaleUpdate){setFinaleUpdate(true)}}} formData={{...item, suppressEdits: true}} FormComponent={EditItemForm} />
                                </div>
                            </InfoBox>
                        </div>
                    </div>
                    {item?.id ? 
                        <ImageArranger item_id={item?.id}
                        updateParentComponent={(imageOrder) => setItem({...item, images: imageOrder})}
                        submitHandler={(img) => {
                            alert(`Image sequence submitted.\n${JSON.stringify(img)}`);
                            setItem({...item, images: img});
                        }} /> : <p>...Loading</p>}                
                    <button className="btn dark-text" onClick={() => pullPhotos()}>Fetch Photos from BC</button><br/>
                    <i className="light-text">(this will refresh the page and clear any changes you've made so far)</i>
                    <br/><br/>
                    <div className="bg-lightest" style={{height: '1px', width: '100%'}} />
                    <br/>
                    <h3 className="light-text">Listing Options:</h3>
                    <form id='ListingOptionsForm'>
                        <div className="container">
                            <div className="contained">
                                <InfoBox title='Where:'>
                                    <label className="text-left"><input form='ListingOptionsForm' type='checkbox' checked={listingOptions?.hideOnBc==1} onChange={e => setListingOptions({...listingOptions, hideOnBc: e.target.checked ? 1 : 0})}/>&nbsp;Hide on BC</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='checkbox' checked={listingOptions?.noEbay==1} onChange={e => setListingOptions({...listingOptions, noEbay: e.target.checked ? 1 : 0})}/>&nbsp;NO eBay</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='checkbox' checked={listingOptions?.noAudiogon==1} onChange={e => setListingOptions({...listingOptions, noAudiogon: e.target.checked ? 1 : 0})}/>&nbsp;NO Audiogon</label>
                                    { listingOptions?.noAudiogon ? <></> : <label className="dark-text text-left"><br/><b>Select AG Category:</b><br/>
                                    <select value={listingOptions?.agCategory} onChange={e => setListingOptions({...listingOptions, agCategory: e.target.value})}>
                                        {AG_CATEGORY_OPTIONS}
                                    </select></label>}
                                </InfoBox>
                            </div>
                            <div className="contained">
                                <InfoBox title='Badges:'>

                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.badge=='None'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, badge: 'None'}) : null}/>&nbsp;None</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.badge=='On Sale'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, badge: 'On Sale'}) : null}/>&nbsp;On Sale</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.badge=='Open Box'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, badge: 'Open Box'}) : null}/>&nbsp;Open Box</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.badge=='Closeout'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, badge: 'Closeout'}) : null}/>&nbsp;Closeout</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.badge=='Demo'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, badge: 'Demo'}) : null}/>&nbsp;Demo</label>
                                </InfoBox>
                            </div>
                            <div className="contained">
                                <InfoBox title='Subtext:'>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.subtext=='Certified Pre-Owned'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, subtext: 'Certified Pre-Owned'}) : null}/>&nbsp;Certified Pre-Owned</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='radio' checked={listingOptions?.subtext=='Factory Authorized'} onChange={e => e.target.checked ? setListingOptions({...listingOptions, subtext: 'Factory Authorized'}) : null}/>&nbsp;Factory Authorized</label>
                                </InfoBox>
                            </div>
                            <div className="contained">
                                <InfoBox title='Extras:'>
                                    <label className="text-left"><input form='ListingOptionsForm' type='checkbox' checked={listingOptions?.featured==1} onChange={e => setListingOptions({...listingOptions, featured: e.target.checked ? 1 : 0})}/>&nbsp;Feature this Listing</label>
                                    <label className="text-left"><input form='ListingOptionsForm' type='checkbox' checked={listingOptions?.specials==1} onChange={e => setListingOptions({...listingOptions, specials: e.target.checked ? 1 : 0})}/>&nbsp;Add to Specials Category</label>
                                    <br/>
                                    <label className="text-left">Price Drop Rate:<br/><input form="ListingOptionsForm" type='number' value={listingOptions?.price_drop_rate} onChange={e => setListingOptions({...listingOptions, price_drop_rate: e.target.value})} /></label>
                                    <i className="dark-text">(price will be dropped by X percent weekly)</i>
                                </InfoBox>
                            </div>
                        </div>
                    </form>
                    <h3 className="light-text text-center">Quantity:&nbsp;{item?.quantity}</h3>
                    <i className="light-text" style={{border: 'thin solid white', borderRadius: '4px'}}>Currently Listing for {parseInt((parseFloat(item?.list_price) / parseFloat(item?.msrp)) * 100)}% of MSRP</i>
                    {item?.images?.length >=0 ? <></> : <p className="red-text text-center"><i>NO IMAGE ORDER DETECTED - PLEASE ENSURE YOU PRESS SUBMIT SEQUENCE</i></p>}
                    <div className="container">
                        <div className="contained" style={{maxWidth: '25%'}}/>
                        <button className="btn bg-red light-text" style={{width: '50%'}} disabled = {submitted ? true : false} onClick={submitListing}>{submitted ? 'Submitting Item...' : 'Finalize'}</button>
                        &nbsp;&nbsp;
                        <label className="text-left light-text vertically-aligned"><input type='checkbox' checked={sendToTester==1} onChange={e => setSendToTester(e.target.checked ? 1 : 0)}/>&nbsp;Email edits to Tester</label>
                        <div className="contained" style={{maxWidth: '25%'}}/>
                    </div>
                    <br/>
                    <br/>
                    <div className="bg-light">
                        <h1 className = "dark-text" style={{padding: '5px 10px'}}>Currently Listed Items</h1>
                            {listedItems ?
                                <TableList columnNames={['SKU','Title','Days Listed', 'Current List Price']}
                                columnWidths={['15%','50%','15%','15%']}
                                entries={
                                    listedItems?.map(row => {
                                        return [
                                            row?.open_box == 0 ? <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+row?.url}>{row?.sku}</a> : <a rel="noreferrer" target='_blank' href={'https://tmraudio.com'+row?.url}>{row?.sku} (OB)</a>,
                                            row?.name,
                                            row?.daysListed,
                                            row?.price                    
                                        ]
                                    })
                                } />
                                :
                                <p className="dark-text">&nbsp; No current listings</p>
                            }
                    </div>
                </div>
            : 'no overview'}
        </div>
    )
}

export default ListItem;