import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const ItemsListed = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [fetched, setFetched] = useState(false)
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportType, setReportType] = useState('summary');
    let [listers, setListers] = useState()
    let [listerId, setListerId] = useState();
    let [listerName, setListerName] = useState();
    let [saleTypes, setSaleTypes] = useState([]);
    let [selectedSaleTypes, setSelectedSaleTypes] = useState([]);
    let [includeRas, setIncludeRas] = useState('include');

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/items-listed', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate, reportType: reportType, listerId: listerId, selectedSaleTypes: selectedSaleTypes, includeRas: includeRas})  
        })
        .then(res => res.json())
        .then(res => {

            setListers(res?.listers)
            setSaleTypes(res?.saleTypes)
            if(!fetched){setSelectedSaleTypes(res?.saleTypes.map((type)=>type?.id))}


            let data = res?.report
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
            setFetched(true)
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, reportType, startDate, endDate, listerId, reportType, selectedSaleTypes, includeRas])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <div className = "bg-light rounded  dark-text" style={{padding: '15px 30px'}}>
                    <div class = "container">
                        <h1 className="text-3xl font-bold" style={{padding: '5px 10px'}}>Items Listed</h1>
                        <InfoButton onClick = {infoFunction}/> 
                    </div>
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report shows the number of items stocked during the selected time period. Items are marked stocked in Flip when dimensions are submitted.</p>
                    </div> 

                    <div className="container">
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            <b className="dark-text">Report Type</b>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                            <br></br>                         
                        </div>
                        <div style={{padding: '15px 15px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 30px'}}>
                            <b className="dark-text">Date Range</b>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                        </div>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                        </div>
                                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                                        {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                        <br/>
                        </div>
                        <div style={{padding: '15px 15px'}}></div>
                        <div className="bg-lightest rounded" style={{padding: '15px 30px'}} >
                            <b className="dark-text">Sale Types</b>
                            {saleTypes ? 
                                    saleTypes.map(type=>
                                        <label className='contained dark-text text-left'>{type.sale_type}:&nbsp;&nbsp;
                                            <input form='EditItemForm' type='checkbox' checked={selectedSaleTypes.includes(type.id)} 
                                                onChange={ e => e.target.checked == 1 ? 
                                                    setSelectedSaleTypes([].concat(selectedSaleTypes, type.id)) 
                                                    : 
                                                    setSelectedSaleTypes([].concat(selectedSaleTypes.filter((e) => e != type.id)))
                                                }
                                            />
                                        </label>
                                    )
                                : 
                                <></>
                            }
                        </div>
                        <div style={{padding: '15px 15px'}}></div>
                        <div className="bg-lightest rounded" style={{padding: '15px 30px'}} >
                            <b className="dark-text">RA Items</b>
                                <div>
                                    <label className=" text-left"><input type='radio' checked={includeRas=='include'} onChange={e => e.target.checked==1 ? setIncludeRas('include') : null}/>&nbsp;Include RAs</label>
                                    <label className=" text-left"><input type='radio' checked={includeRas=='exclude'} onChange={e => e.target.checked==1 ? setIncludeRas('exclude') : null} />&nbsp;Exclude RAs</label>
                                    <label className=" text-left"><input type='radio' checked={includeRas=='only'} onChange={e => e.target.checked==1 ? setIncludeRas('only') : null} />&nbsp;Only RAs</label>
                                </div>
                        </div>
                        <div style={{padding: '15px 15px'}}></div>
                        {reportType =='detail' ? 
                            <div className="bg-lightest rounded" style={{padding: '15px 30px'}} >
                                <b className="dark-text">Lister</b>
                                <label className="contained text-left" for="lister"  >Lister:&nbsp;
                                    <select  name="lister" id="selectlister" onChange={e => {console.log(e);setListerId(e.target.value); setListerName(e.target.selectedOptions[0].label)}} value={listerId}>
                                        <option value='null' >All</option>
                                        {listers ? 
                                            listers.filter(p => p.id != listerId).map(e => <option value={e.id} >{e?.lister}</option>)
                                        : <></>}
                                        {listerId && listerId != 'null' ?
                                        <option value={listerId} >{listerName}</option>
                                        :<></>}
                                    </select>
                                </label>
                            </div>
                        :
                        <></>    
                        }

        

                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='items-listed.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                

  

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType == 'summary' ?
                <TableList columnNames={['Lister', 'Items Listed']}
                        columnWidths={['20%','80%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.lister,
                                    <>{row?.unique_items_listed}&nbsp;&nbsp;&nbsp;{row?.lister == 'List Value' ? '' :' ('+row?.items_listed +')'}</>                 
                                ]
                            })
                        } />
            :
                <TableList columnNames={['Lister', 'SKU','Sale Type', 'Title', 'List Price','Date Listed']}
                columnWidths={['10%','10%','10%', '45%','10%','15%']}
                entries={
                    report?.map(row => {
                        return [
                            row?.lister,
                            <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                            row?.sale_type,
                            row?.title,
                            row?.list_price,
                            row?.date_listed 
                        ]
                    })
                } />
            }
      
            
        </div>
    );
}

export default ItemsListed;