import React from 'react';
import Modal from './Modal';

const TriggerImage = ({source, alter, styleprops, buttonRef, showModal}) => {
    return (
        <div ref={buttonRef} onClick={showModal} style={styleprops}>
            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={source} alt={alter} />
        </div>
    );
};

const ModalImage = ({formData, closeModal, submitForm}) => {
    return <a rel="noreferrer" target='_blank' href={formData['source']}><img style={{maxWidth: '100%', maxHeight: '100%'}} src={formData?.source} alt={formData?.alter} /></a>
}

export class ImageModalContainer extends React.Component {
    state = {isShown: false};

    //shows the modal, which should place focus on the button to close the modal, and also stops the user from scrolling on the background component
    showModal = () => {
        this.setState({isShown: true}, () => {
            this.closeButton.focus();
            this.toggleScrollLock();
        });
    };

    //locks the user out of scrolling on the background component. this allows the user to scroll on the modal view without also scrolling aroudn in the background
    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    //closes the modal view. this is given to the child component so that the modal can close when submitted.
    closeModal = () => {
        this.setState({isShown: false});
        this.TriggerButton.focus();
        this.toggleScrollLock();
    };

    //closes the modal view if the user presses ESC
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };

    //closes the modal view if the user clicks outside the view
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    //renders the modal container, which is simply abutton until the user clicks and displays the modal
    render() {
        return (
            <>
                <TriggerImage
                source={this.props.source}
                alter={this.props.alter}
                styleprops={this.props.styleprops}
                showModal={this.showModal}
                buttonRef={(n) => (this.TriggerButton = n)}
                />

                {this.state.isShown ? ( //conditionally displays the modal view based on the state
                <Modal
                FormComponent={ModalImage}
                formData={{source: this.props.source, alter: this.props.alter}}
                submitForm={this.props.submitForm}
                modalRef={(n) => (this.modal = n)}
                buttonRef={(n) => (this.closeButton = n)}
                closeModal={this.closeModal}
                onKeyDown={this.onKeyDown}
                onClickOutside={this.onClickOutside}
                />
                ) : null}   
            </>
        );
    }
}

export default ImageModalContainer;