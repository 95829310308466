import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const ItemsPhotographed = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportType, setReportType] = useState('summary');
    let [photographers, setPhotographers] = useState()
    let [photographerId, setPhotographerId] = useState();
    let [photographerName, setPhotographerName] = useState(); 

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/items-photographed', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate,reportType: reportType, photographer: photographerId})  
        })
        .then(res => res.json())
        //.then(res => res?.report)
        .then(res => {

            photographers = res?.photographers
            setPhotographers(photographers)
            
            let data = res?.report
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context,startDate, endDate,reportType, photographerId])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Items Photographed</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report shows the number of items photographed during the selected time period.</p>
                    </div> 

                    <div className="container">

                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                        </div>

                        <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                        </div>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                        </div>
                                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                                        {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                        <br/>
                        </div>

                        {reportType =='detail' ? 
                            <label className="contained text-left" for="photographer" style={{padding: '15px 15px'}} >photographer:&nbsp;
                                <select  name="photographer" id="selectphotographer" onChange={e => {console.log(e);setPhotographerId(e.target.value); setPhotographerName(e.target.selectedOptions[0].label)}} value={photographerId}>
                                    <option value='null' >All</option>
                                    {photographers ? 
                                        photographers.filter(p => p.id != photographerId).map(e => <option value={e.id} >{e?.photographer}</option>)
                                    : <></>}
                                    {photographerId && photographerId != 'null' ?
                                    <option value={photographerId} >{photographerName}</option>
                                    :<></>}
                                </select>
                            </label>
                        :
                        <></>    
                        }     

                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='items-photographed.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                

  

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType == 'summary' ?
                <TableList columnNames={['Photographer', 'Items Photographed']}
                    columnWidths={['20%','80%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.photographer,
                                <>{row?.unique_items_photographed}&nbsp;&nbsp;&nbsp;{' ('+row?.items_photographed +')'}</>
                            ]
                        })
                    } 
                />
            :
                <TableList columnNames={['Photographer', 'SKU', 'Title', 'Date Photographed']}
                    columnWidths={['15%','15%', '50%','20%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.photographer,
                                <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                                row?.title,
                                row?.date_photographed
                            ]
                        })
                    } 
                />
            }
          
        </div>
    );
}

export default ItemsPhotographed;