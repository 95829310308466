import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const ChangeSaleTypeForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);

    let [saleTypes, setSaleTypes] = useState([]);
    let [newSaleType, setNewSaleType] = useState(formData?.item?.sale_type_id);
    let [tradeInvolved, setTradeInvolved] = useState(false);
    let [newBuyPrice, setNewBuyPrice] = useState(formData?.item?.purchase_amount);
    let [newConsignmentRate, setNewConsignmentRate] = useState(formData?.tpa?.consignment_rate || 35);

    let [formDisable, setFormDisable] = useState(false);

    function fetchSaleTypes() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tableName: 'sale_types'
            })
        })
        .then(res => res.json())
        .then(results => setSaleTypes(results))
    }

    useEffect(() => {
        fetchSaleTypes();
    }, [])

    //blank consignment rate/new bp when sale type is no longer applicable
    useEffect(() => {
        if(newSaleType==4) {
            //blank bp
            setNewBuyPrice(null);
            setNewConsignmentRate(formData?.tpa?.consignment_rate || 35);
        } else {
            setNewConsignmentRate(null);
            setNewBuyPrice(formData?.item?.purchase_amount || 0);
        }
    }, [newSaleType])

    function submit() {
        event.preventDefault();
        if(newSaleType!=4 && !newBuyPrice) {
            alert('Buy Price is required for non-consignment sale types')
            return;
        } else if (newSaleType==4 && !newConsignmentRate) {
            alert('Consignment Rate is required for consignment sale types')
            return;
        }
        setFormDisable(true);
        submitForm({
            newSaleType: newSaleType,
            newBuyPrice: newBuyPrice,
            tradeInvolved: tradeInvolved,
            newConsignmentRate: newConsignmentRate,
        });
        closeModal();
    }

    if(!context?.feature_flags['change_sale_type']) {
        return (
            <div>
                <h2>This feature is not currently enabled on your version of FLIP</h2>
            </div>
        )
    }

    return (
        <div>
            <form id='ChangeSaleTypeForm' onSubmit={submit} />
            <fieldset form='ChangeSaleTypeForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h2>Change Sale Type</h2>
                    <br/>
                    <label className="dark-text text-left">Select New Sale Type:&nbsp;&nbsp;
                    <select form='ChangeSaleTypeForm' value={newSaleType} onChange={e => setNewSaleType(e.target.value)}>
                        {saleTypes?.map((st, ind) =>
                            <option id={ind} value={st?.id}>{st?.sale_type}</option>
                        )}
                    </select>
                    </label>
                    <i className="dark-text text-left">(current is {saleTypes[saleTypes?.map(s=>s?.id)?.indexOf(formData?.item?.sale_type_id)]?.sale_type})</i>
                    <br/>
                    <br/>
                    {newSaleType != 4 ? <>
                    <label className="dark-text text-left">Trade Involved?&nbsp;&nbsp;
                    <input type="checkbox" checked={tradeInvolved} onChange={e => setTradeInvolved(e.target.checked)} />
                    </label>
                    <br/>
                    <label className="dark-text text-left">Input New Buy Price:&nbsp;&nbsp;
                    <input form="ChangeSaleTypeForm" step="0.01" type='number' value={newBuyPrice} onChange={e => setNewBuyPrice(e.target.value)} />
                    </label>
                    </> : <></>}
                    {newSaleType == 4 ? <>
                    <label className="dark-text text-left">Input New Consignment Rate:&nbsp;&nbsp;
                    <input form="ChangeSaleTypeForm" type='number' step='1' value={newConsignmentRate} onChange={e => setNewConsignmentRate(e.target.value)} />
                    </label>
                    {newSaleType == formData?.item?.sale_type_id ? <>
                        <br/><p className="red-text"><i>WARNING: changing consignment rate will change the rate for the WHOLE RMA. If you want to change just 1 item, split it off to it's own RMA first, then fill out this form.</i></p><br/>
                    </> : <></>}
                    </> : <></>}
                    <input className="btn bg-dark light-text" form='ChangeSaleTypeForm' type='submit'/>
                    <br/>
                    <i className="red-text">WARNING: this operation will make many changes to RMA data, accounting, etc.
                    If you are not familiar with what this feature does please review the documentaion
                    <a rel='noreferrer' target='_blank' href="https://docs.google.com/document/d/1IuFs5OpsEe5Oiusc8vxUwsmFzoDPq5L0xQ4fQJIIutw/edit#heading=h.bo2ipwwrjk3v"> here </a>
                    before submitting.</i>
                </div>
            </fieldset>
        </div>
    )
}

export default ChangeSaleTypeForm;