import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import InfoButton from "../../components/infoButton";


const RasPaidReport = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);

    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }
    


    function fetchRasPaid() {
        secureFetch(context.server + '/reports/ras-paid', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({startDate: startDate, endDate: endDate})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data)
        })
    }

    useEffect(() => {
        fetchRasPaid();
    }, [context, startDate, endDate])

    return(
        <div>

            
            <div style={{padding: '30px 30px'}}>
                
                <div className='container'>
                    <h1>RAs Paid Report</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>More information can be accessed by downloading the complete report below.</p>
                        <p>This report shows individual RA Items. RAs with multiple items will appear more than once in this list.</p>
                </div>
                
                <br/>
                
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                    <CSVLink data={report} filename='ras_paid_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink>
                    <br/>
                </div>
                
                <br/>
                
                <h2 className="light-text">Report at a glance:</h2>
                <TableList columnNames={['RA #', 'Cust.', 'Order #', 'SKU', 'Pay Date', 'Amount', 'Date']}
                columnWidths={['10%', '10%', '10%', '20%', '15%', '20%', '15%']}
                entries={report?.map(row => {
                    return [
                        row?.ra_id,
                        row?.customer,
                        row?.order_number,
                        row?.sku,
                        formatDate(row?.payment_date,{date: true}),
                        row?.amount,
                        formatDate(row?.report_date, {date: true}),
                    ]
                })}
                />
                <br/>
            </div>
        </div>
    );
}

export default RasPaidReport;