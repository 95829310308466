import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import InfoBox from "./InfoBox";
import TableList from "./TableList";


const MergeSubmissionForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);

    let [disableForm, setDisableForm] = useState(false);
    let [targetSubmissionId, setTargetSubmissionId] = useState(null);
    let [targetSubmission, setTargetSubmission] = useState({});
    let [sourceSubmission, setSourceSubmission] = useState(formData);

    function fetchTargetSubmission() {
        secureFetch(context?.server + '/submission-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: targetSubmissionId
            })
        })
        .then(resp => resp.json())
            .then(result => {
                setTargetSubmission({...result?.submission, items: result?.items});
            })
    }

    useEffect(() => {
        setSourceSubmission(formData);
    }, [formData]);

    function submit(event) {
        event.preventDefault();
        setDisableForm(true);
        secureFetch(context?.server + '/merge-submissions', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sourceId: sourceSubmission?.id,
                targetId: targetSubmission?.id
            })
        })
        .then(resp => resp.json())
        .then(() => {
            submitForm(targetSubmission?.id);
            closeModal();
        })
    }

    return (
        <div>
            <h2 className="dark-text">Submission Merge</h2><br/>
            <form id='SubmissionMerge' onSubmit={submit} />
            <fieldset form='SubmissionMerge' disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <label className="dark-text text-left">Enter a Submission ID to merge submission {sourceSubmission?.id} into:<br/>
                        <input form='SubmissionMerge' type='number' value={targetSubmissionId} onChange={e => setTargetSubmissionId(e.target.value)} />
                        &nbsp;&nbsp;<button className="btn bg-dark light-text" onClick={() => fetchTargetSubmission()}>OK</button>
                    </label>
                    <br/><br/>
                    {targetSubmission?.id ? <>
                        <div className="container bg-white rounded">
                            <div className="contained">
                                
                                <h3 className="dark-text">Target Submission - {targetSubmission?.id}:</h3>
                                <InfoBox title='Information:'>
                                    <p className="dark-text"><b>Customer:</b> {targetSubmission?.first_name} {targetSubmission?.last_name}</p>
                                    {targetSubmission?.items?.length > 0 ? <>
                                    <p className="dark-text"><b>Items:</b></p>
                                    <ol style={{padding: '0px 15px'}}>
                                        {targetSubmission?.items?.map(row => <li>{row?.manufacturer} {row?.model_number}</li>)}
                                    </ol>
                                    </> : <>no items</>}
                                </InfoBox>
                            </div>
                            <div className="contained">
                                
                                <h3 className="dark-text">The following items will be merged into {targetSubmission?.id}:</h3>
                                <TableList columnNames={['ID','Title']}
                                columnWidths={['20%','80%']}
                                entries={sourceSubmission?.items?.map(row => {
                                    return [
                                        row?.id,
                                        `${row?.manufacturer} ${row?.model_number}`,
                                    ]
                                })} />
                            </div>
                            
                        </div>
                        <br/>
                        <i className="red-text">WARNING: MERGING SUBMISSIONS IS NOT EASILY REVERSIBLE</i>
                        <br/>
                        <button className="btn bg-dark light-text" onClick={submit}>Confirm Merge</button>
                    </> : <></>}
                    </div>
            </fieldset>
        </div>
    );
}

export default MergeSubmissionForm;