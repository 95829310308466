import InfoBox from "./InfoBox";
import KeyValuePair from "./KeyValuePair";


const InventoryPopup = ({formData, submitForm, closeModal}) => {

    return (
        <div>
            <h1 className="dark-text">Inventory Details:</h1>
            <br/>
            <InfoBox title='Details:'>
                <KeyValuePair label='SKU:' value={formData?.product_id} textClass='dark-text' />
                <KeyValuePair label='Title:' value={formData?.description} textClass='dark-text' />
                <KeyValuePair label='Notes:' value={formData?.notes} textClass='dark-text' />
                <KeyValuePair label='Type:' value={formData?.item_type} textClass='dark-text' />
                <KeyValuePair label='BC ?' value={formData?.big_commerce} textClass='dark-text' />
                <KeyValuePair label='eBay ?' value={formData?.ebay} textClass='dark-text' />
                <KeyValuePair label='List Price:' value={formData?.item_price} textClass='dark-text' />
                <KeyValuePair label='Buy Price:' value={formData?.std_buy_price} textClass='dark-text' />
                <KeyValuePair label='Category:' value={formData?.category} textClass='dark-text' />
                <KeyValuePair label='Manufacturer:' value={formData?.manufacturer} textClass='dark-text' />
                <KeyValuePair label='Sublocation:' value={formData?.sublocation} textClass='dark-text' />
                <KeyValuePair label='Qty On Hand:' value={formData?.quantity_on_hand} textClass='dark-text' />
                <KeyValuePair label='Qty On Order:' value={formData?.on_order_units} textClass='dark-text' />
                <KeyValuePair label='Qty On Reserve:' value={formData?.reservation_units} textClass='dark-text' />
                <KeyValuePair label='Qty Available:' value={formData?.quantity_available} textClass='dark-text' />
                <KeyValuePair label='Sales (7 days):' value={formData?.sales_last_7_days} textClass='dark-text' />
                <KeyValuePair label='Sales (14 days):' value={formData?.sales_last_14_days} textClass='dark-text' />
                <KeyValuePair label='Sales (30 days):' value={formData?.sales_last_30_days} textClass='dark-text' />
                <KeyValuePair label='Sales (60 days):' value={formData?.sales_last_60_days} textClass='dark-text' />
                <KeyValuePair label='Value In Stock:' value={formData?.value_in_stock} textClass='dark-text' />
            </InfoBox>
        </div>
    )
}

export default InventoryPopup;