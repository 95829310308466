import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import AddInventoryForm from "../components/AddInventoryForm";
import ConfirmButton from "../components/ConfirmButton";
import EditItemForm from "../components/EditItemForm";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import SearchSelect from "../components/SearchSelect";
import SelectItemForm from "../components/SelectItemForm";
import { secureFetch } from "../util/flip_utils";


// const BStocking = (props) => {
//     let context = useContext(AppContext);
//     const history = useHistory();
//     let [item, setItem] = useState({});
//     let [locations, setLocations] = useState([])
//     let [fromLocs, setFromLocs] = useState([]);
//     let [fromLoc, setFromLoc] = useState({})
//     let [toLoc, setToLoc] = useState({})
//     let [toQty, setToQty] = useState(0)
//     let [locFetched, setLocFetched] = useState(false)
//     let [locFetching, setLocFetching] = useState(false)

//     //fetch item. if id is given (ie selected from form) get that one. if none is given, get the one from location.state, otherwise do nothing
//     function fetchItem(id) {
//         let idToFetch = -1
//         if(id) {
//             idToFetch = id
//         } else if(!id && props.location?.state?.item_id) {
//             idToFetch = props.location?.state?.item_id
//         }

//         if(idToFetch > 0) {
//             secureFetch(context.server + '/items', {
//                 method: 'POST',
//                 credentials: 'include',
//                 headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     equal: [{key: 'i.id', value: idToFetch}],
//                     limit: 1
//                 })
//             })
//             .then(resp => resp.json())
//             .then(results => setItem(results[0]))
//         } else {
//             setItem({})
//         }
//     }

//     //fetch list of all locations in finale
//     function fetchLocations() {
//         secureFetch(context.server + '/finale/locations', {
//             method: 'GET',
//             credentials: 'include',
//             headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' }
//         })
//         .then(resp => resp.json())
//         .then(results => {
//             results.push({location_id: -5, location_name: 'REMOVE'})
//             results.push({location_id: -6, location_name: 'ADD'})
//             setLocations(results)
//         })
//     }

//     //fetch location and qty of selected item
//     function fetchItemLocation() {
//         //set this so it wont fetch multiple times
//         setLocFetching(true);
//         setFromLocs([]);
//         setFromLoc({})
//         secureFetch(context.server + '/finale/product/' + item?.id, {
//             method: 'GET',
//             credentials: 'include',
//             headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' }
//         })
//         .then(resp => resp.json())
//         .then(result => {
//             //update necessary values if present
//             let tempFromLocs = [];
//             if(result?.locations && result?.locations?.length > 0) {
//                 for(let i = 0; i < result?.locations?.length; i++) {
//                     let location = result?.locations[i]
//                     //split the location id out of the facilityUrl
//                     let temp = location.facilityUrl?.split('/');
//                     let fac_id = temp[temp.length-1];
//                     let loc = locations.filter(row => row?.location_id==fac_id) //find any and all with this facilityId
//                     if(loc.length < 1) { // if there werent any matches, its not in inventory
//                         loc = {location_id: -1, location_name: fac_id}
//                     } else { //if there were matches, take the first one
//                         loc = loc[0]
//                     }
//                     loc.quantity = location?.quantityOnHand;
//                     tempFromLocs.push(loc);
//                 }
//                 //alert(JSON.stringify(loc))
//                 setFromLoc(tempFromLocs[0])
//                 setFromLocs(tempFromLocs);
//             } else {
//                 setFromLoc({location_id: -1, location_name: 'NOT-IN-INVENTORY', quantity: 'NONE'})
//                 setFromLocs([{location_id: -1, location_name: 'NOT-IN-INVENTORY', quantityOnHand: 'NONE'}])
//             }
//             setLocFetched(true)
//             setLocFetching(false)
//         })
//     }

//     //get the item when it first loads (only if given by location.state, otherwise its blank)
//     useEffect(() => { fetchItem() }, [])
//     //get the locations when it first loads
//     useEffect(() => { fetchLocations() }, [])
//     //once locations and item have loaded, if it hasnt yet been fetched, fetch the location and quantity of the currently selected item
//     useEffect(() => { if(locations.length > 0 && item?.id && !locFetching) fetchItemLocation() }, [item, locations])


//     function submitRelocate(event) {
//         event.preventDefault();

//         //client-side checks for validation
//         if(!item?.id) {
//             alert('Must select item first.')
//             return;
//         }
//         if(!toLoc?.location_id) {
//             alert('Must select location to move to.')
//             return;
//         }
//         if(toQty > fromLoc?.quantity) {
//             alert("Can't move more quantity than we have.");
//             return;
//         }
//         if(toQty==0) {
//             alert("Can't move 0 quantity.")
//             return;
//         }

//         secureFetch(context.server + '/finale/relocate', {
//             method: 'POST',
//             credentials: 'include',
//             headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//                 id: item?.id,
//                 from: fromLoc,
//                 to: toLoc,
//                 quantity: toQty
//             })
//         })
//         .then(resp => resp.json())
//         .then(result => {
//             if(result?.message) {
//                 alert('From FinaleInventory:\n' + result?.message)
//             }
//             history.push({pathname: '/stocking', state:{item_id: item?.id}})
//             window.location.reload()
//         })
//     }


//     return (
//         <div style={{padding: '15px 15px'}}>
//             <h1>Stocking Tool</h1>
//             <h3 className='light-text'><i>Sometimes Finale can take a bit to add the item to inventory. If you change something, and it doesn't show up here, give it a few minutes.</i></h3>
//             <br/>
//             <div>
//                 <InfoBox title='Item Information'>
//                     {item?.id ? 
//                     <div className='container'>
//                         <div className='contained'>
//                         <KeyValuePair label='SKU:' value={item?.product_code ? item?.product_code : item?.sku} textClass='dark-text' />
//                         <KeyValuePair label='Title:' value={item?.title} textClass='dark-text' />
//                         <KeyValuePair label='Parts:' value={item?.number_of_parts} textClass='dark-text' />
//                         <KeyValuePair label="What's Included:" value={item?.what_is_included} textClass='dark-text' />
//                         <KeyValuePair label='Status:' value={item?.status} textClass='dark-text' />
//                         </div>
//                         <div className='contained'>
//                         <KeyValuePair label='Weight:' value={item?.weight ? item?.weight + ' lbs' : 'None'} textClass='dark-text' />
//                         <KeyValuePair label='Width:' value={item?.width ? item?.width + ' in' : 'None'} textClass='dark-text' />
//                         <KeyValuePair label='Depth:' value={item?.depth ? item?.depth + ' in' : 'None'} textClass='dark-text' />
//                         <KeyValuePair label='Height:' value={item?.height ? item?.height + ' in' : 'None'} textClass='dark-text' />
//                         <div className='container'>
//                             <Link className='contained btn dark-text' to={{pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}}}>View</Link>
//                             <ModalContainer triggerText='Edit Item' formData={item} FormComponent={EditItemForm} submitForm={i => fetchItem(i?.id)} />
//                         </div>
//                         </div>
//                     </div>
//                     :
//                     <p className='dark-text'>No Item Selected</p>
//                     }
//                 </InfoBox>
//                 <ModalContainer triggerText='Select Item' formData={{}} FormComponent={SelectItemForm} submitForm={i => {setLocFetched(false); setFromLoc({location_name: 'Fetching...', quantity: 'Fetching...'}); fetchItem(i?.id)}} />
//                 <br/><br/>
//                 <div className='container'>
//                     <div className='contained'>
//                         <h2 className='light-text'>Current Location:</h2>
//                         {fromLocs?.length > 1 ?
//                         <>
//                         <br/>
//                         <h3 className='light-text'>Location:</h3>
//                         <select value={fromLoc?.location_id} onChange={e => setFromLoc( fromLocs.filter(row => row?.location_id==e.target.value)[0] )}>
//                             {fromLocs.map(row => 
//                                 <option key={row?.location_id} value={row?.location_id}>{row?.location_name}</option>
//                             )}
//                         </select>
//                         <h3 className='light-text'>Quantity on Hand:</h3>
//                         <input className='bg-lightest' type='text' disabled value={locFetched ? fromLoc?.quantity : (locFetching ? 'Fetching...' : 'Unknown')} />
//                         </>
//                         :
//                         <>
//                         <br/>
//                         <h3 className='light-text'>Location:</h3>
//                         <input className='bg-lightest' type='text' disabled value={locFetched ? fromLoc?.location_name : (locFetching ? 'Fetching...' : 'Unknown')} /> 
//                         <h3 className='light-text'>Quantity on Hand:</h3>
//                         <input className='bg-lightest' type='text' disabled value={locFetched ? fromLoc?.quantity : (locFetching ? 'Fetching...' : 'Unknown')} />
//                         { fromLoc?.quantity==='NOT-FOUND' && item?.id ?
//                             <>
//                             <br/><br/>
//                             <ModalContainer triggerText='Add to Inventory' FormComponent={AddInventoryForm} formData={item} submitForm={item => {history.push({pathname: '/stocking', state:{item_id: item?.id}}); window.location.reload()}} />
//                             </>
//                             : fromLoc?.quantity==='NONE' && item?.id ?
//                             <>
//                             <br/><br/>
//                             <p className='light-text'><i>{'(if NOT-IN-INVENTORY, submit a "Move To" location + quantity to place the item in inventory)'}</i></p>
//                             </>
//                             :
//                             <></>
//                         }
//                         </>}

//                     </div>
//                     <div className='contained'>
//                         <h2 className='light-text'>Move To:</h2>
//                         <br/>
//                         <h3 className='light-text'>Location:</h3>
//                         {/*<SearchSelect options={locations.map(loc => {return {label: loc?.location_name, value: loc?.location_id}})}
//                         initialValue={fromLoc ? {label: fromLoc?.location_name, value: fromLoc?.location_id} : null}
//                         updateParent={opt => 
//                             opt ? setToLoc({location_name: opt?.label, location_id: opt?.value})
//                             : setToLoc({location_name: null, location_id: null})}
//                         />*/}
//                         <select onChange={ e => setToLoc({location_name: locations.filter( (row) => row?.location_id==e.target.value)[0]?.location_name, location_id: e.target.value})} value={toLoc?.location_id}>
//                             <option value='null'>{'*** SELECT LOCATION ***'}</option>
//                             {locations.map( (row) => 
//                                 <option key={row?.location_id} value={row?.location_id}>{row?.location_name}</option>
//                             )}
//                         </select>
//                         <h3 className='light-text'> Quantity:</h3>
//                         <input type='number' defaultValue={0} value={toQty} onChange={e => setToQty(e.target.value)} />
//                     </div>
//                 </div>
//                 { fromLoc?.quantity==='NOT-FOUND' && item?.id ? <></> : <ConfirmButton buttonText='Submit' confirmation='Are you sure you would like to move this inventory?' clickHandler={submitRelocate} /> }
//             </div>
//         </div>
//     );
// }


const Stocking = (props) => {
    let context = useContext(AppContext);
    const history = useHistory();
    let [items, setItems] = useState([]);
    let [locations, setLocations] = useState([])
    let [locFetched, setLocFetched] = useState(true)
    let [formDisable, setFormDisable] = useState(false);

    //fetch an item
    function fetchItem(idToFetch) {
        secureFetch(context.server + '/items', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                equal: [{
                    key: 'i.id',
                    value: idToFetch
                }]
            })
        })
        .then(resp => resp.json())
        .then(results => {
            addItem(results[0]);
        })
    }

    //fetch list of all locations in finale
    function fetchLocations() {
        secureFetch(context.server + '/finale/locations', {
            method: 'GET',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' }
        })
        .then(resp => resp.json())
        .then(results => {
            results.push({location_id: -5, location_name: 'REMOVE'})
            results.push({location_id: -6, location_name: 'ADD'})
            setLocations(results)
        })
    }

    //fetch location and qty of selected item
    async function fetchItemLocation(item) {
        return await secureFetch(context.server + `/finale/product/${item?.id}`, {
            method: 'GET',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' }
        })
        .then(resp => resp.json())
        .then(result => {
            //update necessary values if present
            let tempFromLocs = [];
            if(result?.locations && result?.locations?.length > 0) {
                for(let i = 0; i < result?.locations?.length; i++) {
                    let location = result?.locations[i]
                    //split the location id out of the facilityUrl
                    let temp = location.facilityUrl?.split('/');
                    let fac_id = temp[temp.length-1];
                    let loc = locations.filter(row => row?.location_id==fac_id) //find any and all with this facilityId
                    if(loc.length < 1) { // if there werent any matches, its not in inventory
                        loc = {location_id: -1, location_name: fac_id}
                    } else { //if there were matches, take the first one
                        loc = loc[0]
                    }
                    loc.quantity = location?.quantityOnHand;
                    tempFromLocs.push(loc);
                }
                //alert(JSON.stringify(loc))
                item.fromLoc = tempFromLocs[0]
                //setFromLocs(tempFromLocs);
            } else {
                item.fromLoc = {location_id: -1, location_name: 'NOT-IN-INVENTORY', quantity: 'NONE'}
            }
            return item;
        })
    }

    useEffect(() => {
        fetchLocations();
    }, [])

    function submitRelocate(event) {
        event.preventDefault();
        secureFetch(context.server + '/finale/relocate', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                items: items
            })
        })
        .then(resp => resp.json())
        .then(result => {
            if(result?.message) {
                alert('From Finale:\n' + result?.message)
            }
            window.location.reload();
        })
    }

    async function addItem(item) {
        setLocFetched(false);
        let newItems = items.map(i=>i);
        item = await fetchItemLocation(item);
        newItems.push(item);
        setItems(newItems);
        setLocFetched(true)
    }


    function removeItem(ind) {
        let newItems = items.map(i=>i);
        newItems.splice(ind, 1);
        setItems(newItems);
    }

    function setItem(ind, item) {
        let newItems = items.map(i=>i);
        newItems[ind] = item;
        setItems(newItems);
    }


    return (
        <div style={{padding: '15px 15px'}}>
            <form id='StockingForm' style={{display: 'none'}} onSubmit={submitRelocate} />
            <h1>Stocking Tool</h1>
            <h3 className='light-text'><i>Sometimes Finale can take a bit to add the item to inventory. If you change something, and it doesn't show up here, give it a few minutes.</i></h3>
            <br/>
            <ModalContainer triggerText='Add Item' formData={{}} FormComponent={SelectItemForm} submitForm={i => fetchItem(i?.id)} />
            <InfoBox title={`Items Stocked ${locFetched ? '' : '(fetching...)'}`}>
                <fieldset form='StockingForm' disabled={formDisable ? 'disabled' : ''}>
                    {items?.map((item, ind) => 
                    <div>
                        <p className="dark-text"><b>{item?.sku}</b>:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {item?.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <ModalContainer formData={item} FormComponent={EditItemForm} triggerText='Edit' submitForm={i => {setItem(ind, {...i, fromLoc: {location_name: 'Fetching...', quantity: 'Fetching...'}}); fetchItemLocation(i)}} />&nbsp;&nbsp;&nbsp;
                        <button onClick={e => removeItem(ind)} className="btn bg-red light-text">Remove</button></p>
                        <div className="container">
                            <div className="contained">
                                <p className="dark-text"><b>From:</b></p>
                                <p className='dark-text'>{item?.fromLoc?.location_name}</p>
                                <p className='dark-text'>{item?.fromLoc?.quantity}</p>
                            </div>
                            <div className="contained">
                                <p className="dark-text"><b>To:</b></p>
                                <select onChange={ e => setItem(ind, {...item, toLoc: {location_name: locations.filter( (row) => row?.location_id==e.target.value)[0]?.location_name, location_id: e.target.value, quantity: 0}})} value={item?.toLoc?.location_id}>
                                    <option value='null'>{'*** SELECT LOCATION ***'}</option>
                                    {locations.map( (row) => 
                                        <option key={row?.location_id} value={row?.location_id}>{row?.location_name}</option>
                                    )}
                                </select><br/>
                                <input type='number' form='StockingForm' value={item?.toLoc?.quantity} onChange={e => setItem(ind, {...item, toLoc: {...item?.toLoc, quantity: e.target.value}})} />
                            </div>
                        </div>
                        <div style={{height: '2px', width: '80%'}} className="bg-dark" />
                        
                    </div>)}
                </fieldset>
            </InfoBox>
            <input form='StockingForm' type='submit' className="btn dark-text" value='Submit Locations' />
        </div>
    );
}

export default Stocking;