import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import InfoBox from "./InfoBox";

const optionSetTypes = [
    'radio',
    'checkbox',
    'number',
    'text'
]

const CreateOptionSetForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [classifications, setClassifications] = useState([]);
    let [selectedClassifications, setSelectedClassifications] = useState([]);
    let [optionSet, setOptionSet] = useState({options: [], option_type: 'radio'});

    let [formDisable, setFormDisable] = useState(false);

    function fetchClassifications() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'classifications', params: {
                sort: {
                    key: 'sequence_number',
                    order: 1
                }
            }})
        })
        .then(resp => resp.json())
        .then(results => setClassifications(results));
    }

    useEffect(() => {
        fetchClassifications();
    }, [])

    function submit(event) {
        //event.preventDefault();
        setFormDisable();
        secureFetch(context.server + '/create-option-set', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                optionSet: optionSet,
                selectedClassifications: selectedClassifications
            })
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    function setOptions(opts) {
        setOptionSet({...optionSet, options: opts});
    }

    return (
        <div className='bg-light' style={{padding: '15px 15px'}}>
            <form id='CreateOptionSetForm' onSubmit={submit} />
            <fieldset form='CreateOptionSetForm' disabled={formDisable ? 'disabled' : ''} >
                <h1 className='dark-text'>Create Option Set</h1>
                <br/><br/>
                <label className='dark-text text-left'>Name:</label>
                <input type='text' form='CreateOptionSetForm' required value={optionSet?.name} onChange={e => setOptionSet({...optionSet, name: e.target.value})}
                style={{width: '300px'}} />
                <br/>
                <label className='dark-text text-left'>Type:</label>
                <select form='CreateOptionSetForm' value={optionSet?.option_type} onChange={e => 
                    setOptionSet({...optionSet, option_type: e.target.value})
                }>
                    {optionSetTypes.map((ost, ind) => 
                        <option key={ind} value={ost}>{ost}</option>
                    )}
                </select>

                <br/><br/>
                <InfoBox title='Options:'>
                    {optionSet?.options?.map((row, ind) =>
                        <li className='dark-text'>
                            <input type='text' form='CreateOptionSetForm' required autoFocus value={row?.name} onChange={e => {
                                let opts = optionSet?.options?.map(o=>o);
                                opts[ind].name = e.target.value;
                                setOptions(opts);
                            }} style={{width: '300px'}} />&nbsp;&nbsp;&nbsp;
                            <button className='btn bg-red light-text' onClick={e => {
                                let opts = optionSet?.options?.map(o=>o);
                                opts.splice(ind,1);
                                setOptions(opts);
                            }} style={{padding: '5px 5px'}} >X</button>
                        </li>
                    )}
                </InfoBox>
                <button className='btn bg-dark light-text' onClick={e => {
                    e.preventDefault();
                    let opts = optionSet?.options ? optionSet?.options?.map(o=>o) : [];
                    opts.push({name: ''});
                    setOptions(opts);
                }}>Add Option</button>


                <br/><br/>
                <InfoBox title='Set Classifications:'>
                {classifications.map(row => 
                    <div>
                        <label className='dark-text text-left'><input type='checkbox' form='CreateOptionSetForm' checked={selectedClassifications.map(o=>o?.id).indexOf(row?.id) !== -1} onChange={e => {
                            let newopts = selectedClassifications.map(o=>o);
                            if(e.target.checked) {
                                newopts.push(row)
                            } else {
                                newopts.splice(newopts.map(o=>o?.id).indexOf(row?.id), 1);
                            }
                            setSelectedClassifications(newopts);
                        }} />&nbsp;&nbsp;{row?.name}</label>
                    </div>    
                )}
                </InfoBox>

                <br/><br/>
                <input type='submit' form='CreateOptionSetForm' className='btn bg-dark light-text' />
            </fieldset>
        </div>
    )
}

export default CreateOptionSetForm;