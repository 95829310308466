import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const Queues = () => {
    let context = useContext(AppContext);

    // setting page variables

    let [report, setReport] = useState([]);
    let [summary, setSummary] = useState([]);
    let [reportType, setReportType] = useState('detail');
    let [statusId, setStatusId] = useState(1);
    let [dateColumnLabel] = useState('RMA Created');
    
    if(statusId == 2){
        dateColumnLabel = 'Date Received'
    }else if(statusId==3){
        dateColumnLabel = 'Date Tested'
    }else if(statusId==4){
        dateColumnLabel = 'Date Photoed'
    }else if(statusId==5){
        dateColumnLabel = 'Date Stocked'
    }else if(statusId==6){
        dateColumnLabel = 'Date Listed'
    }else if(statusId==8){
        dateColumnLabel = 'Demo Date'
    }else if(statusId==9){
        dateColumnLabel = 'Hold Date'
    }else if(statusId==10){
        dateColumnLabel = 'Repair Date'
    }else if(statusId==11){
        dateColumnLabel = 'Out For Repair Date'
    }


    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/queues', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({statusId: statusId, reportType: 'detail'})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    function fetchSummary() {
        secureFetch(context.server + '/reports/queues', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({statusId: statusId, reportType: 'summary'})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setSummary(data);
        })
    }

    useEffect(() => {
        fetchSummary();
        fetchReport();
    }, [context, statusId, reportType])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Item Queues</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-dark rounded  dark-text">
                    <div className="container">

        
                        <div className = "bg-light rounded  dark-text">
                                <label className=" text-left" for="status" style={{padding: '15px 15px'}} >Item Status:
                                        <select  name="status" id="selectStatus" onChange={e => setStatusId(Number(e.target.value))}>
                                            <option selected value="1" >Inbound</option>
                                            <option value="2" >Received</option>
                                            <option value="3" >Cleared</option>
                                            <option value="4" >Photographed</option>
                                            <option value="5" >Stocked</option>
                                            <option value="6">Listed</option>
                                            <option value="8">Demo</option>
                                            <option value="9">HOLD</option>
                                            <option value="10">Repair</option>
                                            <option value="11">Out for Repair</option>
                                        </select>
                                </label> 
                                <div  style={{padding: '10px 10px'}}>
                                    <CSVLink data={report} filename='item_queue.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                                </div>      
                        </div>
                        
                        <div style={{padding: '50px 100px'}}>
                        </div>


                        <TableList  columnNames={['Items in Queue:', 'Oldest in Queue:', 'Age (Days):']}
                            columnWidths={['33%','33%','33%']}
                            entries={
                                summary?.map(row => {
                                    return [
                                        row?.total,
                                        row?.date,
                                        row?.age       
                                    ]
                                })
                            } 
                        />
                    </div>
                    
                </div>
                

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report shows all items in any given status.</p>
                </div>

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>


            <TableList columnNames={['SKU', 'Title','Buy Price', 'List Price', 'Consign', 'RMA Number','Status', dateColumnLabel]}
                    columnWidths={['15%','30%','8%','8%','8%','8%','8%','15%']}
                    entries={
                        report?.map(row => {
                            return [
                                
                                <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.product_code}</Link>,
                                row?.title,
                                row?.purchase_amount,
                                row?.list_price,
                                row?.is_consignment,
                                <Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>{row?.tpa_number}</Link>,
                                row?.status,
                                row?.date   
                            ]
                        })
                    } />
           
      
            
        </div>
    );
}

export default Queues;