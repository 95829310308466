import { useEffect, useState } from "react";


const NRTCCalculator = ({formData, submitForm, closeModal}) => {

    let [parentItems, setParentItems] = useState([]);

    let [saleCost, setSaleCost] = useState(0);
    let [saleValue, setSaleValue] = useState(0);
    let [buyValue, setBuyValue] = useState(0);
    let [buyCost, setBuyCost] = useState(0);
    let [nrtc, setNrtc] = useState(0);

    useEffect(() => {
        setParentItems(formData.filter((item =>{return item?.active == 1})))
    }, [formData])

    useEffect(() => {
        setBuyValue(parentItems?.reduce((acc, curr) => {
            return acc + (curr?.rec_list_price || 0);
        }, 0))
        setBuyCost(parentItems?.reduce((acc, curr) => {
            return acc + (parseFloat(curr?.amount_offered) || curr?.rec_buy_price || 0);
        }, 0))
    }, [parentItems])

    useEffect(() => {
        setNrtc(Math.max(Math.min(
            parseFloat((saleValue - saleCost) + (buyValue - buyCost)) * 0.1, 
            buyValue - buyCost
        ), 0))
    }, [buyCost, buyValue, saleCost, saleValue])

    return (
        <div className="bg-light" style={{padding: '15px 15px'}}>
            <h2 className="dark-text">NRTC Calculator</h2>
            <br/>
            {parentItems?.length > 0 ? <>
                <div className="container">
                    <div className="contained">
                        <h3 className="dark-text">Purchase Items</h3>
                        <label className="text-left dark-text">
                            Total Buy Price:&nbsp;&nbsp;
                            <input disabled value={buyCost}/>
                        </label>
                        <label className="text-left dark-text">
                            Total List Price:&nbsp;&nbsp;
                            <input disabled value={buyValue}/>
                        </label>
                    </div>
                    <div className="contained">
                        <h3 className="dark-text">Sale Items</h3>
                        <label className="text-left dark-text">
                            Dealer Cost:&nbsp;&nbsp;
                            <input type='number' value={saleCost} onChange={e => setSaleCost(e.target.value)}/>
                        </label>
                        <label className="text-left dark-text">
                            Sale Price:&nbsp;&nbsp;
                            <input type='number' value={saleValue} onChange={e => setSaleValue(e.target.value)}/>
                        </label>
                    </div>
                </div>
                <br/>
                <div style={{width: '100%', height: '1px', padding: '0% 10%'}} className='bg-dark'/>
                <br/>
                <div className="container">
                    <label className="text-left dark-text contained">
                        Total Purchase Margin:&nbsp;&nbsp;
                        <input type='number' disabled value={buyValue - buyCost}/>
                    </label>
                    <label className="text-left dark-text contained">
                        Total Sale Margin:&nbsp;&nbsp;
                        <input type='number' disabled value={saleValue - saleCost}/>
                    </label>
                </div>
                <br/>
                <div style={{width: '100%', height: '1px', padding: '0% 10%'}} className='bg-dark'/>
                <br/>
                <div>
                <div className="container">
                    
                    <label className="text-left dark-text contained">
                        Net Margin:&nbsp;&nbsp;$
                        <input type='text' disabled value={parseFloat((buyValue - buyCost) + (saleValue - saleCost) - nrtc).toFixed(2)} />
                    </label>
                    <label className="text-left dark-text contained">
                        Available NRTC:&nbsp;&nbsp;
                        <input type='text' disabled className="bg-red light-text" value={`$${parseFloat(nrtc).toFixed(2)}`}/>
                    </label>
                    </div>
                    <label className="text-left dark-text">
                        Net Margin (%):&nbsp;&nbsp;
                        <input type='text' disabled value={ `${(100 * ((buyValue - buyCost) + (saleValue - saleCost) - nrtc) / (parseFloat(saleValue) + buyValue - nrtc)).toFixed(2)}%` } />
                    </label>
                </div>
            </> : <p className="dark-text">Couldn't find items to calculate purchase margins</p>}
        </div>
    )
}

export default NRTCCalculator;