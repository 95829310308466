
export function secureFetch(url, options) {
    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response => {
            // response only can be ok in range of 2XX
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                resolve(response);
            } else {
                //handle errors in the way you want to
                switch (response.status) {
                    case 404:
                        // console.log('Object not found');
                        break;
                    case 500:
                        // console.log('Internal server error');
                        response.json().then(obj => {
                            if(obj?.message) alert(`Oh no! Something went wrong...\n${obj.message}`);
                            else alert('Unknown server error occurred...');
                            window.location.reload();
                        })
                        break;
                    case 401:
                        // console.log('Not Authenticated');
                        window.location.href = window.location.origin + '/login';
                        break;
                    case 503: //this is used for 'Service Unavailable'. Im using this for backend requests that arent entitled
                        response.json().then(obj => {if(obj.message) alert(obj.message)});
                        break;
                    default:
                        // console.log('Some error occured');
                        break;
            }
    
            //here you also can throw custom error too
            reject(response);
            }
        })
        .catch(error => {
            //it will be invoked mostly for network errors
            //do what ever you want to do with error here
            console.log('error: ',error);
            reject(error);
        });
    });
}


//formats a date in YYYY-MM-DD HH:MM:SS format IN UTC TIME ZONE
//options looks like: {date: true, time: false}, or null for everything
export function formatDate(date_string, options) {
    try {
        let final = ''
        // add UTC on the end to specify the time zone so that new Date() will parse it correctly
        date_string += ' UTC';
        let date = new Date(date_string)
        let [DoW, month, day, year] = date.toDateString().split(' ')
        let [hours, minutes, seconds] = date.toTimeString().split(' ')[0].split(':')
        hours = parseInt(hours);
        let pm = hours%12 === hours ? 'am' : 'pm';
        let clock_hour = hours%12==0 ? 12 : hours%12 //so that 12am and pm are 12 instead of 0

        if(options==null || options['date']) {
            final += `${month} ${day} ${year} `;
        }

        if(options==null || options['time']) {
            final += `${clock_hour}:${minutes}`;
        }

        if(options != null && options['seconds']) {
            seconds = parseInt(seconds);
            final += `:${seconds} `;
        }

        if(final){final += `${pm}`}

        return final;
    } catch (err) {
        console.log(err);
        return date_string; //give back the same date string if theres an error, so at least they will have something
    }
    
}

export function dateDifference(date_string1, date_string2) {
    try {
        //add time zone for proper conversion
        date_string1 += ' UTC';
        date_string2 += ' UTC';

        //convert to JS Dates
        let date1 = new Date(date_string1);
        let date2 = new Date(date_string2);

        //get difference in Days
        let diff = date2.getTime() - date1.getTime(); //diff in ms
        let day_diff = parseInt(diff / (1000 * 3600 * 24)) //divide by # of ms in a day, make INT

        return `${day_diff} Days`

    } catch (err) {
        console.log(err);
        return 'N/A';
    }
}
