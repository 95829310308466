/**
 * ItemList.js
 * 
 * lists items, allows for searching items, and viewing individual items. smart search allows for results to display as search terms are typed
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import AppContext from '../AppContext';
import {secureFetch} from '../util/flip_utils';
import TableList from '../components/TableList';
import ModalContainer from '../components/ModalContainer';
import CreateItemForm from '../components/CreateItemForm';
import ImageModalContainer from '../components/ImageModalContainer';
import PaginatedTableList from '../components/PaginatedTableList';
import { useHistory } from 'react-router-dom';


const ItemList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [includeNew, setIncludeNew] = useState(props.location.state?.includeNew || false);
    let [items, setItems] = useState([]);
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '');
    let [testers, setTesters] = useState();
    let [tester, setTester] = useState(props.location.state?.tester || 'null');
    let [statuses, setStatuses] = useState();
    let [status, setStatus] = useState(props.location.state?.status || 'null');
    let [saleTypes, setSaleTypes] = useState();
    let [saleType, setSaleType] = useState(props.location.state?.saleType || 'null');
    let [showFilters, setShowFilters] = useState(props.location.state?.showFilters || false);

    function fetchItems(pageNumber, pageSize) {
        secureFetch(context.server + '/search-items', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                query: searchValue,
                pageSize: pageSize,
                pageNumber: pageNumber,
                includeNew: includeNew,
                tester: tester,
                status: status,
                saleType: saleType
            }) 
        })
        .then(resp => resp.json())
        .then(results => setItems(results) )
    }

    const fetchFields = () => {
            //Get testers
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({tableName: 'users'})
            })
            .then(resp => resp.json())
            .then(results => {
                setTesters(results.filter(e => e.tester && e.active)); console.log(results.filter(e => e.tester && e.active))
            });
        
            //Get statuses
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({tableName: 'item_statuses'})
            })
            .then(resp => resp.json())
            .then(results => {
                setStatuses(results.filter(e=>e.status != 'Sold'))
            });

            //Get sale types
            secureFetch(context.server + '/get-all', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({tableName: 'sale_types'})
            })
            .then(resp => resp.json())
            .then(results => {
                setSaleTypes(results)
            });
        
    }


    useEffect(() => {
        fetchItems(0, 20);
    }, [context, searchValue, includeNew, tester])

    useEffect(() => {
        fetchFields();
    }, [])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/item-list', {searchValue: val, includeNew: includeNew, tester: tester, status: status, saleType: saleType, showFilters: showFilters})
    }

    function updateTester(val){
        setTester(val)
        history.replace('/item-list', {searchValue: searchValue, includeNew: includeNew, tester: val, status: status, saleType: saleType, showFilters: showFilters})
    }

    function updateStatus(val){
        setStatus(val)
        history.replace('/item-list', {searchValue: searchValue, includeNew: includeNew, tester: tester, status: val, saleType: saleType, showFilters: showFilters})
    }

    function updateSaleType(val){
        setSaleType(val)
        history.replace('/item-list', {searchValue: searchValue, includeNew: includeNew, tester: tester, status: status, saleType: val, showFilters: showFilters})
    }

    function updateShowFilters(val){
        setShowFilters(val)
        history.replace('/item-list', {searchValue: searchValue, includeNew: includeNew, tester: tester, status: status, saleType: saleType, showFilters: val})
    }


    
    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained'>Search Items</h1>
                <Link className='btn bg-blue dark-text' to={{pathname: `/tpa-view/${context?.environment=='production' ? 23767 : 1}`}} style={{maxWidth: '100px', minHeight: '100%', marginRight: '10px'}}>PS Audio</Link>
                <ModalContainer triggerText='Create New Item' formData={[]} FormComponent={CreateItemForm} submitForm={
                    (item) => {
                        props.history.push({pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}})
                    }
                } />
                
            </div>
            <form>
                <div className='container'>
                    <input autoFocus type='text' className="search-bar" onChange={e => updateSearchValue(e.target.value)} 
                    value={searchValue} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}/>
                </div>
            </form>

            {showFilters ?
            <div>
                <br/>     
                <br/>
                <div className='bg-light rounded'>
                    <div className = "container">
                        <label className="contained text-left" for="tester" style={{padding: '15px 15px'}} >Tester:&nbsp;
                            <select  name="tester" id="selectTester" onChange={e => updateTester(e.target.value)} value={tester}>
                                <option value='null' >All</option>
                                {testers ? 
                                    testers.map(e => <option value={e.id} >{e?.first_name + ' ' + e.last_name}</option>)
                                : <></>}
                            </select>
                        </label>
                        
                        <label className="contained text-left" for="Status" style={{padding: '15px 15px'}} >Status:&nbsp;
                            <select  name="status" id="selectStatus" onChange={e => updateStatus(e.target.value)} value={status}>
                                <option value='null' >Any</option>
                                {statuses ? 
                                    statuses.map(e => <option value={e.id} >{e?.status}</option>)
                                : <></>}
                            </select>
                        </label>

                        <label className="contained text-left"  style={{padding: '15px 15px'}} >Sale Type:&nbsp;
                            <select  onChange={e => updateSaleType(e.target.value)} value={saleType}>
                                <option value='null' >Any</option>
                                {saleTypes ? 
                                    saleTypes.map(e => <option value={e.id} >{e?.sale_type}</option>)
                                : <></>}
                            </select>
                        </label>
                        
                        &nbsp;&nbsp;
                        
                        <label className='contained light-text vertically-aligned'>Include New:&nbsp;&nbsp;
                            <input type='checkbox' checked={includeNew} onChange={ (e) => setIncludeNew(e.target.checked ? true : false)}/>
                        </label>
                    
                    </div>
                    <button className='btn dark-text' onClick={() => updateShowFilters(!showFilters)}>Hide Filters</button>
                </div>
                <br/>     
                <br/>
            </div>
            :
            <button className='btn dark-text' onClick={() => updateShowFilters(!showFilters)}>Show Filters</button>
            }

            <PaginatedTableList
                columnNames={['Image', 'Title', 'SKU', 'Cost', 'Sale Type',' ', ' ']}
                columnWidths={['15%','35%','10%','10%','10%','10%', '10%']}
                entries={
                    items.map( item => {
                        let img = item?.thumbnail !== null ? 
                        <div className='contained'><ImageModalContainer styleprops={{width: '140'}} source={item?.thumbnail} alter={item?.title} /></div>
                        :
                        <p className='dark-text contained'>{item?.title?.slice(0,40)}</p>;
                        return [
                            img,
                            <Link className='dark-text' to={{pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}}}>{item?.title}</Link>,
                            item?.sku.split('*')[0],
                            item?.purchase_amount,
                            item.sale_type ? item?.sale_type : '',
                            <Link className='btn dark-text' to={{pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}}}>View</Link>,
                            <Link className='btn dark-text' to={{pathname: `/testing/${item?.id}`, state: {item_id: item?.id}}}>Test</Link>,
                        ]
                    })
                }
                getData={fetchItems}
                pageSize={20}
            />
        </div>
    );
}

export default ItemList;