import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
//import InfoList from '../components/InfoList.js';
import { Link, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import { formatDate, secureFetch } from '../util/flip_utils';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import KeyValuePair from '../components/KeyValuePair';


const OrderView = (props) => {
    let context = useContext(AppContext);
    let { order_id } = useParams();
    let [order, setOrder] = useState({});
    let [items, setItems] = useState([]);

    function fetchData() {
        secureFetch(context.server + '/order-overview', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify({id: order_id ? order_id : props.location.state.order_id})
        })
        .then(resp => resp.json())
        .then(results => {
            setOrder(results?.order);
            setItems(results?.items)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    if(!props?.location?.state?.order_id && !order_id) {
        return <h1>Order not found...</h1>
    }
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Order Overview {order?.id ? '' : 'Loading...'}</h1>
            <div className='container'>
                <div className='contained'>
                    <InfoBox title='Order Information:'>
                        <KeyValuePair label='Order ID:' value={order?.id} textClass='dark-text' />
                        <KeyValuePair label='Order Date:' value={formatDate(order?.order_date)} textClass='dark-text' />
                        <KeyValuePair label='Status:' value={order?.order_status} textClass='dark-text' />
                        <KeyValuePair label='Customer:' value={
                            order?.customer_id ?
                            <Link className='btn dark-text' to={{pathname: `/customer-view/${order?.customer_id}`, state: {customer_id: order?.customer_id}}}>{order?.customer}</Link>
                            :
                            'Customer Unavailable'
                        } textClass='dark-text' />
                        <KeyValuePair label='Ship Date:' value={order?.ship_date} textClass='dark-text' />
                        <KeyValuePair label='Carrier:' value={order?.carrier_code} textClass='dark-text' />
                        <KeyValuePair label='Service:' value={order?.service_code} textClass='dark-text' />
                        <KeyValuePair label='Confirmation:' value={order?.confirmation} textClass='dark-text' />
                        <KeyValuePair label='Order Total:' value={order?.total} textClass='dark-text' />
                        <KeyValuePair label='Amount Paid:' value={order?.amount_paid} textClass='dark-text' />
                        <KeyValuePair label='Payment Method:' value={order?.payment_method} textClass='dark-text' />
                        <KeyValuePair label='Weight:' value={order?.weight} textClass='dark-text' />
                        <KeyValuePair label='Dimensions:' value={
                            order?.length + 'x' + order?.width + 'x' + order?.height
                        } textClass='dark-text' />
                        <KeyValuePair label='Shipstation:' value={
                            <a className='btn contained dark-text' href={'https://ship.shipstation.com/orders/all-orders-search-result?quickSearch='+String(order?.order_number)} target='_blank' rel='noreferrer'>{order?.order_number}</a>
                        } textClass='dark-text' />
                        <KeyValuePair label='BC (If applicable):' value={
                            <a className='btn contained dark-text' href={'https://store-6naz0isypk.mybigcommerce.com/manage/orders?viewId='+String(order?.order_number)+'&orderTo='+String(order?.order_number)+'&orderFrom='+String(order?.order_number)} target='_blank' rel='noreferrer'>{order?.order_number}</a>
                        } textClass='dark-text' />
                    </InfoBox>
                </div>
                <div className='contained'>
                    <h2 className='light-text'>Items:</h2>
                    <TableList columnNames={['SKU','Title','Unit Price',' ']}
                    columnWidths={['25%','40%','20%','15%']}
                    entries={
                        items.map( (row) => {
                            return [
                                row?.sku,
                                row?.title,
                                row?.unit_price,
                                <Link className='btn dark-text' to={{pathname: `/item-view/${row?.item_id}`, state: {item_id: row?.id}}}>View</Link>
                            ]
                        })
                    }
                    />
                </div>
            </div>
        </div>
    );
}

export default OrderView;
