/**
 * InfoList.js
 * This component creates an informative list which displays the information stored in the dictionary this.props.data as a formatted key-value pair
 * 
 * InfoList is not a contained class component, so if you want it to be contained, place the <InfoList /> inside a contained component
 **/
import React from 'react';
import '../App.css';

class InfoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
    }

    //allows us to re-render the component when new props are passed to it
    componentWillReceiveProps(nextProps) {
        this.setState({info: nextProps.data});
    }

    //simply renders the info dictionary as a key-value pair
    render() {

        return (
            <>
                <div>
                    <div>
                        {
                            Object.keys(this.state.info).map( (nm, val) => 
                                <div className='container'>
                                    <p className='contained light-text'><b>{nm}:</b></p>
                                    <div className='contained'>
                                        <p className='light-text'>{String(this.state.info[nm])}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default InfoList;