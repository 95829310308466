import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";



const SplitTpaForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [tpa, setTpa] = useState(formData);
    let [items, setItems] = useState(formData?.items);

    let [disableForm, setDisableForm] = useState(false);

    useEffect(() => {
        setTpa(formData);
        setItems(formData?.items);
    }, [formData]);

    function setItem(value, index) {
        let newItems = items?.map(x=>x); //copy without reference
        newItems[index] = value;
        setItems(newItems);
    }

    function submit() {
        secureFetch(context.server + '/split-tpa', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tpa: tpa,
                items: items?.filter(curr => curr?.moveToNewTpa)
            })
        })
        .then(() => {
            submitForm();
            //closeModal();
        })
    }

    return(
        <div>
            <form id='SplitTpaForm' onSubmit={e => submit()} />
            <fieldset form='SplitTpaForm' disabled={disableForm ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className="dark-text">Split RMA</h1>
                    <p className="dark-text"><i>This will create a child RMA under this one. Select below which items you would like to move into the new RMA.
                        The corresponding payment for the original RMA will be adjusted accordingly. If this process would leave the original RMA fully Cleared/OK,
                        its corresponding payment will be approved to pay. A new payment will be created for the new RMA with the appropriate amounts.</i></p>
                    <TableList columnNames={['SKU','Title','Status',' ']}
                    columnWidths={['20%','50%','10%','20%']}
                    entries={items?.map((row, ind) => {
                        return [
                            row?.sku,
                            row?.title,
                            row?.item_status,
                            <input form='SplitTpaForm' type='checkbox' checked={row?.moveToNewTpa=='1'} onChange={e => setItem({...row, moveToNewTpa: e.target.checked ? 1 : 0}, ind)} />
                        ]
                    })} />
                </div>
                <br/>
                <input form='SplitTpaForm' type='submit' className="btn bg-dark light-text" value='OK' />
            </fieldset>
        </div>
    )
}

export default SplitTpaForm;