import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { secureFetch } from "../../util/flip_utils";
import TableList from "../../components/TableList";
import InfoBox from "../../components/InfoBox";

const ValueByStage = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [specifics, setSpecifics] = useState([]);
    let [report, setReport] = useState([]);
    let [recd, setRecd] = useState([]);
    let [recdSKUs, setRecdSKUs] = useState([]);
    let [test, setTest] = useState([]);
    let [testSKUs, setTestSKUs] = useState([]);
    let [photo, setPhoto] = useState([]);
    let [photoSKUs, setPhotoSKUs] = useState([]);
    let [stock, setStock] = useState([]);
    let [stockSKUs, setStockSKUs] = useState([]);
    let [repair, setRepair] = useState([]);
    let [repairSKUs, setRepairSKUs] = useState([]);
    let [hold, setHold] = useState([]);
    let [holdSKUs, setHoldSKUs] = useState([]);
    let [demo, setDemo] = useState([]);
    let [demoSKUs, setDemoSKUs] = useState([]);
    let [total, setTotal] = useState([]);
    let [visible, setVisible] = useState([]);
    let [invisible, setInvisible] = useState([]);

    function fetchReport() {
        secureFetch(context.server + '/reports/value-by-stage', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(data => {
            setReport(data);

            setRecd(data.unlisted.recd);
                setRecdSKUs(data.skus.recdSKUs);
            setTest(data.unlisted.test);
                setTestSKUs(data.skus.testSKUs);
            setPhoto(data.unlisted.photo);
                setPhotoSKUs(data.skus.photoSKUs);
            setStock(data.unlisted.stock);
                setStockSKUs(data.skus.stockSKUs);
            setRepair(data.unlisted.repair);
                setRepairSKUs(data.skus.repairSKUs);
            setHold(data.unlisted.hold);
                setHoldSKUs(data.skus.holdSKUs);
            setDemo(data.unlisted.demo);
                setDemoSKUs(data.skus.demoSKUs);
            setTotal(data.unlisted.total);
            setVisible(data.listed.visible);
            setInvisible(data.listed.invisible);
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context])

    return(
        
        <div className="p-8">
            <InfoBox title="Summary">
                <table>
                    <tr>
                        <th className="w-1/12">Unlisted</th>
                            <td className="w-1/12">rec-test</td>
                            <td className="w-1/12">test-photo</td>
                            <td className="w-1/12">photo-stock</td>
                            <td className="w-1/12">stock-list</td>
                            <td className="w-1/12">REPAIR</td>
                            <td className="w-1/12">HOLD</td>
                            <td className="w-1/12">DEMO</td>
                            <th className="w-1/12">TOTAL</th>
                    </tr>
                    <tr>
                        <td>list prices</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(recd.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(test.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(photo.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(stock.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(repair.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(hold.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(demo.price))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(total.price))}</td>
                    </tr>
                    <tr>
                        <td>cost prices</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(recd.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(test.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(photo.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(stock.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(repair.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(hold.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(demo.cost))}</td>
                            <td className="text-base">{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(total.cost))}</td>
                    </tr>
                    <tr>
                        <td>qty</td>
                        <td>
                            <button onClick={function(){setSpecifics(recdSKUs)}} className="btn text-black">{recd.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(testSKUs)}} className="btn text-black">{test.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(photoSKUs)}} className="btn text-black">{photo.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(stockSKUs)}} className="btn text-black">{stock.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(repairSKUs)}} className="btn text-black">{repair.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(holdSKUs)}} className="btn text-black">{hold.qty}</button></td><td>
                            <button onClick={function(){setSpecifics(demoSKUs)}} className="btn text-black">{demo.qty}</button></td><td>
                            {total.qty}
                        </td>
                    </tr>
                    <br/><br/>
                    <tr>
                        <th>Listed</th>
                        <td>CPO visible</td>
                        <td>CPO invisible</td>
                        <th>TOTAL</th>
                    </tr>
                    <tr>
                        <td>prices</td>
                        <td>{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(visible.prices))}</td>
                        <td>{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(invisible.prices))}</td>
                        <td>{new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(Number(1*visible.prices+1*invisible.prices))}</td>
                    </tr>
                    <tr>
                        <td>qty</td>
                        <td>{visible.qty}</td>
                        <td>{invisible.qty}</td>
                        <td>{1*visible.qty+1*invisible.qty}</td>
                    </tr>
                </table>
            </InfoBox>
            <br/>
            <InfoBox title="Other Details">
                <div className="container">
                    <p className="contained">% inventory in a queue: {Number(100*(recd.qty+test.qty+photo.qty+stock.qty)/(total.qty+visible.qty+invisible.qty)).toFixed(2)}</p>
                    <p className="contained">% CPO listed: {Number(100*(visible.qty+invisible.qty)/(total.qty+visible.qty+invisible.qty)).toFixed(2)}</p>
                    <p className="contained">demos removed: {Number(100*(visible.qty+invisible.qty)/(visible.qty+invisible.qty+total.qty-demo.qty)).toFixed(2)}</p>
                </div>
            </InfoBox>
            <br/>
            <div id='specifics'>
                <h3>Specifics</h3>
                <TableList columnNames={['sku','buy price','price','qty','title']} columnWidths={['7%','10%','10%','3%','70%']}
                    entries={specifics.map(row=>{return [row?.sku,row?.buy,row?.list,row?.qty,row?.title]})} />
            </div>
        </div>
    );
}

export default ValueByStage;