import { useContext, useEffect, useState } from "react"
import AppContext from "../AppContext"
import { secureFetch } from "../util/flip_utils";


const ProductClassificationsForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [classifications, setClassifications] = useState([]);
    let [productClassifications, setProductClassifications] = useState([]);
    let [optionSets, setOptionSets] = useState([]);
    let [productOptions, setProductOptions] = useState([]);

    let [collapsed, setCollapsed] = useState(false);

    
    function fetchOptionSets() {
        secureFetch(context?.server + '/all-option-sets', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
        })
        .then(resp => resp.json())
        .then(results => setOptionSets(results))
    }

    function fetchData() {
        //get pre-selected classifications
        secureFetch(context.server + '/product-classifications', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({product_id: formData?.id})
        })
        .then(resp => resp.json())
        .then(r_prodClass => {
            //get all option set + mappings first
            secureFetch(context?.server + '/all-option-sets', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            })
            .then(resp => resp.json())
            .then(r_optionSets => {
                setOptionSets(r_optionSets)
                // alert(JSON.stringify(r_optionSets));
                //get all classifications
                secureFetch(context?.server + '/get-all', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        tableName: 'classifications',
                    })
                })
                .then(resp => resp.json())
                .then(r_classifications => {
                    //now get mappings of classifications to option sets
                    secureFetch(context?.server + '/get-all', {
                        method: 'POST',
                        credentials: 'include',
                        headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            tableName: 'classification_option_sets',
                        })
                    })
                    .then(resp => resp.json())
                    .then(r_cos => {
                        //generate classifications structure
                        //should result in a list of all classifications, each with a list of related option sets + options
                        r_classifications = r_classifications?.map(cl => {
                            return {...cl,
                                related_option_sets: r_cos?.filter(cos => cos?.classification_id == cl?.id)
                                ?.map(cos => r_optionSets[r_optionSets?.map(os=>os?.id)?.indexOf(cos?.option_set_id)])
                            }
                        })
                        //fill productClassifications stateful variable with the same shape as classifications
                        r_prodClass = r_prodClass?.map(pc => {
                            return r_classifications[ r_classifications?.map(c=>c?.id)?.indexOf(pc?.classification_id) ]
                        })
                        setClassifications(r_classifications);
                        setProductClassifications(r_prodClass);
                    })
                })
            })
        });
    }

    useEffect(() => {
        fetchData();
        fetchOptionSets();
    }, [])

    function submit(event) {
        event?.preventDefault();
        secureFetch(context.server + '/add-product-classifications', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                productId: formData?.id,
                classifications: productClassifications
            })
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    function handleProductClassificationChecked(row) {
        return (e) => {
            let prodClass = productClassifications.map(c=>c);
            if(e?.target?.checked != 0) {
                prodClass.push(row);
            } else {
                let index = productClassifications?.map(pc => pc?.id)?.indexOf(row?.id);
                prodClass.splice(index, 1);
            }
            // alert(prodClass?.map(pc=>JSON.stringify(pc?.related_option_sets)))
            setProductClassifications(prodClass);
        }
    }

    return (
        <div>
            <form id='ProductOptionsForm' onSubmit={submit} />
            
            <div style={{padding: '15px 15px'}} className='bg-light'>
                <h1 className="dark-text">Edit Product Options:</h1>
                <div className="container">
                    <div className='contained' style={{maxWidth: '30%'}}>
                        <div className="bg-lightest" style={{padding: '5px 5px'}}>
                            <h2 className="dark-text">
                                Classifications:
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button className='btn light-text bg-dark' onClick={() => setCollapsed(!collapsed)}>{collapsed ? 'expand v' : 'collapse ^'}</button>
                            </h2>
                            <br/>
                            {collapsed ? <></> : classifications.map((row, ind) => 
                                <label className="dark-text text-left">
                                    <input form='ProductOptionsForm' type='checkbox' key={row?.id} checked={productClassifications.map(c=>c?.id).indexOf(row?.id) >= 0}
                                    onChange={handleProductClassificationChecked(row)} />
                                    &nbsp;&nbsp;&nbsp;{row?.name}
                                </label>
                            )}
                        </div>
                    </div>
                    <div className='contained'>
                        <div className="bg-lightest" style={{padding: '5px 5px'}}>
                            <h2 className="='dark-text">Option Sets:</h2>
                            {productClassifications.map(row =>
                                <div>
                                    <h3 className='dark-text text-left'>{row?.name}</h3>
                                    {row?.related_option_sets
                                    ?.map(row => 
                                        <p><b>{row?.name}</b>&nbsp;-&nbsp;{row?.options?.map(r => r?.name)?.join(', ')}</p>
                                    )}
                                    <hr/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <br/>
                <button className="btn bg-dark light-text" onClick={submit}>Submit Classifications</button>
            </div>
        </div>
    )
}

export default ProductClassificationsForm