import { useEffect, useState } from "react";


//creates the component given the header component and each of the listentry components
const ListBuilder = ({header, entries, footer}) => {
    return (
        <div className='list-view'>
            <div>{header}</div>
            <div>
                {entries.map( (ent, ind) => {
                    return <>
                    <div key={ind}>{ent}</div>
                    <div style={{padding: '0% 2%'}}><div className="bg-light" style={{minHeight: '1px', height: '1px', maxHeight: '1px', width: '100%'}}></div></div>
                    </>;
                })}
            </div>
            <div>{footer}</div>
        </div>
    );
}

//creates the styled header given the column names and widths
const Header = ({columnNames, columnWidths, pageNumber, pageSize, next, prev}) => {
    return (
        <div className='header'>
            <p className='dark-text text-right'>Showing results for page {pageNumber+1}: {pageSize * pageNumber}-{pageSize * (pageNumber+1)}&nbsp;&nbsp;
            <button className='btn dark-text bg-lightest' style={{color: "#162133"}} onClick={prev}>{'<-'}</button>&nbsp;&nbsp;
            <button className='btn dark-text bg-lightest' style={{color: "#162133"}} onClick={next}>{'->'}</button>
            </p>
            <div className='container'>
            {columnNames.map( (col, index) => {
                return <div key={index} className='contained' style={{maxWidth: columnWidths[index]}}>
                    <p  key={index} className='dark-text'><b>{col ? col : 'null'}</b></p>
                </div>;
            })}
            </div>
        </div>
    );
}

//takes a LIST of entries, so you'll need to map your list of objects to [ [col1, col2, col3], [col1, col2, col3], [col1, col2, col3]... ]
const ListEntry = ({entryVals, columnWidths}) => {
    return (
        <div className='container'>
            {entryVals.map( (val, index) => {
                return <div key={index} className='dark-text contained container' style={{maxWidth: columnWidths[index]}}>{val!==null ? val : 'None'}</div>
            })}
        </div>
    );
}

//creates the styled header given the column names and widths
const Footer = ({pageNumber, pageSize, next, prev}) => {
    return (
        <div className='header'>
            <br/>
            <p className='dark-text text-right'>Showing results for page {pageNumber + 1}: {pageSize * pageNumber}-{pageSize * (pageNumber+1)}&nbsp;&nbsp;
            <button className='btn dark-text bg-lightest' style={{color: "#162133"}} onClick={prev}>{'<-'}</button>&nbsp;&nbsp;
            <button className='btn dark-text bg-lightest' style={{color: "#162133"}} onClick={next}>{'->'}</button>
            </p>
            <br/>
        </div>
    );
}

const PaginatedTableList = ({columnNames, columnWidths, entries, getData, pageSize=20}) => {
    let [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        getData(pageNumber, pageSize);
    }, [pageNumber])

    let numCols = columnNames.length;
    if(columnWidths.length !== numCols) return <>widths, names, and entries must be same length</>;
    let head = <Header
        columnNames={columnNames}
        columnWidths={columnWidths}
        pageNumber={pageNumber}
        pageSize={pageSize}
        next={() => setPageNumber(pageNumber+1)}
        prev={() => {if(pageNumber!=0) setPageNumber(pageNumber-1);}} //dont let them go back a page if they cant
        />;
    let foot = <Footer
        pageNumber={pageNumber}
        pageSize={pageSize}
        next={() => setPageNumber(pageNumber+1)}
        prev={() => {if(pageNumber!=0) setPageNumber(pageNumber-1);}} //dont let them go back a page if they cant
        />;
    let rows = entries.map( (entryVals, index) => {
        return <ListEntry entryVals={entryVals} columnWidths={columnWidths}/>;
    })
    return <ListBuilder header={head} entries={rows} footer={foot} />;
}

export default PaginatedTableList;