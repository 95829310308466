import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const BuyerConversionReport = () => {
    let context = useContext(AppContext);

    let [reportType, setReportType] = useState('summary');
    let [subType, setSubType] = useState('organic');
    let [interval, setInterval] = useState(60);
    let [report, setReport] = useState([]);
    let [catBrand, setCatBrand] = useState(-1);
    let [interestedIn, setInterestedIn] = useState("");
    let [subRepId, setSubRepId] = useState("");
    let [subRepName, setSubRepName] = useState("");
    let [source, setSource] = useState("B2C");
    let [orderBy, setOrderBy] = useState("rep");
    let [subReps, setReps] = useState([]);
    let [dateType, setDateType] = useState('interval')
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);


    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }

    function changeDateTypeInterval(event){ //using this so that the report matches the default value when switching back to "interval" dateType
        setDateType('interval')
        setInterval(60)
    }

    function fetchReport() {
        secureFetch(context.server + '/reports/buyer-conversion', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({reportType: reportType, interval: interval, subType: subType,catBrand: catBrand,interestedIn: interestedIn, subRep: subRepId,source: source, orderBy: orderBy, dateType: dateType, startDate:startDate, endDate:endDate})
        })
        .then(res => res.json())
        .then(res => {

            setReps(res.submission_reps)

            let data = res.report
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data);



        })
    }

    useEffect(() => {
        fetchReport();
    }, [context, reportType, subType, interval, catBrand,interestedIn,subRepId,source,orderBy, dateType, startDate, endDate])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>

                <div className = "bg-light rounded  dark-text" style={{padding: '15px 30px'}}>
                    <div class = "container">
                        <h1  className="text-3xl font-bold" style={{padding: '5px 10px'}}>Buyer Conversion Report</h1>
                        <InfoButton onClick = {infoFunction}/> 
                    </div>
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report looks at all submissions excluding the following:</p>
                        <p>- Manually created submissions</p>
                        <p>- Submissions marked "pass"</p>
                        <p>- Submissions for which all items have been marked "pass" by appraising</p>
                        <p>- Inactive submissions (merged into others)</p>
                        <p>- Submissions without an assigned rep</p>
                        <br></br>
                        <p><b>Date Range:</b> </p>
                        <p> This parameter filters for submissions assigned to a rep within the given frame. When using the Interval option, this includes a 14 day delay. For example, the 30 day report looks at all submissions submitted between 44 and 14 days ago and shows how many of those specific submissions have been received.
                            When using the Custom Date Range option, all submissions which have an assigned rep during that time frame are shown, with no 14 day delay.
                        </p>
                        <br></br>
                        <p><b>Trade-In Button:</b>  </p>
                        <p>This filters submissions by how the user arrived at our submission form, whether from a new product page, a used product page, or neither. Leaving this as "Select an Option" applies no filter, showing submissions that come from anywhere on the website.</p>
                        <br></br>
                        <p><b>Interested In:</b>  </p>
                        <p>Selected by the customer when filling out the submission.</p>
                        <br></br>
                        <p>**Selecting "Trade Towards New" and "Trade Towards Used" also shows submissions navigated to using the New/Used Product Trade buttons using the following logic:</p>
                        <p>Trade Towards New =  (Trade Towards New selected) OR (New Product Trade button AND Trade Towards Used not selected)</p>
                        <p>Trade Towards Used = (Trade Towards Used selected) OR (Used Product Trade button AND Trade Towards New not selected)</p> 
                        <br></br>
                        <p><b>Receiving Data:</b> </p>
                        <p>For this report an RMA is considered received if at least one item has been received. </p>
                        <br></br>
                        <p><b>Caveats: </b></p>
                        <p>- Submission rep data not populated until late 12/22</p>
                        <p>- Receiving data was not collected until late 7/22</p>
                        
                
                    </div>
                

                    <div className="container">               
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                                <b className="dark-text">Report Type</b>
                                <div className="container">
                                    <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                                </div>
                                <div className="container">
                                    <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                                </div>
                                <br></br>                         
                        </div>
                        <div style={{padding: '15px 10px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            <b className="dark-text">Date Range</b>
                            <div style={{padding: '15px 15px'}}>
                                <label className=" text-left"><input type='radio' checked={dateType=='interval'} onChange={e => e.target.checked==1 ? changeDateTypeInterval()  : null} />&nbsp;Interval</label>
                                <label className=" text-left"><input type='radio' checked={dateType=='customRange'} onChange={e => e.target.checked==1 ? setDateType('customRange') : null}/>&nbsp;Custom Date Range</label>
                            </div>
                            {dateType == 'interval' ? <> 
                                <label className=" text-left" for="interval" style={{padding: '15px 15px'}} >Report Interval:
                                    <select  name="inverval" id="selectInterval" onChange={e => setInterval(Number(e.target.value))}>
                                        <option value="30" >30 Days</option>
                                        <option selected value="60" >60 Days</option>
                                        <option value="120" >120 Days</option>
                                        <option value="365" >365 Days</option>
                                        <option value="0">All Time (Without Offset)</option>
                                    </select>
                                </label>

                            </> : <>
                                <div className='bg-white rounded' style={{padding: '15px 10px'}}>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                    </div>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                    </div>
                                    <p><i>Leave end date blank to get a one-day report.</i></p>
                                    {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                    <br/>
                                </div>
                            </>}
                        </div>
                        <div style={{padding: '15px 10px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            <b className="dark-text">Parameters</b>
                            <label className=" text-left" for="subType" style={{padding: '15px 10px'}} >Submission Type: &nbsp;
                                    <select  name="subType" id="selectSubType" onChange={e => setSubType(e.target.value)}>
                                        <option selected value="organic" >Organic</option>
                                        <option value="sg" >Self-Generated (Manual)</option>
                                        <option value="any" >Any</option>
                                    </select>
                            </label>
                    
                            <label className=" text-left" for="catBrand" style={{padding: '15px 10px'}} >Trade-In Button: &nbsp;     
                                <select  name="catBrand" id="selectCatBrand" onChange={e => setCatBrand(Number(e.target.value))}>
                                    <option selected value="-1" >Select an Option</option>
                                    <option value="2" >New Product Trade</option>
                                    <option  value="1" >Used Product Trade</option>
                                    <option value="0" >Neither</option>
                                    
                                </select>
                            </label>

                            <label className=" text-left" for="interestedIn" style={{padding: '15px 10px'}} >Interested In:&nbsp;      
                                <select  name="interestedIn" id="selectInterestedIn" onChange={e => setInterestedIn(e.target.value)}>
                                    <option selected value="" >Select an Option</option>
                                    <option value="sell" >Sell To TMR</option>
                                    <option value="consign" >Sell With TMR</option>
                                    <option value="tradeNew" >Trade Towards New</option>
                                    <option value="tradeUsed" >Trade Towards Used</option>
                                    <option value="notSure" >Not Sure</option>
                                    
                                </select>
                            </label>

                            <label className=" text-left" for="source" style={{padding: '15px 10px'}} >Source: &nbsp;     
                                <select  name="catBrand" id="selectSource" onChange={e => setSource(e.target.value)}>
                                    <option selected value="B2C" >B2C</option>
                                    <option value="B2B" >B2B</option>
                                    <option  value="All" >All</option>
                                </select>
                            </label>
  
                        </div>
                        <div style={{padding: '15px 10px'}}></div>
                        <div className='bg-lightest rounded' style={{padding: '15px 15px'}}>
                            {reportType=='summary' ? <>
                                <div>
                                    <b>Order By:</b> 
                                </div>

                                <div style={{padding: '15px 15px'}}>
                                    <select  name="orderBy" id="selectOrderBy" onChange={e => setOrderBy(e.target.value)}>
                                        <option selected value="rep" >Rep</option>
                                        <option value="submissions" >Total Submissions</option>
                                        <option  value="recieved_TPAs" >Received RMAs</option>
                                        <option value="conversion_sorting_ignore" >Conversion Percentage</option>    
                                    </select>
                                </div>
                                
                            </> : <>
                                <div>
                                        <div>
                                            <b>Submission Rep</b>
                                        </div>
                                        <div style={{padding: '15px 15px'}}>
                                            <select  name="submissionRep" id="selectSubmissionRep" onChange={e => {setSubRepId(e.target.value); setSubRepName(e.target.selectedOptions[0].label)}} value={subRepId}>
                                                <option value='null' >All</option>
                                                {subReps ? 
                                                    subReps.filter(p => p.id != subRepId).map(e => <option value={e.id} >{e?.submission_rep}</option>)
                                                : <></>}
                                                {subRepId && subRepId != 'null' ?
                                                <option value={subRepId} >{subRepName}</option>
                                                :<></>}
                                            </select>
                                        </div>
                                   
                                </div>
                            </>}

                        </div>
                    
                    </div> 
                    
                    <div style={{padding: '10px 10px'}}></div>
                    
                
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='buyer_conversion.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>

                
                {reportType=='summary' ? <>
                <br></br>
                
                <TableList columnNames={['Rep','Total Submissions','Rec. List (Sum)','Received RMAs','Conversion Percentage']}
                    columnWidths={['20%','20%','20%','20%','20%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.rep,
                                row?.submissions,
                                '$'+row?.rec_list_sum,
                                row?.recieved_TPAs,
                                row?.received_conversion_percentage
                            ]
                        })
                    } />
                </> : <>
                <br></br>
                <TableList columnNames={['Sub. ID','Sub. Rep','Rec. List (Sum)','Sub. Assigned to Rep','TPA','Received']}
                    columnWidths={['15%','15%','15%','15%', '20%','20%']}
                    entries={report?.map(row => {
                        return [
                            <Link className='btn dark-text' to={{pathname: `/submission-view/${row?.submission_id}`, state: {submission_id: row?.submission_id}}}>{row?.submission_id}</Link>,
                            row?.submission_rep,
                            '$'+row?.rec_list_sum,
                            formatDate(row?.report_date, {date: true}),
                            <Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>{row?.tpa_number}</Link>,
                            row?.received
                        ]
                    })}
                />
                </>}
                <br/>
            </div>
        </div>
    );
}

export default BuyerConversionReport;