/**
 * CustomerList.js
 * 
 * Renders a list of all most recently created customers. Alternatively, if query terms are entered into the search bar, a smart search will give results matching the search criteria given
 * next to each use, a button allows you to navigate to a the CustomerView page, which displays information about the specific customer.
 */


import React, { useContext, useEffect, useState } from 'react';
import AppContext from "../AppContext"
import '../App.css';
import { Link } from 'react-router-dom';
import {formatDate, secureFetch} from '../util/flip_utils';
import TableList from '../components/TableList';
import PaginatedTableList from '../components/PaginatedTableList';
import ModalContainer from '../components/ModalContainer';
import CreateCustomerForm from '../components/CreateCustomerForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const CustomerList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [customers, setCustomers] = useState([]);
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '');

    //fetch the list of customers from searchValue
    function fetchCustomers(pageNumber, pageSize) {
        secureFetch(context.server + '/search-customers', { 
            credentials: 'include', 
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            }) 
        })
        .then(resp => resp.json())
        .then(results => setCustomers(results) )
    }

    //fetch customers with empty query when page mounts, and every time search string changes
    useEffect(() => {
        fetchCustomers(0, 20);
    }, [context, searchValue])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/customer-list', {searchValue: val})
    }

    //renders the actual html for the page.
    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained'>Search Customers</h1>
                <ModalContainer triggerText='Create New Customer' formData={{}} FormComponent={CreateCustomerForm} submitForm={cust => {
                    history.push({pathname: `/customer-view/${cust?.id}`, state: {customer_id: cust?.id}});
                }} />
            </div>
            <form>
                <div className='container'>
                    <input autoFocus type='text' className="search-bar" value={searchValue} 
                    onChange={e => updateSearchValue(e.target.value)} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}} />
                </div>
            </form>
            <br/>
            <br/>

            <PaginatedTableList
            columnNames={['Name', 'Email', 'Phone', 'ID Number', 'Created', 'Action']}
            columnWidths={['20%','20%','20%','20%','10%','10%']}
            entries={
                customers.map( (row) => {
                    return [
                        row?.name,
                        row?.email,                            
                        row?.phone,
                        row?.id,
                        formatDate(row?.created),
                        <Link className='btn contained dark-text' to={{pathname: `/customer-view/${row?.id}`, state: {customer_id: row?.id}}}>View</Link>
                    ]
                })
            }
            getData={fetchCustomers}
            />

        </div>
    );
}

export default CustomerList;
