import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import {secureFetch} from '../util/flip_utils';
import KeyValuePair from '../components/KeyValuePair';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import { RatingColorCoder } from '../components/ColorCoders';
import BrandPartnerLogo from '../components/BrandPartnerLogo';
import CheckmarkYN from '../components/CheckmarkYN';
import ModalContainer from '../components/ModalContainer';
import EditBrandForm from '../components/EditBrandForm';

const BrandView = (props) => {
    let context = useContext(AppContext);
    let { brand_id } = useParams();
    let [brand, setBrand] = useState({});
    let [products, setProducts] = useState([]);
        
    function fetchData() {
        secureFetch(context.server + '/brand-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: brand_id ? brand_id : props.location.state.brand_id})
        })
        .then(resp => resp.json())
        .then(result => {
            setProducts(result?.products);
            setBrand(result?.brand);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    
    if(!props?.location?.state?.brand_id && !brand_id) {
        return <h1>Product not found...</h1>
    }


    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Brand Overview {brand?.id ? '' : 'Loading...'}</h1>
            <div className='container'>
                <div className='contained'>
                    
                    <InfoBox title='Brand Information:'>
                        <KeyValuePair label={'ID:'} value={brand?.id} textClass={'dark-text'} />
                        <KeyValuePair label={'Brand Name:'} value={brand?.brand} textClass={'dark-text'} />
                        <KeyValuePair label={'Brand Partner:'} value={brand?.brand_partner ? <BrandPartnerLogo height = {"25px"}/>  : <CheckmarkYN val={brand?.brand_partner} />} textClass={'dark-text'}/>
                        <KeyValuePair label={<>Rating:&nbsp;<Link className="btn dark-text" to={{pathname: '/brand-ratings'}}>Manage</Link></>} value={<RatingColorCoder content={brand?.generic_rating || 'N/A'} rating={brand?.generic_rating} />} textClass='dark-text' />
                        <KeyValuePair label={"Internal Brand Notes:"} value={brand?.notes} />
                    </InfoBox>
                    <ModalContainer triggerText="Edit Brand" formData={brand} submitForm={() => window.location.reload()} FormComponent={EditBrandForm} />
                    <br/>
                    <h3 className='light-text'>Recent Products:</h3>
                    <TableList columnNames={['Title',' ',]}
                    columnWidths={['75%%','25%']}
                    entries={products?.map(row => {
                        return [
                            row?.title,
                            <Link className='btn dark-text' to={{pathname: `/product-view/${row?.id}`, state: {product_id: row?.id}}} >View</Link>,
                        ]
                    })}
                    />
                    
                </div>
                
            </div>
        </div>
    );
}

export default BrandView;
