import ModalContainer from "./ModalContainer";


const ConfirmationModal = ({formData, submitForm, closeModal}) => {
    function cancelButtonClicked(e) {
        formData?.cancelHandler(e);
        closeModal();
    }

    return (
        <div>
            <p className='dark-text'>{formData?.confirmation}</p>
            <div className='container'>
                <button style={{maxWidth: '100px'}} className='btn bg-green' onClick={(event) => {event.preventDefault();submitForm(event);closeModal();}} >OK</button>
                <button style={{maxWidth: '100px'}} className='btn' onClick={cancelButtonClicked}>Cancel</button>
            </div>
        </div>
    );
}


const ConfirmButton = ({buttonText,buttonClass = "btn dark-text",clickHandler,cancelHandler = ()=>{},confirmation}) => {
    return (
        <ModalContainer triggerText={buttonText} buttonClass={buttonClass || 'btn dark-text'} submitForm={clickHandler} formData={{confirmation: confirmation, cancelHandler: cancelHandler}} FormComponent={ConfirmationModal} />
    );
}

export default ConfirmButton;