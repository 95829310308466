import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const UnappraisedSubmissions = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [reportType, setReportType] = useState('summary');
    let [subRep, setSubRep] = useState("")


    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }



    function fetchReport() {
        secureFetch(context.server + '/reports/unappraised-submissions', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({reportType: reportType, subRep: subRep})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    function appraiseButton(productId, itemId, submission_id){
        if(productId > 0){
            return <Link className='btn dark-text' to={{pathname: `/appraise-item/${itemId}`, state: {item_id: itemId}}} target='_blank'>Appraise</Link>
        }else{
            return <Link className='btn dark-text' to={{pathname: `/submission-view/${submission_id}`, state: {submission_id: submission_id}}} target='_blank'>Assign Product</Link>
        }
    }

    useEffect(() => {
        fetchReport();
    }, [context, reportType, subRep])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Unappraised Submissions</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    <div className="container">                       
                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='submission'} onChange={e => e.target.checked==1 ? setReportType('submission') : null} />&nbsp;Submissions</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='item'} onChange={e => e.target.checked==1 ? setReportType('item') : null} />&nbsp;Items</label>
                        </div>
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='unappraised-submissions.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report shows all active submissions which have a status of "unappraised" or "partially appraised".</p>
 
                </div>

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType=='summary' ? <>
                <br></br>
                <TableList columnNames={['Submission Rep', 'Unappraised Submissions']}
                        columnWidths={['20%','20%']}
                        entries={
                            report?.map(row => {
                                return [
                                    row?.submission_rep,
                                    row?.unappraised_submissions,
                                ]
                            })
                        } />
                </> : <>
                {reportType == 'submission' ? <>
                    <label className="light-text text-left bg-" for="subRep" style={{padding: '4px 15px'}}  >Submission Rep:      
                            <select  name="subRep" id="selectSubRep" onChange={e => setSubRep(e.target.value)}>
                                <option selected value="" >Select an Option</option>
                                <option value="Ben Klenk" >Ben Klenk</option>
                                <option  value="Devin Schmidt" >Devin Schmidt</option>
                                <option value="Ian LaHood" >Ian Lahood</option>
                                <option value="Jason Manning" >Jason Manning</option>
                                <option value="Jerry Kall" >Jerry Kall</option>
                                <option value="Mario Alonzo" >Mario Alonzo</option>
                                <option value="Michael Horne" >Michael Horne</option>
                                <option value="Nicholas Lucini" >Nicholas Lucini</option>
                                <option value="Ryan Tarasen" >Ryan Tarasen</option>
                                <option value="Unassigned" >Unassigned</option>
                            </select>
                    </label>
                    <TableList columnNames={['Submission ID', 'Submission Status','Email Address', 'Submission Rep', 'Submission Created','Sub Rep Assigned', 'Current Assignee']}
                        columnWidths={['10%','12%','15%','12%','15%','15%','12%']}
                        entries={
                            report?.map(row => {
                                return [
                                    <Link className='btn dark-text' to={{pathname: `/submission-view/${row?.submission_id}`, state: {submission_id: row?.submission_id}}} target='_blank'>{row?.submission_id}</Link>,
                                    row?.submission_status,
                                    row?.email_address,
                                    row?.rep,
                                    row?.created,
                                    row?.rep_assigned_date,
                                    row?.current_assignee
                                ]
                            })
                        } 
                    />
                </> : <>
                <TableList columnNames={[' ', 'Manufacturer', 'Model','Submission', 'Submission Status', 'Customer', 'Submission Date', 'Submission Rep', 'Current Assignee' ]}
                        columnWidths={['8%','10%','20%','8%','12%','12%','10%','10%','8%']}
                        entries={
                            report?.map(row => {
                                return [
                                    appraiseButton(row?.product_id, row?.id,row?.submission_id),
                                    row?.manufacturer,
                                    row?.model_number,
                                    <Link className='btn dark-text' to={{pathname: `/submission-view/${row?.submission_id}`, state: {submission_id: row?.submission_id}}} target='_blank'>{row?.submission_id}</Link>,
                                    row?.submission_status,
                                    row?.customer,
                                    row?.submission_date,
                                    row?.submission_rep,
                                    row?.current_assignee
                                ]
                            })
                        } 
                    />
                </>}       
            </>}       
            
        </div>
        
                    

    );
}

export default UnappraisedSubmissions;