import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import InfoBox from "../components/InfoBox";
import { secureFetch } from "../util/flip_utils";

const Documentation = () => {
    let context = useContext(AppContext);
    let [sops, setSops] = useState([]);
    let [categories, setCategories] = useState([]);

    function fetchData() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'sops',
                params: {
                    sort: {
                        key: 'sequence_number',
                        order: 1
                    }
                }
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setSops(results);

            //get unique categories by converting to Set, then back to array
            let cats = [...new Set( results?.map(row => row?.category) )]
            setCategories(cats);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div style={{padding: '15px 15px'}}>
            <div>
                <h1>Standard Operating Procedures</h1>
                <br/><br/>
            </div>
            <div className='container'>
                <div className='contained'>
                    {sops?.length > 0 ?
                    <>
                        {categories?.map(cat => {
                            let entries = sops?.filter(row => row?.category == cat);
                            return <>
                            <InfoBox title={cat}>
                                {entries?.map(row => 
                                    <><a className="btn dark-text" target='_blank' rel='noreferrer' href={row?.link}>{row?.sop_name}</a><br/><br/></>
                                )}
                            </InfoBox>
                            <br/>
                            </>
                        })}
                    </>
                    :<p className="light-text">SOPs Unavailable</p>}         
                </div>
            </div>
        </div>
    );
}

export default Documentation;