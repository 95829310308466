import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const RmaItemBrandRating = () => {
    let context = useContext(AppContext);

    let [report, setReport] = useState([]);
    let [interval, setInterval] = useState(7)
    let [dateType, setDateType] = useState('interval')
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [brandRating, setBrandRating] = useState(null);
    let [itemType, setItemType] = useState(null);


    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }

    function changeDateTypeInterval(event){ //using this so that the report matches the default value when switching back to "interval" dateType
        setDateType('interval')
        setInterval(7)
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/rma-item-brand-rating', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({dateType: dateType, interval: interval, startDate: startDate, endDate: endDate, brandRating: brandRating, itemType: itemType})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context, interval,dateType, startDate, endDate, brandRating, itemType])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>

                
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>RMA Item Brand Rating</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>
                
                <div id="infoBox" class = "bg-light rounded" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report shows all items on RMAs created in the selected time period, along with the associated Brand's rating.</p> 
                </div>
                
                

                <div className = "bg-light rounded  dark-text">
                    <div className="container">       
                        <div className="container" >
                            <div style={{padding: '15px 15px'}}>
                                <label className=" text-left"><input type='radio' checked={dateType=='interval'} onChange={e => e.target.checked==1 ? changeDateTypeInterval()  : null} />&nbsp;Interval</label>
                                <label className=" text-left"><input type='radio' checked={dateType=='customRange'} onChange={e => e.target.checked==1 ? setDateType('customRange') : null}/>&nbsp;Custom Date Range</label>
                            </div>
                            {dateType == 'interval' ? <> 
                                <label className=" text-left" for="interval" style={{padding: '15px 15px'}} >Report Interval:
                                    <select  name="inverval" id="selectInterval" onChange={e => setInterval(Number(e.target.value))}>
                                        <option selected value="7" >7 Days</option>
                                        <option value="14" >14 Days</option>
                                        <option value="30" >30 Days</option>
                                        <option value="60" >60 Days</option>
                                        <option value="120" >120 Days</option>
                                        <option value="365" >365 Days</option>
                                        <option value="">All Time (may take a few moments to load)</option>
                                    </select>
                                </label>

                            </> : <>
                                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                    </div>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                    </div>
                                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                                    {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                    <br/>
                                </div>
                            </>}
                            <label className=" text-left" for="brandRating" style={{padding: '15px 15px'}} >Brand Rating: 
                                <select  name="inverval" id="selectBrandRating" onChange={e => setBrandRating(e.target.value)}>
                                    <option selected value = ''  >Any</option>
                                    <option value="A" >A</option>
                                    <option value="B" >B</option>
                                    <option value="C" >C</option>
                                    <option value="D" >D</option>
                                    <option value="F" >F</option>
                                    
                                </select>
                            </label>
                            <label className=" text-left" for="itemType" style={{padding: '15px 15px'}} >Item Type: 
                                <select  name="itemType" id="selectBrandRating" onChange={e => setItemType(e.target.value)}>
                                    <option selected value = ''  >Any</option>
                                    <option value="0" >Purchase</option>
                                    <option value="1" >Consign</option>
                                    
                                </select>
                            </label>                         
                        </div>
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='rma_item_brand_rating.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                
                
                
               

                
                


                <h2 className="light-text" style={{padding: '0px 0px 15px 0px'}}>Report at a glance:</h2>
                
                

                <TableList columnNames={['title', 'brand', 'brand_rating', 'is_consignment','sales_rep', 'list_price', 'purchase_amount', 'rma_number', 'created']}
                    columnWidths={['11%','11%','11%','11%','11%','11%','11%','11%','11%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.title,
                                row?.brand,
                                row?.brand_rating,
                                row?.is_consignment,
                                row?.sales_rep,
                                row?.list_price,
                                row?.purchase_amount,
                                row?.rma_number,
                                row?.created

                            ]
                        })
                    } />
               
                <br/>
            </div>
        </div>
    );
}

export default RmaItemBrandRating