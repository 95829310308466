import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from '../components/TableList';

const AddNoteForm = ({formData,  closeModal}) => {
    let context = useContext(AppContext);
    let [currentListings, setCurrentListings] = useState([]);
   
    function fetchData() {
        secureFetch(context?.server + '/currently-listed-consignments', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                customerId: formData
            })
        })
        .then(resp => resp.json())
        .then(resp =>{setCurrentListings(resp?.currentListings); console.log(resp?.currentListings);})
        //.then(resp => setCurrentListings(resp?.currentListings))
    }
    

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>
        <h1 className="dark-text">Current Consignment Listings from Big Commerce:</h1>
        <br/>
        <TableList
            columnNames={['SKU','Title','RMA','Current List Price']}
            columnWidths={['15%','15%','20%','30%']}
            entries={
                currentListings?.map( row => {
                    return [
                        <Link className={ 'btn dark-text' } to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                        row?.title,
                        <Link className={ 'btn dark-text' } to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>{row?.tpa_number}</Link>,
                        row?.currentListPrice,
                        row?.visible ? <a className = { 'btn dark-text' } rel="noreferrer" target='_blank' href={'https://tmraudio.com'+row?.url}>View Listing</a> : 'Listing not visible in BC'
                        
                    ]
                })
            }
        />
        
    </div>
    );
}

export default AddNoteForm;