import { useEffect, useState } from "react";


const modes = [
    {id: 0, name: 'Buy/Sell'},
    {id: 1, name: 'Consignment - Standard'},
    {id: 2, name: 'Consignment - Vintage'},
    {id: 3, name: 'Mixed - Buy/Sell + Standard Consignment'},
]

function round(val) {
    return parseInt(val + 0.5);
}


const SHIP_BUDGET_PCG = 0.15;

//parent component which renders the correct form and info depending on which option they have selected
const ShippingBudgetCalculator = ({formData, submitForm, closeModal}) => {
    let [mode, setMode] = useState(0);

    function submit(data) {
        submitForm(data);
        closeModal();
    }

    return(
        <div className="bg-light" style={{padding: '15px 15px'}}>
            <h1 className="dark-text">Shipping Budget Calculator</h1>
            <br/>
            <label className="dark-text text-left">Calculation Mode:</label>
            <select form='ShippingBudgetCalc' onChange={e => setMode(e.target.value)} value={mode}>
                {modes?.map(row =>
                    <option key={row?.id} value={row?.id}>{row?.name}</option>
                )}
            </select>
            {mode==0 ?
                <BuySell callback={submit} />
            : mode==1 ?
                <ConsignStd callback={submit} />
            : mode==2 ?
                <ConsignVtg callback={submit} />
            :
                <Mixed callback={submit} />
            }
        </div>
    )
}


const BuySell = ({callback}) => {
    let [mv, setMv] = useState(0);
    let [bp, setBp] = useState(0);
    let [ship, setShip] = useState(0);

    let [margin, setMargin] = useState(0);
    let [shipBudget, setShipBudget] = useState(0);
    let [custCost, setCustCost] = useState(0);
    let [adjBp, setAdjBp] = useState(0);

    useEffect(() => {
        //all answers rounded to whole numbers

        //margin as market value less buy price
        setMargin(  round(mv-bp)  );

        //shipping budget as percentage of margin
        setShipBudget(  round(SHIP_BUDGET_PCG * (mv-bp))  )

        //customer cost as total shipping minus what we will pay
        let cc = round(ship - (SHIP_BUDGET_PCG * (mv-bp)))
        setCustCost(cc <= 0 ? 0 : cc);

        //adjust buy price to account for how much of shipping they need to pay us
        //dont need to round adj because all the involved numbers are already rounded and its only subtraction
        setAdjBp(cc > 0 ? bp-cc : 'N/A')
    }, [mv, bp, ship])

    function submit(e) {
        e.preventDefault();
        callback({
            sale_type: 'Buy/Sell',
            market_value: mv,
            buy_price: bp,
            shipping_cost: ship,
            margin: margin,
            shipping_budget: shipBudget,
            customer_shipping_cost: custCost,
            adjusted_buy_price: adjBp
        })
    }

    return (
        <div>
            <br/>
            <form id='BuySell' onSubmit={submit}>
                <label className="dark-text text-left">Market Value: *&nbsp;&nbsp;
                    <input form='BuySell' type='number' required value={mv} onChange={e => setMv(e.target.value)} />
                </label>
                <label className="dark-text text-left">Buy Price: *&nbsp;&nbsp;
                    <input form='BuySell' type='number' required value={bp} onChange={e => setBp(e.target.value)} />
                </label>
                <label className="dark-text text-left">Shipping Cost:&nbsp;&nbsp;
                    <input form='BuySell' type='number' value={ship} onChange={e => setShip(e.target.value)} />
                </label>
                <br/>
                <label className="dark-text text-left">Margin:&nbsp;&nbsp;
                    <input form='BuySell' type='text' value={margin} disabled />
                </label>
                <label className="dark-text text-left">Our Shipping Budget:&nbsp;&nbsp;
                    <input form='BuySell' type='text' value={shipBudget} disabled />
                </label>
                <label className="dark-text text-left">Customer's Shipping Cost:&nbsp;&nbsp;
                    <input form='BuySell' type='text' value={custCost} disabled />
                </label>
                <label className="dark-text text-left">Adjusted Total Buy Price:&nbsp;&nbsp;
                    <input form='BuySell' type='text' value={adjBp} disabled />
                </label>
                <br/>
                <input form='BuySell' className="btn bg-dark light-text" type='submit' value='Copy to Clipboard'/>
            </form>
        </div>
    )
}


const CSN_AVG_SALE_PCG = 0.898957
const CSN_STD_FEE = 0.35
const CSN_HIGH_MV_FEE = 0.15
const CSN_HIGH_MV_THRESHHOLD = 1000
const CSN_HIGH_MV_FLAT = parseInt(CSN_STD_FEE * CSN_HIGH_MV_THRESHHOLD)

const ConsignStd = ({callback}) => {
    let [lp, setLp] = useState(0);
    let [ship, setShip] = useState(0);

    let [mvEst, setMvEst] = useState(0);
    let [csnFee, setCsnFee] = useState(0);
    let [bpEst, setBpEst] = useState(0);
    let [shipBudget, setShipBudget] = useState(0);
    let [custCost, setCustCost] = useState(0);

    useEffect(() => {
        //all answers rounded to whole numbers

        //estimate market value (ie sale price)
        let mv = round(lp*CSN_AVG_SALE_PCG)
        setMvEst(  mv  );

        //use market value to estimate "margin" (ie consignment fee)
        //consign fee is 35% of the first $1000, plus 15% after that (ie 350 + 15% after 1000)
        let csf = round(
            mv <= CSN_HIGH_MV_THRESHHOLD ?
                ( mv * CSN_STD_FEE )
                : ( CSN_HIGH_MV_FLAT + ((mv-CSN_HIGH_MV_THRESHHOLD) * CSN_HIGH_MV_FEE) )
        )
        setCsnFee(  csf  );

        //generate a "buy price" just for sanity check
        let bp = round(mv - csf)
        setBpEst(  bp  )

        //calc shipping budget based on "margin" (consign fee)
        setShipBudget(  round(SHIP_BUDGET_PCG * (mv-bp))  )

        //calc customer cost (total shipping cost - what we will pay)
        let cc = round(ship - (SHIP_BUDGET_PCG * (mv-bp)))
        setCustCost(cc <= 0 ? 0 : cc);
    }, [lp, ship])

    function submit(e) {
        e.preventDefault();
        callback({
            sale_type: 'Consignment - Standard',
            list_price: lp,
            shipping_cost: ship,
            market_value_estimate: mvEst,
            consignment_fee: csnFee,
            buy_price: bpEst,
            shipping_budget: shipBudget,
            customer_shipping_cost: custCost,
        })
    }

    return (
        <div>
            <br/>
            <form id='ConsignStandard' onSubmit={submit}>
                <label className="dark-text text-left">List Price: *&nbsp;&nbsp;
                    <input form='ConsignStandard' type='number' required value={lp} onChange={e => setLp(e.target.value)} />
                </label>
                <label className="dark-text text-left">Shipping Cost:&nbsp;&nbsp;
                    <input form='ConsignStandard' type='number' value={ship} onChange={e => setShip(e.target.value)} />
                </label>
                <br/>
                <label className="dark-text text-left">Market Value Est.:&nbsp;&nbsp;
                    <input form='ConsignStandard' type='text' value={mvEst} disabled />
                </label>
                <label className="dark-text text-left">Consign Fee / "Margin":&nbsp;&nbsp;
                    <input form='ConsignStandard' type='text' value={csnFee} disabled />
                </label>
                <label className="dark-text text-left">"Buy Price":&nbsp;&nbsp;
                    <input form='ConsignStandard' type='text' value={bpEst} disabled />
                </label>
                <label className="dark-text text-left">Our Shipping Budget:&nbsp;&nbsp;
                    <input form='ConsignStandard' type='text' value={shipBudget} disabled />
                </label>
                <label className="dark-text text-left">Customer's Shipping Cost:&nbsp;&nbsp;
                    <input form='ConsignStandard' type='text' value={custCost} disabled />
                </label>
                <br/>
                <input form='ConsignStandard' className="btn bg-dark light-text" type='submit' value='Copy to Clipboard'/>
            </form>
        </div>
    )
}

const CSN_VTG_FEE = 0.5

const ConsignVtg = ({callback}) => {
    let [lp, setLp] = useState(0);
    let [ship, setShip] = useState(0);

    let [mvEst, setMvEst] = useState(0);
    let [csnFee, setCsnFee] = useState(0);
    let [bpEst, setBpEst] = useState(0);
    let [shipBudget, setShipBudget] = useState(0);
    let [custCost, setCustCost] = useState(0);

    useEffect(() => {
        //all answers rounded to whole numbers

        //estimate market value (ie sale price)
        let mv = round(lp*CSN_AVG_SALE_PCG)
        setMvEst(  mv  );

        //use market value to estimate "margin" (ie consignment fee)
        //consign fee is flat for vintage
        let csf = round( mv * CSN_VTG_FEE )
        setCsnFee(  csf  );

        //generate a "buy price" just for sanity check ( will be equal to consign fee if the rate is 50%)
        let bp = round(mv - csf)
        setBpEst(  bp  )

        //calc shipping budget based on "margin" (consign fee)
        setShipBudget(  round(SHIP_BUDGET_PCG * (mv-bp))  )

        //calc customer cost (total shipping cost - what we will pay)
        let cc = round(ship - (SHIP_BUDGET_PCG * (mv-bp)))
        setCustCost(cc <= 0 ? 0 : cc);
    }, [lp, ship])

    function submit(e) {
        e.preventDefault();
        callback({
            sale_type: 'Consignment - Standard',
            list_price: lp,
            shipping_cost: ship,
            market_value_estimate: mvEst,
            consignment_fee: csnFee,
            buy_price: bpEst,
            shipping_budget: shipBudget,
            customer_shipping_cost: custCost,
        })
    }

    return (
        <div>
            <br/>
            <form id='ConsignVintage' onSubmit={submit}>
                <label className="dark-text text-left">List Price: *&nbsp;&nbsp;
                    <input form='ConsignVintage' type='number' required value={lp} onChange={e => setLp(e.target.value)} />
                </label>
                <label className="dark-text text-left">Shipping Cost:&nbsp;&nbsp;
                    <input form='ConsignVintage' type='number' value={ship} onChange={e => setShip(e.target.value)} />
                </label>
                <br/>
                <label className="dark-text text-left">Market Value Est.:&nbsp;&nbsp;
                    <input form='ConsignVintage' type='text' value={mvEst} disabled />
                </label>
                <label className="dark-text text-left">Consign Fee / "Margin":&nbsp;&nbsp;
                    <input form='ConsignVintage' type='text' value={csnFee} disabled />
                </label>
                <label className="dark-text text-left">"Buy Price":&nbsp;&nbsp;
                    <input form='ConsignVintage' type='text' value={bpEst} disabled />
                </label>
                <label className="dark-text text-left">Our Shipping Budget:&nbsp;&nbsp;
                    <input form='ConsignVintage' type='text' value={shipBudget} disabled />
                </label>
                <label className="dark-text text-left">Customer's Shipping Cost:&nbsp;&nbsp;
                    <input form='ConsignVintage' type='text' value={custCost} disabled />
                </label>
                <br/>
                <input form='ConsignVintage' className="btn bg-dark light-text" type='submit' value='Copy to Clipboard'/>
            </form>
        </div>
    )
}



const Mixed = ({callback}) => {
    let [totalBsMv, setTotalBsMv] = useState(0);
    let [totalBsBp, setTotalBsBp] = useState(0);
    let [totalCsnLp, setTotalCsnLp] = useState(0);
    let [ship, setShip] = useState(0);

    let [totalCsnMv, setTotalCsnMv] = useState(0);
    let [totalMargin, setTotalMargin] = useState(0);
    let [totalBp, setTotalBp] = useState(0);
    let [shipBudget, setShipBudget] = useState(0);
    let [custCost, setCustCost] = useState(0);

    useEffect(() => {
        //all answers rounded to whole numbers

        //margin as market value less buy price + Consignment fee
        let csn_mv = round(totalCsnLp * CSN_AVG_SALE_PCG);
        setTotalCsnMv(  csn_mv  )
        let csf = round(
            csn_mv <= CSN_HIGH_MV_THRESHHOLD ?
                ( csn_mv * CSN_STD_FEE )
                : ( CSN_HIGH_MV_FLAT + ((csn_mv-CSN_HIGH_MV_THRESHHOLD) * CSN_HIGH_MV_FEE) )
        )
        let mgn = round(totalBsMv - totalBsBp + csf)
        setTotalMargin(  mgn  );

        //total "buy price" as buy/sell buy price + (consign total mv - consign margin)
        //for some reason totalBsBp comes as a string and appends as opposed to adding the numbers
        let bp = round(parseInt(totalBsBp) + (csn_mv - csf))
        setTotalBp(  bp  );

        //shipping budget as percentage of margin
        setShipBudget(  round(SHIP_BUDGET_PCG * (mgn))  )

        //customer cost as total shipping minus what we will pay
        let cc = round(ship - (SHIP_BUDGET_PCG * (mgn)))
        setCustCost(cc <= 0 ? 0 : cc);
    }, [totalBsMv, totalBsBp, totalCsnLp, ship])

    function submit(e) {
        e.preventDefault();
        callback({
            sale_type: 'Mixed - Buy/Sell + Standard Consignment',
            total_buy_sell_market_value: totalBsMv,
            total_buy_sell_buy_price: totalBsBp,
            total_consign_list_price: totalCsnLp,
            shipping_cost: ship,
            total_margin: totalMargin,
            total_buy_price: totalBp,
            shipping_budget: shipBudget,
            customer_shipping_cost: custCost
        })
    }

    return (
        <div>
            <br/>
            <form id='MixedBuyConsign' onSubmit={submit}>
                <label className="dark-text text-left">Total Market Value (Buy/Sell): *&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='number' required value={totalBsMv} onChange={e => setTotalBsMv(e.target.value)} />
                </label>
                <label className="dark-text text-left">Total Buy Price (Buy/Sell): *&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='number' required value={totalBsBp} onChange={e => setTotalBsBp(e.target.value)} />
                </label>
                <label className="dark-text text-left">Total List Price (Consign): *&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='number' required value={totalCsnLp} onChange={e => setTotalCsnLp(e.target.value)} />
                </label>
                <label className="dark-text text-left">Total Shipping Cost:&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='number' value={ship} onChange={e => setShip(e.target.value)} />
                </label>
                <br/>
                <label className="dark-text text-left">Total Consignment "Market Value":&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='text' value={totalCsnMv} disabled />
                </label>
                <label className="dark-text text-left">Total "Margin":&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='text' value={totalMargin} disabled />
                </label>
                <label className="dark-text text-left">Total "Buy Price":&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='text' value={totalBp} disabled />
                </label>
                <label className="dark-text text-left">Total Shipping Budget:&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='text' value={shipBudget} disabled />
                </label>
                <label className="dark-text text-left">Customer's Shipping Cost:&nbsp;&nbsp;
                    <input form='MixedBuyConsign' type='text' value={custCost} disabled />
                </label>
                <br/>
                <input form='MixedBuyConsign' className="btn bg-dark light-text" type='submit' value='Copy to Clipboard'/>
            </form>
        </div>
    )
}


export default ShippingBudgetCalculator;