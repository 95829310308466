import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const ItemsTested = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportType, setReportType] = useState('summary');
    let [testers, setTesters] = useState()
    let [tester, setTester] = useState();
    

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }

    function fetchTesters(){
        //Get testers
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'users'})
        })
        .then(resp => resp.json())
        .then(results => {
            setTesters(results.filter(e => e.tester && e.active)); console.log(results.filter(e => e.tester && e.active))
        });
    }

    function fetchReport() {
        secureFetch(context.server + '/reports/items-tested', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate, reportType: reportType, tester: tester})  
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, startDate, endDate, reportType, tester])

    useEffect(()=>{
        fetchTesters();
    },[])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Items Tested</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report shows all items that have been tested during the selected time period.</p>
                    </div> 

                    <div className="container">
                        
                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                        </div>

                        <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                        </div>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                        </div>
                                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                                        {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                        <br/>
                        </div>

                        {reportType =='detail' ? 
                            <label className="contained text-left" for="tester" style={{padding: '15px 15px'}} >Tester:&nbsp;
                                <select  name="tester" id="selectTester" onChange={e => setTester(e.target.value)} value={tester}>
                                    <option value='null' >All</option>
                                    {testers ? 
                                        testers.map(e => <option value={e.id} >{e?.first_name + ' ' + e.last_name}</option>)
                                    : <></>}
                                </select>
                            </label>
                        :
                        <></>    
                        }
                        

        

                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='items-tested.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                

  

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType == 'summary' ? 
                <TableList columnNames={['Tester', 'Total Items Tested', 'RA Items Tested', 'Issues Marked']}
                columnWidths={['20%','15%', '15%', '50%']}
                entries={
                    report?.map(row => {
                        return [
                            row?.tester,
                            <>{row?.unique_items_tested}&nbsp;&nbsp;&nbsp;{row?.tester == 'Totals:' || row?.tester == 'Freshdesk Tickets' || row?.tester == 'Total List Value' ? '' : ' ('+row?.items_tested +')'}</>,
                            row?.ra_items_tested,
                            row?.issues_marked
                            
                        ]
                    })
                } />
            :
            <TableList columnNames={['Tester', 'SKU', 'Title', 'Cleared/Issue', 'Current Status','Date Tested']}
                columnWidths={['10%','10%','35%','15%','15%','15%']}
                entries={
                    report?.map(row => {
                        return [
                            row?.tester,
                            <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                            row?.title,
                            row?.tested_status,
                            row?.current_status,
                            row?.date_tested
                            
                            
                        ]
                    })
                } />
            }
            
           
      
            
        </div>
    );
}

export default ItemsTested;