import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import { Link, useParams } from "react-router-dom";
import TableList from "../../components/TableList";
import FlipRTV from "../../components/FlipRTV";

const AutolisterCheck = () => {
    let context = useContext(AppContext);
    let [report, setReport] = useState([]);

    function fetchReport() {
        secureFetch(context.server + '/reports/autolister-check', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' } 
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    useEffect(() => {
        fetchReport();
    }, [])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>Autolister Check</h1>
                <br/>
                <p className="light-text">Items shown were finalized &gt; 24 hours ago and do not have a BC ID</p>
                <br/>
                <CSVLink data={report} filename='autolister_check.csv' className='btn dark-text'>Export Report to CSV</CSVLink>
                <br/>
                <br/>
                <h2 className="light-text">Report at a glance:</h2>
                <TableList columnNames={['SKU', 'Title', 'List Date']}
                columnWidths={['20%', '55%', '25%']}
                entries={report?.map(row => {
                    return [
                        <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {row_id: row?.id}}}>{row?.sku}</Link>,
                        row?.title,
                        row?.item_listed_date,
                    ]
                })}
                />
                <br/>
            </div>
        </div>
    );
}

export default AutolisterCheck;