import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import AppContext from '../AppContext';
import ImageArranger from '../components/ImageArranger';
import InfoBox from '../components/InfoBox';
import InfoList from '../components/InfoList';
import KeyValuePair from '../components/KeyValuePair';
import ModalContainer from '../components/ModalContainer';
import TableList from '../components/TableList';
import { formatDate, secureFetch } from '../util/flip_utils';
import EditListingForm from '../components/EditListingForm';
import { Link } from 'react-router-dom';

const ListingQueue = (props) => {
    let context = useContext(AppContext);
    let [items, setItems] = useState([]);
    let [searchValue, setSearchValue] = useState('');


    function fetchQueue() {
        secureFetch(context.server + '/listing-queue', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
        })
        .then(resp => resp.json())
        .then(results => setItems(results));
    }

    useEffect(() => {
        fetchQueue();
    },[])


    
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Listing Queue</h1>
            <br></br>
            <Link className='btn dark-text' to={{pathname: '/reports/autolister-check'}} >Autolister Check</Link><br/><br/>
            <br></br>
            <TableList columnNames={['SKU','Title','Photo Date','Action']}
            columnWidths={['10%','50%','20%','20%']}
            entries={
                items.map( row => {
                    return [
                        row?.sku,
                        row?.title,
                        formatDate(row?.photo_date),
                        <Link className='btn dark-text' to={{pathname: `/list-item/${row?.id}`, state: {item_id: row?.id}}}>Finalize</Link>
                    ]
                })
            }
            />
        </div>
    );
}

export default ListingQueue;
