import { useContext, useEffect, useState } from "react"
import AppContext from "../../AppContext"
import { secureFetch } from "../../util/flip_utils";
import InfoBox from "../InfoBox";
import OptionsUI from "./OptionsUI";

/**
 * only provide item IF IT EXISTS, this component does NOT support updating item after render
 */
const ItemOptionsUI = ({item, updateParent}) => {
    let context = useContext(AppContext);

    let [defaults, setDefaults] = useState([]);
    let [availableOptionSets, setAvailableOptionSets] = useState([]);

    let [usingProduct, setUsingProduct] = useState(false);

    function fetchItemOptions() {
        secureFetch(context.server + '/item-options-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({itemId: item?.id})
        })
        .then(resp => resp.json())
        .then(results => {
            setAvailableOptionSets(results?.possibleOptions);
            if(results?.itemOptions?.length == 0) {
                setDefaults(results?.productOptions)
                setUsingProduct(true);
                updateParent({...item, options: results?.productOptions})
            } else {
                setDefaults(results?.itemOptions);
                setUsingProduct(false);
                updateParent({...item, options: results?.itemOptions})
            }
            
        })
    }

    useEffect(() => {
        if(item?.product_id)
            fetchItemOptions();
    }, [])

    useEffect(() => {
        updateParent({...item, options: defaults})
    }, [defaults])

    if(!item?.product_id) {
        return(
            <InfoBox title="Options">
                <p className="dark-text">No Product ID set, can't pull option sets.</p>
            </InfoBox>
        )
    }

    return(<div>
        {usingProduct ? <p className="light-text bg-red"><i>(currently using product defaults)</i></p> : <></>}
        <OptionsUI parentOptions={defaults} updateParentOptions={setDefaults} availableOptionSets={availableOptionSets} />

    </div>)
}

export default ItemOptionsUI;