import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import ImageModalContainer from "./ImageModalContainer";

//only these fields can be edited
const editable_fields = [
    'id',
    'item_id',
    'photo_link',
    'sequence_number',
    'active'
]

//gets the safe version of the image, cutting out all fields that arent allowed to be edited
function getSafeVersion(image) {
    let newImage = {};
    let keys = Object.keys(image);
    let vals = Object.values(image);
    for(let i = 0; i < keys.length; i++) {
        if( editable_fields.indexOf( keys[i] ) >= 0 ) { //if the field is allowed to edit, put that part into the new json
            newImage[keys[i]] = vals[i]
        }
    }
    return newImage;
}

//takes a list of images, and returns the list of safe versions
function batchGetSafeVersion(imgs) {
    let newImgs = []
    imgs.forEach( img => {
        newImgs.push(getSafeVersion(img))
    })
    return newImgs;
}



const ImageArranger = ({item_id, submitHandler, updateParentComponent = ()=>{}}) => {
    //set up state, context, history
    const context = useContext(AppContext);
    let history = useHistory();
    const [images, setImages] = useState([]);
    const [fetched, setFetched] = useState(false);

    //fetches the images from the db and updates state
    function fetchImages() {
        setFetched(false); //first set fetched to false so they cant do stuff while its loading
        let body = {
            sort: {
                key: 'sequence_number',
                order: 1
            },
            equal: [{
                key: 'item_id',
                value: item_id
            }]
        }
        //fetch the photos
        secureFetch(context.server + '/item-photos', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(resp => resp.json())
        .then(results => {
            //console.log(results)

            //update the state for the items, and set fetched back to true
            setImages(results);
            setFetched(true);
        })
    }

    /* eslint-disable */
    //fetch the images on load
    useEffect( () => {
        fetchImages();
    }, [])
    /* eslint-enable */

    
    useEffect(() => {
        updateParentComponent(images);
    }, [images])


    //takes an id and gives the index of that image in the images state
    function findImage(id) {
        for(let i = 0; i < images.length; i++) {
            if(images[i]?.id === id)
                return i;
        }
        return -1;
    }


    //removes an image by id from the used images
    //this is done by setting its sequence number to -1, then adjusting the rest of the list to reflect it being removed
    function remove(id) {
        let newImgs = images;
        let index = findImage(id);
        if(index !== -1) {
            newImgs[index].sequence_number = -1; //set the SN to -1
            //move all the images right of it in the order 1 to the left
            for(let i = index + 1; i < images.length; i++) {
                newImgs[i].sequence_number--;
            }
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //moves the image with the given id to the left of the order
    function moveFarLeft(id) {
        let newImgs = images;
        let index = findImage(id);
        if(index !== -1 && newImgs[index]?.sequence_number !== 0) { //dont let do anything if its already on the left
            //move everything left of the selected one to the right 1
            newImgs.forEach( img => {
                if(img?.sequence_number !== -1 && img?.sequence_number < newImgs[index]?.sequence_number)
                    img.sequence_number++;
            })
            newImgs[index].sequence_number = 0;
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //moves the given item left by one
    function moveLeft(id) {
        let newImgs = images;
        let index = findImage(id);
        if(index !== -1 && newImgs[index]?.sequence_number !== 0) { //dont do anything if its already on the left
            //move the image to the left right by one
            newImgs.forEach( img => {
                if(img?.sequence_number === newImgs[index]?.sequence_number -1)
                    img.sequence_number++;
            })
            newImgs[index].sequence_number--;
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //moves the given item right by one
    function moveRight(id) {
        let newImgs = images;
        let index = findImage(id);
        //figure out what the last SN is
        let last = newImgs.reduce((acc, curr) => curr?.sequence_number > acc ? curr?.sequence_number : acc, -1);
        if(index !== -1 && newImgs[index]?.sequence_number !== last) { //dont do anything if its already on the right
            //move the item to the right left by one
            newImgs.forEach( img => {
                if(img?.sequence_number === newImgs[index]?.sequence_number + 1)
                    img.sequence_number--;
            })
            newImgs[index].sequence_number++;
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //moves the given item to the right end of the order
    function moveFarRight(id) {
        let newImgs = images;
        let index = findImage(id);
        //figure out what the last SN is
        let last = newImgs.reduce((acc, curr) => curr?.sequence_number > acc ? curr?.sequence_number : acc, -1);
        if(index !== -1 && newImgs[index]?.sequence_number !== last) { //dont do anything if its on the right already
            //move everything right of it to the left by one
            newImgs.forEach( img => {
                if(img?.sequence_number !== -1 && img?.sequence_number > newImgs[index]?.sequence_number)
                    img.sequence_number--;
            })
            newImgs[index].sequence_number = last;
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //adds an image back into the order at the end, from the 'unused images' space.
    function addImage(id) {
        let newImgs = images;
        let index = findImage(id);
        if(index !== -1) {
            let count = 0;
            //figure out the last SN in the list
            newImgs.forEach( img => {
                if(img?.sequence_number !== -1)
                    count++
            })
            //put the image at the end
            newImgs[index].sequence_number = count;
        }
        //console.log(newImgs)
        //sort the list so it displays correctly
        newImgs.sort( (a, b) => {return a?.sequence_number-b?.sequence_number }); //see JavaScript docs for how this works
        setImages([...newImgs]);
    }


    //submits the order of photos to the backend and goes back to the item view page
    function submitOrder() {
        secureFetch(context.server + '/update-photos', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({images: batchGetSafeVersion(images)})
        })
        .then( () => submitHandler(images)) 
    }




    if(fetched)
        if(images?.length > 0)
        return (
            <>
                <h2 className='light-text'>Image Order:</h2>
                <div className='scroll-wrapper'>
                <div className='scroll-container'>
                    {images.map( (imgObject, index) => {
                        if(imgObject?.sequence_number !== -1) {
                            return <div className='contained-2' key={index} style={{maxWidth: '170px'}}>
                                <ImageModalContainer id={`img_${index}`} styleprops={{height: '100px', maxWidth: '95%'}} source={imgObject?.photo_link} alter='link failed' />
                                <div className='container-2'>
                                    <button className='btn dark-text contained-2' onClick={() => moveFarLeft(imgObject?.id)}>{'<<'}</button>
                                    <button className='btn dark-text contained-2' onClick={() => moveLeft(imgObject?.id)}>{'<'}</button>
                                    <button className='btn dark-text contained-2' onClick={() => remove(imgObject?.id)}>{'x'}</button>
                                    <button className='btn dark-text contained-2' onClick={() => moveRight(imgObject?.id)}>{'>'}</button>
                                    <button className='btn dark-text contained-2' onClick={() => moveFarRight(imgObject?.id)}>{'>>'}</button>
                                </div>
                                <p className='light-text'>{imgObject?.sequence_number}</p>
                            </div>
                        } else {
                            return null;
                        }
                    })}
                </div>
                </div>
                <div className='container'>
                    <h2 className='light-text contained'>Unused Images:</h2>
                </div>
                <div className='container'>
                {images.map( (imgObject, index) => {
                        if(imgObject?.sequence_number === -1) {
                            return <div className='contained' key={index} style={{maxWidth: '140px'}}>
                                <ImageModalContainer id={`img_${index}`} styleprops={{ height: '100px', maxWidth: '95%'}} source={imgObject?.photo_link} alter='link failed' />
                                <div className='container'>
                                    <button className='btn dark-text contained' onClick={() => addImage(imgObject?.id)}>{'+'}</button>
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    })}
                </div>
                <br/><br/>
                <button className='btn dark-text contained' style={{maxWidth: '150px'}} onClick={submitOrder}>Submit Sequence</button>
            </>
        )
        else
            return (
                <>
                <h2 className='light-text'>No images to display for this item...</h2>
                </>
            )
    else
        return (
            <h1 className='light-text'>Loading images...</h1>
        );
}

export default ImageArranger;
