import React, { useContext, useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import '../App.css';
import AppContext from '../AppContext';
import KeyValuePair from '../components/KeyValuePair';
import ModalContainer from '../components/ModalContainer';
import { secureFetch } from '../util/flip_utils';
import EditProductForm from '../components/EditProductForm';
import SelectProductForm from '../components/SelectProductForm';
import InfoBox from '../components/InfoBox';
import { Link, useParams } from 'react-router-dom';
import ConfirmButton from '../components/ConfirmButton';
import FlipRTE from '../components/FlipRTE';
import ItemOptionsUI from '../components/OptionsUIs/ItemOptionsUI';
import CheckmarkYN from '../components/CheckmarkYN';
import SplitQuantityForm from '../components/SplitQuantityForm';
import DatePicker from "react-date-picker";

const REPAIR_ID = 10;
const HOLD_ID = 9;
const CLEARED_ID = 3;

const default_functional = 'Operational condition of this item is excellent, fully tested and no issues found.';

const editable_fields = [,
    'id',
    'sku',
    'title',
    'serial_number',
    'number_of_parts',
    'cosmetic_condition_id',
    'cosmetic_description',
    'item_condition_id',
    'service_history',
    'what_is_included',
    'functional_notes',
    'age_in_years',
    'num_of_owners',
    'tobacco_exposure',
    'list_price',
    'active',
    'description',
    'item_status_id',
    'options',
    'tpa_id',
    'is_consignment',
    'deductions',
    'tester_notes',
    'purchase_amount', //needed so that the backend knows if its consignment or not
    'submission_item_id', //needed to get submission data for RMA Issue ticket
]

function getSafeVersion(item) {
    let safe_item = {}
    editable_fields.forEach((key) => {
        safe_item[`${key}`] = item[`${key}`];
    })
    //console.log(safe_item);
    return safe_item;
}


const Testing = (props) => {
    let context = useContext(AppContext);
    let { item_id } = useParams();
    const history = useHistory();
    let [item, setItem] = useState({});
    let [product, setProduct] = useState({});
    let [productOptions, setProductOptions] = useState([]);

    let [cosmetics, setCosmetics] = useState([]);
    let [conditions, setConditions] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [issueTags, setIssueTags] = useState([]);

    let [formDisable, setFormDisable] = useState(false);
    let [fetched, setFetched] = useState(false);
    let [noProductBlurb, setNoProductBlurb] = useState(false);
    let [issue, setIssue] = useState(null);
    let [qtyConfirmed, setQtyConfirmed] = useState(0);

    let [receivedDate, setReceivedDate] = useState(new Date())

    const DEDUCTIONS_ENABLED = context?.feature_flags?.deductions;

    //fetch the conditions and cosmetics for use in the editing area
    const fetchConditions = () => {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName:'cosmetic_conditions'})
        })
        .then (resp => resp.json())
        .then(results => setCosmetics(results))

        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'item_conditions'})
        })
        .then(resp => resp.json())
        .then(results => setConditions(results))

        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'item_statuses'})
        })
        .then(resp => resp.json())
        .then(results => setStatuses(results));

        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'item_issue_tags'})
        })
        .then(resp => resp.json())
        .then(results => setIssueTags(results));
    }

    //fetches the one item once it has been selected
    function fetchOverview() {
        setFetched(false);
        secureFetch(context.server + '/testing-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                itemId: item_id ? item_id : props?.location?.state?.item_id
            })
        })
        .then(resp => resp.json())
        .then(results => {
            let newItem = {...results?.item}
            //default to product description if the item doesnt have one yet
            if(!(newItem?.description?.length > 0)) newItem.description = results?.product?.description

            //clean the item first, replacing nulls with '', and filling in the default functional notes if they are empty
            
            Object.keys(results?.item).forEach( key => {
                newItem[key] = results?.item[key] ? results?.item[key] : ''
            })
            if(newItem?.functional_notes === '') newItem.functional_notes = default_functional;

            newItem.issue_tags = newItem?.issue_tags?.split(',');
            newItem.prev_item_status_id = newItem?.item_status_id;
            newItem.item_status_id=CLEARED_ID;
            
            setItem(newItem);
            setProduct(results?.product);
            if(!(results?.product?.description?.length > 0)) {
                setNoProductBlurb(true);
            }
            setProductOptions(results?.options);
            setFetched(true);
        })
    }

    //grab the conditions and cosmetics once when the page loads
    useEffect(() => {
        fetchConditions();
        fetchOverview();
    }, [])

    useEffect(() => {
        if(item?.quantity==1){setQtyConfirmed(1)}
    },[item?.quantity])

    function createDocument(docType) {
        secureFetch(context.server + '/create-document', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({product: product, docType: docType})
        })
        .then(() => fetchOverview())
    }

    function submitForm(event) {
        event.preventDefault();
        //require that they checked issue y/n
        if(issue!=1 && issue!=0) {
            alert('Please mark issue/ok before submitting')
            return;
        }
        setFormDisable(true);
        let safe_item = getSafeVersion(item);

        secureFetch(context.server + '/update-item-options', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item_id: item?.id,
                options: item?.options
            })
        })

        secureFetch(context.server + '/test-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item: {...safe_item, product_id: product?.id, tester_id: context.user.id, sale_type_id: item?.sale_type_id},
                tester_id: context.user.id,
                tester_notes: item?.tester_notes,
                update_product_description: noProductBlurb ? 1 : 0,
                issue: issue,
                issue_notes: item?.issue_notes,
                issue_tags: item?.issue_tags,
                prev_item_status_id: item?.prev_item_status_id
            })
        })
        .then( () => history.push({pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}}))
    }

    
    const onSelectProduct = (prod) => {
        secureFetch(context.server + '/update-item-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: item?.id,
                keys: ['product_id'],
                values: [prod?.id],
                message: 'user reassigned product to item from testing page'
            })
        })
        .then(() => {
            fetchOverview();
        })
    }

    //handles when the cosmetic condition dropdown is changed
    function cosmeticChangeHandler (event) {
        setItem({...item, cosmetic_condition_id: event.target.value});
    }

    //handles when the item condition dropdown is changed
    function conditionChangeHandler (event) {
        setItem({...item, item_condition_id: event.target.value});
    }

    function setProductDefaults(event) {
        secureFetch(context.server + '/set-product-option-defaults', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({product: {...product, options: item?.options?.filter(o => o?.option_set_id >= 0)}})
        })
        .then(() => {
            alert('Successfully set product defaults');
            fetchOverview();
        })
    }

    function splitQuantity(qty) {
        secureFetch(context.server + `/split-quantity`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                item_id: item?.id,
                split_quantity: qty
            })
        })
        .then(() => {
            alert('Quantity has been split, continue testing');
            setQtyConfirmed(1);
        })
    }

    function updateTags(idx, val) {
        let newTags = item?.issue_tags?.map(t=>t);
        newTags[idx] = val;
        setItem({...item, issue_tags: newTags});
    }

    function addTag() {
        let newTags = item?.issue_tags?.map(t=>t) || [];
        newTags.push("-");
        setItem({...item, issue_tags: newTags});
    }

    function removeTag(idx) {
        let newTags = item?.issue_tags?.map(t=>t);
        delete newTags[idx];
        setItem({...item, issue_tags: newTags});
    }

    //Adds received date, receiver id, and updates status.
    function receiveItem(){
        secureFetch(context.server + `/receive-at-testing`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`,'Content-Type': 'application/json' },
            body: JSON.stringify({
                item: item,
                receivedDate: receivedDate
            })
        })
        .then(() => {
            fetchOverview()
        })
    }
    return (
        <div style={{padding: '15px 15px'}}>
        {fetched ?
            <div>
                <h1>Testing: {item?.title}</h1>
                {item?.received_date ?
                    <div>
                        <br/><br/>
                        <div className='container'>
                            <div className='contained' style={{minWidth: '70%'}}>
                                <InfoBox title='Item Info:'>
                                    <KeyValuePair label='SKU:' value={
                                        <Link to={{pathname: `/item-view/${item?.id}`}} className='btn dark-text'>{item?.sku}</Link>} textClass='dark-text' />
                                    <KeyValuePair label='Quantity:' value={item?.quantity} textClass='dark-text' />
                                    <KeyValuePair label='Product:' value={
                                        product?.id ? <><Link className='contained btn dark-text' to={{pathname: `/product-view/${product?.id}`, state: {product_id: product?.id}}}>{product?.title}</Link>
                                        &nbsp;&nbsp;&nbsp;<ModalContainer triggerText="Edit" formData={product} FormComponent={EditProductForm} submitForm={() => fetchOverview()} />
                                        &nbsp;&nbsp;&nbsp;<ModalContainer className='contained' triggerText='Select New' submitForm={onSelectProduct}
                                        formData={item} FormComponent={SelectProductForm} /></>
                                        : <ModalContainer className='contained' triggerText={'Select Product'} submitForm={onSelectProduct}
                                        formData={item} FormComponent={SelectProductForm} />
                                    } textClass='dark-text' />
                                    <KeyValuePair label={'Brand Partner:'} value={product?.brand_partner ? <p className = 'green-text'>True</p> : <p className = 'red-text'>False</p> } textClass={'dark-text'}/>
                                    <KeyValuePair label='RMA:' value={
                                        item?.tpa_id ? <Link className='btn dark-text' to={{pathname: `/tpa-view/${item?.tpa_id}`, state: {tpa_id: item?.tpa_id}}}>{item?.tpa_number}</Link>
                                        : "- unknown -"
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Submission:' value={
                                        item?.submission_id ? <Link className='btn dark-text' to={{pathname: `/submission-view/${item?.submission_id}`, state: {submission_id: item?.submission_id}}}>{item?.submission_id}</Link>
                                        : "- unknown -"
                                    } textClass='dark-text' />
                                    {item?.fd_ticket_id ? 
                                        <KeyValuePair label={'Freshdesk Ticket:'} value={<a className='btn dark-text' rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${item?.fd_ticket_id}`}>Go to Ticket</a>}/>
                                    :
                                        <></>
                                    }
                                    <KeyValuePair label='Model (From Customer):' value={item?.model_from_submission} textClass='dark-text' />
                                    <KeyValuePair label={'Notes from Appraiser:'} value={item?.notes_from_appraiser != 'undefined' ? item?.notes_from_appraiser : ''} textClass='dark-text' />
                                    <KeyValuePair label='Rep:' value={item?.rep} textClass='dark-text' />
                                    <KeyValuePair label={'Receiving Notes:'} value={
                                        item?.receiving_notes
                                    } textClass={'dark-text'} />
                                    <KeyValuePair label={'Testing Notes:'} value={
                                        product?.testing_notes
                                    } textClass={'dark-text'} />
                                    <KeyValuePair label={'Brand-level Notes:'} value={
                                        <p className='blue-text'>{product?.brand_notes || '-'}</p>
                                    } textClass={'dark-text'} />
                                    <KeyValuePair label={'Category-level Notes:'} value={
                                        product?.classifications.every((c)=>c.notes == null)?
                                            <>-</>
                                            :
                                            product?.classifications.map( (row) =>
                                                row.notes? 
                                                    <div className='blue-text'>
                                                        <u >{row?.name+' Notes:'}</u><p>{row?.notes}</p>
                                                    </div>
                                                :<></>
                                            )
                                    } textClass='dark-text'/>  
                                    <KeyValuePair label={'Customer Notes:'} value={item?.customer_notes} textClass='dark-text' />
                                    
                                    <KeyValuePair label={'eBay Only:'} value={<CheckmarkYN val={item?.ebay_only} />} textClass={'dark-text'}/>
                                </InfoBox>
                            </div>
                            <div className='contained'>
                                <InfoBox title="Categories:">
                                    {product?.classifications?.length > 0 ?
                                            product?.classifications?.map( (row, ind) => 
                                                <b className='dark-text'>{row?.supertype + ' / ' + row?.name}</b>
                                            )
                                        :
                                        <p className='dark-text'>No Product Categories Set</p>
                                    }
                                </InfoBox>
                                <br/>
                                <InfoBox title="Product Links:">
                                    <KeyValuePair label='Manual:' value={
                                        product?.manual_link ? <a className='btn bg-blue dark-text' rel="noreferrer" target="_blank" href={product?.manual_link}>View</a> : null
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Specs:' value={
                                        product?.specs_link ? <a className='btn bg-blue dark-text' rel="noreferrer" target="_blank" href={product?.specs_link}>View</a> : null
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Review 1:' value={
                                        product?.review_link_1 ? <a rel="noreferrer" target="_blank" href={product?.review_link_1}>{product?.review_name_1 || 'No Source'}</a> : null
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Review 2:' value={
                                        product?.review_link_2 ? <a rel="noreferrer" target="_blank" href={product?.review_link_2}>{product?.review_name_2 || 'No Source'}</a> : null
                                    } textClass='dark-text' />
                                    <KeyValuePair label='Review 3:' value={
                                        product?.review_link_3 ? <a rel="noreferrer" target="_blank" href={product?.review_link_3}>{product?.review_name_3 || 'No Source'}</a> : null
                                    } textClass='dark-text' />
                                </InfoBox>
                            </div>
                        </div>
                        <br/><br/>
                        <div style={{padding: '15px'}}>
                            <div className='container'>
                                <h2 className='light-text text-left contained'>Item Information:</h2>
                                <label className='text-right contained'><ConfirmButton buttonText='Save Selection As Product Defaults' clickHandler={setProductDefaults}
                                confirmation='Are you sure you want to set these as defaults for this product? This will overwrite any existing defaults for the product.' /></label>
                            </div>
                            <br/>
                            {item?.id ? <ItemOptionsUI item={item} updateParent={setItem} /> : <></>}
                            <form id='TestingForm' onSubmit={submitForm} />
                            <fieldset form='TestingForm' disabled={formDisable ? 'disabled' : ''}>
                                <div className='container'>
                                    <div className='contained'>
                                        <label className='light-text text-left'>Cosmetic:</label>
                                        <select form='TestingForm' required onChange={ cosmeticChangeHandler } value={item?.cosmetic_condition_id}>
                                            <option value='null' key={0}>SELECT COSMETIC CONDITION</option>
                                            {cosmetics.map( (cos) => 
                                                <option key={cos?.id} value={cos?.id} >{cos?.cosmetic_condition}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='contained'>
                                        <label className='light-text text-left'>Condition:</label>
                                        <select form='TestingForm' required onChange={ conditionChangeHandler } value={item?.item_condition_id}>
                                            <option value='null' key={0}>SELECT ITEM CONDITION</option>
                                            {conditions.map( (con) => 
                                                <option key={con?.id} value={con?.id} >{con?.item_condition}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='container'>
                                    <div className='contained'>
                                        <label className='light-text text-left'>Cosmetic Description:</label>
                                        <textarea form='TestingForm' rows='8' style={{minWidth: '600px', width: '600px', maxWidth: '600px'}} value={item?.cosmetic_description} onChange={ (e) => setItem({...item, cosmetic_description: e.target.value})}/>
                                        <label className='light-text text-left'>Description:{item?.description==product?.description ? <i>&nbsp;(using product default)</i>: <></>}</label>
                                        <div style={{minWidth: '600px', width: '600px', maxWidth: '600px'}}>
                                            <FlipRTE text={item?.description} updateParent={ (e) => setItem({...item, description: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='contained'>
                                        <label className='light-text text-left'>What is Included:</label>
                                        <textarea form='TestingForm' required rows='8' style={{minWidth: '600px', width: '600px', maxWidth: '600px'}} value={item?.what_is_included} onChange={ (e) => setItem({...item, what_is_included: e.target.value})}/>
                                        <label className='light-text text-left'>Functional Notes:</label>
                                        <textarea form='TestingForm' required rows='8' style={{minWidth: '600px', width: '600px', maxWidth: '600px'}} value={item?.functional_notes} onChange={ (e) => setItem({...item, functional_notes: e.target.value})}/>
                                    </div>
                                </div>
                                <div>
                                    <label className='contained light-text text-left'>SN:&nbsp;&nbsp;
                                    <input form='TestingForm' className='contained' type='text' value={item?.serial_number ? item?.serial_number : ''} onChange={ (e) => setItem({...item, serial_number: e.target.value})}/>
                                    {item?.submission_btob || item?.customer_btob ? <b className='blue-text'>&nbsp;B2B</b> : <></>}
                                    </label>
                                    <label className='contained light-text text-left'>UPC Code:&nbsp;&nbsp;
                                    <input disabled form='TestingForm' className='contained' type='text' value={product?.upc_code || 'None'} />&nbsp;<i>(edit product to modify)</i>
                                    </label>
                                    <label className='contained light-text text-left'>Parts:&nbsp;&nbsp;
                                    <input form='TestingForm' required className='contained' type='number' value={item?.number_of_parts ? item?.number_of_parts : ''} onChange={ (e) => setItem({...item, number_of_parts: e.target.value})}/>
                                    </label>
                                    <label className='contained light-text text-left'>Age:&nbsp;&nbsp;
                                    {/* <input form='TestingForm' required className='contained' type='number' value={item?.age_in_years ? item?.age_in_years : ''} onChange={ (e) => setItem({...item, age_in_years: e.target.value})}/> */}
                                        <select form="TestingForm" value={item?.age_in_years} onChange={e => setItem({...item, age_in_years: e.target.value==-11 ? null : e.target.value})}>
                                            <option value={-11}>-</option>
                                            <option value={-1}>Unknown</option>
                                            <option value={0}>Less than 1 year</option>
                                            <option value={1}>1 year</option>
                                            <option value={2}>2 years</option>
                                            <option value={3}>3 years</option>
                                            <option value={4}>4 years</option>
                                            <option value={5}>5 years</option>
                                            <option value={6}>6 years</option>
                                            <option value={7}>7 years</option>
                                            <option value={8}>8 years</option>
                                            <option value={9}>9 years</option>
                                            <option value={10}>10 years</option>
                                            <option value={11}>11 years</option>
                                            <option value={12}>12 years</option>
                                            <option value={13}>13 years</option>
                                            <option value={14}>14 years</option>
                                            <option value={15}>15 years or older</option>
                                        </select>
                                    </label>
                                    <label className='contained light-text text-left'>Owners:&nbsp;&nbsp;
                                    {/* <input form='TestingForm' className='contained' type='number' value={item?.num_of_owners ? item?.num_of_owners : ''} onChange={ (e) => setItem({...item, num_of_owners: e.target.value})}/> */}
                                        <select form="TestingForm" value={item?.num_of_owners} onChange={e => setItem({...item, num_of_owners: e.target.value==-11 ? null : e.target.value})}>
                                            <option value={-11}>-</option>
                                            <option value={-1}>Unknown</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4 or more</option>
                                        </select>
                                    </label>
                                    <label className='contained light-text text-left'>Tobacco:&nbsp;&nbsp;
                                    <input form='TestingForm' className='contained' type='checkbox' checked={item?.tobacco===1} onChange={ (e) => setItem({...item, tobacco: e.target.checked ? 1 : 0})}/>
                                    </label>
                                    <label className='contained light-text text-left'>Title:&nbsp;&nbsp;
                                    <input form='TestingForm' required type='text' style={{minWidth: '600px', width: '600px', maxWidth: '600px'}} value={item?.title} onChange={ (e) => e.target.value?.length >= 80 ? null : setItem({...item, title: e.target.value})} />
                                    &nbsp;&nbsp;<i>{item?.title?.length + 1}/80 characters</i></label>
                                    <br/>
                                    <label className = 'contained light-text text-left'>Brand Partner: &nbsp; &nbsp;{product?.brand_partner ? <b className = 'green-text'>True</b> : <b className = 'red-text'>False</b> }</label>
                                    <br/><br/>
                                    {/* <label className='light-text text-left'>Item Status:&nbsp;<i className='light-text'>(this will automatically update on submit, no need to modify unless its out of the ordinary)</i></label>
                                    <select form='TestingForm' required onChange={ e => setItem({...item, item_status_id: e.target.value}) } value={item?.item_status_id}>
                                        {statuses.map( (stat) => 
                                            <option key={stat?.id} value={stat?.id} >{stat?.status}</option>
                                        )}
                                    </select> */}
                                    <h3 className='light-text'>Tester Notes:&nbsp;&nbsp;</h3>
                                    <textarea form='TestingForm' rows='6' style={{minWidth: '700px', width: '700px', maxWidth: '700px'}} value={item?.tester_notes} onChange={ (e) => setItem({...item, tester_notes: e.target.value})} />
                                    
                                    {DEDUCTIONS_ENABLED && item?.is_consignment==1 && item?.quantity == 1 ? 
                                    item?.has_been_tested ? <><br/><br/>
                                        <h3 className='light-text'>Deductions:&nbsp;<i>(record deduction reason in notes above)</i></h3>
                                        <p><i>(this item has already been tested, use the add deduction form on the item view page if you need to add new deductions)</i></p>
                                    </>
                                    : <>
                                        <br/><br/>
                                        <h3 className='light-text'>Deductions:&nbsp;<i>(record deduction reason in notes above)</i></h3>
                                        {item?.deductions?.length > 0 ?
                                            <div>
                                                {item?.deductions?.map((row, ind) =>
                                                    <label className='light-text text-left'>
                                                        <button className='btn bg-red light-text' onClick={() => {
                                                            let newDeds = item?.deductions?.map(d=>d);
                                                            delete newDeds[ind];
                                                            setItem({...item, deductions: newDeds});
                                                        }}>X</button>
                                                        &nbsp;&nbsp;&nbsp;Amount:&nbsp;
                                                        <input type='number' value={row?.amount} onChange={e => {
                                                            let newDeds = item?.deductions?.map(d=>d);
                                                            newDeds[ind] = {...row, amount: e.target.value};
                                                            setItem({...item, deductions: newDeds});
                                                        }}/>
                                                    </label>
                                                )}
                                            </div>
                                        :<></>}
                                        <button className='btn bg-green light-text' onClick={() => {
                                            let newDeds = item?.deductions?.map(d=>d) || [];
                                            newDeds.push({amount: 0});
                                            setItem({...item, deductions: newDeds});
                                        }}>+</button>
                                    </>:
                                    DEDUCTIONS_ENABLED && item?.is_consignment==1 && item?.quantity > 1 ?
                                    <>
                                    <br></br>
                                    <br></br>
                                    <h3 className='light-text'>Deductions cannot be made when Qty &gt; 1</h3>
                                    <i className='light-text'>If a unit requires deduction it must be split off into a separate item.</i>
                                    </> :
                                    <></>}

                                    <br/><br/>
                                    <h3 className='light-text'>OK/Issue:</h3>
                                    <div>
                                        <label className='light-text text-left'><input type='radio' checked={issue==0} onChange={e => {
                                            if(e.target.checked==1) {
                                                setIssue(0);
                                                setItem({...item, item_status_id: 3});
                                            }
                                        }}/>&nbsp;OK</label>
                                        <label className='light-text text-left'><input type='radio' checked={issue==1} onChange={e => e.target.checked==1 ? setIssue(1) : null}/>&nbsp;Issue</label>
                                    </div>
                                </div>
                                <div>
                                    {issue==1 ? <>
                                        <br/><br/>
                                        <label className='light-text text-left'>Item Status:</label>
                                        <select form='TestingForm' required onChange={ e => setItem({...item, item_status_id: e.target.value}) } value={item?.item_status_id}>
                                            {statuses
                                            ?.filter(stat => stat?.id==REPAIR_ID || stat?.id==HOLD_ID || stat?.id==CLEARED_ID)
                                            ?.map( (stat) => 
                                                <option key={stat?.id} value={stat?.id} >{stat?.status}</option>
                                            )}
                                        </select>
                                    
                                        <label className='light-text text-left'>Issue Notes:&nbsp;&nbsp;</label>
                                        <textarea form='TestingForm' rows='6' style={{minWidth: '700px', width: '700px', maxWidth: '700px'}} value={item?.issue_notes} onChange={ (e) => setItem({...item, issue_notes: e.target.value})} />
                                        <br/>
                                        <label className='light-text text-left'>Tags:</label>
                                        {item?.issue_tags?.length > 0 ? <>
                                            {item?.issue_tags?.map((row, ind) =>
                                                <>
                                                <select value={row} onChange={e => updateTags(ind, e.target.value)}>
                                                    {issueTags?.map(tag => <option value={tag?.name} id={tag?.id}>{tag?.name}</option>)}
                                                </select>
                                                <button className='btn bg-red light-text' onClick={() => removeTag(ind)}>&nbsp;-&nbsp;</button>
                                                </>
                                            )}
                                        </> : <></>}
                                        <br/>
                                        
                                        <button className='btn bg-green light-text' onClick={() => addTag()}>&nbsp;+&nbsp;</button>
                                        
                                    </>
                                    : <></>}
                                </div>
                                {item?.prev_item_status_id != 2 && item?.prev_item_status_id != 8 && item?.prev_item_status_id != 9 && item?.prev_item_status_id != 10 ? <div className='bg-red light-text'>
                                            THIS ITEM IS NOT IN AN EXPECTED STATUS FOR TESTING, PLEASE NOTE THAT SUBMITTING THIS PAGE WILL OVERWRITE THE CURRENT STATUS
                                        </div> : <></>}
                                <br/><br/>
                                <div hidden={qtyConfirmed ? "hidden" : ""}>
                                        <p className='red-text'>ITEM HAS QUANTITY: {item?.quantity} - PLEASE CONFIRM THIS IS CORRECT TO PROCEED</p>
                                        <button className='btn bg-green light-text' onClick={() => setQtyConfirmed(1)}>Confirm</button>
                                        <p className='red-text'>- OR -</p>
                                        <ModalContainer triggerText='Split Quantity' formData={item} FormComponent={SplitQuantityForm} submitForm={splitQuantity} />
                                </div>
                                <div hidden={qtyConfirmed ? "" : "hidden"}>
                                    <input  form='TestingForm' className='btn dark-text' type='submit' />
                                </div> 
                            </fieldset>
                        </div>
                    </div>
                :<div>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h3>This item must be received before it can be tested.</h3>
                    <br></br>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Date Received:</p>
                        <DatePicker className='contained' value={receivedDate} onChange={setReceivedDate} locale='en-US'/>
                    </div>
                    <br/>
                    <button className='btn bg-dark light-text' type="button" onClick={()=>{receiveItem()}}>Receive Item</button>
                </div>                         
                </div>}                         
            </div>
            : //THIS IS SHOWN IF NO ITEM IS SELECTED ON THE PAGE
            <div>
                <h2 className='light-text'>Item Not Loaded...</h2>
            </div>
        }
        </div>
    );
}

export default Testing;