import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import InfoBox from "../../components/InfoBox";
import KeyValuePair from "../../components/KeyValuePair";

const TpasReceivedReport = () => {
    let context = useContext(AppContext);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [report, setReport] = useState([]);

    function fetchReport() {
        setReport([]);
        secureFetch(context.server + '/reports/tpas-received', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate
            })
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data)
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context, startDate, endDate])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>RMAs Received Report</h1>
                <br/>
                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                    </div>
                    <div className='container'>
                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                    </div>
                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                    {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                    <br/>
                </div>
                <br/>
                
                <InfoBox title='Report'>
                    <p>{report ? 
                    <>
                        Items:<br/>
                        {report?.itemsMatrix?.length > 0 ? 
                            report?.itemsMatrix?.map(row => <div>
                                <p><b>{row?.rep}</b></p>
                                <div className="container">
                                    {row?.itemsByType?.map(entry => <p className="contained">{entry?.deal_type}: {entry?.num_received}</p>)}
                                </div>
                                <p>Total: {row?.itemsTotal[0]?.total_received}</p>
                            </div>)
                            : <p>pulling report...</p>
                        }
                        <br/><br/><br/>


                        TPAs:<br/>
                        {report?.tpasMatrix?.length > 0 ? 
                            report?.tpasMatrix?.map(row => <div>
                                <p><b>{row?.rep}</b></p>
                                <div className="container">
                                    {row?.tpasByType?.map(entry => <p className="contained">{entry?.deal_type}: {entry?.num_received}</p>)}
                                </div>
                                <p>Total: {row?.tpasTotal[0]?.total_received}</p>
                            </div>)
                            : <p>pulling report...</p>
                        }
                        <br/><br/><br/>
                    </>
                    : 'pulling report'}</p>
                </InfoBox>
                <br/>
            </div>
        </div>
    );
}

export default TpasReceivedReport;