import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import CreateCustomerForm from "../components/CreateCustomerForm";
import EditCustomerForm from "../components/EditCustomerForm";
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import ModalContainer from "../components/ModalContainer";
import SelectCustomerForm from "../components/SelectCustomerForm";
import { secureFetch } from "../util/flip_utils";
import TableList from "../components/TableList";
import SelectItemForm from "../components/SelectItemForm";

const COUPON_ITEM_ID = 116226;

const COL_WIDTHS = ['10%','40%','20%','20%','10%'];

const HorizRow = ({widths, entries}) => {
    if(widths?.length != entries?.length) {
        return (
            <div className="bg-light">
                <p className="dark-text">Cols and widths must be same length</p>
            </div>
        )
    }

    return (
        <div className="container">
            {entries?.map((ent, ind) => 
                <div className="contained" style={{minWidth: widths[ind], width: widths[ind], maxWidth: widths[ind]}}>{ent}</div>
            )}
        </div>
    )
}


const CreateDraftOrder = (props) => {
    //set up stateful variables
    let context = useContext(AppContext);
    let [customerOverview, setCustomerOverview] = useState({});
    let [draftOrder, setDraftOrder] = useState({
        discounts: [],
        items: []
    });
    //let [items, setItems] = useState([]);
    //let [urlOutput, setUrlOutput] = useState('');
    let [formDisable, setFormDisable] = useState(false);

    //fetches the customer overview from db
    function fetchCustomer(cust) {
        secureFetch(context.server + '/customer-overview', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: cust?.id
            })
        })
        .then(resp => resp.json())
        .then(results => {
            setCustomerOverview(results);
        })
    }

    
    //set up a cart object, and send it back to server. once server has processed it, the url appears in urlOutput field
    function generateURL(event){
        event.preventDefault();
        setFormDisable(true);
        setUrlOutput('');


        let cart = [];
        let fail_msg = '';
        if(!customerOverview?.customer?.id) {
            fail_msg += `Cart failed: Must select customer to assign cart to.\n`;
        }
        if(draftOrder?.items?.length < 1) {
            fail_msg += `Cart failed: Cart must not be empty\n`;
        }
        if(draftOrder?.items?.length === 1 && draftOrder?.items[0]?.id === COUPON_ITEM_ID) {
            fail_msg += `Cart failed: Cart must have at least one non-coupon item.\n`
        }
        draftOrder?.items.forEach( row => {
            if(row?.sale_quantity > row?.max_sale_quantity) {
                fail_msg += `Cart failed: SKU (${row?.sku}) only has ${row?.max_sale_quantity} in stock.\n`;
            }
            if(row?.sale_quantity <= 0) {
                fail_msg += `Cart failed: SKU (${row?.sku}) sale quantity cannot be zero or negative.\n`;
            }
            if(cart.map(c => c?.id).indexOf(row?.bc_product_id) !== -1) {
                fail_msg += `Cart failed: SKU (${row?.sku}) cannot be in cart twice.\n`;
            }
            cart.push({
                id: row.bc_product_id,
                qty: row.sale_quantity,
                price: row.sale_price
            })
        })
        if(fail_msg !== '') {
            alert(fail_msg)
            setFormDisable(false);
            return;
        }

        secureFetch(context.server + '/create-invoice',{
            method:'POST',
            credentials:'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type':'application/json' },
            body: JSON.stringify({
                customerId: customerOverview?.customer?.id,
                items: draftOrder?.items
            })
        })
        .then(resp => resp.json())
        .then(result => {
            setUrlOutput(result);
            setFormDisable(false);
        });
    }


    function submitDO(event) {
        event.preventDefault()
        setFormDisable(true);
        secureFetch(context.server + '/create-draft-order', {
            method:'POST',
            credentials:'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type':'application/json' },
            body: JSON.stringify({
                customerId: customerOverview?.customer?.id,
                draftOrder: draftOrder
            })
        })
        .then(() => {
            setFormDisable(false);
            alert('success!');
        })
    }


    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Draft Order Tool</h1>
            <br/><br/>
            <div>
                <InfoBox title='Customer Info:'>
                    {customerOverview?.customer ?
                    <div className='container'>
                        <div className='contained'>
                            <KeyValuePair label='Name:' value={customerOverview?.customer?.id ? `${customerOverview?.customer?.name}` : 'unselected'} textClass='dark-text' />
                            <KeyValuePair label='Phone:' value={customerOverview?.customer?.phone} textClass='dark-text' />
                            {customerOverview?.emails.map((row, ind) => 
                                <KeyValuePair label={ind===0 ? 'Emails:' : ' '} value={row?.email} textClass='dark-text' />
                            )}
                        </div>
                        <div className='contained'>
                            <KeyValuePair label='Notes:' value={customerOverview?.customer?.notes} textClass='dark-text' />
                        </div>
                    </div>
                    :
                    <p className='dark-text'>No customer selected...</p>
                    }
                </InfoBox>
                <ModalContainer triggerText='Select Customer' submitForm={cust => fetchCustomer(cust)} FormComponent={SelectCustomerForm} formData={{}} />
                <ModalContainer triggerText='Edit Customer' submitForm={cust => fetchCustomer(cust)} FormComponent={EditCustomerForm} formData={customerOverview} />
                <ModalContainer triggerText='New Customer' submitForm={cust => fetchCustomer(cust)} FormComponent={CreateCustomerForm} formData={{}} />
                <br/>
                <br/>
                <form id='CreateCustomCart' onSubmit={submitDO}></form>
                <fieldset form='CreateCustomCart' disabled={formDisable ? 'disabled' : ''}>
                    <DOList overview={draftOrder} updateCallback={setDraftOrder} />
                    <br/><br/>
                    <br/><br/>
                    {/* <input form='CreateCustomCart' className='btn dark-text' type='submit' value='Generate Custom Cart URL' />&nbsp;&nbsp;
                    <input placeholder='cart url goes here' readOnly='readOnly' size='75' value={urlOutput} /> */}
                    <input form='CreateCustomCart' className="btn dark-text" type='submit' value='Create Draft Order' />
                    <br/><br/>
                </fieldset>
            </div>    
        </div>
    );
}


const DOList = ({overview, updateCallback}) => {
    let context = useContext(AppContext);
    let [draftOrder, setDraftOrder] = useState(overview);
    let [discountTypes, setDiscountTypes] = useState([]);

    function fetchDiscountTypes() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ tableName: 'discount_types' })
        })
        .then(resp => resp.json())
        .then(results => setDiscountTypes(results))
    }

    useEffect(() => {
        fetchDiscountTypes();
    }, [])

    useEffect(() => {
        setDraftOrder(overview);
    }, [overview]);

    //adds a new item (and fills in blank values via bcGet)
    function addItem(item) {
        let newItems = draftOrder?.items.map(row => row);
        newItems.push({...item, discounts: []});
        updateCallback({...draftOrder, items: newItems});

        //fill in blanks from BC info
        bcGet(draftOrder?.items.length, item);
    }

    function updateItem(ind, item) {
        let newItems = draftOrder?.items.map(row=>row);
            newItems[ind] = item;
        updateCallback({...draftOrder, items: newItems});
    }

    function removeItem(ind) {
        let newItems = draftOrder?.items.map( row => row );
        newItems.splice(ind, 1);
        updateCallback({...draftOrder, items: newItems});
    }

    //get info from bc to update empty fields. primarily called when a new item is added to the cart
    function bcGet(ind, item){
        let bcID = item?.bc_product_id;

        secureFetch(context.server + "/bc/product/" + bcID, {
            credentials: 'include'
        })
        .then(resp => resp.json())
        .then(response => {
            item.sale_price = response.data?.price
            item.sale_quantity = response.data?.inventory_level
            item.max_sale_quantity = response.data?.inventory_level
            updateItem(ind, item); //update fields in state
        });
    }

    function addDiscount() {
        let newDiscounts = draftOrder?.discounts?.map(r=>r);
        newDiscounts?.push({discount_type_id: 1, amount: 0});
        updateCallback({...draftOrder, discounts: newDiscounts});
    }

    function updateDiscount(index, discount) {
        let newDiscounts = draftOrder?.discounts?.map(r=>r);
        newDiscounts[index] = discount;
        updateCallback({...draftOrder, discounts: newDiscounts});
    }

    function removeDiscount(index) {
        let newDiscounts = draftOrder?.discounts?.map(r=>r);
        newDiscounts.splice(index, 1);
        updateCallback({...draftOrder, discounts: newDiscounts});
    }

    

    return (
        <div className="bg-lightest rounded">
            <div className="bg-light rounded">
                <HorizRow widths={COL_WIDTHS} entries={[
                    <h3 className="dark-text">SKU</h3>,
                    <h3 className="dark-text">Title</h3>,
                    <h3 className="dark-text">Price</h3>,
                    <h3 className="dark-text">Quantity</h3>,
                    <h3 className="dark-text">Remove</h3>,
                ]} />
            </div>
            <div>
                {draftOrder?.items?.map((row, ind) => 
                    <DOListRow ind={ind} entry={row} updateCallback={updateItem} removeItem={removeItem} discTypes={discountTypes} />
                )}
            </div>
            <div style={{padding: '15px 15px'}}>
                <ModalContainer buttonClass='btn bg-dark light-text' triggerText='Add Item' submitForm={(item) => addItem(item)} FormComponent={SelectItemForm} formData={{}} />
            </div>
            {draftOrder?.discounts?.length > 0 ?
                <div>
                    {draftOrder?.discounts?.map((disc, discInd) => 
                        <HorizRow widths={COL_WIDTHS} entries={[
                            <></>,
                            <select value={disc?.discount_type_id} onChange={e => updateDiscount(discInd, {...disc, discount_type_id: e.target.value})}>
                                {discountTypes?.map(dt => <option key={dt?.id} value={dt?.id}>{dt?.discount_type}</option>)}
                            </select>,
                            <input type='number' value={disc?.amount} onChange={e => updateDiscount(discInd, {...disc, amount: e.target.value})} />,
                            <button className="btn bg-red light-text" onClick={() => removeDiscount(discInd)}>Remove</button>,
                            <></>,
                        ]} />
                    )}
                </div>
            : <></>}
            <div style={{padding: '10px 10px'}}>
                {draftOrder?.items?.length > 0 ? <button className="btn bg-dark light-text" onClick={addDiscount}>Add Order Discount</button> : <></>}
            </div>
            <HorizRow widths={COL_WIDTHS} entries={[
                <></>,
                <></>,
                <label className="dark-text text-left">Order Total:<br/><input disabled value={
                    (
                        (draftOrder?.items?.reduce(
                            (itemAcc, itemCurr) => { //get total for all items
                                return itemAcc + itemCurr?.sale_price + ( //total up the discounts, or 0 if there are none yet
                                    itemCurr?.discounts?.reduce(
                                        (lineAcc, lineCurr) => {
                                            return lineAcc + parseFloat(lineCurr?.amount)
                                        }, 0
                                    ) || 0
                                )
                            }, 0
                        ) || 0)
                        +
                        (draftOrder?.discounts?.reduce(
                            (discAcc, discCurr) => {
                                return discAcc + parseFloat(discCurr?.amount)
                            }, 0
                        ) || 0)

                    )
                    } />
                </label>,
                <></>,
                <></>,
            ]} />
        </div>
    )
}


const DOListRow = ({ind, entry, updateCallback, removeItem, discTypes}) => {
    let [item, setItem] = useState(entry);
    let [discountTypes, setDiscountTypes] = useState([]);

    useEffect(() => {
        setItem(entry);
    }, [entry]);

    useEffect(() => {
        setDiscountTypes(discTypes);
    }, [discTypes])

    function updateDiscount(index, discount) {
        let newDiscounts = item?.discounts?.map(r=>r);
        newDiscounts[index] = discount;
        updateCallback(ind, {...item, discounts: newDiscounts});
    }

    function removeDiscount(index) {
        let newDiscounts = item?.discounts?.map(r=>r);
        newDiscounts.splice(index, 1);
        updateCallback(ind, {...item, discounts: newDiscounts});
    }

    return(
        <div>
            <div className="container">
                <div className="contained" style={{minWidth: COL_WIDTHS[0], width: COL_WIDTHS[0], maxWidth: COL_WIDTHS[0]}}>
                    <p>{item?.sku}</p>
                </div>
                <div className="contained" style={{minWidth: COL_WIDTHS[1], width: COL_WIDTHS[1], maxWidth: COL_WIDTHS[1]}}>
                    <p>{item?.title}</p>
                </div>
                <div className="contained" style={{minWidth: COL_WIDTHS[2], width: COL_WIDTHS[2], maxWidth: COL_WIDTHS[2]}}>
                    <input form='CreateCustomCart' disabled type='number' value={item?.sale_price} />
                </div>
                <div className="contained" style={{minWidth: COL_WIDTHS[3], width: COL_WIDTHS[3], maxWidth: COL_WIDTHS[3]}}>
                    <input form='CreateCustomCart' required type='number' value={item?.sale_quantity} onChange={(e) => updateCallback(ind, {...item, sale_quantity: e.target.value})} />
                </div>
                <div className="contained" style={{minWidth: COL_WIDTHS[4], width: COL_WIDTHS[4], maxWidth: COL_WIDTHS[4]}}>
                    <button className='btn bg-red light-text' style={{width:'100%'}} onClick={() => removeItem(ind)}> X </button>
                </div>
            </div>
            {item?.discounts?.length > 0 ?
            <div>
                {item?.discounts?.map((disc, discInd) => 
                    <HorizRow widths={COL_WIDTHS} entries={[
                        <></>,
                        <select value={disc?.discount_type_id} onChange={e => updateDiscount(discInd, {...disc, discount_type_id: e.target.value})}>
                            {discountTypes?.map(dt => <option key={dt?.id} value={dt?.id}>{dt?.discount_type}</option>)}
                        </select>,
                        <input type='number' value={disc?.amount} onChange={e => updateDiscount(discInd, {...disc, amount: e.target.value})} />,
                        <button className="btn bg-red light-text" onClick={() => removeDiscount(discInd)}>Remove</button>,
                        <></>,
                    ]} />
                )}
            </div> : <></>}
            <HorizRow widths={COL_WIDTHS} entries={[
                <></>,
                <button className="btn bg-dark light-text" 
                onClick={() => updateCallback(ind, {
                    ...item, 
                    discounts: item?.discounts ? 
                        [...item?.discounts, {discount_type_id: 1, amount: 0}] 
                        : [{discount_type_id: 1, amount: 0}]
                    })}>Add Item Discount</button>,
                <label className="dark-text text-left">Item Total:<br/><input disabled value={
                    item?.sale_price + ( //total up the discounts, or 0 if there are none yet
                        item?.discounts?.reduce((acc, curr) => {return acc + parseFloat(curr?.amount)}, 0)
                         || 0
                    )} />
                </label>,
                <></>,
                <></>,
            ]} />
            <div className="container" style={{padding: '0px 60px'}}><div className="bg-dark contained" style={{height: '1px'}}></div></div>
            <br/>
        </div>
    )
}

export default CreateDraftOrder;