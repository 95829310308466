import e from "cors";
import { useContext, useEffect, useState } from "react"
import DatePicker from "react-date-picker";
import { Link, useHistory, useParams } from "react-router-dom";
import AppContext from "../AppContext"
import InfoBox from "../components/InfoBox";
import KeyValuePair from "../components/KeyValuePair";
import { formatDate, secureFetch } from "../util/flip_utils";


const ReceiveRAItem = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { ra_item_id } = useParams();
    let [receiveDate, setReceiveDate] = useState(new Date());
    let [returnReasons, setReturnReasons] = useState();
    let [testEvents, setTestEvents] = useState([]);
    let [users, setUsers] = useState([]);
    let [selectedTest, setSelectedTest] = useState({});
    let [raItem, setRaItem] = useState({});
    let [item, setItem] = useState({});
    let [originalPurchaseAmount, setOriginalPurchaseAmount] = useState();

    let [formDisable, setFormDisable] = useState(false);
    let [fetched, setFetched] = useState(false);

    const RA_QUANTITIES_ENABLED = context?.feature_flags['ra_quantities'];
    const RA_SKU_DUPLICATION_ENABLED = context?.feature_flags['ra_sku_duplication'];

    function fetchOverview() {
        secureFetch(context?.server + '/ra-item-overview', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: ra_item_id ? ra_item_id : props?.location?.state?.ra_item_id
            })
        })
        .then(resp => resp.json())
        .then(result => {
            setItem(result?.item)
            if(result?.item?.sale_type_id ==6){
                setOriginalPurchaseAmount(null)
            }else if(result?.item?.sale_type_id ==4 && result?.item?.consignment_payout?.amount){
                setOriginalPurchaseAmount(result?.item?.consignment_payout?.amount)    
            }else if(result?.item?.sale_type_id !=4){setOriginalPurchaseAmount(result?.item?.purchase_amount)} 
            setRaItem({...result?.raItem, quantity: 1})
            setTestEvents(result?.testEvents)
        })
    }

    function fetchReturnReasons() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'return_reasons'})
        })
        .then(resp => resp.json())
        .then(results => setReturnReasons(results));
    }

    function fetchUsers() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'users',
                params: {
                    equal: [{key: 'tester', value: '1'}]
                }
            })
        })
        .then(resp => resp.json())
        .then(results => {
            results.push({id: null, first_name: 'New Product Return', last_name: ''})
            results.push({id: -1, first_name: 'N/A', last_name: ''})
            setUsers(results)
        });
    }

    

    //fetch all when page loads
    useEffect(() => {
        fetchOverview();
        fetchReturnReasons();
        fetchUsers();
    }, [])

    //set fetched to true once all is fetched
    useEffect(() => {
        if(item && raItem?.order_item_id && returnReasons?.length > 0 && users?.length > 0) {
            if(raItem?.tester_id)
                setSelectedTest({user_id: raItem?.tester_id, user: users.filter(row => row?.id==raItem?.tester_id)[0]?.first_name});
            else
                setSelectedTest({user_id: -1, user: 'N/A'});
            setFetched(true);
        }
    }, [item, raItem, testEvents, returnReasons, users])

    function submit(event) {
        event.preventDefault();
        secureFetch(context?.server + '/receive-ra-item', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                raItem: {
                    ...raItem,
                    receive_date: receiveDate,
                    receiver_id: raItem?.receiver_id ? raItem?.receiver_id : context?.user?.id,
                    tester_id: selectedTest?.user_id
                },
                originalPurchaseAmount: originalPurchaseAmount,
                orderNumber: item?.order_number
            })
        })
        .then(res=>res.json())
        .then(response => {
            secureFetch(context?.server + '/freshdesk/add-note', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: raItem?.ra_id,
                    content: `SKU & Order Number: ${item?.sku} - ${item?.order_number}\n`
                        + `New SKU: ${response?.new_sku}\n`
                        + `Received: ${receiveDate.toDateString()}\n`
                        + `Customer Return Reason: ${returnReasons.filter(row => row?.id==raItem?.return_reason_id)[0]?.reason}\n`
                        + `Actual Return Reason: ${returnReasons.filter(row => row?.id==raItem?.actual_return_reason_id)[0]?.reason}\n`
                        + `Received By: ${raItem?.receiver || response?.user?.first_name} ${raItem?.receiver ? '' : response?.user?.last_name}\n`
                        + `Originally Tested By: ${selectedTest?.user}\n`
                        + `OK to resolve?\t${raItem?.issue ? 'No' : 'Yes'}${raItem?.issue ? `\nIssue: ${raItem?.issue_notes}` : ''}\n`
                        + `Receiving Notes: ${raItem?.notes}`
                })
            })
            //if a new duplicate item was created, redirect to testing that item
            if(response?.dupeItemId > 0) {
                history.push({pathname: `/testing/${response?.dupeItemId}`});
            } else {
                history.push({pathname: `/ra-view/${raItem?.ra_id}`, state: {ra_id: raItem?.ra_id}});
            }
        })
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <form id='ReceiveRAItemForm' onSubmit={submit} />
            <h1>RA Receiving: {item?.sku}</h1>
            <br/>
            <InfoBox title='Item Overview'>
                {/* <KeyValuePair label='Product:' value={
                    <Link className="dark-text" to={{pathname: `/product-view/${overview?.product?.id}`, state: {product_id: overview?.product?.id}}}>{overview?.product?.title}</Link>
                } textClass='dark-text' /> */}
                <KeyValuePair label='SKU:' value={
                    <Link className="dark-text" to={{pathname: `/item-view/${item?.id}`, state: {item_id: item?.id}}}>{item?.sku}</Link>
                } textClass='dark-text' />
                <KeyValuePair label='Title:' value={item?.title} textClass='dark-text' />
                <KeyValuePair label='Parts:' value={item?.number_of_parts} textClass='dark-text' />
                <KeyValuePair label='Return Notes:' value={raItem?.return_notes} textClass='dark-text' />
                <KeyValuePair label='Return Actions:' value={raItem?.return_actions} textClass='dark-text' />
            </InfoBox>
            <br/>
            <InfoBox title='Return Info'>
                {fetched ? 
                <div className="container">
                    <div className="contained">
                    <fieldset form="ReceiveRAItemForm" disabled={formDisable ? 'disabled' : ''}>
                    <label className="dark-text text-left">RA Number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>{raItem?.ra_id}</b></label><br/>
                        <label className="dark-text text-left">Receive Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <DatePicker value={receiveDate} onChange={setReceiveDate} /></label>
                        <br/>
                        <label className="dark-text text-left">Customer Return Reason:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <select form="ReceiveRAItemForm" required className={raItem?.return_reason_id ? "" : "bg-red"} onChange={(e) => setRaItem({...raItem, return_reason_id: e.target.value})} value={raItem?.return_reason_id}>
                            <option key={0} value=''>SELECT RETURN REASON</option>
                            {returnReasons?.map(row => 
                                <option key={row?.id} value={row?.id}>{row?.reason}</option>
                            )}
                        </select>
                        </label>
                        <br/>
                        {/* <label className="dark-text text-left">Confirm Reason:</label>
                        <div className="container bg-light rounded" style={{width: '60%'}}>
                            <label className="contained dark-text text-left"><input form='ReceiveRAItemForm' type='radio' checked={raItem?.reason_confirmed == 1} onChange={e => setRaItem({...raItem, reason_confirmed: 1}) } />&nbsp;&nbsp;Confirm</label>
                            <label className="contained dark-text text-left"><input form='ReceiveRAItemForm' type='radio' checked={raItem?.reason_confirmed == 0} onChange={e => setRaItem({...raItem, reason_confirmed: 0}) } />&nbsp;&nbsp;Contest</label>
                        </div> */}
                        <label className="dark-text text-left">"Actual" Return Reason:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <select form="ReceiveRAItemForm" required className={raItem?.actual_return_reason_id ? "" : "bg-red"} onChange={(e) => setRaItem({...raItem, actual_return_reason_id: e.target.value})} value={raItem?.actual_return_reason_id}>
                            <option key={0} value=''>SELECT RETURN REASON</option>
                            {returnReasons?.map(row => 
                                <option key={row?.id} value={row?.id}>{row?.reason}</option>
                            )}
                        </select>
                        </label>
                        <br/>
                        <label className="dark-text text-left">Received By:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>{raItem?.receiver ? raItem?.receiver : 'Not yet received'}</b></label>
                        <br/>
                        <label className="dark-text text-left">Tested By:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <b>{selectedTest?.user}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {testEvents?.length > 1 ?
                            <select form="ReceiveRAItemForm" onChange={(e) => setSelectedTest(testEvents.filter(row => row?.id==e.target.value)[0])} value={testEvents.filter(row => row?.id==raItem?.tester_id)[0]} >
                                {testEvents.map(row => 
                                    <option key={row?.id} value={row?.id}>{`${row?.user} ${formatDate(row?.created, {date:true})}`}</option>
                                )}
                            </select> : <></>} */}
                            <select form="ReceiveRAItemForm" onChange={e => {setRaItem({...raItem, tester_id: e.target.value}); setSelectedTest({user_id: e.target.value, user: users.filter(row => row?.id==e.target.value)[0]?.first_name});}} value={selectedTest?.user_id || raItem?.tester_id} >
                                {users?.map( row => 
                                    <option key={row?.id} value={row?.id}>{row?.first_name} {row?.last_name}</option>
                                )}
                            </select>
                        </label>
                        <br/>
                        <label className="dark-text text-left">Reuse Photos?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input form="ReceiveRAItemForm" type="checkbox" checked={raItem?.reuse_photos==1} onChange={e => setRaItem({...raItem, reuse_photos: e.target.checked ? 1 : 0})} /></label>
                        <br/>
                        {RA_SKU_DUPLICATION_ENABLED ? <></> : <><label className="dark-text text-left">New SKU:
                        <input form='ReceiveRAItemForm' type='text' value={raItem?.new_sku} onChange={e => setRaItem({...raItem, new_sku: e.target?.value})} /></label>
                        <br/></>}
                        {RA_QUANTITIES_ENABLED ? <>
                            <label className="dark-text text-left">Quantity Received:
                            <input form='ReceiveRAItemForm' type='number' value={raItem?.quantity} onChange={e => setRaItem({...raItem, quantity: e.target?.value})} /></label>
                            <br/>
                        </> : <></>}

                        {[1,2,3,5].includes(item?.sale_type_id) ?
                        /*If original item sale type is OB, Closeout, UsedBuy, or B-stock, check for purchase amount. If present use it, if not prompt for tester to enter it.*/
                            item?.purchase_amount ?
                                // if there's a purchase amount, use it. Should this be editable?
                                    <label className="dark-text text-left">Original Purchase Amount:&nbsp;
                                        <input form='ReceiveRAItemForm' type='number' step='.01' disabled value={originalPurchaseAmount} onChange={e => setOriginalPurchaseAmount(e.target?.value)} />
                                    </label>       
                            :
                                //If no purchase amount, prompt for entry
                                <div>
                                    <label className="dark-text text-left">Original Purchase Amount:&nbsp;
                                        <input form='ReceiveRAItemForm' type='number' step='.01' required value={originalPurchaseAmount} onChange={e => setOriginalPurchaseAmount(e.target?.value)} />
                                    </label>
                                    <i className="red-text">No purchase amount found, please enter one.</i>
                                </div>
                        :
                        <></>
                        }

                        {item?.sale_type_id == 6  ?
                        <div>
                            <label className="dark-text text-left">Original Purchase Amount:&nbsp;
                                <input form='ReceiveRAItemForm' type='number' step='.01' required value={originalPurchaseAmount} onChange={e => setOriginalPurchaseAmount(e.target?.value)} />
                            </label>
                            <i className="red-text">For new items, buy price must be retreived from Finale.</i>
                        </div>
                        :
                        <></>
                        }
                        {item?.sale_type_id == 4  ?
                        item?.consignment_payout ?
                        <div>
                            <label className="dark-text text-left">Original Purchase Amount:&nbsp;
                                <input form='ReceiveRAItemForm' type='number' step='.01' required value={originalPurchaseAmount} onChange={e => setOriginalPurchaseAmount(e.target?.value)} />
                            </label>
                            <i>Purchase amount from consignment payment &nbsp;<Link className="dark-text btn" to={{pathname: `/payment-view/${item?.consignment_payout?.id}`, state: {payment_id: item?.consignment_payout?.id}}}>View Payment</Link></i>
                        </div>
                        :
                        <div>
                            <label className="dark-text text-left">Original Purchase Amount:&nbsp;
                                <input form='ReceiveRAItemForm' type='number' step='.01' required value={originalPurchaseAmount} onChange={e => setOriginalPurchaseAmount(e.target?.value)} />
                            </label>
                            <i className="red-text">No consignment payout found for original sku.</i>
                        </div>
                        :
                        <></>
                        }
                            
                           
                        <br></br>
                        <br></br>
                        <label className="dark-text text-left">OK to resolve?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input form="ReceiveRAItemForm" type="checkbox" checked={raItem?.issue==0} onChange={e => setRaItem({...raItem, issue: e.target.checked ? 0 : 1})} /></label>
                        <label className="dark-text text-left">Issue:</label>
                        <textarea form="ReceiveRAItemForm" style={{height: '150px', width: '400px'}} value={raItem?.issue_notes} onChange={e => setRaItem({...raItem, issue_notes: e.target.value})} />
                        <br/>
                        <label className="dark-text text-left">Receiving Notes:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <textarea form="ReceiveRAItemForm" style={{height: '150px', width: '400px'}} value={raItem?.notes} onChange={e => setRaItem({...raItem, notes: e.target.value})} />
                        <br/>
                        <input form='ReceiveRAItemForm' className="btn bg-dark light-text" type='submit' />
                    </fieldset>
                    </div>
                    <div className="contained">
                        <h2 className="dark-text">Content:</h2>
                        <br/>
                        <p>SKU & Order Number: {item?.sku} - {item?.order_number}</p>
                        <p>Received: {receiveDate?.toDateString()}</p>
                        <p>Customer Return Reason: {returnReasons.filter(row => row?.id==raItem?.return_reason_id)[0]?.reason}</p>
                        <p>Actual Return Reason: {returnReasons.filter(row => row?.id==raItem?.actual_return_reason_id)[0]?.reason}</p>
                        <p>Originally Tested By: {selectedTest?.user}</p>
                        <p>OK to resolve?&nbsp;&nbsp;&nbsp;{raItem?.issue ? 'No' : 'Yes'}</p>
                        {raItem?.issue ? <p>Issue: {raItem?.issue_notes}</p> : <></>}
                        <p>Receiving Notes: {raItem?.notes}</p>
                        <br/><br/>
                        <p><i>This content will be sent automatically to the FD ticket when you click "Submit"</i></p>
                        <a rel='noreferrer' target='_blank' href={'https://themusicroom.freshdesk.com/a/tickets/' + raItem?.ra_id}>See FD Ticket</a>
                    </div>
                </div>
                : <p>Fetching RA Info...</p>}
            </InfoBox>
        </div>
    )
}

export default ReceiveRAItem;