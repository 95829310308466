import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const NewProductInv = () => {
    let context = useContext(AppContext);


    let [reportType, setReportType] = useState('summary');
    let [itemType, setItemType] = useState("any");
    let [report, setReport] = useState([]);
    let [availability, setAvailability] = useState(-1)
    let [orderBy, setOrderBy] = useState("rep")


    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/new-product-inv', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({reportType: reportType, itemType: itemType, availability: availability, orderBy: orderBy})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    useEffect(() => {
        fetchReport();
    }, [context,reportType, itemType, availability,orderBy])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>

                
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>New Product Inventory</h1>
                    <InfoButton onClick = {infoFunction}/> 
                
                
                </div>
                
                <div id="infoBox" class = "bg-light rounded" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report looks at inventory data in Finale. Note that the CSV export contains more data than what is shown in this view.</p>
                    
             
                </div>
                

                <div className = "bg-light rounded  dark-text">
                    <div className="container">       
                        <div className="container" >
                                <label className=" text-left" for="itemType" style={{padding: '15px 15px'}} >Item Type:
                                        <select  name="inverval" id="selectInterval" onChange={e => setItemType(e.target.value)}>
                                            <option selected value="Any" >Any</option>
                                            <option value="New Product" >New Product</option>
                                            <option value="Pre-Owned" >Pre-owned</option>
                                            <option value="Consignment">Consignment</option>
                                            <option value="Open Box">Open Box</option>
                                        </select>
                                </label>
                        
                                <label className=" text-left" for="availability" style={{padding: '15px 15px'}} >Availability:      
                                    <select  name="availability" id="selectAvailability" onChange={e => setAvailability(Number(e.target.value))}>
                                        <option selected value="0" >Any</option>
                                        <option value="1" >Availble Items Only</option>                                       
                                    </select>
                                </label>                                
                        </div>
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='buyer_conversion.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                


                
                


                <h2 className="light-text" style={{padding: '0px 0px 15px 0px'}}>Report at a glance:</h2>
                
                

                <TableList columnNames={['product_id', 'manufacturer', 'category', 'item_type', 'quantity_available', 'quantity_on_hand','on_order_units', 'reservation_units']}
                    columnWidths={['12%','12%','12%','12%','12%','12%','12%','12%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.product_id,
                                row?.manufacturer,
                                row?.category,
                                row?.item_type,
                                row?.quantity_available,
                                row?.quantity_on_hand,
                                row?.on_order_units,
                                row?.reservation_units

                            ]
                        })
                    } />
               
                <br/>
            </div>
        </div>
    );
}

export default NewProductInv;