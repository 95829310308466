import { useEffect, useState } from "react"


const TabBar = ({tabs, selected, updateParent}) => {
    let [barTabs, setBarTabs] = useState(tabs);

    useEffect(() => {
        setBarTabs(tabs);
    }, [tabs])

    let [barSelected, setBarSelected] = useState(selected);

    useEffect(() => {
        setBarSelected(selected);
    }, [selected])

    return (
        <div className="bg-light rounded container" style={{padding: '10px 10px'}}>
            {barTabs?.map((name,ind) => {
                return ind == barSelected ?
                    <div className="contained btn bg-lightest green-text">{name}</div>
                :
                    <div className="contained btn bg-light" onClick={() => updateParent(ind)}>{name}</div>
            })}
        </div>
    )
}

const TabbedDisplay = ({tabNames, contents}) => {
    let [selectedTab, setSelectedTab] = useState(0);

    if(tabNames?.length != contents?.length) {
        return (
            <div className="bg-light rounded">
                <p className="dark-text">Tabs and Contents lengths do not match</p>
            </div>
        )
    }

    return (
        <>
            <TabBar tabs={tabNames} selected={selectedTab} updateParent={ind => setSelectedTab(ind)} />
            {contents[selectedTab]}
        </>
    )
}

export default TabbedDisplay;