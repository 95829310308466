import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import {formatDate, secureFetch} from '../util/flip_utils';
import KeyValuePair from '../components/KeyValuePair';
import TableList from '../components/TableList';
import InfoBox from '../components/InfoBox';
import ModalContainer from '../components/ModalContainer';
import GenerateEmailTemplateForm from '../components/GenerateEmailTemplateForm';
import SelectUserForm from '../components/SelectUserForm';
import SelectCustomerForm from '../components/SelectCustomerForm';
import CreateCustomerForm from '../components/CreateCustomerForm';
import SelectProductForm from '../components/SelectProductForm';
import CreateProductForm from '../components/CreateProductForm';
import ConfirmButton from '../components/ConfirmButton';
import CreateSubmissionItemForm from '../components/CreateSubmissionItemForm';
import CreateBrandForm from '../components/CreateBrandForm';
import LoadingPopup from '../components/LoadingPopup';
import FlipRTE from '../components/FlipRTE';
import EditSubmissionForm from '../components/EditSubmissionForm';
import SendQuoteForm from '../components/SendQuoteForm';
import { CosmeticColorCoder } from '../components/ColorCoders';
import MergeSubmissionForm from '../components/MergeSubmissionForm';
import LogisticsConfirmation from '../components/LogisticsConfirmation';
import CheckmarkYN from '../components/CheckmarkYN';
import NRTCCalculator from '../components/NRTCCalculator';
import BrandPartnerLogo from '../components/BrandPartnerLogo';

const SubmissionView = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let { submission_id } = useParams();
    let [submission, setSubmission] = useState({});
    let [associatedRmas, setAssociatedRmas]= useState([]);
    let [items, setItems] = useState([]);
    let [resubmits, setResubmits] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [customer, setCustomer] = useState({});
    let [user, setUser] = useState({});

    let [fetched, setFetched] = useState(false);

    function fetchData() {
        setFetched(false);
        //get the submission info
        secureFetch(context.server + '/submission-overview', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({id: submission_id ? submission_id : props.location.state.submission_id}) 
        })
        .then(response => response.json())
        .then(data => {
            setSubmission(data?.submission);
            setItems(data?.items);
            setUser(data?.user);
            setCustomer(data?.customer);
            setAssociatedRmas(data?.associatedRmas)
            setFetched(true);
        })
    }

    function fetchStatuses() {
        secureFetch(context?.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'submission_statuses'})
        })
        .then(resp => resp.json())
        .then(results => setStatuses(results?.filter(row => row?.id != 9))); //filter out sequester so that people cant use it anymore
    }

    function fetchResubmitCheck() {
        secureFetch(context?.server + '/submission-resubmit-check', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({id: submission_id})
        })
        .then(resp => resp.json())
        .then(results => setResubmits(results))
    }

    useEffect(() => {
        fetchData();
        fetchResubmitCheck();
        fetchStatuses();
    }, [context, submission_id])

    function assignUser(id) {
        if(submission.assignee_id !== id) {
            secureFetch(context.server + '/assign-submission', {
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    submission_id: submission?.id,
                    assignee_id: id,
                }),
            })
            .then(response => response.json())
            .then(result => fetchData())
        } else {
            alert('This submission is already assigned to that user!');
        }
    }

    function assignCustomer(id) {
        secureFetch(context.server + '/update-submission-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: submission?.id,
                keys: ['customer_id'],
                values: [id],
                message: 'user reassigned the customer for this submission'
            })
        })
        .then(() => fetchData())
    }

    function assignProduct(id, item_id) {
        //update the submission item and wait for response so we can send the info back to the submission view page
        secureFetch(context.server + '/update-submission-item-fields', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                id: item_id,
                keys: ['product_id'],
                values: [id],
                message: 'user reassigned product id for this submission item'
            })
        })
        .then(() => fetchData())
    }

    function addRemoveItem(item) {
        //update the submission item and wait for response so we can send the info back to the submission view page
        secureFetch(context.server + '/update-submission-item-fields', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                id: item?.id,
                keys: ['active'],
                values: [item?.active == 1 ? 0 : 1],
                message: `user ${item?.active == 1 ? 'deactivated' : 'reactivated'} this submission item`
            })
        })
        .then(() => {
            alert('updated submission item!');
            fetchData();
        })
    }

    function submitNotes(event) {
        event.preventDefault();
        secureFetch(context.server + '/update-submission-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: submission?.id,
                keys: ['notes', 'submission_status_id'],
                values: [submission?.notes, submission?.submission_status_id],
                message: 'user edited submission notes'
            })
        })
        .then(() => fetchData())
    }

    function deactivateSubmission() {
        secureFetch(context.server + '/update-submission-fields', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: submission?.id,
                keys: ['active'],
                values: ['0'],
                message: 'user deactivated submission'
            })
        })
        .then(() => fetchData())
        history.push({pathname: '/submission-list', state: {}})
        window.location.reload();
    }

    function checkWaiting(e) {
        e.preventDefault();
        //alert(new Date().toISOString())
        if(e.target.checked) {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['waiting', 'wait_date'],
                    values: ['1', new Date().toISOString()],
                    message: 'user marked submission as waiting'
                })
            })
            .then(() => fetchData())
        } else {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['waiting', 'wait_date'],
                    values: ['0', null],
                    message: 'user marked submission as NOT waiting'
                })
            })
            .then(() => fetchData())
        }
    }

    function checkBtob(e) {
        e.preventDefault();
        if(e.target.checked) {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['btob'],
                    values: ['1'],
                    message: 'user marked submission as b2b'
                })
            })
            .then(() => fetchData())
        } else {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['btob'],
                    values: ['0'],
                    message: 'user marked submission as NOT b2b'
                })
            })
            .then(() => fetchData())
        }
    }

    function checkPriority(e) {
        e.preventDefault();
        if(e.target.checked) {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['priority'],
                    values: ['5'],
                    message: 'user marked submission as high priority'
                })
            })
            .then(() => fetchData())
        } else {
            secureFetch(context.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['priority'],
                    values: ['0'],
                    message: 'user marked submission as low priority'
                })
            })
            .then(() => fetchData())
        }
    }

    function createTicket() {
        return secureFetch(context.server + '/create-submission-ticket', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submissionId: submission?.id
            })
        })
        // .then(() => {
        //     alert('Ticket created!');
        //     fetchData();
        // })
    }

    function notifyLogistics(yn) {
        return (e) => {
            e.preventDefault();
            secureFetch(context?.server + '/update-submission-fields', {
                method: 'POST',
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: submission?.id,
                    keys: ['notify_logistics', 'logistics_notes'],
                    values: [yn ? '1' : '0', submission?.logistics_notes],
                    message: `user marked submission as${yn ? '' : ' dont'} notify logistics`
                })
            })
            .then(() => setSubmission({...submission, notify_logistics: yn ? 1 : 0}))
        }
    }

    function copyActiveItems() {
        let copytext = '';
        items.forEach(row => {
            copytext += row?.manufacturer + ' ' + row?.model_number + '\n'
        })
        navigator.clipboard.writeText(copytext);

    }



    if(!props?.location?.state?.submission_id && !submission_id) {
        return <h1>Submission not found...</h1>
    }

    if(!fetched) {
        return <h1>Fetching Submission Data...</h1>
    }

    if(!submission?.customer_id) {
        return (
            <div style={{padding: '15px 15px'}}>
                <h2>No customer is assigned to this submission - you must assign one to continue.</h2>
                <br/>
                <ModalContainer triggerText='Assign Customer' formData={{defaultSearch: submission?.email_address}} FormComponent={SelectCustomerForm} submitForm={cust => assignCustomer(cust?.id)} />
                <ModalContainer triggerText='Create New Customer' formData={{}} FormComponent={CreateCustomerForm} submitForm={cust => assignCustomer(cust?.id)} />
            </div>
        )
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <Link className='btn dark-text' to={{pathname: '/submission-list'}}>Back to List</Link>
                <h1 className='dark-text'>|</h1>
                <button 
                className={context.user?.id===submission?.assignee_id ? 'bg-green btn dark-text' : 'btn dark-text'} 
                onClick={() => {
                    assignUser(context.user?.id);
                }}>
                    Assign This Submission To Me
                </button>
                <h1 className='dark-text'>|</h1>
                <ModalContainer buttonClass={submission?.assignee_id && submission?.assignee_id!=context?.user?.id ? 'btn bg-green dark-text' : 'btn dark-text'}
                triggerText='Assign to a User' formData={{searchValue: submission?.preferred_rep != 'No Preference' ? submission?.preferred_rep : '',
                params: {
                    equal: [{
                        key: 'purchaser',
                        value: '1'
                    }]
                }}} FormComponent={SelectUserForm} submitForm={u => assignUser(u?.id)} />
            </div>
            {customer?.blacklisted == 1 ? <><br/><div className='bg-red rounded' style={{padding: '15px 15px'}}><p className='light-text text-center'><b>THIS CUSTOMER IS BLACKLISTED</b></p></div></> : <></>}
            <div className='container'>
                <div className='contained-smaller'>
                    <h1>Submission Overview {submission?.id ? '' : 'Loading...'}</h1>
                    <InfoBox title='Submission Info'>
                        <KeyValuePair label={'Submission ID:'} value={submission?.id} textClass='dark-text' />
                        <KeyValuePair label={'Submission Date:'} value={formatDate(submission?.created, {date: true})} textClass='dark-text' />
                        <KeyValuePair label={'Freshdesk:'} value={submission?.fd_id ? 
                        <a className='btn dark-text' rel='noreferrer' target='_blank' href={`https://themusicroom.freshdesk.com/a/tickets/${submission?.fd_id}`}>Go to Ticket</a>
                        : <ModalContainer triggerText='Create Corresponding FD Ticket' formData={{task: createTicket, message: 'This process could take a few minutes... sorry! :)'}} FormComponent={LoadingPopup} submitForm={()=>{fetchData()}} />} textClass='dark-text' />
                        <br/>
                        <br/>
                        <KeyValuePair label={'Name:'} value={<b>{submission?.first_name + ' ' + submission?.last_name}</b>} textClass='dark-text' />
                        <KeyValuePair label={'Email:'} value={submission?.email_address} textClass='dark-text' />
                        <KeyValuePair label={'Phone:'} value={submission?.phone_number} textClass='dark-text' />
                        <KeyValuePair label={'Location:'} value={submission?.state + ', ' + submission?.country + ` (${submission?.zip})`} textClass='dark-text' />
                        <KeyValuePair label={'Reason for Selling:'} value={submission?.reason_for_selling} textClass='dark-text' />
                        {submission?.reason_for_selling == 'Upgrading' ? 
                            <KeyValuePair label={'Upgrading to:'} value={submission?.upgrading_to} textClass='dark-text' />
                        : <></>}
                        <KeyValuePair label={'Interested In:'} value={submission?.interested_in} textClass='dark-text' />
                        <KeyValuePair label={'Additional Comments:'} value={submission?.additional_comments} textClass='dark-text' />
                        <br/>
                        <br/>
                        <KeyValuePair label={'Preferred Rep:'} value={<b>{submission?.preferred_rep}</b>} textClass='dark-text' />
                        <KeyValuePair label={'Source:'} value={submission?.source=='wwstereo' ? <b className='bg-red light-text'>{'WW STEREO'}</b> : submission?.source} textClass='dark-text' />
                        {submission?.trade_towards ? 
                            <KeyValuePair label={'Trading Towards (from storefront):'} value={submission?.trade_towards} textClass={'dark-text'} />
                        : <></>}
                        {submission?.sell_or_consign ? 
                            <KeyValuePair label={'Sell or Consign (from storefront):'} value={submission?.sell_or_consign} textClass={'dark-text'} />
                        : <></>}
                        <br/>
                        <br/>
                        {user?.id ? <KeyValuePair label={'Assignee:'} value={user?.first_name + ' ' + user?.last_name} textClass='dark-text' /> : <></>}
                        {/* <KeyValuePair label={'Completed?:'} value={submission?.completed ? 'Yes' : 'No'} textClass='dark-text' /> */}
                        <br/>
                        <br/>
                        <ModalContainer triggerText='Edit Info' submitForm={()=>{window.location.reload()}} formData={submission} FormComponent={EditSubmissionForm} />
                    </InfoBox>
                    <br/>
                    <InfoBox title='Associated RMAs'>
                        <TableList
                            columnNames={['RMA Number', 'RMA Type']}
                            columnWidths={['50%','50%']}
                            entries={
                                associatedRmas.map( associatedRmas => {
                                    return [
                                        <Link className='btn dark-text' to={{pathname: `/tpa-view/${associatedRmas?.id}`, state: {tpa_id: associatedRmas?.id}}}>{associatedRmas?.tpa_number}</Link>,
                                        associatedRmas?.is_consignment == 1 ? 'Consign' : 'buy'
                                    ]
                                })
                            }
                        />
                    </InfoBox>
                    

                    {customer?.id ? 
                    <><br/>
                    <InfoBox title='Customer Info'>
                        <KeyValuePair label={'Name:'} value={customer?.name} textClass='dark-text' />
                        <KeyValuePair label={'B-to-B:'} value={<b><CheckmarkYN val={customer?.btob}/>&nbsp;{customer?.btob ? customer?.company_name :""}</b>} textClass='dark-text' />
                        <KeyValuePair label={'Email:'} value={customer?.email} textClass='dark-text' />
                        <KeyValuePair label={'Phone:'} value={customer?.phone} textClass='dark-text' />
                        <KeyValuePair label={'Last Payment Method:'} value={customer?.last_rma_payment_method} textClass='dark-text' />
                        <KeyValuePair label={'Notes:'} value={customer?.notes} textClass='dark-text' />
                        <KeyValuePair label={'View Customer:'} value={<Link className='btn dark-text' to={{pathname: `/customer-view/${submission?.customer_id}`, state: {customer_id: submission?.customer_id}}}>View</Link>} textClass='dark-text' />
                    </InfoBox></>
                    :
                    <></>
                    }
                    <ModalContainer triggerText='Assign to Customer' formData={{}} FormComponent={SelectCustomerForm} submitForm={cust => assignCustomer(cust?.id)} />
                    <ModalContainer triggerText='Create + Assign Customer' formData={{}} FormComponent={CreateCustomerForm} submitForm={cust => assignCustomer(cust?.id)} />
                </div>
                <div className='contained'>
                    <h1 className='light-text'>Items:</h1>
                    <TableList
                    columnNames={[' ','Title','Pricing','Qty','Box?',' ',' ',' ',' ']}
                    columnWidths={['3%','24%','15%','6%','5%','10%','10%','15%','12%']}
                    entries={
                        items.map( item => {
                            return [
                                item?.brand_partner ? <div><BrandPartnerLogo height = {'20px'}/></div> : '',
                                `${item?.manufacturer} ${item?.model_number}`,
                                <div>
                                    BP: {parseInt(item?.rec_buy_price) || '-'}<br/>
                                    LP: {parseInt(item?.rec_list_price) || '-'}<br/>
                                    MV: {parseInt(item?.market_value) || '-'}
                                    {item?.amount_offered ?
                                        <><br/>Offer: {parseInt(item?.amount_offered) || '-'}</>
                                    : <></>}
                                </div>,
                                item?.quantity,
                                <CheckmarkYN val={item?.has_packaging} />,
                                <Link className='btn dark-text' to={{pathname: `/submission-item-view/${item?.id}`, state: {item_id: item?.id}}}>View</Link>,
                                <ModalContainer  buttonClass={ item?.product_id ? 'bg-green btn dark-text' : 'btn dark-text' }
                                triggerText='Assign Product'
                                formData={{title: item?.manufacturer + ' ' + item?.model_number}}
                                FormComponent={SelectProductForm}
                                submitForm={prod => assignProduct(prod?.id, item?.id)}
                                />,
                                item?.product_id ?
                                <Link className={
                                    item?.pass ? 'bg-red btn light-text' :
                                        item?.is_consignment ? 'bg-blue btn dark-text' : 
                                            item?.appraiser ?'bg-light-green btn dark-text' :
                                                'btn dark-text' }
                                to={{pathname: `/appraise-item/${item?.id}`, state: {item_id: item?.id}}}>
                                    {'Appraise' + (item?.appraiser ? `d by ${item?.appraiser}` : '')}
                                </Link>
                                : <button className='btn dark-text'>{"<---"}</button>,
                                <button className={item?.active ? 'btn light-text bg-red' : 'btn light-text bg-green'} onClick={() => addRemoveItem(item)}>{item?.active ? 'Deactivate' : 'Reactivate'}</button>
                            ]
                        })
                    }
                    />
                    <div className='container'>
                        <div className='contained' style={{maxWidth: '20%'}}>
                            <ModalContainer triggerText='Add an Item' formData={submission} FormComponent={CreateSubmissionItemForm} submitForm={() => fetchData()} />
                        </div>
                        <div className='contained'>
                            <button className='btn dark-text' onClick={() => copyActiveItems()}>Copy Active Items</button>
                        </div>
                        <label className='contained text-right'>
                            {submission?.submission_status_id == 4 ? //pending
                                <Link className='btn bg-green dark-text' style={{padding: '5px 5px'}} 
                                to={{pathname: `/accept-quote/${submission?.id}`, state: {submission_id: submission?.id}}}>Accept Quote</Link>
                                : <></>
                            }
                            {submission?.submission_status_id == 3 ? //appraised
                                <ModalContainer triggerText='Send Quote' formData={submission} FormComponent={SendQuoteForm} submitForm={() => {alert('Submission status updated!'); fetchData()}} />
                                : <></>
                            }
                        </label>
                    </div>
                    

                    {/*<ModalContainer submitForm={() => {}} triggerText={'Generate Email Template'} FormComponent={GenerateEmailTemplateForm} formData={submission} /> */}
                    <div className='container'>
                        
                        <div className='contained'><ModalContainer triggerText='+ Brand' formData={{}} FormComponent={CreateBrandForm} submitForm={() => alert('New brand created!')} /></div>
                        <div className='contained'><ModalContainer triggerText='+ Product' formData={{}} FormComponent={CreateProductForm} submitForm={() => alert('New product created! You can now assign it to an item under "Assign Product"')} /></div>
                        <div className='contained'></div><div className='contained'></div>
                        <div className='contained'></div><div className='contained'></div>
                    </div>

                    <div className='container'>
                        <div className='contained'>
                            <ModalContainer triggerText="NRTC Calc" formData={items} FormComponent={NRTCCalculator} submitForm={() => {}} />
                        </div>
                        <div className='contained'></div>
                        <div className='contained'></div>
                        <div className='contained'></div>
                    </div>
                    <br/>
                    <InfoBox title='Buyer Notes'>
                        <div className='container'>
                            <label className="dark-text text-left contained">Submission Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <select onChange={(e) => setSubmission({...submission, submission_status_id: e.target.value})} value={submission?.submission_status_id}>
                                    {statuses?.map(row => 
                                        <option key={row?.id} value={row?.id}>{row?.submission_status}</option>
                                    )}
                                </select>
                            </label>
                            <br/>
                            <LogisticsConfirmation buttonText={'Notify Logistics'} notes={submission?.logistics_notes} notesUpdater={(val) => setSubmission({...submission, logistics_notes: val})} clickHandler={notifyLogistics} logisticsChecked={submission?.notify_logistics == '1'} />
                        </div>
                        <div className='container'>
                            <label className='dark-text text-left contained'>
                                    <input type='checkbox' checked={submission?.waiting=='1'} onChange={checkWaiting} />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiting?<br/>
                                {submission?.waiting ? <i>since {formatDate(submission?.wait_date, {date:true})}</i> : <></>}</label>
                            
                            <label className='dark-text text-left contained'>
                                    <input type='checkbox' checked={submission?.btob=='1'} onChange={checkBtob} />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B2B</label>
                            <div className='contained'>
                                <label className='dark-text text-left contained'>
                                    <input type='checkbox' checked={submission?.priority=='5'} onChange={checkPriority} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;High Priority</label>
                            </div>
                            <div className='contained'></div>
                        </div>
                        <br/>
                        {/* <textarea style={{minWidth: '600px', width: '600px', maxWidth: '600px', height: '100px'}} value={submission?.notes || ''} onChange={e => setSubmission({...submission, notes: e.target.value})} /> */}
                        <FlipRTE text={submission?.notes} updateParent={e => setSubmission({...submission, notes: e.target.value})} />
                        <br/><br/>
                        <button className='btn light-text bg-dark' onClick={submitNotes}>Save Notes</button>
                    </InfoBox>
                    {resubmits?.length > 0 ? 
                    <>
                        <br/><br/>
                        <h3 className='light-text'>Recent Submissions from this Customer:</h3>
                        <TableList columnNames={['ID', 'Status', 'Items', 'Date']}
                        columnWidths={['10%','15%','60%','15%']}
                        entries={resubmits?.map(row => {
                            return [
                                <Link className='btn dark-text' to={{pathname: `/submission-view/${row?.id}`, state: {submission_id: row?.id}}}>{row?.id}</Link>,
                                //row?.assignee,
                                row?.submission_status,
                                row?.items,
                                formatDate(row?.created, {date: true}),
                            ]
                        })} />
                    </>
                    : <></>}
                    <br/><br/>
                    <ConfirmButton buttonText='Deactivate Submission' confirmation='Are you sure you want to deactivate this submission? This process is NOT easily reversible!'
                    clickHandler={deactivateSubmission} />
                    <br/><br/>
                    <ModalContainer triggerText='Merge Into Another Submission' formData={{...submission, items: items}} FormComponent={MergeSubmissionForm} submitForm={(id) => {
                        history.push({pathname: `/submission-view/${id}`, state: {submission_id: id}});
                        window.location.reload();
                    }} />
                </div>
            </div>
        </div>
    );
}

export default SubmissionView;