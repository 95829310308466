import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { formatDate, secureFetch } from "../util/flip_utils";
import TableList from "./TableList";

const SelectRAForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [ras, setRas] = useState([]);
    let [searchValue, setSearchValue] = useState('');
    let [fetched, setFetched] = useState(false);

    const fetchRas = () => {
        secureFetch(context.server + '/search-ras', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: searchValue})
        })
        .then(resp => resp.json())
        .then(results => {
            setRas(results)
            setFetched(true)
        })
    }

    /* eslint-disable */
    useEffect(() => {
        fetchRas();
    },[context, searchValue])
    /* eslint-enable */

    const submit = (ra) => {
        submitForm(ra);
        closeModal();
    }

    if(!fetched) {
        return (
            <h1 className='dark-text'>Loading RAs...</h1>
        );
    }

    return (
        <div>
            <div className="container">
                <h1 className='dark-text contained'>Select RA</h1>
                <button className="btn bg-dark light-text contained" style={{maxWidth: '20%'}} onClick={() => submit({})}>Deselect RA</button>
            </div>
            <input autoFocus className='search-bar' type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <br/><br/>
            <TableList columnNames={['RA Number','Customer','Title','Date','Select']}
            columnWidths={['8%','8%','50%','14%','20%']}
            entries={
                ras.map( row => {
                    return [
                        row?.id,
                        row?.customer,
                        row?.title,
                        formatDate(row?.created, {date: true}),
                        <button className='btn dark-text' onClick={() => submit(row)}>Select</button>
                    ]
                })
            }
            />
        </div>
    );
}

export default SelectRAForm;