import { useContext,useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";

const EditTpaForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [tpa, setTpa] = useState(formData);
    let [formDisable, setFormDisable] = useState(false);
    let [users, setUsers] = useState([]);

    const fetchUsers = () => {
        secureFetch(context.server + '/search-users', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({params: {
                equal: [{
                    key: 'purchaser',
                    value: '1'
                }]
            }})
        })
        .then(resp => resp.json())
        .then(results => {
            setUsers(results);
        })
    }



    function submit(e) {
        e.preventDefault();
        setFormDisable(true);
        secureFetch(context.server + '/update-tpa', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: tpa?.id,
                keys: ['notes', 'active', 'salesperson_id'],
                values: [tpa?.notes, tpa?.active, tpa?.salesperson_id]
            })
        })
        .then(resp => resp.json())
        .then(newTpa => {
            submitForm(newTpa?.id);
            closeModal();
        })
    }

    useEffect(() => {
        fetchUsers()
        console.log(tpa);
    }, [context, tpa])

    return (
        <div>
            <form id='EditTpaForm' onSubmit={submit} />
            <fieldset form='EditTpaForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className='dark-text'>Edit RMA</h1>
                    <div className="container">
                        <label className=''>Sales Rep:&nbsp;</label>
                        <select value={tpa?.salesperson_id} onChange={e => setTpa({...tpa, salesperson_id: e.target.value})}>
                            <option key={0} value={null}>-- None --</option>
                            {users.map( (user) => 
                                <option key={user?.id} value={user?.id} >{user?.first_name + ' '+user?.last_name}</option>
                            )}
                        </select>
                    </div>



                    <label className='dark-text text-left'>RMA Notes:</label>
                    <textarea form='EditTpaForm' required rows='10' style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} value={tpa?.notes} onChange={e => setTpa({...tpa, notes: e.target.value})} />
                    <label className='dark-text text-left'>Active:&nbsp;&nbsp;
                    <input form='EditTpaForm' type='checkbox' checked={tpa?.active==1} onChange={e => setTpa({...tpa, active: e.target.checked ? 1 : 0})} /> </label>
                    <br/><br/>
                    <input form='EditTpaForm' className='btn light-text bg-dark' type='submit' />
                </div>
            </fieldset>
        </div>
    )
}

export default EditTpaForm;