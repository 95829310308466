import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import FlipRTV from "../../components/FlipRTV";

const PickList = () => {
    let context = useContext(AppContext);
    let [report, setReport] = useState([]);

    function fetchReport() {
        secureFetch(context.server + '/reports/generic-report', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reportName: 'pick_list_view',
                useDate: false,
            })
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }
            setReport(data)
        })
    }

    useEffect(() => {
        fetchReport();
    }, [])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>
                <h1>Pick List Report</h1>
                <br/>
                <CSVLink data={report} filename='pick_list_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink>
                <br/>
                <br/>
                <h2 className="light-text">Report at a glance:</h2>
                <TableList columnNames={['SKU', 'Title', 'Order #', 'Customer', 'Date Modified']}
                columnWidths={['15%', '40%', '15%', '15%', '15%']}
                entries={report?.map(row => {
                    return [
                        row?.sku,
                        row?.name,
                        row?.orderNumber,
                        row?.customerEmail,
                        formatDate(row?.modifyDate, {date: true}),
                    ]
                })}
                />
                <br/>
            </div>
        </div>
    );
}

export default PickList;