import { useContext } from "react"
import AppContext from '../AppContext';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ImageArranger from "../components/ImageArranger";
import { secureFetch } from "../util/flip_utils";



const ItemImages = (props) => {
    let history = useHistory();
    let { item } = useParams();
    let context = useContext(AppContext);

    function pullPhotos() {
        secureFetch(context.server + '/fetch-item-photos', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item: item ? item : props?.location?.state?.item
            })
        })
        .then(() => {
            window.location.reload();
        })
    }



    if(props?.location?.state?.item?.id || item?.id)
        return (
            <div style={{padding: '15px 15px'}}>
                <ImageArranger item_id={item?.id ? item?.id : props?.location?.state?.item?.id}
                submitHandler={() => history.goBack()} /> {/* back from whence you came >:)*/}
                <button className="btn dark-text" onClick={() => pullPhotos()}>Fetch Photos from BC</button><br/>
                <i className="light-text">(this will refresh the page and clear any changes you've made so far)</i>
            </div>
        );
    else
        return (
            <h1 className='light-text'>No item_id given</h1>
        );
}

export default ItemImages;
