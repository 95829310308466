import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const InventoryValue = () => {
    let context = useContext(AppContext);

    let [report, setReport] = useState([]);



    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/inventory-value', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }

    useEffect(() => {
        fetchReport();
    }, [])

    return(
        <div>
            <div style={{padding: '30px 30px'}}>

                
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Inventory Value</h1>
                    <InfoButton onClick = {infoFunction}/>
                </div>
                
                <div id="infoBox" class = "bg-light rounded" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report shows the value of our current Finale inventory.</p>
                    <p>Consignment "cost" is calculated as 65% of original list price.</p>
                </div>
                

                <div className = "bg-light rounded  dark-text">
                    <div className="container">       
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='inventory_value.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                


                
                
                <h2 className="light-text" style={{padding: '0px 0px 15px 0px'}}>Report at a glance:</h2>
                
                

                <TableList columnNames={['Item Type', 'QOH', 'QOH Cost', 'QOH List']}
                    columnWidths={['12%','12%','12%','12%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.item_type,
                                row?.qoh,
                                Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row?.qoh_cost),
                                Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row?.qoh_list)

                            ]
                        })
                    } />
               
                <br/>
            </div>
        </div>
    );
}

export default InventoryValue;