import { useContext, useState } from "react";
import { secureFetch } from "../util/flip_utils";
import AppContext from "../AppContext";


export default function AddDeductionForm({formData, submitForm, closeModal}) {
    let context = useContext(AppContext);
    let [deduction, setDeduction] = useState();

    let [formDisable, setFormDisable] = useState(false);

    function submit(e) {
        e.preventDefault();
        setFormDisable(true);
        secureFetch(context?.server + '/add-deduction', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({item: formData, deduction: deduction})
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    return (
        <div>
            <form id='AddDeductionForm' onSubmit={submit} />
            <fieldset form='AddDeductionForm' disabled={formDisable ? 'disabled' : ''} >
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1>Add Deduction</h1><br/>
                    <label className="dark-text text-left">Note:</label>
                    <textarea form="AddDeductionForm" style={{minWidth: '100%', width: '100%', maxWidth: '100%'}} rows='8'
                    value={deduction?.note} onChange={e => setDeduction({...deduction, note: e.target.value})} placeholder="deduction notes" />
                    <br/>
                    <label className="dark-text text-left">Amount (+):&nbsp;&nbsp;
                        <input form="AddDeductionForm" type="number" value={deduction?.amount} onChange={e => setDeduction({...deduction, amount: e.target.value})} />
                    </label>
                    <br/><br/>
                    <input form="AddDeductionForm" type='submit' value="OK" className="btn dark-text" />
                </div>
            </fieldset>
        </div>
    )
}