import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const ItemsStocked = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [reportType, setReportType] = useState('summary');
    let [stockers, setStockers] = useState()
    let [stockerId, setStockerId] = useState();
    let [stockerName, setStockerName] = useState();

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/items-stocked', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({startDate: startDate, endDate: endDate, stockerId: stockerId, reportType: reportType})  
        })
        .then(res => res.json())
        .then(res => {

            stockers = res?.stockers
            setStockers(stockers)

            let data = res?.report

            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, reportType, startDate, endDate, stockerId])





        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>Items Stocked</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    
                    <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                        <p>This report shows the number of items stocked during the selected time period. Items are marked stocked in Flip when dimensions are submitted.</p>
                    </div> 

                    <div className="container">

                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                        </div>
                        
                        <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                            <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                        </div>
                                        <div className='container'>
                                            <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                            <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                        </div>
                                        <p><i>(Leave end date blank to get a one-day report)</i></p>
                                        {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                        <br/>
                        </div>

                        {reportType =='detail' ? 
                            <label className="contained text-left" for="stocker" style={{padding: '15px 15px'}} >stocker:&nbsp;
                                <select  name="stocker" id="selectstocker" onChange={e => {console.log(e);setStockerId(e.target.value); setStockerName(e.target.selectedOptions[0].label)}} value={stockerId}>
                                    <option value='null' >All</option>
                                    {stockers ? 
                                        stockers.filter(p => p.id != stockerId).map(e => <option value={e.id} >{e?.stocker}</option>)
                                    : <></>}
                                    {stockerId && stockerId != 'null' ?
                                    <option value={stockerId} >{stockerName}</option>
                                    :<></>}
                                </select>
                            </label>
                        :
                        <></>    
                        }

        

                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='items-stocked.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>
                

  

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType == 'summary' ?
                <TableList columnNames={['Stocker', 'Items Stocked']}
                    columnWidths={['20%','80%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.stocker,
                                <>{row?.unique_items_stocked}&nbsp;&nbsp;&nbsp;{row?.stocker == 'Approx. Value' || row?.stocker == 'Total Weight' ? '' :' ('+row?.items_stocked +')'}</>
                                
                                
                            ]
                        })
                    } 
                />
            :
            <TableList columnNames={['Stocker', 'SKU', 'Title', 'Date Stocked']}
                columnWidths={['15%','15%', '50%','20%']}
                entries={
                    report?.map(row => {
                        return [
                            row?.stocker,
                            <Link className='btn dark-text' to={{pathname: `/item-view/${row?.id}`, state: {item_id: row?.id}}}>{row?.sku}</Link>,
                            row?.title,
                            row?.date_stocked                   
                        ]
                    })
                } 
            />
        }
           
      
            
        </div>
    );
}

export default ItemsStocked;