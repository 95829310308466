import { useContext, useEffect, useState } from "react"
import AppContext from "../AppContext"
import { formatDate, secureFetch } from "../util/flip_utils";
import InfoBox from "./InfoBox";
import KeyValuePair from "./KeyValuePair";
import TableList from "./TableList";
import ShowHideBox from "./ShowHideBox";


const BulkPayoutForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [tpa, setTpa] = useState(formData);
    let [payments, setPayments] = useState(formData?.payments?.filter(p => p?.payment_status == "Approved"));
    let [newPaymentMethodId, setNewPaymentMethodId] = useState(formData?.payment_method_id);
    let [payoutNotes, setPayoutNotes] = useState("");
    let [payoutLink, setPayoutLink] = useState("");
    let [payMethods, setPayMethods] = useState([]);

    let [formDisable, setFormDisable] = useState(true)

    function checkEntitled() {
        secureFetch(context.server + '/verify-entitled', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                entitlement: 'pay-out'
            })
        })
        .then(resp => resp.json())
        .then(result => {
            if(!result?.access) {
                alert('You are not entitled to close payments.');
                closeModal();
            } else {
                setFormDisable(false);
            }
        })
    }

    function fetchMethods() {
        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'payment_methods', params: {equal: [{key: 'rma', value: '1'}, {key: 'active', value: '1'}]}})
        })
        .then(resp => resp.json())
        .then(results => setPayMethods(results))
    }

    useEffect(() => {
        // i tried to do this without a useEffect for consistency, but it resulted in a weird bug
        // that caused the form to spontaneously attempt the fetch methods again and fail, and enter an infinite loop
        checkEntitled();
        fetchMethods();
    },[context])

    function submit(event) {
        event.preventDefault();

        let datestring = new Date().toISOString().replace('T', ' ');
        datestring = datestring.substring(0, datestring.length - 5); //cut off last .0000 seconds
        secureFetch(context.server + '/bulk-pay-out', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tpa: tpa,
                payments: payments?.filter(p => p?.selected),
                payment_method_id: newPaymentMethodId,
                payout_notes: payoutNotes,
                payout_link: payoutLink,
                payment_date: datestring
            })
        })
        .then(() => {
            closeModal();
            submitForm();
        })
        .catch(err => errors += JSON.stringify(err))

    }

    // return ( <div><p>{JSON.stringify(tpa)}</p><p>{JSON.stringify(payments)}</p></div> )

    return (
        <div>
            <form id='BulkPayoutForm' onSubmit={submit} />
            <fieldset form='BulkPayoutForm' disabled={formDisable ? 'disabled' : ''}>
                <div className="bg-light" style={{padding: '15px 15px'}}>
                    <h1 className="dark-text">Bulk Pay Out:</h1>
                    <InfoBox title='RMA Info:'>
                        <KeyValuePair label="RMA:" value={tpa?.tpa_number} />
                        <KeyValuePair label="Customer:" value={tpa?.seller_name} />
                        <KeyValuePair label="Date Created:" value={formatDate(tpa?.created)} />
                    </InfoBox>
                    <br/>
                    <h2 className="dark-text">Payments</h2>
                    <TableList columnNames={['Pay?','SKU','Amount','Details','Amount']} columnWidths={['15%','15%','15%','35%', '20']}
                    entries={payments?.map((row, ind) => {
                        return [
                            <input type="checkbox" onChange={e => {
                                let newPayments = [...payments];
                                newPayments[ind].selected = e.target.checked;
                                setPayments(newPayments);
                            }} />,
                            row?.sku,
                            row?.amount,
                            <ShowHideBox showText="v" hideText="^" isShown={false} content={
                                <table>
                                    {row?.line_items?.map(line => <tr><td className="px-3">{line?.title}:</td><td>{line?.amount}</td></tr>)}
                                </table>
                            } />,
                            row?.amount,
                        ]
                    })} />
                    <label className="dark-text text-right">Total:&nbsp;&nbsp;<input disabled value={payments?.filter(p => p?.selected)?.reduce(function(acc, curr){return parseFloat(acc) + (parseFloat(curr?.amount) || 0)}, 0).toFixed(2)} /></label>
                    <br/>
                    <label className="dark-text text-left">Payment Method:&nbsp;&nbsp;&nbsp;
                    <select form='BulkPayoutForm' required className='contained' onChange={ e => setNewPaymentMethodId(e.target.value) } value={newPaymentMethodId}>
                        <option value={null} key={0}>*** SELECT PAYMENT METHOD ***</option>
                        {payMethods.map( (row) => 
                            <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                        )}
                    </select>
                    </label>
                    <br/>
                    <label className="dark-text text-left">Payout Link:&nbsp;&nbsp;&nbsp;
                    <input form='BulkPayoutForm' style={{width: '700px'}} value={payoutLink} onChange={e => setPayoutLink(e.target.value)} /></label>
                    <br/>
                    <label className="dark-text text-left">Payout Notes:</label>
                    <textarea form='BulkPayoutForm' style={{minWidth: '800px', width: '800px', maxWidth: '800px'}} rows='6' value={payoutNotes} onChange={e => setPayoutNotes(e.target.value)} />
                    <br/><br/>
                    <input form='BulkPayoutForm' type='submit' className="btn light-text bg-dark" />
                </div>
            </fieldset>
        </div>
    )
}

export default BulkPayoutForm;