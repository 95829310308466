import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";

const SelectItemForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let [items, setItems] = useState([]);
    let [searchValue, setSearchValue] = useState('');
    let [fetched, setFetched] = useState(false);

    const fetchItems = () => {
        secureFetch(context.server + '/search-items', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: searchValue, saleType: "null", tester: "null", status: "null", includeNew: 1})
        })
        .then(resp => resp.json())
        .then(results => {
            setItems(results)
            setFetched(true)
        })
    }

    /* eslint-disable */
    useEffect(() => {
        fetchItems();
    },[context, searchValue])
    /* eslint-enable */

    const submit = (item) => {
        submitForm(item);
        closeModal();
    }

    if(!fetched) {
        return (
            <h1 className='dark-text'>Loading Items...</h1>
        );
    }

    return (
        <div>
            <h1 className='dark-text'>Select Item</h1>
            <input autoFocus className='search-bar' type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <br/><br/>
            <TableList columnNames={['SKU','Title','List Price','Select']}
            columnWidths={['10%','50%','20%','20%']}
            entries={
                items.map( row => {
                    return [
                        row?.sku.split('*')[0].split('new').join(' new '),
                        row?.title,
                        row?.list_price ? `$${row?.list_price}` : 'None',
                        <button className='btn dark-text' onClick={() => submit(row)}>Select</button>
                    ]
                })
            }
            />
        </div>
    );
}

export default SelectItemForm;