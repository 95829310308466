import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import CreateCustomerForm from "../components/CreateCustomerForm";
import DraftItemForm from "../components/DraftItemForm";
import ModalContainer from "../components/ModalContainer";
import SelectCustomerForm from "../components/SelectCustomerForm";
import TableList from "../components/TableList";
import { secureFetch } from "../util/flip_utils";
import SearchSelect from "../components/SearchSelect";
import { useHistory } from "react-router";

const CreateTPA = (props) => {
    let history = useHistory();
    let context = useContext(AppContext);
    let [tpa, setTpa] = useState({salesperson_id: context.user?.id});
    let [customer, setCustomer] = useState({});
    let [items, setItems] = useState([]);
    let [disableButton, setDisableButton] = useState(false);

    let [payMethods, setPayMethods] = useState([])

    function fetchOptions() {
        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'payment_methods'})
        })
        .then(resp => resp.json())
        .then(results => setPayMethods(results))
    }

    useEffect(() => {
        fetchOptions();
    }, [])

    function addItem(item) {
        let newItems = items.map(i=>i);
        newItems.push(item);
        setItems(newItems);
    }

    function removeItem(index) {
        let newItems = items.map(i=>i);
        newItems.splice(index, 1);
        setItems(newItems);
    }


    function submitTpa(event) {
        event.preventDefault();
        setDisableButton(true);
        secureFetch(context.server + '/draft-tpa', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tpa: tpa,
                items: items,
            })
        })
        .then(tpa => {
            alert('RMA created!');
            history.push({pathname: `/tpa-view/${tpa?.id}`, state: {tpa_id: tpa?.id}});
            window.location.reload();
        })
    }

    useEffect(() => {
        let newTpa = {...tpa, customer_id: customer?.id};
        setTpa(newTpa);
    }, [customer])

    return (
        <div style={{padding: '15px 15px'}}>
            <h1>Draft a New RMA</h1>
            <form id='CreateTPA' onSubmit={submitTpa} style={{display: 'none'}}></form>
            <fieldset form='CreateTpa' disabled={disableButton ? 'disabled' : ''}>
                <div className='container'>
                    <div className='contained' style={{width: '50%'}}>
                        <label className='light-text text-left'><b>Notes: </b></label>
                        <textarea form='CreateTPA' required style={{maxWidth: '80%', minWidth: '80%', width: '80%'}} rows='10' value={tpa?.notes} onChange={e => setTpa({...tpa, notes: e.target.value})} />
                        <br/><br/>

                        <label className='light-text text-left'>Payment Method:</label>
                        {/*<SearchSelect options={payMethods.map(row => { return {label: row?.payment_method, value: row?.id} })}
                        initialValue={{label: null, value: null}} updateParent={opt => {
                            opt ? setTpa({...tpa, payment_method_id: opt['value']})
                            : setTpa({...tpa, payment_method_id: null})
                        }} />*/}
                        <select form='CreateTPA' required className='contained' onChange={ e => setTpa({...tpa, payment_method_id: e.target.value}) } value={tpa?.payment_method_id}>
                            <option value={null} key={0}>*** SELECT PAYMENT METHOD ***</option>
                            {payMethods.map( (row) => 
                                <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                            )}
                        </select>

                        <br/><br/>
                        <label className='light-text text-left'><b>Customer: </b>{customer?.name ? <strong style={{padding: '5px'}} className='bg-lightest dark-text'>{`${customer?.name}`}</strong> : 'None'}</label>
                        <br/>
                        <ModalContainer triggerText='Select Customer' formData={{}} FormComponent={SelectCustomerForm} submitForm={cust => setCustomer(cust)} />
                        <ModalContainer triggerText='Create Customer' formData={{}} FormComponent={CreateCustomerForm} submitForm={cust => setCustomer(cust)} />
                    </div>
                    <div className='contained'>
                        <i className='light-text'>(these items will not be saved until you press the SUBMIT button at the bottom of this page)</i>
                        <TableList columnNames={['Title', 'Cosmetic','Buy Price', 'View', 'Remove']}
                        columnWidths={['50%','10%','20%','10%', '10%']}
                        entries={
                            items.map( (row, index) => {
                                return [
                                    row?.title,
                                    row?.cosmetic_condition_id ? `${row?.cosmetic_condition_id}/10` : 'None',
                                    row?.purchase_amount,
                                    <ModalContainer triggerText='View' formData={row} submitForm={(item)=>{
                                        let newItems = items.map(i=>i);
                                        newItems[index] = item;
                                        setItems(newItems);
                                    }} FormComponent={DraftItemForm} />,
                                    <button className='btn bg-red light-text' onClick={(e) => {
                                        e.preventDefault();
                                        removeItem(index)
                                    }}>-</button>
                                ]
                            })
                        }
                        />
                        <ModalContainer triggerText='Add Item' formData={{}} submitForm={item => addItem(item)} FormComponent={DraftItemForm} />
                    </div>
                </div>
                <br/><br/><br/>
                <input form='CreateTPA' className='btn dark-text' type='submit' />
            </fieldset>
        </div>
    );
}

export default CreateTPA;