//creates the component given the header component and each of the listentry components
const ListBuilder = ({header, entries}) => {
    return (
        <table className='table-auto border-collapse border border-slate-500' style={{borderRadius: '5px'}}>
            <thead className={''}>
                {header}
            </thead>
            <tbody>
                {entries.map( (ent, ind) => {
                    return <>
                        {ent}
                    </>;
                })}
            </tbody>
        </table>
    );
}

//creates the styled header given the column names and widths
const Header = ({columnNames, columnWidths}) => {
    return (
        <tr className=''>
            {columnNames.map( (col, index) => {
                return <th key={index} className='border border-slate-600 bg-gray-400 h-11' >
                    {col ? col : '-'}
                </th>;
            })}
        </tr>
    );
}

//takes a LIST of entries, so you'll need to map your list of objects to [ [col1, col2, col3], [col1, col2, col3], [col1, col2, col3]... ]
const ListEntry = ({entryVals, columnWidths}) => {
    return (
        <tr>
            {entryVals.map( (val, index) => {
                return <td key={index} className='border border-slate-700 px-2 bg-gray-50' >{val!==null ? val : '-'}</td>
            })}
        </tr>
    );
}

const TableListTwo = ({columnNames, columnWidths, entries}) => {
    let numCols = columnNames.length;
    if(columnWidths.length !== numCols) return <>widths, names, and entries must be same length</>;
    let head = <Header columnNames={columnNames} columnWidths={columnWidths} />;
    let rows = entries.map( (entryVals, index) => {
        return <ListEntry entryVals={entryVals} columnWidths={columnWidths}/>;
    })
    return <ListBuilder header={head} entries={rows} />;
}

export default TableListTwo;