/*
App.js: Main component rendered by index.js this drives the rendering of everything else.

App: Contains the image logo header, the navbar, and the main content component
Navigation: each li NavLink row is another button in the Nav bar across the top. not every page needs to be linked here, just the main ones.
Main: contains routes to each of the pages. when you add a new page, you need to add it here
*/

//import react, the navbar tools, and the style sheet
import React from 'react';
import AppContext from "./AppContext"
import './App.css';
import { Switch, Route /*, withRouter*/ } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";

//import all of the components form our other files
import Home from './pages/Home';
import CustomerView from './pages/CustomerView';
import ProductView from './pages/ProductView';
import Testing from './pages/Testing';
import CustomerList from './pages/CustomerList';
import ProductList from './pages/ProductList';
import OrderList from './pages/OrderList';
import OrderView from './pages/OrderView';
import ItemView from './pages/ItemView';
import ItemList from './pages/ItemList';
import ListingQueue from './pages/ListingQueue';
import SubmissionList from './pages/SubmissionList';
import SubmissionView from './pages/SubmissionView';
import SubmissionItemView from './pages/SubmissionItemView';
import AppraiseItem from './pages/AppraiseItem';

import PageNotFound from './pages/404/404-page'
import EditEntitlements from './pages/EditEntitlements';
import AcceptQuote from './pages/AcceptQuote';
import Sandbox from './pages/Sandbox';
import ItemImages from './pages/ItemImages';
import Reports from './pages/Reports';
import Documentation from './pages/Documentation';
import TestingReport from './pages/reports/TestingReport';
import TPAList from './pages/TPAList';
import TPAView from './pages/TPAView';
import CreateDraftOrder from './pages/CreatDraftOrder';
import CreateTPA from './pages/CreateTPA';
import FileUploader from './pages/FileUploader';
import Stocking from './pages/Stocking';
import OutgoingPayments from './pages/OutgoingPayments';
import FilterOptions from './pages/FilterOptions';
import RAList from './pages/RAList';
import RAView from './pages/RAView';
import ReceiveRAItem from './pages/ReceiveRAItem';
import PaymentView from './pages/PaymentView';
import RasOpenedReport from './pages/reports/RasOpenedReport';
import RasPaidReport from './pages/reports/RasPaidReport';
import InventoryList from './pages/InventoryList';
import TesterReturnPerformanceReport from './pages/reports/TesterReturnPerformanceReport';
import DefectiveByTesterReport from './pages/reports/DefectiveByTesterReport';
import NADByTesterReport from './pages/reports/NADByTesterReport';
import RasPendingIssuesReport from './pages/reports/RasPendingIssuesReport';
import SalesScorecard from './pages/reports/SalesScorecard';
import EbayDefects from './pages/reports/EbayDefects';
import SpiffReturns from './pages/reports/SpiffReturns';
import CoDeliveryTax from './pages/reports/CoDeliveryTax';
import BCMissingInv from './pages/reports/BCMissingInv';
import FinaleMissingInv from './pages/reports/FinaleMissingInv';
import NewProductVelocity from './pages/reports/NewProductVelocity';
import PendingSubmissions from './pages/reports/PendingSubmissions';
import PickList from './pages/reports/PickList';
import RecentSSOrders from './pages/reports/RecentSSOrders';
import SubmissionsOpened from './pages/reports/SubmissionsOpened';
import GenericReports from './pages/reports/GenericReport';
import DraftOrderList from './pages/DraftOrderList';
import DraftOrderView from './pages/DraftOrderView';
import SubmissionsProgress from './pages/reports/SubmissionsProgress';
import BrandRatings from './pages/BrandRatings';
import TpasReceivedReport from './pages/reports/TpasReceived';
import ProductManagementTools from './pages/ProductManagementTools';
import BuyerConversionReport from './pages/reports/BuyerConversionReport';
import RmasWrittenReceived from './pages/reports/RMAsWrittenReceived'
import ConsignmentItemsReceived from './pages/reports/ConsignmentItemsReceived';
import NewProductInv from './pages/reports/NewProductInv';
import RmaItemBrandRating from './pages/reports/RmaItemBrandRating';
import ItemsTested from './pages/reports/ItemsTested';
import ItemsPhotographed from './pages/reports/ItemsPhotographed';
import ItemsStocked from './pages/reports/ItemsStocked';
import RmaReceiving from './pages/reports/RmaReceiveing';
import UnappraisedSubmissions from './pages/reports/UnappraisedSubmissions';
import VarianceReport from './pages/reports/VarianceReport';
import ListItem from './pages/ListItem';
import BcFinaleInventoryRec from './pages/reports/bcFinaleInventoryRec';
import InventoryValue from './pages/reports/InventoryValue';
import ItemsListed from './pages/reports/ItemsListed';
import ValueByStage from './pages/reports/ValueByStage';
import ItemsWithoutListPrices from './pages/reports/ItemsWithoutListPrices';
import AppraiserConversionReport from './pages/reports/AppraiserConversionReport';
import Queues from './pages/reports/Queues';
import AutolisterCheck from './pages/reports/autolisterCheck';
import Login from './pages/Login';
import BrandList from './pages/BrandList';
import BrandView from './pages/BrandView';
import PayoutReport from './pages/reports/PayoutReport';
import ErrorBoundary from './boundaries/ErrorBoundary';
import AuthBoundary from './boundaries/AuthBoundary';
import FeatureFlagBoundary from './boundaries/FeatureFlagBoundary';


//component to render the other subcomponents
const App = (props) => {
    //hide main component within a bunch of boundaries so that the child components don't render unless everything has been fetched properly
    return (
    <div className="app relative">
        <ErrorBoundary>
            <AppContext.Provider value={{...props.app}}>
                <AuthBoundary>
                    <FeatureFlagBoundary>
                        <Main/>
                    </FeatureFlagBoundary>
                </AuthBoundary>
            </AppContext.Provider>
        </ErrorBoundary>
        
        <br/>
        <h5 className='text-white bg-darkest absolute inset-x-0 bottom-0'>Copyright 2022 FFTM, Inc.  All rights reserved.</h5>
    </div>
    );
}

//this component just switches its content based on the url path listed.
const Routes = [
    {path: '/', comp: Home},
    {path: '/customer-view/:customer_id', comp: CustomerView},
    {path: '/product-view/:product_id', comp: ProductView},
    {path: '/customer-list', comp: CustomerList},
    {path: '/product-list', comp: ProductList},
    {path: '/testing/:item_id', comp: Testing},
    {path: '/order-list', comp: OrderList},
    {path: '/order-view/:order_id', comp: OrderView},
    {path: '/item-list', comp: ItemList},
    {path: '/item-view/:item_id', comp: ItemView},
    {path: '/listing-queue', comp: ListingQueue},
    {path: '/list-item/:item_id', comp: ListItem},
    {path: '/submission-list', comp: SubmissionList},
    {path: '/submission-view/:submission_id', comp: SubmissionView},
    {path: '/appraise-item/:item_id', comp: AppraiseItem},
    {path: '/submission-item-view/:item_id', comp: SubmissionItemView},
    {path: '/edit-entitlements', comp: EditEntitlements},
    {path: '/accept-quote/:submission_id', comp: AcceptQuote},
    {path: '/sandbox', comp: Sandbox},
    {path: '/item-images/:item_id', comp: ItemImages},
    {path: '/docs', comp: Documentation},
    {path: '/tpa-list', comp: TPAList},
    {path: '/tpa-view/:tpa_id', comp: TPAView},
    {path: '/create-draft-order', comp: CreateDraftOrder},
    {path: '/draft-order-list', comp: DraftOrderList},
    {path: '/draft-order-view/:do_id', comp: DraftOrderView},
    {path: '/create-tpa', comp: CreateTPA},
    {path: '/file-uploader', comp: FileUploader},
    {path: '/stocking', comp: Stocking},
    {path: '/outgoing-payments', comp: OutgoingPayments},
    {path: '/filter-options', comp: FilterOptions},
    {path: '/ra-list', comp: RAList},
    {path: '/ra-view/:ra_id', comp: RAView},
    {path: '/receive-ra-item/:item_id', comp: ReceiveRAItem},
    {path: '/payment-view/:payment_id', comp: PaymentView},
    {path: '/inventory-list', comp: InventoryList},
    {path: '/brand-ratings', comp: BrandRatings},
    {path: '/product-management', comp: ProductManagementTools},
    {path: '/brand-list', comp: BrandList},
    {path: '/brand-view/:brand_id', comp: BrandView},


    {path: '/reports', comp: Reports},
    {path: '/reports/new-product-inventory', comp: NewProductInv},
    {path: '/reports/rma-item-brand-rating', comp: RmaItemBrandRating},
    {path: '/reports/buyer-conversion', comp: BuyerConversionReport},
    {path: '/reports/appraiser-conversion', comp: AppraiserConversionReport},
    {path: '/reports/consignment-items-received', comp: ConsignmentItemsReceived},
    {path: '/reports/rmas-written-received', comp: RmasWrittenReceived},
    {path: '/reports/testing', comp: TestingReport},
    {path: '/reports/ras-opened', comp: RasOpenedReport},
    {path: '/reports/ras-paid', comp: RasPaidReport},
    {path: '/reports/ras-pending-issues', comp: RasPendingIssuesReport},
    {path: '/reports/tester-return-performance', comp: TesterReturnPerformanceReport},
    {path: '/reports/defective-by-tester', comp: DefectiveByTesterReport},
    {path: '/reports/nad-by-tester', comp: NADByTesterReport},
    {path: '/reports/sales-scorecard', comp: SalesScorecard},
    {path: '/reports/ebay-defective-returns', comp: EbayDefects},
    {path: '/reports/spiff-returns', comp: SpiffReturns},
    {path: '/reports/colorado-delivery-tax', comp: CoDeliveryTax},
    {path: '/reports/bc-missing-inventory', comp: BCMissingInv},
    {path: '/reports/finale-missing-inventory', comp: FinaleMissingInv},
    {path: '/reports/new-product-velocity', comp: NewProductVelocity},
    {path: '/reports/pending-submissions', comp: PendingSubmissions},
    {path: '/reports/submissions-progress', comp: SubmissionsProgress},
    {path: '/reports/pick-list', comp: PickList},
    {path: '/reports/recent-ss-orders', comp: RecentSSOrders},
    {path: '/reports/submissions-opened', comp: SubmissionsOpened},
    {path: '/reports/tpas-received', comp: TpasReceivedReport},
    {path: '/reports/generic-reports', comp: GenericReports},
    {path: '/reports/items-tested', comp: ItemsTested},
    {path: '/reports/items-photographed', comp: ItemsPhotographed},
    {path: '/reports/items-stocked', comp: ItemsStocked},
    {path: '/reports/items-listed', comp: ItemsListed},
    {path: '/reports/value-by-stage', comp: ValueByStage},
    {path: '/reports/items-without-list-prices', comp: ItemsWithoutListPrices},
    {path: '/reports/rma-receiving', comp: RmaReceiving},
    {path: '/reports/unappraised-submissions', comp: UnappraisedSubmissions},
    {path: '/reports/variance-report', comp: VarianceReport},
    {path: '/reports/bc-finale-inventory-rec', comp: BcFinaleInventoryRec},
    {path: '/reports/inventory-value', comp: InventoryValue},
    {path: '/reports/queues', comp: Queues},
    {path: '/reports/autolister-check', comp: AutolisterCheck},
    {path: '/reports/payout-report', comp: PayoutReport},
];

const ProtectedRoute = ({component, ...args}) => {
    return (
        <Route
            component={withAuthenticationRequired(component, {
                onRedirecting: () => <Loading />,
            })}
            {...args}
        />
    )
}

const Main = () => {
    return (
        <>
            <Switch>
                {Routes.map((k, i) => {
                    return(
                        <ProtectedRoute exact path={k.path} component={k.comp} key={i} />
                    );
                })}
                <Route exact path={'/login'} component={Login} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}


export default App;
