const BrandPartnerLogo =  ({height}) => {
return <svg
xmlns="http://www.w3.org/2000/svg"
xmlnsXlink="http://www.w3.org/1999/xlink"
version="1.1"
viewBox="50.9 73.19 72.37 75.12"
height = {height}
width = {height}
>
<defs>
  <linearGradient id="linearGradient14">
    <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
    <stop offset="1" stopColor="#d6c332" stopOpacity="0"></stop>
  </linearGradient>
  <path
    id="rect13"
    d="M105.395 639.989H544.7520000000001V1079.346H105.395z"
  ></path>
  <radialGradient
    id="radialGradient16"
    cx="111.978"
    cy="646.832"
    r="19.604"
    fx="111.978"
    fy="646.832"
    gradientTransform="matrix(1 0 0 .88747 0 72.787)"
    gradientUnits="userSpaceOnUse"
    xlinkHref="#linearGradient14"
  ></radialGradient>
</defs>
<g
  strokeDasharray="none"
  strokeOpacity="1"
  transform="translate(5.376 -.672)"
>
  <circle
    cx="80"
    cy="112.047"
    r="32"
    fill="#162133"
    fillOpacity="0"
    stroke="#8B7A00"
    strokeWidth="4"
  ></circle>
  <text
    fill="#7A6B00"
    fillOpacity="1"
    stroke="url(#radialGradient16)"
    strokeWidth="2"
    display="inline"
    fontFamily=".New York"
    fontSize="20"
    fontStretch="normal"
    fontStyle="normal"
    fontVariant="normal"
    fontWeight="normal"
    transform="matrix(2.205 0 0 2.247 -175.092 -1348.762)"
    xmlSpace="preserve"
    style={{
      InkscapeFontSpecification: "'.New York'",
      whiteSpace: "pre",
    }}
  >
    <tspan x="105.395" y="657.499">
      <tspan style={{}} fontFamily="Arial Narrow">
        BP
      </tspan>
    </tspan>
  </text>
</g>
</svg>
}

export default BrandPartnerLogo;