import { useContext, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const EditSubmissionItemForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [item, setItem] = useState(formData);
    
    let [disableForm, setDisableForm] = useState(false);

    function submit(event) {
        event.preventDefault();
        setDisableForm(true);
        secureFetch(context?.server + '/update-submission-item-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: item?.id,
                keys: ['manufacturer', 'model_number', 'actual_age_of_item', 'number_of_owners', 'cosmetic_condition', 'tobacco_exposure', 'what_is_included', 'notes', 'has_packaging'],
                values: [item?.manufacturer, item?.model_number, item?.actual_age_of_item, item?.number_of_owners, item?.cosmetic_condition, item?.tobacco_exposure, item?.what_is_included, item?.notes, item?.has_packaging],
                message: 'User edited submission item info manually from form'
            })
        })
        .then(() => {
            submitForm();
            closeModal();

        })
    }

    return (
        <div>
            <form id='EditSubmissionItemForm' onSubmit={submit} />
            <fieldset form='EditSubmissionItemForm' disabled={disableForm ? 'disabled' : ''}>

                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h1 className="dark-text">Edit Submission Item</h1>
                    <br/>
                    <div className="container">
                        <label className="dark-text text-left contained">Manufacturer:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionItemForm' type='text' value={item?.manufacturer} onChange={e => setItem({...item, manufacturer: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Model Number:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionItemForm' type='text' value={item?.model_number} onChange={e => setItem({...item, model_number: e.target.value})} />
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Age (yrs):&nbsp;&nbsp;&nbsp;
                            <select form="EditSubmissionItemForm" value={item?.actual_age_of_item} onChange={e => setItem({...item, actual_age_of_item: e.target.value==-11 ? null : e.target.value})}>
                                <option value={-11}>-</option>
                                <option value={-1}>Unknown</option>
                                <option value={0}>Less than 1 year</option>
                                <option value={1}>1 year</option>
                                <option value={2}>2 years</option>
                                <option value={3}>3 years</option>
                                <option value={4}>4 years</option>
                                <option value={5}>5 years</option>
                                <option value={6}>6 years</option>
                                <option value={7}>7 years</option>
                                <option value={8}>8 years</option>
                                <option value={9}>9 years</option>
                                <option value={10}>10 years</option>
                                <option value={11}>11 years</option>
                                <option value={12}>12 years</option>
                                <option value={13}>13 years</option>
                                <option value={14}>14 years</option>
                                <option value={15}>15 years or older</option>
                            </select>
                        </label><br/>
                        <label className="dark-text text-left contained"># of Owners:&nbsp;&nbsp;&nbsp;
                            <select form="EditSubmissionItemForm" value={item?.number_of_owners} onChange={e => setItem({...item, number_of_owners: e.target.value==-11 ? null : e.target.value})}>
                                <option value={-11}>-</option>
                                <option value={-1}>Unknown</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4 or more</option>
                            </select>
                        </label><br/>
                    </div>
                    <div className="container">
                        <label className="dark-text text-left contained">Cosmetic (out of 10):&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionItemForm' type='number' value={item?.cosmetic_condition} onChange={e => setItem({...item, cosmetic_condition: e.target.value})} />
                        </label><br/>
                        <label className="dark-text text-left contained">Tobacco Exposure:&nbsp;&nbsp;&nbsp;
                            <input form='EditSubmissionItemForm' type='checkbox' checked={item?.tobacco_exposure==1} onChange={e => setItem({...item, tobacco_exposure: e.target.checked ? 1 : 0})} />
                        </label><br/>
                    </div>
                    <br/><br/>
                    <label className="dark-text text-left contained">Has Packaging:&nbsp;&nbsp;&nbsp;
                        <input form='EditSubmissionItemForm' type='checkbox' checked={item?.has_packaging==1} onChange={e => setItem({...item, has_packaging: e.target.checked ? 1 : 0})} />
                    </label><br/>
                    <br/>
                    <label className="dark-text text-left">What's Included:</label>
                    <textarea form='EditSubmissionItemForm' rows='8' cols='45' value={item?.what_is_included} onChange={e => setItem({...item, what_is_included: e.target.value})} />
                    <br/>
                    <label className="dark-text text-left">Notes:</label>
                    <textarea form='EditSubmissionItemForm' rows='8' cols='45' value={item?.notes} onChange={e => setItem({...item, notes: e.target.value})} />
                    <br/>
                    <br/>
                    <input form='EditSubmissionItemForm' className="btn bg-dark light-text" type='submit' />
                </div>
            </fieldset>
        </div>
    )
}

export default EditSubmissionItemForm;