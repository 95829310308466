import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";


const CreateClassificationForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [optionSets, setOptionSets] = useState([]);
    let [selectedOptionSets, setSelectedOptionSets] = useState([]);
    let [classification, setClassification] = useState({});

    let [formDisable, setFormDisable] = useState(false);

    function fetchOptionSets() {
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({tableName: 'option_sets'})
        })
        .then(resp => resp.json())
        .then(results => setOptionSets(results));
    }

    useEffect(() => {
        fetchOptionSets();
    }, [])

    function submit(event) {
        event.preventDefault();
        setFormDisable();
        secureFetch(context?.server + '/create-classification', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                classification: classification,
                selectedOptionSets: selectedOptionSets
            })
        })
        .then(() => {
            submitForm();
            closeModal();
        })
    }

    return (
        <div className='bg-light' style={{padding: '15px 15px'}}>
            <form id='CreateClassificationForm' onSubmit={submit} />
            <fieldset form='CreateClassificationForm' disabled={formDisable ? 'disabled' : ''} >
                <h1 className='dark-text'>Create Classification</h1>
                <label className='dark-text text-left'>Name:</label>
                <input type='text' form='CreateClassificationForm' required value={classification?.name} onChange={e => setClassification({...classification, name: e.target.value})}
                style={{width: '300px'}} />

                <label className='dark-text text-left'>Option Sets:</label>
                {optionSets.map(opt => 
                    <div>
                        <label className='dark-text text-left'><input type='checkbox' form='CreateClassificationForm' checked={selectedOptionSets.map(o=>o?.id).indexOf(opt?.id) !== -1} onChange={e => {
                            let newopts = selectedOptionSets.map(o=>o);
                            if(e.target.checked) {
                                newopts.push(opt)
                            } else {
                                newopts.splice(newopts.map(o=>o?.id).indexOf(opt?.id), 1);
                            }
                            setSelectedOptionSets(newopts);
                        }} />&nbsp;&nbsp;{opt?.name}</label>
                    </div>
                )}

                <br/><br/>
                <input type='submit' form='CreateClassificationForm' className='btn bg-dark light-text' />
            </fieldset>
        </div>
    )
}

export default CreateClassificationForm;