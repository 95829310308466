import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { secureFetch } from '../util/flip_utils';
//import SearchSelect from './SearchSelect';
import TableList from './TableList';
import { Link } from 'react-router-dom';
import { RatingColorCoder } from './ColorCoders';

const SelectProductForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);
    let [products, setProducts] = useState([]);
    let [searchValue, setSearchValue] = useState(formData?.title || '');

    let [mergeDestinationId, setMergeDestinationId] = useState("");

    let [fetched, setFetched] = useState(false);

    const submit = (prod) => {
        submitForm(prod);
        closeModal();
    }

    function fetchProducts() {
        secureFetch(context.server + '/search-products', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({query: searchValue})
        })
        .then(resp => resp.json())
        .then(products => {
            setProducts(products.map(p => {return {...p, mergeIsOpen: false}}))
            setFetched(true)
        })
    }

    useEffect(() => {
        fetchProducts();
    }, [context, searchValue])

    function setMergeIsOpen(ind) {
        let newProducts = products.map(p => p);
        newProducts[ind].mergeIsOpen = !newProducts[ind].mergeIsOpen;
        setProducts(newProducts);
    }

    function mergeIsOpen(ind) {
        return products[ind].mergeIsOpen;
    }

    function mergeProducts(sourceId, destinationId) {
        //alert(`${sourceId} ${destinationId}`);
        secureFetch(context.server + '/merge-products', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                sourceId: sourceId,
                destinationId: destinationId
            })
        })
        .then(() => {
            alert("merge successful");
            fetchProducts();
        })
    }

    if(!fetched) {
        return (
            <h1 className='dark-text'>Loading Products...</h1>
        );
    }

    return (
        <div>
            <h1 className='dark-text'>Select Product: {formData?.title ? formData?.title : ''}</h1>
            <input autoFocus type='text' className="search-bar" onChange={ev => setSearchValue(ev.target.value)} value={searchValue}/>
            <br/><br/>
            <button className='btn dark-text' onClick={() => submit({id: 61065})}>Misc. Pass</button>
            <br/><br/>
            <TableList columnNames={[' ','ID','Title','Brand', 'Model',' ',' ']}
            columnWidths={['10%','8%','30%','12%','20%','10%','10%']}
            entries={
                products.map( (row, ind) => {
                    return [
                        <div>
                            <button className='btn dark-text' onClick={() => setMergeIsOpen(ind)}>Merge</button>
                            {mergeIsOpen(ind) ? 
                            <div>
                                <label className='dark-text text-left'>Destination ID</label>
                                <input value={mergeDestinationId} onChange={e => setMergeDestinationId(e.target.value)} />
                                <button className='btn bg-red light-text' onClick={() => mergeProducts(row?.id, mergeDestinationId)}>Do Merge</button>
                            </div>
                            : <></>}
                        </div>,
                        row?.id,
                        row?.title,
                        row?.brand,
                        row?.model,
                        <Link onClick={closeModal} className='btn dark-text' to={{pathname: `/product-view/${row?.id}`, state: {product_id: row?.id}}}>View</Link>,
                        <button className='btn dark-text' onClick={() => submit(row)}>Select</button>,
                    ]
                })
            }
            />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
        </div>
    );
}

export default SelectProductForm;