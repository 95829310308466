/**
 * SubmissionList.js
 * 
 * lists submissions, allows for searching, and viewing individual submissions. smart search allows for results to display as search terms are typed
 */


import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import AppContext from '../AppContext';
import { Link, useHistory } from 'react-router-dom';
import {secureFetch, formatDate} from '../util/flip_utils';
import PaginatedTableList from '../components/PaginatedTableList';
import ModalContainer from '../components/ModalContainer';
import SelectUserForm from '../components/SelectUserForm';
import SendQuoteForm from '../components/SendQuoteForm';
import CreateSubmissionForm from '../components/CreateSubmissionForm';
import GridView from '../components/GridView';
import TabbedDisplay from '../components/TabbedDisplay';
import InfoBox from '../components/InfoBox';

const REDUCED_FILTERS = ['Any (open)', 'Any', 'Unappraised', 'Appraised', 'Pending', 'Complete'];

const SubmissionList = (props) => {
    //constructor declares state variables
    let context = useContext(AppContext);
    let history = useHistory();
    let [submissions, setSubmissions] = useState([]);
    let [filterUser, setFilterUser] = useState({});
    let [queryTerms, setQueryTerms] = useState({
        user: props?.location?.state?.user || context?.user?.id,
        status: props.location.state?.status || -1,
        order: props.location.state?.order || 1,
    })
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue ? props.location.state?.searchValue : '')
    let [statuses, setStatuses] = useState([]);

    let [showAllFilters, setShowAllFilters] = useState(false);
    let [fetching, setFetching] = useState(true);

    // i turned off the auto-refresh because it closes the Send Quote form which seems
    // annoying and i didnt really ever like that feature anyways
    // let [intervalNumber, setIntervalNumber] = useState(null);

    // useEffect(() => {
    //     let ivl = setInterval(() => window.location.reload(), 60000)

    //     setIntervalNumber(ivl)

    //     return () => {
    //         clearInterval(ivl);
    //         setIntervalNumber(null);
    //     }
    // }, [])


    function fetchStatuses() {
        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                tableName: 'submission_statuses'
            })
        })
        .then(resp => resp.json())
        .then(results => {
            results?.sort((a,b) => a?.id-b?.id);
            
            //results?.splice(0,0,{id: -5, submission_status: 'Any (pre-offer)'});
            //results?.splice(4,0,{id: -6, submission_status: 'Any (post-offer)'});
            //results?.splice(8,0,{id: -7, submission_status: 'Any (other)'});
            results?.push({id: -1, submission_status: 'Any (open)'});
            results?.push({id: -2, submission_status: 'Any'});
            
            setStatuses(results)
        })
        // .catch(()=>{})
    }


    function fetchSubmissions(pageNumber, pageSize) {
        setFetching(true);
        secureFetch(context.server + '/search-submissions', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`,'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                ...queryTerms,
                user: queryTerms?.user=="null" ? null : queryTerms?.user, //had to stringify null so that it wouldn't be defaulted incorrectly when "not present"
                searchValue: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            })
        })
        .then(response => response.json())
        .then(data => {
            setFetching(false);
            setSubmissions(data)
        })
        // .catch(() =>{})
    }

    function searchHandler(event) {
        if(event.key == 'Enter') { //enter key
            fetchSubmissions(0,20);
        }
    }

    useEffect(() => {
        fetchStatuses();
    }, [])
    
    useEffect(() => {
        fetchSubmissions(0, 20);
    }, [context, queryTerms])

    function updateQueryTerms(qterm) {
        setQueryTerms(qterm);
        history.replace('/submission-list', {...qterm, searchValue: searchValue});
    }

    function assignUser(sub_id,user_id) {
        let submission = submissions?.filter(row => row?.id==sub_id)[0]
        if(submission.assignee_id !== user_id) {
            secureFetch(context.server + '/assign-submission', {
                credentials: 'include',
                headers: {'Authorization': `Bearer ${context?.accessToken}`,'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    submission_id: sub_id,
                    assignee_id: user_id,
                }),
            })
            .then(response => response.json())
            .then(result => fetchSubmissions(0,20))
        } else {
            alert('This submission is already assigned to that user!');
        }
    }

    return (
        <div   style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained text-3xl font-bold'>Submissions</h1>
                <ModalContainer buttonClass='btn dark-text contained' triggerText='Create Blank Submission' formData={{}} FormComponent={CreateSubmissionForm}
                submitForm={sub => {
                    history.push({pathname: `/submission-view/${sub?.id}`})
                    window.location.reload();
                }} />
            </div>
            <div>
                <div>

                        <label className='light-text text-left'>Assignee</label>
                        <div className='container bg-light rounded ml-0' style={{maxWidth: '50%'}}>
                            <label className='dark-text text-left contained'><input className='contained' type='radio' onChange={() => {updateQueryTerms({...queryTerms, user: context.user?.id}); setFilterUser({});}} checked={queryTerms?.user == context.user?.id}/>&nbsp;&nbsp;Mine</label>
                            <label className='dark-text text-left contained'><input className='contained' type='radio' onChange={() => {updateQueryTerms({...queryTerms, user: "null"}); setFilterUser({});}} checked={!queryTerms?.user}/>&nbsp;&nbsp;Unassigned</label>
                            <label className='dark-text text-left contained'><input className='contained' type='radio' onChange={() => {updateQueryTerms({...queryTerms, user: -1}); setFilterUser({});}} checked={queryTerms?.user == -1} />&nbsp;&nbsp;Any</label>
                            <><ModalContainer buttonClass='btn bg-dark light-text' triggerText='Filter by User' formData={{searchValue: ''}} FormComponent={SelectUserForm} submitForm={user => {
                                setFilterUser(user);
                                updateQueryTerms({...queryTerms, user: user?.id})
                            }} />{filterUser?.id ? <i className='dark-text'>&nbsp;&nbsp;Current: {filterUser?.first_name} {filterUser?.last_name}</i> : <></>}</>
                        </div>

                    <br/>
                    <label className='light-text text-left'>Submission Status</label>
                    {showAllFilters ? 
                            <div className=' bg-light rounded' style={{maxWidth: '90%'}}>
                                <GridView columns={4}>
                                {statuses?.map(stat =>
                                    <label className="dark-text text-left">
                                        <input type='radio' value={stat?.id} checked={queryTerms?.status === stat?.id} onChange={e => updateQueryTerms({...queryTerms, status: stat?.id})} />
                                            &nbsp;{stat?.submission_status}
                                    </label>)}
                                </GridView>
                            </div>
                            : 
                            <div className=' bg-light rounded' style={{maxWidth: '90%'}}>
                                <GridView columns={4}>
                                {statuses?.filter(stat => REDUCED_FILTERS?.indexOf(stat?.submission_status) >= 0)
                                ?.map(stat =>
                                    <label className="dark-text text-left">
                                        <input type='radio' value={stat?.id} checked={queryTerms?.status === stat?.id} onChange={e => updateQueryTerms({...queryTerms, status: stat?.id})} />
                                            &nbsp;{stat?.submission_status}
                                    </label>)}
                                </GridView>
                            </div>}
                    <br/><button className='btn dark-text'
                    onClick={() => setShowAllFilters(!showAllFilters)}
                    >{showAllFilters ? 'Hide' : 'Show'} All</button>
                    
                    <br/><br/>
                    
                    <div className='container'>
                        <div className='contained'>
                            <label className='light-text text-left'>Sort By</label>
                            <div className='container bg-light rounded ml-0' style={{maxWidth: '70%'}}>
                                <label className='dark-text text-left contained'><input className='contained' type='radio' onChange={() => updateQueryTerms({...queryTerms, order: -1})} checked={queryTerms?.order === -1}/>&nbsp;&nbsp;Most Recent</label>
                                <label className='dark-text text-left contained'><input className='contained' type='radio' onChange={() => updateQueryTerms({...queryTerms, order: 1})} checked={queryTerms?.order === 1}/>&nbsp;&nbsp;Least Recent</label>
                            </div><br/>

                            <label className='light-text text-left'>Search</label>
                            <div className='container bg-light rounded' style={{maxWidth: '100%'}}>
                                <label className="contained dark-text text-left"><input className='search-bar' type='text' value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyPress={searchHandler}/></label>
                                <button className='btn bg-green light-text contained' style={{maxWidth: '50px'}} onClick={() => searchHandler({key: 'Enter'})}>{fetching ? '...' : 'OK'}</button>
                            </div>
                        </div>
                        <div className='contained'>
                            <InfoBox title="Tag Legend:">
                                <div className='container'>
                                    <div className='contained'><button className='btn bg-purple' style={{padding: '7px 7px'}}/>B2B</div>
                                    <div className='contained'><button className='btn bg-orange' style={{padding: '7px 7px'}} />Waiting</div>
                                    <div className='contained'><button className='btn bg-light-green' style={{padding: '7px 7px'}}/>New Trade</div>
                                </div>
                                <div className='container'>
                                    <div className='contained'><button className='btn bg-green' style={{padding: '7px 7px'}}/>Used Trade</div>
                                    <div className='contained'><button className='btn bg-dark-green' style={{padding: '7px 7px'}}/>Upgrade</div>
                                    <div className='contained'><button className='btn bg-red' style={{padding: '7px 7px'}}/>High Priority</div>
                                </div>
                            </InfoBox>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            

            <PaginatedTableList
                columnNames={['ID','Date','Customer','Assignee','Status','# Items','Rec. List','Tags',' ']}
                columnWidths={['5%','10%','15%','10%','8%','6%','6%','14%','26%']}
                entries={
                    submissions.map( sub => {
                        return [
                            sub?.id,
                            formatDate(sub?.created),//, {date:true}),
                            //sub?.first_name,
                            sub?.customer_id ? 
                                <Link className='btn dark-text' to={{pathname: `/customer-view/${sub?.customer_id}`, state: {customer_id: sub?.customer_id}}}>{sub?.first_name + ' ' + sub?.last_name}</Link>
                                : sub?.first_name + ' ' + sub?.last_name, 
                            <>{sub?.assignee ? sub?.assignee : 'unassigned'}&nbsp;
                            {context.user?.id===sub?.assignee_id ? <></> :
                            <button 
                                className='btn dark-text'
                                onClick={() => {
                                    assignUser(sub?.id,context.user?.id);
                                }}>
                                    Claim
                            </button>}</>,
                            sub?.submission_status,
                            sub?.item_count,
                            '$'+sub?.rec_list_price,
                            <div>
                                {sub?.priority == 5 ? <><button className='btn bg-red' style={{padding: '7px 7px'}}/>&nbsp;</> : <></>}
                                {sub?.btob ? <><button className='btn bg-purple' style={{padding: '7px 7px'}}/>&nbsp;</> : <></>}
                                {sub?.catbrand == 2 ?
                                        <><button className='btn bg-light-green' style={{padding: '7px 7px'}}/>&nbsp;</>
                                        : sub?.catbrand == 1 ? <><button className='btn bg-green' style={{padding: '7px 7px'}}/>&nbsp;</>
                                    : <></>}
                                {sub?.reason_for_selling == "I'm Looking to Upgrade" ? <><button className='btn bg-dark-green' style={{padding: '7px 7px'}}/>&nbsp;</> : <></>}
                                {sub?.waiting ? <><button className='btn bg-orange' style={{padding: '7px 7px'}} />
                                &nbsp;<i>{formatDate(sub?.wait_date, {date:true})}</i><br/></> 
                                : <></>}
                            </div>,
                            <div className='container contained'>
                                <Link className='btn contained dark-text' to={{pathname: `/submission-view/${sub?.id}`, state: {submission_id: sub.id}}}>View</Link>
                                {sub?.submission_status_id===4 ? <Link className='btn contained dark-text' to={{pathname: `/accept-quote/${sub?.id}`, state: {submission_id: sub?.id}}}>Accept Quote</Link>
                                : sub?.submission_status_id===3 || sub?.submission_status_id===2 ? <ModalContainer triggerText='Send Quote' FormComponent={SendQuoteForm} formData={sub} submitForm={() => {
                                    alert('Submission status updated!')
                                    window.location.reload();
                                }} />
                                : <div className='contained'></div>}
                            </div>
                        ]
                    })
                }
                getData={fetchSubmissions}
            />
        </div>
    );
}

export default SubmissionList;