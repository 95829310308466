import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { secureFetch } from "../util/flip_utils";
import TableList from "./TableList";

const TRADE_CREDIT_ID = 28;

const ChangeRmaSaleTypeForm = ({formData, submitForm, closeModal}) => {
    let context = useContext(AppContext);

    let [saleTypes, setSaleTypes] = useState([]);
    let [paymentMethods, setPaymentMethods] = useState([])
    let [rma, setRma] = useState({...formData, items: formData?.items?.map(i => {return {...i, new_purchase_amount: i?.purchase_amount, split_selected: 1}})});
    let [newSaleType, setNewSaleType] = useState(formData?.items[0]?.sale_type_id);
    let [newConsignmentRate, setNewConsignmentRate] = useState(null);

    let [formState, setFormState] = useState(1);
    let [formDisable, setFormDisable] = useState(false);

    function fetchFields() {
        //get sale types for dropdown
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'sale_types'
            })
        })
        .then(res => res.json())
        .then(results => setSaleTypes(results))

        //get rma-type payment methods
        secureFetch(context.server + '/get-all', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                tableName: 'payment_methods',
                params: {
                    equal: [{
                        key: 'rma',
                        value: '1'
                    }]
                }
            })
        })
        .then(res => res.json())
        .then(results => setPaymentMethods(results))
    }

    //fetch dropdown fields on load
    useEffect(() => {
        fetchFields();
    }, [])

    //blank consignment rate/new bp when sale type is no longer applicable
    useEffect(() => {
        if(newSaleType==4) {
            //blank bp
            let newItems = rma?.items?.map(i => {return {...i, new_purchase_amount: null}});
            setRma({...rma, items: newItems});
            setNewConsignmentRate(35);
        } else {
            //blank consign and reset bp
            let newItems = rma?.items?.map(i => {return {...i, new_purchase_amount: i?.purchase_amount}});
            setRma({...rma, items: newItems});
            setNewConsignmentRate(null);
        }
    }, [newSaleType])

    //set is_trade depending on payment_method
    useEffect(() => {
        if(rma?.payment_method_id == TRADE_CREDIT_ID) {
            setRma({...rma, is_trade: 1})
        } else {
            setRma({...rma, is_trade: 0, difference_owed: null})
        }
    }, [rma?.payment_method_id])

    function submit() {
        //bounce if they didn't provide bps for everything
        if(newSaleType!=4 && !(rma?.items?.reduce((acc, curr) => acc && (curr?.new_purchase_amount >= 0 || !curr?.split_selected), true))) {
            alert('Buy Price is required for non-consignment sale types')
            return;
        //bounce if they didn't provide consignment rate
        } else if (newSaleType==4 && !newConsignmentRate) {
            alert('Consignment Rate is required for consignment sale types')
            return;
        }

        setFormDisable(true);
        secureFetch(context?.server + '/change-rma-sale-type', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...rma,
                new_sale_type_id: newSaleType,
                new_consignment_rate: newConsignmentRate
            })
        })
        .then(resp=>resp.json())
        .then(res => {
            submitForm(res?.tpa_id || rma?.id);
            closeModal();
        })
        
    }

    return (
        <div>
            <form id='ChangeRmaSaleTypeForm' onSubmit={submit} />
            <fieldset form='ChangeRmaSaleTypeForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                    <h2>Multi Change Sale Type</h2>
                    <br/>
                    {formState==1 ? <>
                        <h3>Select Which Items to Modify</h3>
                        <TableList columnNames={['SKU','Title','Selected?']}
                        columnWidths={['20%','60%','20%']}
                        entries={rma?.items?.map((row,ind) => {
                            return [
                                row?.sku,
                                row?.title,
                                <input type='checkbox' className = 'large-checkbox' checked={row?.split_selected == 1} onChange={e => {
                                    let newItems = rma?.items?.map(r => r); //copy items
                                    newItems[ind].split_selected = e.target.checked ? 1 : 0
                                    setRma({...rma, items: newItems})
                                }} />
                            ]
                        })} />
                        <p><i>The items selected above will have their sale type changed. Click "Confirm Selection" to continue to the details.</i></p>
                        <br/>
                        <button className="btn dark-text" onClick={() => setFormState(2)}>Confirm Selection</button>
                    </> : <>
                        <label className="dark-text text-left">Select New Sale Type:&nbsp;&nbsp;
                        <select form='ChangeRmaSaleTypeForm' value={newSaleType} onChange={e => setNewSaleType(e.target.value)}>
                            {saleTypes?.filter(st => st?.id==3 || st?.id==rma?.items[0]?.sale_type_id)?.map((st, ind) =>
                                <option id={ind} value={st?.id}>{st?.sale_type}</option>
                            )}
                        </select>
                        </label>
                        <i className="dark-text text-left">(current is {saleTypes[saleTypes?.map(s=>s?.id)?.indexOf(rma?.items[0]?.sale_type_id)]?.sale_type})</i>
                        <br/>
                        <br/>
                        {newSaleType != 4 ? <>
                            <TableList columnNames={['SKU', 'Title', 'New BP']}
                            columnWidths={['20%', '50%', '30%']}
                            entries={rma?.items?.map((row, ind) => {
                                return [
                                    row?.sku,
                                    row?.title,
                                    <input form="ChangeRmaSaleTypeForm" step="0.01" type='number' disabled={row?.split_selected ? '' : 'disabled'} value={row?.new_purchase_amount}onChange={e => {
                                        let newItems = rma?.items?.map(r => r); //copy items
                                        newItems[ind].new_purchase_amount = e.target.value
                                        setRma({...rma, items: newItems})
                                    }} />
                                ]
                            })} />
                        </> : <></>}
                        {newSaleType == 4 ? <>
                            <label className="dark-text text-left">Input New Consignment Rate:&nbsp;&nbsp;
                            <input form="ChangeRmaSaleTypeForm" type='number' step='1' value={newConsignmentRate} onChange={e => setNewConsignmentRate(e.target.value)} />
                            </label>
                        </> : <></>}
                        <br/>
                        <label className="dark-text text-left">New Payment Method:&nbsp;&nbsp;
                            <select form='ChangeRmaSaleTypeForm' onChange={ e => setRma({...rma, payment_method_id: e.target.value}) } value={rma?.payment_method_id}>
                                {paymentMethods.map( (row) => 
                                    <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                                )}
                            </select>
                        </label>
                        {rma?.is_trade ? <>
                            <label className="dark-text text-left">Difference Owed:&nbsp;&nbsp;
                            <input type="number" form="ChangeRmaSaleTypeForm" value={rma?.difference_owed} onChange={e => setRma({...rma, difference_owed: e.target.value})} />
                            </label>
                        </> : <></>}
                        <br/>
                        <label className="dark-text text-left">New Payment Terms:&nbsp;&nbsp;<br/>
                        <textarea style={{minWidth: '100%', width: '100%', maxWidth: '100%'}} rows="6" value={rma?.payment_terms} onChange={e => setRma({...rma, payment_terms: e.target.value})} />
                        </label>
                        <input className="btn bg-dark light-text" form='ChangeRmaSaleTypeForm' type='submit'/>
                        <br/>
                        <i className="red-text">WARNING: this operation will make many changes to RMA data, accounting, etc.
                        If you are not familiar with what this feature does, please request help before submitting.</i>
                    </>}
                </div>
            </fieldset>
        </div>
    )
}

export default ChangeRmaSaleTypeForm;