import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext';
import {secureFetch} from '../util/flip_utils';
import ModalContainer from '../components/ModalContainer';
import CreateBrandForm from '../components/CreateBrandForm';
import PaginatedTableList from '../components/PaginatedTableList';
import { useHistory } from 'react-router-dom';
import { RatingColorCoder } from '../components/ColorCoders';
import BrandPartnerLogo from '../components/BrandPartnerLogo';


const BrandList = (props) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [brands, setBrands] = useState([])
    let [searchValue, setSearchValue] = useState(props.location.state?.searchValue || '')

    //called when the component is mounted on the DOM
    function fetchBrands(pageNumber, pageSize) {
        secureFetch(context.server + '/search-brands', {
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'}, 
            method: 'POST', 
            body: JSON.stringify({
                query: searchValue,
                pageNumber: pageNumber,
                pageSize: pageSize
            }) 
        })
        .then(resp => resp.json())
        .then(results => setBrands(results))
    }

    useEffect(() => {
        fetchBrands(0, 20);
    }, [context, searchValue])

    function updateSearchValue(val) {
        setSearchValue(val);
        history.replace('/brand-list', {searchValue: val})
    }

    return (
        <div style={{padding: '15px 15px'}}>
            <div className='container'>
                <h1 className='contained text-left'>Search Brands</h1>
                <ModalContainer triggerText='Create New Brand' submitForm={() => alert('Brand created!')} formData={{}} FormComponent={CreateBrandForm} />
            </div>
            <form>
                <div className='container'>
                        <input autoFocus type='text' className="search-bar" onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}
                        onChange={e => updateSearchValue(e.target.value)} value={searchValue}/>
                </div>
            </form>
            <br/>
            <br/>
            <PaginatedTableList
            columnNames={["Name","Brand Partner","Rating"," "]}
            columnWidths={["50%","15%","15%","20%"]}
            entries={
                brands.map( (row, index) => {
                    return [
                        row?.brand,
                        row?.brand_partner ? 
                            <div className='vertically-aligned'>
                                <BrandPartnerLogo height = {'25px'}/>
                            </div>
                            : 
                            <></>,
                        <RatingColorCoder content={row?.generic_rating} rating={row?.generic_rating} />,
                        <Link className='btn contained dark-text' to={{pathname: `/brand-view/${row?.id}`, state: {brand_id: row?.id}}}>View</Link>
                    ]
                })
            }
            getData={fetchBrands}
            />
            
        </div>
    );
}

export default BrandList;
