import { formatDate } from "../util/flip_utils";
import FlipRTV from "./FlipRTV";
import InfoBox from "./InfoBox";
import KeyValuePair from "./KeyValuePair";


const AppraisalPopup = ({formData, submitForm, closeModal}) => {
    return (
        <div>
            <h1 className="dark-text">Appraisal Details</h1>
            <br/><br/>
            <InfoBox title='Details'>
                <KeyValuePair label='Appraiser:' value={formData?.appraiser} textClass='dark-text' />
                <KeyValuePair label='Date:' value={formatDate(formData?.created)} textClass='dark-text' />
                <KeyValuePair label='Market Value:' value={formData?.market_value} textClass='dark-text' />
                <KeyValuePair label='Recommended List Price:' value={formData?.rec_list_price} textClass='dark-text' />
                <KeyValuePair label='Recommended Buy Price:' value={formData?.rec_buy_price} textClass='dark-text' />
                <KeyValuePair label='Appraising Notes:' value={<FlipRTV text={formData?.notes} />} textClass='dark-text' />
            </InfoBox>
        </div>
    )
}

export default AppraisalPopup;