import { useRef, useState } from "react";
import ImageModalContainer from "../components/ImageModalContainer";


const FileUploader = (prop) => {
    let [selectedFile, setSelectedFile] = useState({});
    let [image, setImage] = useState(null);
    let fileInput;

    function submitHandler(e){
        e.preventDefault();
        alert(selectedFile.name)
    }

    function onImageChange(event) {
        if(event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]))
            setSelectedFile(event.target.files[0])
        } else {
            setImage(null)
            setSelectedFile(null)
        }
    }
    
    return (
        <div style={{padding: '15px 15px'}}>
            <h1>File Uploader Tool</h1>
            <h2 className='light-text'>This feature is NOT implemented yet, sorry!</h2>
            <br/><br/>
            <label className='light-text text-left'> Click to choose a file:</label>
            <input
            type='file'
            onChange={onImageChange}
            accept='image/*'
            ref={refParam => fileInput = refParam}
            hidden
            />
            <button className='btn dark-text' onClick={() => fileInput.click()}>Select File</button>
            <br/>
            {
                selectedFile && image ? 
                <>
                    <p className='light-text'>{selectedFile.name}</p>
                    <ImageModalContainer source={image} alter={selectedFile.name} styleprops={{maxWidth: '100px'}} />
                </>
                : <></>
            }
            <button className='btn dark-text' onClick={submitHandler}>Upload</button>
            <br/><br/>
        </div>
    );
}

export default FileUploader;