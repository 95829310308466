import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        //console.log(errorInfo)
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
              <div>
                  <h2 className='light-text'>Something went wrong.</h2>
                  <details style={{ whiteSpace: 'pre-wrap' }} className='light-text'>
                      {this.state.error && this.state.error.toString()}
                      <br />
                      {this.state.errorInfo.componentStack}
                  </details>

                  <a href={window.location.origin + '/login'} className='btn bg-green light-text'>Take me Home</a>
              </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;